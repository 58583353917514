import React, {useState} from "react";
import {
    Row,
    Col,
    Button,
    Card,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
    UncontrolledTooltip,
    Spinner,
    CustomInput
} from "reactstrap";
import ApplicationForRegistrationSurvey from "../forms/ApplicationForRegistrationSurvey";
import DeclarationOfOwnership from "../forms/DeclarationOfOwnership";
import RegistrationSurveyAttachments from "../forms/formAttachments/RegistrationSurveyAttachments";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import ApplicationForRegistrationSurveyPrint from "../prints/ApplicationForRegistrationSurveyPrint";
import DeclarationOfOwnershipPrint from "../prints/DeclarationOfOwnershipPrint";
import CertificateOfSurveyPrint from "../prints/CertificateOfSurveyPrint";
import DeclarationOfSurveyPrint from "../prints/DeclarationOfSurveyPrint";
import CertificateOfRegistryPrint from "../prints/CertificateOfRegistryPrint";
import Modals from "../../../common/Modals";
import RejectReasonModal from "./subComponents/RejectReasonModal";

type TypeChecking = {
    userProfile: {},
    approvers: {},
    formFieldSource: ApplicationFormFieldsSource,
    onSave: (updatedVessel: ApplicationFormFieldsSource) => void,
    vesselId: string,
    formsToBeFilledMode: boolean,
    saveVessel: () => void,
    updateVessel: () => void,
    submitVessel: () => void,
    fileUpload: () => void,
    fileDelete: () => void,
    fileDownload: () => void,
    approveStage: () => void,
    rejectStage: () => void,
};

const FormsToBeFilled = ({userProfile, approvers, formFieldSource, onSave, vesselId, allData, formsToBeFilledMode, saveVessel, updateVessel, submitVessel, fileUpload, fileDelete, fileDownload, approveStage, rejectStage}: TypeChecking) => {

    const [formOne, setFormOne] = useState(true);
    const [formTwo, setFormTwo] = useState(false);
    const [formAttachments, setFormAttachments] = useState(false);

    const placeRadios = ["KAKINADA", "MACHILIPATNAM"];

    const formButtons = [
        {
            name: "Application for Registration / Survey",
            color: "secondary",
            id: "registrationSurvey",
            disable: false,
            onClick: () => {
                setFormOne(true);
                setFormTwo(false);
                setFormAttachments(false);
            }
        },
        {
            name: "Declaration of Ownership",
            color: "secondary",
            id: "ownership",
            disable: false,
            onClick: () => {
                setFormTwo(true);
                setFormOne(false);
                setFormAttachments(false);
            }
        },
        {
            name: "Enclosures / Attachments",
            color: "secondary",
            id: "enclosures",
            disable: false,
            onClick: vesselId ?
                () => {
                    setFormAttachments(true);
                    setFormTwo(false);
                    setFormOne(false);
                }
                :
                () => {
                }
        }
    ];

    const printFormButtons = [
        {
            name: "Print Forms ",
            color: "info",
        }
    ];

    const operationButtons = [
        {
            name: vesselId ? "Update" : "Save",
            color: "info",
            type: "button",
            id: "saveUpdate",
            onClick: vesselId ?
                () => updateVessel()
                :
                formFieldSource.registration_or_survey.vessel_registration_place.length !== 0 ?
                    () => saveVessel()
                    :
                    () => {
                    }
        },
        {
            name: "Submit to Port",
            color: "info",
            type: vesselId ? "submit" : "button",
            id: "submit",
            onClick: vesselId ?
                () => submitVessel()
                :
                () => {
                }
        }
    ];

    const handlePlace = radio => event => formFieldSource.registration_or_survey.setVessel_registration_place(radio);

    formFieldSource.subscribeForStateChange(formFieldValues => {
        onSave(formFieldValues);
    });

    return (
        <div>
            <form
                className="needs-validation"
                noValidate
                id="myForm"
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <Row>
                    {/*These are application form buttons with rejection reasons button*/}
                    <Card className="mx-3 mb-3 col-9 justify-content-center align-content-center" style={{height: 200}}>
                        <div className="text-center">
                            <Row className="my-3">
                                <Col>
                                    {
                                        formFieldSource.registration_or_survey.vessel_registration_place.length !== 0 && vesselId ?
                                            <div className="h-100" style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                <h2>{formFieldSource.registration_or_survey.vessel_registration_place}</h2>
                                                <>
                                                    {
                                                        allData !== undefined ?
                                                            allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.rejection_reason.length !== 0 ?
                                                                <div className="my-3">
                                                                    <Modals
                                                                        name={"REJECTION REASON"}
                                                                        header={"Here are the reasons why your application has been rejected"}
                                                                        reasons={allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.rejection_reason}
                                                                    />
                                                                </div>
                                                                : null
                                                            : null
                                                    }
                                                </>
                                            </div>
                                            :
                                            placeRadios && placeRadios.map((r, index) => {
                                                return (
                                                    <Row key={index} style={{width: 260, margin: "32px 0px 32px 100px"}}>
                                                        <CustomInput id={index}
                                                                     onClick={handlePlace(r)}
                                                                     type="radio"
                                                                     name="settings"/>
                                                        {r}
                                                    </Row>
                                                )
                                            })
                                    }
                                </Col>
                                <Col>
                                    {formButtons.map((button, index) => (
                                        <React.Fragment key={index}>
                                            <Button key={index} id={button.id} color={button.color}
                                                    disabled={button.disable}
                                                    onClick={button.onClick}
                                                    style={{width: 260}}
                                                    className="m-2">
                                                {button.name}
                                            </Button>
                                            {!vesselId ?
                                                <UncontrolledTooltip
                                                    placement={"bottom"}
                                                    target={"enclosures"}>
                                                    Attachments can be accessed when file is saved first
                                                </UncontrolledTooltip>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                    ))}
                                </Col>
                            </Row>
                        </div>
                    </Card>

                    <Card className="mr-3 mb-3 col justify-content-center align-content-center">
                        {/*These are the approve and reject buttons also approved text*/}
                        {
                            userProfile.userType === "agency-admin" ?
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by.length !== 0 ?
                                        <h4 className="text-center">
                                            <span style={{color: "#0e7a18"}}>Approved By</span>
                                            <br/>
                                            <span
                                                style={{color: "#0e7a18"}}>{allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by[0].user.full_name}</span>
                                        </h4>
                                        :
                                        formsToBeFilledMode === false ?
                                            operationButtons.map((button, index) => (
                                                <React.Fragment key={index}>
                                                    <Button key={index} id={button.id} color={button.color}
                                                            type={button.type}
                                                            onClick={button.onClick}
                                                            className="mt-1 mb-1">
                                                        {button.name}
                                                    </Button>
                                                    {!vesselId ?
                                                        <UncontrolledTooltip
                                                            placement={"bottom"}
                                                            target={"submit"}>
                                                            Save the file first before Submitting
                                                        </UncontrolledTooltip>
                                                        :
                                                        null
                                                    }
                                                </React.Fragment>
                                            ))
                                            : null
                                    :
                                    operationButtons.map((button, index) => (
                                        <React.Fragment key={index}>
                                            <Button key={index} id={button.id} color={button.color}
                                                    type={button.type}
                                                    onClick={button.onClick}
                                                    className="mt-1 mb-1">
                                                {button.name}
                                            </Button>
                                            {!vesselId ?
                                                formFieldSource.registration_or_survey.vessel_registration_place.length !== 0 ?
                                                    <UncontrolledTooltip
                                                        placement={"bottom"}
                                                        target={"submit"}>
                                                        Save the file first before Submitting
                                                    </UncontrolledTooltip>
                                                    :
                                                    <UncontrolledTooltip
                                                        placement={"bottom"}
                                                        target={"saveUpdate"}>
                                                        Select a place before saving
                                                    </UncontrolledTooltip>
                                                :
                                                null
                                            }
                                        </React.Fragment>
                                    ))
                                : null
                        }
                        {userProfile.userType === "port-operator" ?
                            <div>
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by.length !== 0 ?
                                            <h4 className="text-center">
                                                <span style={{color: "#0e7a18"}}>Approved By</span>
                                                <br/>
                                                <span
                                                    style={{color: "#0e7a18"}}>{allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by[0].user.full_name}</span>
                                            </h4>
                                            :
                                            allData.vesselActivity.application_status === "rejected" ?
                                                <h4 className="text-center">
                                                    <span style={{color: "#ff010f"}}>Rejected</span>
                                                    <br/>
                                                    Waiting for Resubmission
                                                </h4>
                                                :
                                                <div>
                                                    <h4 className="text-center">{userProfile.name}</h4>
                                                    <React.Fragment key={"one"}>
                                                        <Button key={"approveOne"} id={"approve"} color={"success"}
                                                                type={"button"}
                                                                onClick={() => approvers.stage_forms_to_be_filled.includes(userProfile.id) ?
                                                                    approveStage(userProfile, "stage_forms_to_be_filled", "")
                                                                    :
                                                                    () => {
                                                                    }}
                                                                className="mt-1 mb-1 w-100">
                                                            Approve
                                                        </Button>
                                                        {!approvers.stage_forms_to_be_filled.includes(userProfile.id) ?
                                                            <UncontrolledTooltip
                                                                placement={"bottom"}
                                                                target={"approve"}>
                                                                You are not authorized to Approve or Reject
                                                            </UncontrolledTooltip>
                                                            : null}
                                                    </React.Fragment>
                                                    <React.Fragment key={"two"}>
                                                        <RejectReasonModal
                                                            name={"Reject"}
                                                            header={"Rejection Reason Entry"}
                                                            subTitle={"Please enter the reason below"}
                                                            stage={"stage_forms_to_be_filled"}
                                                            approvers={approvers.stage_forms_to_be_filled}
                                                            id={userProfile.id}
                                                            rejectStage={approvers.stage_forms_to_be_filled.includes(userProfile.id) ? rejectStage : () => {
                                                            }}
                                                        />
                                                        {!approvers.stage_forms_to_be_filled.includes(userProfile.id) ?
                                                            <UncontrolledTooltip
                                                                placement={"bottom"}
                                                                target={"reject"}>
                                                                You are not authorized to Approve or Reject
                                                            </UncontrolledTooltip>
                                                            : null}
                                                    </React.Fragment>
                                                </div>
                                        : null
                                }
                            </div>
                            : null
                        }

                        {/*These are print form buttons*/}
                        {vesselId ?
                            printFormButtons.map((button, index) => (
                                <UncontrolledButtonDropdown key={index} className="mt-1 mb-1">
                                    <DropdownToggle caret color={button.color}>
                                        {button.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {
                                            allData ?
                                                <ApplicationForRegistrationSurveyPrint
                                                    formFieldSource={allData}/>
                                                :
                                                <Spinner color="success" className="mr-2"/>
                                        }
                                        {
                                            allData ?
                                                <DeclarationOfOwnershipPrint
                                                    formFieldSource={allData}/>
                                                :
                                                <Spinner color="success" className="mr-2"/>
                                        }
                                        {
                                            allData !== undefined ?
                                                allData.vesselActivity.close_call === true ?
                                                    <>
                                                        {
                                                            allData ?
                                                                <CertificateOfSurveyPrint
                                                                    formFieldSource={allData}/>
                                                                :
                                                                <Spinner color="success" className="mr-2"/>
                                                        }
                                                        {
                                                            allData ?
                                                                <DeclarationOfSurveyPrint
                                                                    formFieldSource={allData}/>
                                                                :
                                                                <Spinner color="success" className="mr-2"/>
                                                        }
                                                        {
                                                            allData ?
                                                                <CertificateOfRegistryPrint
                                                                    formFieldSource={allData}/>
                                                                :
                                                                <Spinner color="success" className="mr-2"/>
                                                        }
                                                    </>
                                                    :
                                                    null
                                                :
                                                null
                                        }
                                    </DropdownMenu>
                                </UncontrolledButtonDropdown>
                            )) : null
                        }

                    </Card>
                </Row>

                <Card>
                    {
                        formOne ?
                            <ApplicationForRegistrationSurvey formFieldSource={formFieldSource}
                                                              formsToBeFilledMode={formsToBeFilledMode}
                                                              userProfile={userProfile}
                                                              allData={allData}/>
                            :
                            formTwo ?
                                <DeclarationOfOwnership formFieldSource={formFieldSource}
                                                        formsToBeFilledMode={formsToBeFilledMode}
                                                        userProfile={userProfile}
                                                        allData={allData}/>
                                :
                                <RegistrationSurveyAttachments formsToBeFilledMode={formsToBeFilledMode}
                                                               userProfile={userProfile}
                                                               allData={allData}
                                                               fileUploadOne={(file) => {
                                                                   fileUpload(file)
                                                               }}
                                                               fileDeleteOne={(file) => {
                                                                   fileDelete(file)
                                                               }}
                                                               fileDownloadOne={(file) => {
                                                                   fileDownload(file)
                                                               }}/>
                    }
                </Card>
            </form>
            <script>
                (function() {
                window.addEventListener('load', function () {
                    let forms = document.getElementsByClassName('needs-validation');
                    let validation = Array.prototype.filter.call(forms, function (form) {
                        form.addEventListener('submit', function (event) {
                            if (form.checkValidity() === false) {
                                event.preventDefault();
                                event.stopPropagation();
                            } else {
                                // submitVessel();
                            }
                            form.classList.add('was-validated');
                        }, false);
                    });
                }, false)
            })();
            </script>
        </div>
    )
};

export default FormsToBeFilled;
