import React, { useEffect, useState } from "react";
import { Row, Col, Button, Card, CardBody, CardHeader, Container, Input, Media } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import paginationFactory from "react-bootstrap-table2-paginator";

import { AlertTriangle, XCircle, Info } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlusCircle
} from "@fortawesome/free-solid-svg-icons";

import routesHelper from "../../routes/routes.helper";
// Redux
import { connect } from 'react-redux';
import { FetchVessels, CountVessels, SearchVessel, FetchExpiredVessels, FetchExpiringVessels } from '../../redux/actions/vesselActions';
import SetAxiosAuthHeaders from "../../config/SetAxiosAuthHeaders";

import { EMPTY_COUNT } from "../../redux/actions/ActionTypesAllModules";

const { SearchBar } = Search;

const columns = [
    {
        dataField: "name",
        text: "Name",
        sort: true
    },
    {
        dataField: "Owner.name",
        text: "Owner Name",
        sort: true
    },
    {
        dataField: "agency.name",
        text: "Agency Name",
        sort: true
    },
    {
        dataField: "registration_number",
        text: "Registration Number",
        sort: true
    },
    {
        dataField: "breadth",
        text: "breadth",
        sort: true
    },
    {
        dataField: "depth",
        text: "depth",
        sort: true
    },
    {
        dataField: "length",
        text: "length",
        sort: true
    },
    {
        dataField: "vessel_type",
        text: "Vessel type",
        sort: true
    }
];


const VesselsList = (props) => {

    //searching
    const [searchText, setSearchText] = useState("");
    const [searchColumn, setSearchColumn] = useState("name");

    //pagination
    const [currentPage, setCurrentPage] = useState(1);
    const [offSet, setOffSet] = useState(0);
    const [limit, setLimit] = useState(5);
    const [count, setCount] = useState(0);

    useEffect(() => {
        SetAxiosAuthHeaders();
    }, []);

    useEffect(() => {
        props.dispatch(CountVessels());
        props.dispatch(FetchVessels());
        props.dispatch(FetchExpiredVessels());
        props.dispatch(FetchExpiringVessels());
        return () => {
            props.dispatch({ type: EMPTY_COUNT, payload: null });
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCount(Number(props.counts));
    }, [props.counts]);

    useEffect(() => {
        if (searchText !== "") {
            props.dispatch(SearchVessel(searchColumn, searchText));
        } else {
            props.dispatch(FetchVessels());
        }
        // eslint-disable-next-line
    }, [searchColumn, searchText]);

    /*     const searchServerSide = (column, text) => {
            useEffect(()=>{
                if(text){
                    props.dispatch(SearchVessel(column, text));
                }
            },[column, text]);

        }; */

    const getUserProfile = () => {
        if (JSON.parse(localStorage.getItem('userProfile')) &&
            JSON.parse(localStorage.getItem('userProfile')).employerType) {
            return JSON.parse(localStorage.getItem('userProfile')).employerType;
        } else {
            return null;
        }
    }

    const handleTableChange = (type, { page, sizePerPage, searchText, sortField, sortOrder, data, }) => {
        console.log("table changed!");
        console.log(searchText);
        //make search calls
        //searchServerSide(searchColumn ,searchText);
        setSearchText(searchText);
        if (sortOrder === "asc") {
            console.log(sortField);
            //setSearchColumn(sortField);
        }
        console.log(page);
        const currentIndex = (page - 1) * limit;
        setOffSet(currentIndex);
        setCurrentPage(page);
        //totalSize = count;
        console.log(pagination);
    };

    const handleDataChange = () => {
        console.log("data changed!");
        //setCount(Number(props.counts));
    }

    const SearchColumnDropDown = () => (
        <Input
            type="select"
            id="searchColumnName"
            name="searchColumnName"
            className="mb-3"
            value={searchColumn}
            onChange={e => setSearchColumn(e.target.value)}
        >
            {columns.map(option => (
                <option key={option.dataField} value={option.dataField}>
                    {option.text}
                </option>
            ))}
        </Input>
    );

    const handleOnSelect = (row, isSelect, rowIndex) => {
        if (isSelect) {
            //console.log(isSelect);
            console.log(row);
            //send this data to vessel activity view
            props.history.push(
                '/vessels/activityView',
                row
            );
        }
    };

    const pagination = paginationFactory({
        sizePerPage: 5,
        sizePerPageList: [5, 10, 25, 50],
    });

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: handleOnSelect
    };

    return (

        <Container fluid className="p-0">
            <Row>
                <Col md="9" sm="9">
                    <h1 className="h3 mb-3">Vessels</h1>
                </Col>
                <Col md="3" sm="3">
                    <Button color="primary" className="mr-1 mb-1" style={{ marginLeft: `48%`, display: getUserProfile() === "port" ? `none` : `inline-block` }} onClick={() => routesHelper.vesselForm.create.go()}><FontAwesomeIcon icon={faPlusCircle} /> New Vessel</Button>
                </Col>
            </Row>
            {getUserProfile() === "port" ? (<Row>
                <Col md="3">
                    <Card className="flex-fill" >
                        <CardBody className="py-4">
                            <Media>
                                <div className="d-inline-block mt-2 mr-3">
                                    <Info className="feather-lg text-primary" />
                                </div>
                                <Media body>
                                    <h3 className="mb-2">{props.counts ? props.counts : "0"}</h3>
                                    <div className="mb-0">Total Vessels</div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="flex-fill" onClick={() => props.dispatch(FetchExpiringVessels(...Array(4), "loadData"))}>
                        <CardBody className="py-4">
                            <Media>
                                <div className="d-inline-block mt-2 mr-3">
                                    <AlertTriangle className="feather-lg text-warning" />
                                </div>
                                <Media body>
                                    <h3 className="mb-2">{props.expiringVesselsCount ? props.expiringVesselsCount : "0"}</h3>
                                    <div className="mb-0">Expiring Vessels (in 10 days)</div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="3">
                    <Card className="flex-fill" onClick={() => props.dispatch(FetchExpiredVessels(...Array(4), "loadData"))} >
                        <CardBody className="py-4">
                            <Media>
                                <div className="d-inline-block mt-2 mr-3">
                                    <XCircle className="feather-lg text-danger" />
                                </div>
                                <Media body>
                                    <h3 className="mb-2">{props.expiredVesselsCount ? props.expiredVesselsCount : "0"}</h3>
                                    <div className="mb-0">Expired Vessels</div>
                                </Media>
                            </Media>
                        </CardBody>
                    </Card>
                </Col>

                <Col md="3" sm="3">
                    <Button color="secondary" className="mr-1 mb-1" style={{ marginLeft: `48%`, display: getUserProfile() === "port" ? `inline-block` : 'none' }} onClick={() => props.dispatch(FetchVessels())}>Reset Filter</Button>
                </Col>
            </Row>) : null}

            <Card>
                <CardHeader>

                </CardHeader>
                <CardBody>
                    <ToolkitProvider
                        keyField="id"
                        data={props.vessels}
                        columns={columns}
                        search
                    >{
                            props => (
                                <>
                                    <Row>
                                        <Col md="2">
                                            <SearchColumnDropDown />
                                        </Col>
                                        <Col md="2">
                                            <SearchBar {...props.searchProps} value={searchText}
                                                onChange={e => {
                                                    setSearchText(e.target.value);
                                                }} />
                                        </Col>
                                    </Row>

                                    <BootstrapTable
                                        {...props.baseProps}
                                        keyField="id"
                                        bootstrap4
                                        remote={{ search: true }}
                                        onTableChange={handleTableChange}
                                        onDataSizeChange={handleDataChange}
                                        wrapperClasses="table-responsive"
                                        bordered={false}
                                        pagination={pagination}
                                        selectRow={selectRow}
                                    />
                                </>
                            )
                        }

                    </ToolkitProvider>
                </CardBody>
            </Card>
        </Container>
    );
};

const mapStateToProps = ({ vessels, status, counts, expiredVesselsCount, expiringVesselsCount }) => ({ vessels, status, counts, expiredVesselsCount, expiringVesselsCount });

const mapDispatchToProps = dispatch => ({ FetchVessels, CountVessels, SearchVessel, FetchExpiredVessels, FetchExpiringVessels, dispatch });

export default connect(
    mapStateToProps,
    mapDispatchToProps)
    (VesselsList);
