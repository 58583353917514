import React from "react";
import Vessel from "../Vessel";
import { FormGroup, Label, Input } from "reactstrap";
import { AccomodationItem } from "../models/Vessel.accomodation";
type VesselAccomodationFormType = {
    /**
     * ApplicationFormFieldsSource Class
     */
    vessel: Vessel;
	mode: "Create" | "View" | "Edit" | "EditViaForm";
	onSave: (updateVessel: Vessel) => void;
};
const VesselAccomodationForm: React.FC<VesselAccomodationFormType> = ({
    vessel,
    mode
}: VesselAccomodationFormType) => {
    const isViewMode = mode === "View";
    const { accomodation } = vessel;
    return (
        <div className="row">
            <div className="col-6 mt-3 mb-3">
                <h3>Escape_routes</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.escape_routes}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Washrooms</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.washrooms}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Toilets</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.toilets}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Galley</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.galley}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Messrooms</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.messrooms}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Water tanks</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.water_tanks}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Heating cooling ventilation</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.heating_cooling_ventilation}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Berth</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.berth}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Access</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.access}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Lighting</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.lighting}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Machinery guards</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.machinery_guards}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Guard rails</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.guard_rails}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Non slip surfaces</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.non_slip_surfaces}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Ladders</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.ladders}
                />
            </div>
            <div className="col-6 mt-3 mb-3">
                <h3>Noise insulation</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.noise_insulation}
                />
            </div>
            <div className="col-6 mt-3 mb-4">
                <h3>Floam flotation material</h3>
                <AccomodationFormItem
                    isViewMode={isViewMode}
                    accomodationItem={accomodation.floam_flotation_material}
                />
            </div>
        </div>
    );
};
export default VesselAccomodationForm;

type accomodationFormItemType = {
    accomodationItem: AccomodationItem;
    isViewMode: boolean;
};
const AccomodationFormItem = ({
    accomodationItem,
    isViewMode
}: accomodationFormItemType) => {
    return (
        <div className="row">
            <div className="col-6">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={accomodationItem.condition}
                        onChange={e =>
                            accomodationItem.setField(
                                "condition",
                                e.target.value
                            )
                        }
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-6">
                <FormGroup>
                    <Label>Comment</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={accomodationItem.comment}
                        onChange={e =>
                            accomodationItem.setField("comment", e.target.value)
                        }
                        placeholder="Comment"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
