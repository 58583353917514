import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { formRulesType } from "../Vessel.form.helper";
import Vessel from "../Vessel";

type VesselBasicFormType = {
    vessel: Vessel,
    requiredFields: formRulesType,
    mode: "Create" | "View" | "Edit" | "EditViaForm",
    onSave: (updatedVessel: Vessel) => void,
};

const VesselBasicForm: React.FC<VesselBasicFormType> = ({
    vessel: vesselState,
    onSave,
    mode,
    requiredFields: fields,
}: VesselBasicFormType) => {

    const isViewMode = mode === "View";
    const { Owner, agency } = vesselState;
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label for="nameOfVessel">Name of ApplicationFormFieldsSource</Label>
                    <Input
                        required={fields.name}
                        type="text"
                        name="name"
                        disabled={isViewMode}
                        value={vesselState.name}
                        onChange={(e) => vesselState.setName(e.target.value)}
                        placeholder="Name of ApplicationFormFieldsSource"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Owner Name</Label>
                    <Input
                        required={fields.Owner.name}
                        disabled={isViewMode}
                        type="text"
                        value={Owner.name}
                        onChange={(e) => Owner.setName(e.target.value)}
                        placeholder="Owner Name"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Owner Address</Label>
                    <Input
                        required={fields.Owner.address}
                        disabled={isViewMode}
                        type="text"
                        value={Owner.address}
                        onChange={(e) => Owner.setAddress(e.target.value)}
                        placeholder="Owner Address"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Owner Telephone</Label>
                    <Input
                        required={fields.Owner.telephone_no}
                        disabled={isViewMode}
                        type="text"
                        value={Owner.telephone_no}
                        onChange={(e) => Owner.setTelephone_no(e.target.value)}
                        placeholder="Owner Telephone"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Agency Name</Label>
                    <Input
                        required={fields.agency.name}
                        disabled={isViewMode}
                        type="text"
                        value={agency.name}
                        onChange={(e) => agency.setName(e.target.value)}
                        placeholder="Agency Name"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Agency Address</Label>
                    <Input
                        required={fields.agency.address}
                        disabled={isViewMode}
                        type="text"
                        value={agency.address}
                        onChange={(e) => agency.setAddress(e.target.value)}
                        placeholder="Agency Address"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Agency Telephone</Label>
                    <Input
                        required={fields.agency.telephone_no}
                        disabled={isViewMode}
                        type="number"
                        value={agency.telephone_no}
                        onChange={(e) => agency.setTelephone_no(e.target.value)}
                        placeholder="Agency Telephone"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Length(mtr)</Label>
                    <Input
                        required={fields.length}
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.length}
                        onChange={(e) => vesselState.setLength(e.target.value)}
                        placeholder="Length(mtr)"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Breadth(mtr)</Label>
                    <Input
                        required={fields.breadth}
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.breadth}
                        onChange={(e) => vesselState.setBreadth(e.target.value)}
                        placeholder="Breadth(mtr)"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Depth(mtr)</Label>
                    <Input
                        required={fields.depth}
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.depth}
                        onChange={(e) => vesselState.setDepth(e.target.value)}
                        placeholder="Depth(mtr)"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>ApplicationFormFieldsSource Type</Label>
                    <Input
                        required={fields.vessel_type}
                        disabled={isViewMode}
                        type="select"
                        name="state"
                        value={vesselState.vessel_type}
                        onChange={(e) =>
                            vesselState.setVessel_type(e.target.value)
                        }
                    >
                        <option value="Passenger">Passenger</option>
                        <option value="Cargo cum Passenger">
                            Cargo cum Passenger
                        </option>
                        <option value="Chemical Carrier">
                            Chemical Carrier
                        </option>
                        <option value="Liguid Carrier">Liguid Carrier</option>
                        <option value="Cargo">Cargo</option>
                        <option value="Tanker">Tanker</option>
                        <option value="bunker barge">bunker barge</option>
                        <option value="utility boat">utility boat</option>
                        <option value="tourism/passenger boat">
                            tourism/passenger boat
                        </option>
                        <option value="dredger">dredger</option>
                        <option value="jackup barge">jackup barge</option>
                        <option value="flat top barge">flat top barge</option>
                        <option value="pilot launch">pilot launch</option>
                        <option value="mooring launch">mooring launch</option>
                        <option value="water barge">water barge</option>
                        <option value="supt barge">supt barge</option>
                        <option value="house boat">house boat</option>
                        <option value="dumb hopper barge">
                            dumb hopper barge
                        </option>
                        <option value="tugs">tugs</option>
                    </Input>
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Area of Operation</Label>
                    <Input
                        required={fields.area_of_operation}
                        disabled={isViewMode}
                        type="select"
                        name="as"
                        value={vesselState.area_of_operation}
                        onChange={(e) =>
                            vesselState.setArea_of_operation(e.target.value)
                        }
                    >
                        <option value="Zone 1">Zone 1</option>
                        <option value="Zone 2">Zone 2</option>
                        <option value="Zone 3">Zone 3</option>
                    </Input>
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Official ApplicationFormFieldsSource Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.official_no_of_vessel}
                        onChange={(e) =>
                            vesselState.setOfficial_no_of_vessel(e.target.value)
                        }
                        placeholder="Official ApplicationFormFieldsSource Number"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Official Port Registry</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={vesselState.port_registry_of_vessel}
                        onChange={(e) =>
                            vesselState.setPort_registry_of_vessel(
                                e.target.value
                            )
                        }
                        placeholder="Official Port Registry"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Tonnage Registered</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.tonnage_registered}
                        onChange={(e) =>
                            vesselState.setTonnage_registered(e.target.value)
                        }
                        placeholder="Tonnage Registered"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Tonnage Gross</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={vesselState.tonnage_gross}
                        onChange={(e) =>
                            vesselState.setTonnage_gross(e.target.value)
                        }
                        placeholder="Tonnage Registered"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Category</Label>
                    <Input
                        disabled={isViewMode}
                        type="select"
                        name="state"
                        value={vesselState.category}
                        onChange={(e) =>
                            vesselState.setCategory(e.target.value)
                        }
                    >
                        <option value="Category A">Category A</option>
                        <option value="Category B">Category B</option>
                    </Input>
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Place of Last Survey</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={vesselState.place_last_survey}
                        onChange={(e) =>
                            vesselState.setPlace_last_survey(e.target.value)
                        }
                        placeholder="Place of Last Survey"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Date of Last Survey</Label>
                    <Input
                        disabled={isViewMode}
                        type="date"
                        value={vesselState.date_last_survey}
                        onChange={(e) =>
                            vesselState.setDate_last_survey(e.target.value)
                        }
                        placeholder="Date of Last Survey"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Place of Proposed Visit</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={vesselState.place_of_proposed_visit}
                        onChange={(e) =>
                            vesselState.setPlace_of_proposed_visit(
                                e.target.value
                            )
                        }
                        placeholder="Place of Proposed Visit"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3 ">
                <FormGroup>
                    <Label>Date of Proposed Visit</Label>
                    <Input
                        disabled={isViewMode}
                        type="date"
                        value={vesselState.date_of_proposed_visit}
                        onChange={(e) =>
                            vesselState.setDate_of_proposed_visit(
                                e.target.value
                            )
                        }
                        placeholder="Date of Proposed Visit"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default VesselBasicForm;

/**
 *
 */
