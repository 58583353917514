import React, {useEffect, useState} from 'react';
import {Container, Row, Col} from "reactstrap";
import CardsRow from "./subComponents/CardsRow";
import RegistrationsBarChart from "./subComponents/RegistrationsBarChart";
// import UpcomingSurveys from "./subComponents/UpcomingSurveys";
import {connect} from "react-redux";
import {
    CountDashboardBelowVessels,
    DashboardBelowVesselsRegistrationsPerMonth,
    // DashboardBelowVesselsUpcomingSurveys,
    ClearCountBelowVessel,
    ClearRegCountBelowVessel,
} from "../../../redux/actions/Below12Actions/BelowVesselActions";

const DashboardComponent = ({belowVesselCount, belowVesselRegCount, dispatch}) => {

    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('userProfile')));

    useEffect(() => {
        dispatch(CountDashboardBelowVessels(userProfile));
        if (userProfile.userType === "port-operator") {
            dispatch(DashboardBelowVesselsRegistrationsPerMonth(userProfile));
        }
        // dispatch(DashboardBelowVesselsUpcomingSurveys(userProfile));

        return function cleanUpCount() {
            dispatch(ClearCountBelowVessel());
            //dispatch(ClearRegCountBelowVessel());
        }
    }, []);

    return (
        <Container fluid className="p-0">
            <CardsRow
                dashboardBelowVesselsCount={belowVesselCount}
            />
            {
                userProfile.userType === "port-operator" ?
                    <Row>
                        <Col lg="12" className="d-flex">
                            <RegistrationsBarChart dashboardBelowVesselRegCount={belowVesselRegCount}/>
                        </Col>
                        {/*<Col lg="6" className="d-flex">*/}
                        {/*    <UpcomingSurveys/>*/}
                        {/*</Col>*/}
                    </Row>
                    :
                    null
            }
        </Container>
    );
};

const mapStateToProps = ({belowVesselCount, belowVesselRegCount}) => ({
    belowVesselCount,
    belowVesselRegCount
});

const mapDispatchToProps = (dispatch) => ({
    CountDashboardBelowVessels,
    DashboardBelowVesselsRegistrationsPerMonth,
    // DashboardBelowVesselsUpcomingSurveys,
    ClearCountBelowVessel,
    ClearRegCountBelowVessel,
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardComponent);
