import axios from 'axios';
import Path from "../../../config/Paths";
import history from "../../../routes/history";
import RoutesHelper from "../../../routes/routes.helper";
import {
    SAVE_BELOW_VESSEL,
    UPDATE_BELOW_VESSEL,
    SUBMIT_BELOW_VESSEL,
    FETCH_BELOW_VESSEL,
    COUNT_BElOW_VESSEL,
    REG_COUNT_BElOW_VESSEL,
    FETCH_ALL_BELOW_VESSEL,
    //CLOSE_BELOW_VESSEL,
    CLEAR_BELOW_VESSEL,
    CLEAR_COUNT_BELOW_VESSEL,
    CLEAR_REG_COUNT_BELOW_VESSEL,
    CLEAR_ALL_BELOW_VESSEL,
    FETCH_BELOW_VESSEL_APPROVERS,
    FETCH_BELOW_VESSEL_USER,
} from '../ActionTypes/b12ActionTypes';
import * as status from '../ActionTypes/statusActionTypes';
import {AUTH_TIMEOUT} from "../ActionTypes/commonActionTypes";

const logout = (dispatch) => {
    dispatch({type: CLEAR_BELOW_VESSEL, payload: {}});
    localStorage.removeItem("user");
    history.push("/auth/sign-in/");
};

// Approvers related actions

export const StageWiseApprovers = (vesselId) => {

    return (dispatch) => {
        const config = {
            params: {
                filter: {
                    where: {vesselId: vesselId},
                    fields: {stages_info: true}
                }
            },
        };
        axios.get(Path.below12Api.FetchBelowVesselActivity(), config)
            .then(response => {
                let stages = response.data[0].stages_info.stages;
                let stageWiseApprovers = {};
                Object.entries(stages).map(each => {
                    stageWiseApprovers[each[0]] = each[1].approvers.map(item => {
                        return item.user.id;
                    });
                });
                dispatch({type: FETCH_BELOW_VESSEL_APPROVERS, payload: stageWiseApprovers});
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "SOMETHING WENT WRONG!",
                        message: 'Refresh page to try again',
                        variant: 'warning'
                    },
                })
            }
        })
    }
};

// User related actions

export const GetUserDetails = (vesselId) => {
    return (dispatch) => {
        const config = {
            params: {
                filter: {
                    fields: {created_by_details: true}
                }
            },
        };
        axios.get(Path.below12Api.FetchBelowVessel(vesselId), config)
            .then(response => {
                dispatch({type: FETCH_BELOW_VESSEL_USER, payload: response.data.created_by_details});
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "SOMETHING WENT WRONG!",
                        message: 'Refresh page to try again',
                        variant: 'warning'
                    },
                })
            }
        })
    }
};

// Vessel related actions

export const SaveBelowVesselAndActivity = values => {

    delete values.setStateChange;
    delete values.file_uploads;
    values.id = JSON.parse(localStorage.getItem('userProfile')).id;

    let activity = {
        id: JSON.parse(localStorage.getItem('userProfile')).id,
        application_no: "",
        application_status: "saved",
        active_status: true,
        close_call: false,
        stages_info: {
            current_stage: "stage_forms_to_be_filled",
            rejected: false,
            stages: {
                stage_forms_to_be_filled: {
                    status: "",
                    stage_status: "saved",
                    rejection_reason: [],
                    approvers: [],
                    enclosures: [],
                    approved_by: []
                },
                stage_survey_fee: {
                    status: "",
                    stage_status: "",
                    notify_payment: false,
                    payment_done: false,
                    payment_success: false,
                    invoice_number: "",
                    approvers: [],
                    approved_by: []
                },
                stage_survey_date: {
                    status: "",
                    stage_status: "",
                    survey_date: null,
                    confirmed_survey_date: null,
                    request_another_survey_date: false,
                    survey_date_confirmation: false,
                    approvers: [],
                    approved_by: []
                },
                stage_survey_status: {
                    status: "",
                    stage_status: "",
                    survey_status: "",
                    registration_number: "",
                    details_of_tests_carried_out: [],
                    limitations_of_operations: "",
                    remarks: "",
                    request_survey_again: false,
                    rejection_reason: [],
                    approvers: [],
                    approved_by: []
                },
                stage_note_file: {
                    status: "",
                    stage_status: "",
                    note_file: {
                        amount: "",
                        gst: "",
                        dated: "",
                        receipt: "",
                        receiptDate: ""
                    },
                    approvers: [],
                    approved_by: []
                },
                stage_registration_fee: {
                    status: "",
                    stage_status: "",
                    notify_payment: false,
                    payment_done: false,
                    payment_success: false,
                    invoice_number: "",
                    approvers: [],
                    approved_by: []
                },
                stage_certificate_issue: {
                    status: "",
                    stage_status: "",
                    issue_certificate: false,
                    approvers: [],
                    approved_by: []
                }
            }
        },
        cancel_call: false,
        vessel_registration_place: values.registration_or_survey.vessel_registration_place,
        send_mail_to: "",
        send_mail: false
    };

    return (dispatch) => {
        axios
            .post(Path.below12Api.SaveBelowVessel(), values)
            .then(response => {
                if (response.data.id.length !== 0) {
                    activity.vesselId = response.data.id;
                    axios.post(Path.below12Api.CreateBelowVesselActivity(), activity)
                        .then(activityResponse => {
                            RoutesHelper.below.registrationSurvey.edit.replace(response.data.id);
                            response.data.vesselActivity = {...activityResponse.data};
                            dispatch({type: SAVE_BELOW_VESSEL, payload: response.data});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {title: "SAVED", message: "Vessel Saved Successfully", variant: 'success'},
                            });
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout(dispatch);
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.FAILED,
                                payload: {
                                    title: "FAILED",
                                    message: 'Something went Wrong! Try again',
                                    variant: 'warning'
                                },
                            })
                        }
                    })
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "ERROR",
                        message: 'Vessel Saving Error! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const UpdateBelowVessel = (values, vesselId) => {

    return (dispatch) => {
        axios
            .patch(Path.below12Api.UpdateBelowVessel(vesselId), values)
            .then(response => {
                dispatch({type: UPDATE_BELOW_VESSEL, payload: response.data});
                dispatch({
                    type: status.SUCCESS,
                    payload: {title: "UPDATED", message: "Vessel Updated Successfully", variant: 'success'},
                });
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Vessel Updating Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const SubmitBelowVessel = (values, vesselId) => {

    let vesselActivity = values.vesselActivity;
    let vesselActivityId = values.vesselActivity.id;
    vesselActivity.send_mail = true;
    vesselActivity.send_mail_to = "port";
    delete values.vesselActivity.id;
    delete values.vesselActivity;

    return (dispatch) => {
        axios
            .patch(Path.below12Api.SubmitBelowVessel(vesselId), values)
            .then(response => {
                if (response.status === 200) {
                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(vesselActivityId), vesselActivity)
                        .then(activityResponse => {
                            response.data.vesselActivity = {...activityResponse.data};
                            dispatch({type: SUBMIT_BELOW_VESSEL, payload: response.data});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {
                                    title: "SUBMITTED",
                                    message: "Vessel Submitted Successfully",
                                    variant: 'success'
                                },
                            });
                            // setTimeout(function () {
                            //     RoutesHelper.below.registrationSurvey.list.replace();
                            // }, 5000);
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout(dispatch);
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'Something went Wrong! Try again',
                                    variant: 'warning'
                                },
                            })
                        }
                    })
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Vessel Submission Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const FetchBelowVessel = vesselId => {

    return (dispatch) => {
        axios
            .get(Path.below12Api.FetchBelowVessel(vesselId))
            .then(response => {
                if (response.data.id === vesselId) {
                    const config = {
                        params: {
                            filter: {
                                where: {vesselId: response.data.id},
                            },
                        },
                    };
                    axios.get(Path.below12Api.FetchBelowVesselActivity(), config)
                        .then(activityResponse => {
                            response.data.vesselActivity = {...activityResponse.data[0]};
                            dispatch({type: FETCH_BELOW_VESSEL, payload: response.data});
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            dispatch({type: CLEAR_BELOW_VESSEL, payload: {}});
                            logout(dispatch);
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'Vessel fetching Failed! Try again',
                                    variant: 'warning'
                                },
                            })
                        }
                    })
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                dispatch({type: CLEAR_BELOW_VESSEL, payload: {}});
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "Error",
                        message: 'Vessel fetching Error! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const CountDashboardBelowVessels = (userProfile) => {

    let total;
    let underProcess;
    let finished;
    let cancelled;
    let countResult = {};

    if (userProfile.userType === "agency-admin") {

        total = {
            params: {where: {created_by: userProfile.id}}
        };

        underProcess = {
            params: {
                where: {
                    or: [
                        {and: [{created_by: userProfile.id}, {application_status: "submitted"}]},
                        {and: [{created_by: userProfile.id}, {application_status: "rejected"}]}
                    ]
                }
            }
        };

        finished = {
            params: {where: {and: [{created_by: userProfile.id}, {application_status: "approved"}]}}
        };

        cancelled = {
            params: {where: {and: [{created_by: userProfile.id}, {application_status: "cancelled"}]}}
        };
    }

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {

        total = {
            params: {
                where: {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
            }
        };

        underProcess = {
            params: {
                where: {or: [{application_status: "submitted"}, {application_status: "rejected"}]}
            }
        };

        finished = {
            params: {
                where: {or: [{application_status: "approved"}]}
            }
        };

        cancelled = {
            params: {
                where: {or: [{application_status: "cancelled"}]}
            }
        };
    }

    return (dispatch) => {

        Promise.all([
            axios.get(Path.below12Api.CountBelowVessels(), total),
            axios.get(Path.below12Api.CountBelowVessels(), underProcess),
            axios.get(Path.below12Api.CountBelowVessels(), finished),
            axios.get(Path.below12Api.CountBelowVessels(), cancelled)
        ]).then(response => {
            countResult.total = response[0].data.count;
            countResult.underProcess = response[1].data.count;
            countResult.finished = response[2].data.count;
            countResult.cancelled = response[3].data.count;
        }).then(() => {
            dispatch({type: COUNT_BElOW_VESSEL, payload: countResult});
        }).catch((error) => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Fetching Vessel Count Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        });
    }
};

export const DashboardBelowVesselsRegistrationsPerMonth = (userProfile) => {

    let date = new Date(new Date().getFullYear(), 0, 1);
    let datesArray;
    let registrationsPerMonthResult = [];

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {

        datesArray = [
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth(), 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 1, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 2, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 2, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 3, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 3, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 4, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 4, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 5, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 5, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 6, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 6, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 7, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 7, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 8, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 8, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 9, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 9, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 10, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 10, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 11, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
            {
                params: {
                    where: {
                        and: [
                            {and: [{createdAt: {gt: new Date(date.getFullYear(), date.getMonth() + 11, 1).toISOString()}}, {createdAt: {lt: new Date(date.getFullYear(), date.getMonth() + 12, 0).toISOString()}}]},
                            {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                        ]
                    }
                }
            },
        ];
    }

    return (dispatch) => {

        datesArray.map((each, index) => {
            axios.get(Path.below12Api.CountBelowVessels(), each)
                .then(response => {
                    registrationsPerMonthResult[index] = response.data.count;
                    if (datesArray.length === index + 1) {
                        dispatch({type: REG_COUNT_BElOW_VESSEL, payload: registrationsPerMonthResult});
                    }
                }).catch((error) => {
                if (error.response && error.response.status === 403) {
                    logout(dispatch);
                }
                if (error.response && error.response.status === 401) {
                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                } else {
                    dispatch({
                        type: status.ERROR,
                        payload: {
                            title: "FAILED",
                            message: 'Fetching Monthly Registrations Failed! Try again',
                            variant: 'error'
                        },
                    })
                }
            })
        })
    }
};

// export const DashboardBelowVesselsUpcomingSurveys = (userProfile) => {
//
//     let config;
//     let date = new Date();
//     let upcomingSurveysArray;
//
//     if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {
//
//         let stitchDate = "stages_info.stages.stage_survey_date.survey_date";
//         let stitchConfirmation = "stages_info.stages.stage_survey_date.survey_date_confirmation";
//         let from = {[stitchDate]: {gt: new Date(date).toISOString()}};
//         let to = {[stitchDate]: {lt: new Date(date.setDate(date.getDate() + 10)).toISOString()}};
//         let check = {[stitchConfirmation]: true};
//
//         config = {
//             params: {
//                 where: {
//                     and: [
//                         {and: [from, to]},
//                         {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
//                     ]
//                 }
//             }
//         }
//     }
//
//     return (dispatch) => {
//         axios.get(Path.below12Api.FetchBelowVesselActivity(), config)
//             .then(activityResponse => {
//                 console.log("1");
//                 if (activityResponse.status === 200) {
//                     console.log("2");
//                     activityResponse.data.map((each, index) => {
//                         console.log("3");
//                         axios.get(Path.below12Api.FetchBelowVessel(each.vesselId))
//                             .then(response => {
//                                 if (response.status === 200) {
//                                     upcomingSurveysArray[index] = {
//                                         name_of_the_craft: response.data.registration_or_survey.name_of_the_craft,
//                                         survey_date: each.stages_info.stages.stage_survey_date.survey_date,
//                                         phone: response.data.created_by_details.phone
//                                     };
//                                     console.log(upcomingSurveysArray);
//                                     if (activityResponse.data.length === index + 1) {
//                                         console.log(upcomingSurveysArray);
//                                     }
//                                 }
//                             }).catch((error) => {
//                             if (error.response && error.response.status === 403) {
//                                 logout(dispatch);
//                             }
//                             if (error.response && error.response.status === 401) {
//                                 dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
//                             } else {
//                                 dispatch({
//                                     type: status.ERROR,
//                                     payload: {
//                                         title: "FAILED",
//                                         message: 'Fetching Upcoming Surveys Failed! Try again',
//                                         variant: 'error'
//                                     },
//                                 })
//                             }
//                         })
//                     })
//                 }
//             }).catch((error) => {
//             if (error.response && error.response.status === 403) {
//                 logout(dispatch);
//             }
//             if (error.response && error.response.status === 401) {
//                 dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
//             } else {
//                 dispatch({
//                     type: status.ERROR,
//                     payload: {
//                         title: "FAILED",
//                         message: 'Fetching Upcoming Surveys Failed! Try again',
//                         variant: 'error'
//                     },
//                 })
//             }
//         })
//     }
// };

export const CountBelowVessels = (userProfile) => {

    let config;

    if (userProfile.userType === "agency-admin") {
        config = {
            params: {
                where: {created_by: userProfile.id}
            },
        };
    }

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {
        config = {
            params: {
                where: {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
            },
        };
    }

    return (dispatch) => {
        axios
            .get(Path.below12Api.CountBelowVessels(), config)
            .then(response => {
                dispatch({type: COUNT_BElOW_VESSEL, payload: response.data});
            }).catch((error) => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Fetching Vessel Count Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const FetchAllBelowVessels = (userProfile, offset, limit, column = "createdAt", order = "DESC") => {

    let config;

    if (userProfile.userType === "agency-admin") {
        config = {
            params: {
                filter: {
                    offset: offset,
                    limit: limit,
                    order: `${column} ${order}`,
                    where: {created_by: userProfile.id}
                },
            },
        };
    }

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {
        config = {
            params: {
                filter: {
                    offset: offset,
                    limit: limit,
                    order: `${column} ${order}`,
                    where: {or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}
                },
            },
        };
    }

    return (dispatch) => {
        axios
            .get(Path.below12Api.FetchAllBelowVessels(offset, limit), config)
            .then(response => {
                if (response.status === 200) {
                    response.data.map((each, index) => {
                        if (each.id.length !== 0) {
                            let config = {
                                params: {
                                    filter: {
                                        where: {vesselId: each.id},
                                    },
                                },
                            };
                            axios.get(Path.below12Api.FetchBelowVesselActivity(), config)
                                .then(activityResponse => {
                                    if (activityResponse.status === 200) {
                                        if (activityResponse.data.id !== 0 && activityResponse.data[0].vesselId === each.id) {
                                            each.vesselActivity = {...activityResponse.data}
                                        }
                                    }
                                    if (activityResponse.status === 200) {
                                        if (response.data.length === index + 1) {
                                            dispatch({type: FETCH_ALL_BELOW_VESSEL, payload: response.data});
                                        }
                                    }
                                }).catch(error => {
                                if (error.response && error.response.status === 403) {
                                    dispatch({type: CLEAR_ALL_BELOW_VESSEL, payload: {}});
                                    logout(dispatch);
                                }
                                if (error.response && error.response.status === 401) {
                                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                                } else {
                                    dispatch({
                                        type: status.ERROR,
                                        payload: {
                                            title: "FAILED",
                                            message: 'All Vessels fetching Failed! Try again',
                                            variant: 'error'
                                        },
                                    });
                                }
                            })
                        }
                    });
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                dispatch({type: CLEAR_ALL_BELOW_VESSEL, payload: {}});
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'All Vessels fetching Failed! Try again',
                        variant: 'error'
                    },
                });
            }
        })
    }
};

export const SearchCountBelowVessels = (userProfile, column, searchFor) => {

    let config;

    if (userProfile.userType === "agency-admin") {

        let stitch = column === "application_status" ? column : "registration_or_survey." + column;
        let temp = {[stitch]: {like: searchFor}};

        config = {
            params: {
                where: {
                    and: [{created_by: userProfile.id}, temp]
                }
            },
        };
    }

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {

        let stitch = column === "application_status" ? column : "registration_or_survey." + column;
        let temp = {[stitch]: {like: searchFor}};

        config = {
            params: {
                where: {
                    and: [{or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}, temp]
                }
            },
        };
    }

    return (dispatch) => {
        axios
            .get(Path.below12Api.CountBelowVessels(), config)
            .then(response => {
                console.log(response.data);
                dispatch({type: COUNT_BElOW_VESSEL, payload: response.data});
            }).catch((error) => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Fetching Vessel Count Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const SearchFetchAllBelowVessels = (userProfile, offset, limit, column, searchFor, orderColumn = "createdAt", order = "DESC") => {

    let config;

    if (userProfile.userType === "agency-admin") {

        let stitch = column === "application_status" ? column : "registration_or_survey." + column;
        let temp = {[stitch]: {like: searchFor}};

        config = {
            params: {
                filter: {
                    offset: offset,
                    limit: limit,
                    order: `${orderColumn} ${order}`,
                    where: {and: [{created_by: userProfile.id}, temp]}
                },
            },
        };
    }

    if (userProfile.userType === "port-operator" || userProfile.userType === "port-admin") {

        let stitch = column === "application_status" ? column : "registration_or_survey." + column;
        let temp = {[stitch]: {like: searchFor}};

        config = {
            params: {
                filter: {
                    offset: offset,
                    limit: limit,
                    order: `${orderColumn} ${order}`,
                    where: {
                        and: [{or: [{application_status: "submitted"}, {application_status: "approved"}, {application_status: "rejected"}]}, temp]
                    }
                },
            },
        };
    }

    return (dispatch) => {
        axios
            .get(Path.below12Api.FetchAllBelowVessels(offset, limit), config)
            .then(response => {
                if (response.status === 200) {
                    response.data.map((each, index) => {
                        if (each.id.length !== 0) {
                            let config = {
                                params: {
                                    filter: {
                                        where: {vesselId: each.id},
                                    },
                                },
                            };
                            axios.get(Path.below12Api.FetchBelowVesselActivity(), config)
                                .then(activityResponse => {
                                    if (activityResponse.status === 200) {
                                        if (activityResponse.data.id !== 0 && activityResponse.data[0].vesselId === each.id) {
                                            each.vesselActivity = {...activityResponse.data}
                                        }
                                    }
                                    if (activityResponse.status === 200) {
                                        if (response.data.length === index + 1) {
                                            console.log(response.data);
                                            dispatch({type: FETCH_ALL_BELOW_VESSEL, payload: response.data});
                                        }
                                    }
                                }).catch(error => {
                                if (error.response && error.response.status === 403) {
                                    logout(dispatch);
                                }
                                if (error.response && error.response.status === 401) {
                                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                                } else {
                                    dispatch({
                                        type: status.ERROR,
                                        payload: {
                                            title: "FAILED",
                                            message: 'All Vessels fetching Failed! Try again',
                                            variant: 'error'
                                        },
                                    });
                                }
                            })
                        }
                    });
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout(dispatch);
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'All Vessels fetching Failed! Try again',
                        variant: 'error'
                    },
                });
            }
        })
    }
};

export const ClearBelowVessel = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_BELOW_VESSEL, payload: {}});
    }
};

export const ClearCountBelowVessel = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_COUNT_BELOW_VESSEL, payload: {}});
    }
};

export const ClearRegCountBelowVessel = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_REG_COUNT_BELOW_VESSEL, payload: {}});
    }
};

export const ClearAllBelowVessels = () => {
    return (dispatch) => {
        dispatch({type: CLEAR_ALL_BELOW_VESSEL, payload: {}});
    }
};

// Vessel Payment related actions

// export const FetchPaymentStatusOfVessel = (activityId, allData) => {
//
//     console.log("In Payment");
//     let vessel = allData;
//
//     return (dispatch) => {
//         axios.get(Path.below12Api.FetchPaymentStatusOfVessel(activityId))
//             .then(activityResponse => {
//                 vessel.vesselActivity = {...activityResponse.data};
//                 dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
//             }).catch(error => {
//             if (error.response && error.response.status === 403) {
//                 logout(dispatch);
//             }
//             if (error.response && error.response.status === 401) {
//                 dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
//             } else {
//                 dispatch({
//                     type: status.ERROR,
//                     payload: {
//                         title: "FAILED",
//                         message: 'Something went Wrong! Refresh page',
//                         variant: 'warning'
//                     },
//                 })
//             }
//         });
//     }
// };

// Vessel approval actions

export const ApproveBelowVesselStage = (userProfile, stage, data, allData) => {

    let approvedBy = {
        user: {
            id: userProfile.id,
            full_name: userProfile.name,
            user_type: userProfile.userType,
            employer_type: userProfile.employerType,
        },
        timeStamp: new Date().toISOString()
    };
    let vesselId = allData.id;
    let activityId = allData.vesselActivity.id;
    let vessel = allData;
    let activity = allData.vesselActivity;

    delete vessel.id;
    delete vessel.created_by;
    delete vessel.vesselActivity;
    delete activity.id;

    return (dispatch) => {
        switch (stage) {
            case "stage_forms_to_be_filled":
                activity.send_mail = true;
                activity.send_mail_to = "agency";
                vessel.application_status = "submitted";
                activity.application_status = "submitted";
                activity.stages_info.current_stage = "stage_survey_fee";
                activity.stages_info.stages.stage_forms_to_be_filled.status = "approved";
                activity.stages_info.stages.stage_forms_to_be_filled.approved_by.push(approvedBy);
                axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                    .then(activityResponse => {
                        vessel.vesselActivity = {...activityResponse.data};
                        dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                        dispatch({
                            type: status.SUCCESS,
                            payload: {
                                title: "APPROVED",
                                message: "Vessel Approved Successfully",
                                variant: 'success'
                            },
                        });
                    }).catch(error => {
                    if (error.response && error.response.status === 403) {
                        logout(dispatch);
                    }
                    if (error.response && error.response.status === 401) {
                        dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                    } else {
                        dispatch({
                            type: status.ERROR,
                            payload: {
                                title: "FAILED",
                                message: 'Something went Wrong! Try again',
                                variant: 'warning'
                            },
                        })
                    }
                });
                break;
            case "stage_survey_fee":
                if (userProfile.userType === "port-operator") {
                    if (data.type === "notify-payment") {
                        activity.send_mail = true;
                        activity.send_mail_to = "agency";
                        activity.stages_info.stages.stage_survey_fee.stage_status = "submitted";
                        activity.stages_info.stages.stage_survey_fee.notify_payment = true;
                        activity.stages_info.stages.stage_survey_fee.invoice_number = data.invoiceNo;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "NOTIFIED",
                                        message: "Payment Notified Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    } else if (data.type === "payment-successful") {
                        activity.send_mail = true;
                        activity.send_mail_to = "agency";
                        activity.stages_info.current_stage = "stage_survey_date";
                        activity.stages_info.stages.stage_survey_fee.status = "approved";
                        activity.stages_info.stages.stage_survey_fee.payment_success = true;
                        activity.stages_info.stages.stage_survey_fee.approved_by.push(approvedBy);
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "PAYMENT RECEIVED",
                                        message: "Payment received Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                } else if (userProfile.userType === "agency-admin") {
                    if (data.type === "payment-done") {
                        activity.send_mail = false;
                        activity.stages_info.stages.stage_survey_fee.payment_done = true;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "PAYMENT DONE",
                                        message: "Payment done Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                }
                break;
            case "stage_survey_date":
                if (userProfile.userType === "port-operator") {
                    if (data.type === "issue-date") {
                        activity.send_mail_to = "agency";
                        activity.send_mail = true;
                        activity.stages_info.current_stage = "stage_survey_date";
                        activity.stages_info.stages.stage_survey_date.status = "";
                        activity.stages_info.stages.stage_survey_date.survey_date = data.date;
                        activity.stages_info.stages.stage_survey_date.stage_status = "submitted";
                        //Added
                        activity.stages_info.stages.stage_survey_date.request_another_survey_date = false;
                        activity.stages_info.stages.stage_survey_date.approved_by = [];
                        activity.stages_info.stages.stage_survey_status.request_survey_again = false;
                        //Added
                        activity.stages_info.stages.stage_survey_date.approved_by.push(approvedBy);
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "DATE ISSUED",
                                        message: "Survey date issued Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                } else if (userProfile.userType === "agency-admin") {
                    if (data.type === "confirm-date") {
                        activity.send_mail = true;
                        activity.send_mail_to = "port";
                        activity.stages_info.current_stage = "stage_survey_status";
                        activity.stages_info.stages.stage_survey_date.status = "approved";
                        activity.stages_info.stages.stage_survey_date.confirmed_survey_date = data.date;
                        activity.stages_info.stages.stage_survey_date.survey_date_confirmation = true;
                        activity.stages_info.stages.stage_survey_date.request_another_survey_date = false;
                        activity.stages_info.stages.stage_survey_status.request_survey_again = false;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "DATE CONFIRMED",
                                        message: "Survey date confirmed Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    } else if (data.type === "request-date") {
                        activity.stages_info.stages.stage_survey_date.request_another_survey_date = true;
                        //activity.stages_info.stages.stage_survey_date.survey_date = null;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "DATE REQUEST SENT",
                                        message: "Survey date request sent Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                }
                break;
            case "stage_survey_status":
                if (userProfile.userType === "port-operator") {
                    if (data.type === "success") {
                        activity.send_mail = true;
                        activity.send_mail_to = "agency";
                        vessel.registration_or_survey.registration_number_of_the_craft = data.registrationNo;
                        activity.stages_info.current_stage = "stage_note_file";
                        activity.stages_info.stages.stage_survey_status.status = "approved";
                        activity.stages_info.stages.stage_survey_status.stage_status = "submitted";
                        activity.stages_info.stages.stage_survey_status.survey_status = "success";
                        activity.stages_info.stages.stage_survey_status.registration_number = data.registrationNo;
                        activity.stages_info.stages.stage_survey_status.details_of_tests_carried_out = [...data.tests];
                        activity.stages_info.stages.stage_survey_status.limitations_of_operations = data.limits;
                        activity.stages_info.stages.stage_survey_status.remarks = data.remarks;
                        //activity.stages_info.stages.stage_survey_status.request_survey_again = false;
                        activity.stages_info.stages.stage_survey_status.approved_by.push(approvedBy);
                        axios.patch(Path.below12Api.UpdateBelowVessel(vesselId), vessel)
                            .then(response => {
                                if (response.status === 200) {
                                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                                        .then(activityResponse => {
                                            vessel.vesselActivity = {...activityResponse.data};
                                            dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                            dispatch({
                                                type: status.SUCCESS,
                                                payload: {
                                                    title: "SURVEY SUCCESS",
                                                    message: "Vessel Surveyed Successfully",
                                                    variant: 'success'
                                                },
                                            });
                                        }).catch(error => {
                                        if (error.response && error.response.status === 403) {
                                            logout(dispatch);
                                        }
                                        if (error.response && error.response.status === 401) {
                                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                                        } else {
                                            dispatch({
                                                type: status.ERROR,
                                                payload: {
                                                    title: "FAILED",
                                                    message: 'Something went Wrong! Try again',
                                                    variant: 'warning'
                                                },
                                            })
                                        }
                                    });
                                }
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                }
                break;
            case "stage_note_file":
                if (data.type === "note-save") {
                    activity.send_mail = false;
                    activity.stages_info.current_stage = "stage_registration_fee";
                    activity.stages_info.stages.stage_note_file.status = "approved";
                    activity.stages_info.stages.stage_note_file.stage_status = "submitted";
                    activity.stages_info.stages.stage_note_file.note_file.noteContent = data.noteContent;
                    activity.stages_info.stages.stage_note_file.approved_by = [];
                    activity.stages_info.stages.stage_note_file.approved_by.push(approvedBy);
                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                        .then(activityResponse => {
                            vessel.vesselActivity = {...activityResponse.data};
                            dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {
                                    title: "SAVED",
                                    message: "Note File Saved Successfully",
                                    variant: 'success'
                                },
                            });
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout(dispatch);
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'Something went Wrong! Try again',
                                    variant: 'warning'
                                },
                            })
                        }
                    });
                } else if (data.type === "note-update") {
                    activity.stages_info.stages.stage_note_file.note_file.noteContent = data.noteContent;
                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                        .then(activityResponse => {
                            vessel.vesselActivity = {...activityResponse.data};
                            dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {
                                    title: "UPDATED",
                                    message: "Note File Updated Successfully",
                                    variant: 'success'
                                },
                            });
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout(dispatch);
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'Something went Wrong! Try again',
                                    variant: 'warning'
                                },
                            })
                        }
                    });
                }
                break;
            case "stage_registration_fee":
                if (userProfile.userType === "port-operator") {
                    if (data.type === "notify-payment") {
                        activity.send_mail = true;
                        activity.send_mail_to = "agency";
                        activity.stages_info.stages.stage_registration_fee.stage_status = "submitted";
                        activity.stages_info.stages.stage_registration_fee.notify_payment = true;
                        activity.stages_info.stages.stage_registration_fee.invoice_number = data.invoiceNo;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "NOTIFIED",
                                        message: "Payment Notified Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    } else if (data.type === "payment-successful") {
                        activity.send_mail = true;
                        activity.send_mail_to = "agency";
                        activity.stages_info.current_stage = "stage_certificate_issue";
                        activity.stages_info.stages.stage_registration_fee.status = "approved";
                        activity.stages_info.stages.stage_registration_fee.payment_success = true;
                        activity.stages_info.stages.stage_registration_fee.approved_by.push(approvedBy);
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "PAYMENT RECEIVED",
                                        message: "Payment received Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                } else if (userProfile.userType === "agency-admin") {
                    if (data.type === "payment-done") {
                        activity.send_mail = false;
                        activity.stages_info.stages.stage_registration_fee.payment_done = true;
                        axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                            .then(activityResponse => {
                                vessel.vesselActivity = {...activityResponse.data};
                                dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                dispatch({
                                    type: status.SUCCESS,
                                    payload: {
                                        title: "PAYMENT DONE",
                                        message: "Payment done Successfully",
                                        variant: 'success'
                                    },
                                });
                            }).catch(error => {
                            if (error.response && error.response.status === 403) {
                                logout(dispatch);
                            }
                            if (error.response && error.response.status === 401) {
                                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                            } else {
                                dispatch({
                                    type: status.ERROR,
                                    payload: {
                                        title: "FAILED",
                                        message: 'Something went Wrong! Try again',
                                        variant: 'warning'
                                    },
                                })
                            }
                        });
                    }
                }
                break;
            case "stage_certificate_issue":
                activity.send_mail = true;
                activity.send_mail_to = "agency";
                vessel.application_status = "approved";
                activity.application_status = "approved";
                activity.stages_info.stages.stage_certificate_issue.status = "approved";
                activity.stages_info.stages.stage_certificate_issue.stage_status = "submitted";
                activity.stages_info.stages.stage_certificate_issue.issue_certificate = true;
                activity.close_call = true;
                activity.stages_info.stages.stage_certificate_issue.approved_by.push(approvedBy);
                axios.patch(Path.below12Api.UpdateBelowVessel(vesselId), vessel)
                    .then(response => {
                        if (response.status === 200) {
                            axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                                .then(activityResponse => {
                                    vessel.vesselActivity = {...activityResponse.data};
                                    dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                                    dispatch({
                                        type: status.SUCCESS,
                                        payload: {
                                            title: "ISSUED",
                                            message: "Certificates Issued Successfully",
                                            variant: 'success'
                                        },
                                    });
                                }).catch(error => {
                                if (error.response && error.response.status === 403) {
                                    logout(dispatch);
                                }
                                if (error.response && error.response.status === 401) {
                                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                                } else {
                                    dispatch({
                                        type: status.ERROR,
                                        payload: {
                                            title: "FAILED",
                                            message: 'Something went Wrong! Try again',
                                            variant: 'warning'
                                        },
                                    })
                                }
                            });
                        }
                    }).catch(error => {
                    if (error.response && error.response.status === 403) {
                        logout(dispatch);
                    }
                    if (error.response && error.response.status === 401) {
                        dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                    } else {
                        dispatch({
                            type: status.ERROR,
                            payload: {
                                title: "FAILED",
                                message: 'Something went Wrong! Try again',
                                variant: 'warning'
                            },
                        })
                    }
                });
                break;
            default:
            //Do Nothing
        }
    }
};

export const RejectBelowVesselStage = (stage, rejectionReason, allData) => {

    let vesselId = allData.id;
    let activityId = allData.vesselActivity.id;
    let vessel = allData;
    let activity = allData.vesselActivity;

    delete vessel.id;
    delete vessel.created_by;
    delete vessel.vesselActivity;
    delete activity.id;

    return (dispatch) => {
        switch (stage) {
            case "stage_forms_to_be_filled":
                activity.send_mail = true;
                activity.send_mail_to = "agency";
                vessel.application_status = "rejected";
                activity.application_status = "rejected";
                activity.stages_info.stages.stage_forms_to_be_filled.status = "rejected";
                activity.stages_info.stages.stage_forms_to_be_filled.stage_status = "saved";
                activity.stages_info.stages.stage_forms_to_be_filled.rejection_reason.push({reason: rejectionReason});
                axios.patch(Path.below12Api.UpdateBelowVessel(vesselId), vessel)
                    .then(response => {
                        if (response.status === 200) {
                            axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                                .then(activityResponse => {
                                    response.vesselActivity = {...activityResponse.data};
                                    dispatch({type: UPDATE_BELOW_VESSEL, payload: response});
                                    dispatch({
                                        type: status.SUCCESS,
                                        payload: {
                                            title: "REJECTED",
                                            message: "Vessel Rejected Successfully",
                                            variant: 'success'
                                        },
                                    });
                                }).catch(error => {
                                if (error.response && error.response.status === 403) {
                                    logout(dispatch);
                                }
                                if (error.response && error.response.status === 401) {
                                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                                } else {
                                    dispatch({
                                        type: status.ERROR,
                                        payload: {
                                            title: "FAILED",
                                            message: 'Something went Wrong! Try again',
                                            variant: 'warning'
                                        },
                                    })
                                }
                            });
                        }
                    }).catch(error => {
                    if (error.response && error.response.status === 403) {
                        logout(dispatch);
                    }
                    if (error.response && error.response.status === 401) {
                        dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                    } else {
                        dispatch({
                            type: status.ERROR,
                            payload: {
                                title: "FAILED",
                                message: 'Something went Wrong! Try again',
                                variant: 'warning'
                            },
                        })
                    }
                });
                break;
            case "stage_survey_fee":
                break;
            case "stage_survey_date":
                break;
            case "stage_survey_status":
                activity.stages_info.stages.stage_survey_date.survey_date_confirmation = false;
                //activity.stages_info.stages.stage_survey_date.survey_date = null;
                //Added
                activity.stages_info.stages.stage_survey_date.request_another_survey_date = false;
                //Added
                activity.stages_info.stages.stage_survey_status.survey_status = "rejected";
                activity.stages_info.stages.stage_survey_status.request_survey_again = true;
                activity.stages_info.stages.stage_survey_status.rejection_reason.push({reason: rejectionReason});
                axios.patch(Path.below12Api.UpdateBelowVesselActivity(activityId), activity)
                    .then(activityResponse => {
                        vessel.vesselActivity = {...activityResponse.data};
                        dispatch({type: UPDATE_BELOW_VESSEL, payload: vessel});
                        dispatch({
                            type: status.SUCCESS,
                            payload: {
                                title: "REJECTED",
                                message: "Vessel Rejected Successfully",
                                variant: 'success'
                            },
                        });
                    }).catch(error => {
                    if (error.response && error.response.status === 403) {
                        logout(dispatch);
                    }
                    if (error.response && error.response.status === 401) {
                        dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                    } else {
                        dispatch({
                            type: status.ERROR,
                            payload: {
                                title: "FAILED",
                                message: 'Something went Wrong! Try again',
                                variant: 'warning'
                            },
                        })
                    }
                });
                break;
            case "stage_note_file":
                break;
            case "stage_registration_fee":
                break;
            case "stage_certificate_issue":
                break;
            default:
            //Do Nothing
        }
    }
};

