import React, {useState} from 'react';
//import {Eye as ViewIcon} from "react-feather";
import {Card, CardBody, Button, Progress, Input, InputGroup, InputGroupAddon, CustomInput} from "reactstrap";
import routesHelper from '../../../../routes/routes.helper';

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import {MinusCircle, PlusCircle} from "react-feather";

const BelowVesselList = ({actionRequiredList, belowVesselCount, allBelowVessel, page, sizePerPage, column, searchFor, sendPage, sendOffset, sendLimit, sendColumn, sendSearchFor, searchData}) => {

    const [expanded, setExpanded] = useState([]);

    const tableColumns = [
        {
            dataField: "name_of_the_craft",
            text: "Craft Name",
            sort: true
        },
        {
            dataField: "firm_name",
            text: "Firm Name",
            sort: true
        },
        {
            dataField: "type_of_the_craft",
            text: "Craft Type",
            sort: true
        },
        {
            dataField: "registration_number_of_the_craft",
            text: "Reg. No",
            sort: true
        },
        {
            dataField: "passenger_capacity",
            text: "Capacity",
            sort: true
        },
        {
            dataField: "vessel_registration_place",
            text: "Place of Reg",
            sort: true
        },
        {
            dataField: "application_status",
            text: "Status",
            sort: true
        },
        {
            dataField: "view_button",
            text: "",
            sort: false
        },
    ];

    const view = (id, currentStep, status) => {
        switch (currentStep) {
            case "stage_forms_to_be_filled":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 1);
                break;
            case "stage_survey_fee":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 2);
                break;
            case "stage_survey_date":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 3);
                break;
            case "stage_survey_status":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 4);
                break;
            case "stage_note_file":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 5);
                break;
            case "stage_registration_fee":
                routesHelper.below.registrationSurvey.edit.goToStep(id, 6);
                break;
            case "stage_certificate_issue":
                status === "approved" ?
                    routesHelper.below.registrationSurvey.edit.goToStep(id, 1)
                    :
                    routesHelper.below.registrationSurvey.edit.goToStep(id, 7);
                break;
            default:
                return;
        }
    };

    let tableData = allBelowVessel.map(each => ({
        id: each.id,
        name_of_the_craft: each.registration_or_survey.name_of_the_craft,
        firm_name: each.registration_or_survey.firm_name,
        type_of_the_craft: each.registration_or_survey.type_of_the_craft,
        registration_number_of_the_craft:
            each.registration_or_survey.registration_number_of_the_craft !== "" ?
                each.registration_or_survey.registration_number_of_the_craft
                :
                "NYA"
        ,
        passenger_capacity: each.registration_or_survey.passenger_capacity,
        vessel_registration_place: each.registration_or_survey.vessel_registration_place,
        application_status: each.application_status,
        // view_button: <Button onClick={() => routesHelper.below.registrationSurvey.edit.go(each.id)}><ViewIcon/></Button>
        view_button: <Button className="w-100"
                             onClick={() => view(each.id, each.vesselActivity[0].stages_info.current_stage, each.vesselActivity[0].application_status)}>VIEW</Button>
    }));

    const handleOnExpand = (row, isExpand) => {
        if (isExpand) {
            setExpanded([row.id]);
        }
    };

    const expandRow = {
        onlyOneExpanding: true,
        renderer: row => (
            allBelowVessel.map(each =>
                each.id === expanded[0] ?
                    each.vesselActivity[0].stages_info.current_stage === "stage_forms_to_be_filled" && each.vesselActivity[0].application_status === "saved" ?
                        (<div><Progress bar value="16">Forms Saved</Progress></div>)
                        :
                        each.vesselActivity[0].stages_info.current_stage === "stage_forms_to_be_filled" && each.vesselActivity[0].application_status === "submitted" ?
                            (<div><Progress bar value="16">Forms Submitted</Progress></div>)
                            :
                            each.vesselActivity[0].stages_info.current_stage === "stage_survey_fee" ?
                                (<div><Progress bar value="32">Survey Fee</Progress></div>)
                                :
                                each.vesselActivity[0].stages_info.current_stage === "stage_survey_date" ?
                                    (<div><Progress bar value="32">Survey Date</Progress></div>)
                                    :
                                    each.vesselActivity[0].stages_info.current_stage === "stage_survey_status" ?
                                        (<div><Progress bar value="48">Survey Status</Progress></div>)
                                        :
                                        each.vesselActivity[0].stages_info.current_stage === "stage_note_file" ?
                                            (<div><Progress bar value="64">Note File</Progress></div>)
                                            :
                                            each.vesselActivity[0].stages_info.current_stage === "stage_registration_fee" ?
                                                (<div><Progress bar value="80">Registration Fee</Progress></div>)
                                                :
                                                each.vesselActivity[0].stages_info.current_stage === "stage_certificate_issue" && each.vesselActivity[0].application_status === "approved" ?
                                                    (<div><Progress bar value="100">Successfully Finished</Progress>
                                                    </div>)
                                                    :
                                                    each.vesselActivity[0].stages_info.current_stage === "stage_certificate_issue" ?
                                                        (<div><Progress bar value="100">Certificate Issue</Progress>
                                                        </div>)
                                                        :
                                                        each.vesselActivity[0].application_status === "rejected" ?
                                                            (<div><Progress bar value="100" color="danger">Rejected</Progress></div>)
                                                            :
                                                            (<div><Progress bar value="100">Updating...</Progress></div>)
                    :
                    null
            )
        ),
        expanded: expanded,
        onExpand: handleOnExpand,
        expandHeaderColumnRenderer: ({isAnyExpands}) =>
            isAnyExpands ? (
                <MinusCircle width={16} height={16}/>
            ) : (
                <PlusCircle width={16} height={16}/>
            ),
        expandColumnRenderer: ({expanded}) =>
            expanded ? (
                <MinusCircle width={16} height={16}/>
            ) : (
                <PlusCircle width={16} height={16}/>
            )
    };

    const actionRequired = (row) => {
        const style = {};
        if (actionRequiredList.includes(row.id)) {
            style.backgroundColor = '#00BFFF';
            style.fontWeight = 'bold';
            style.color = 'white';
        }

        return style;
    };

    const options = {
        page: page,
        sizePerPage: sizePerPage,
        sizePerPageList: [5, 10, 25, 50],
        hidePageListOnlyOnePage: true,
        totalSize: belowVesselCount,
        onSizePerPageChange: (sizePerPage) => {
            sendLimit(sizePerPage);
        },
        onPageChange: (page, sizePerPage) => {
            sendPage(page);
            sendOffset(sizePerPage * (page - 1));
        },

    };

    return (
        <Card>
            <CardBody>
                <InputGroup className="mb-3">
                    <CustomInput type="select" id="exampleCustomSelect" name="customSelect"
                                 value={column} onChange={(e) => sendColumn(e.target.value)}>
                        <option value="">Select Column...</option>
                        {
                            tableColumns.map(each =>
                                <option key={each.dataField} value={each.dataField}>{each.text}</option>
                            )
                        }
                    </CustomInput>
                    <Input placeholder="Search for..." value={searchFor}
                           onChange={(e) => sendSearchFor(e.target.value)}/>
                    <InputGroupAddon addonType="append" color="primary">
                        <Button onClick={() => searchData()}>SEARCH</Button>
                    </InputGroupAddon>
                </InputGroup>
                <BootstrapTable
                    remote
                    bootstrap4
                    bordered={true}
                    keyField="id"
                    data={tableData}
                    columns={tableColumns}
                    expandRow={expandRow}
                    rowStyle={actionRequired}
                    pagination={paginationFactory(options)}
                />
            </CardBody>
        </Card>
    );
};

export default BelowVesselList;
