import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { HosesFittingNozzles } from "../Vessel.Fireappliance";
type HosesFormType = {
    hose: HosesFittingNozzles;
    isViewMode: boolean;
};
const HosesForm: React.FC<HosesFormType> = ({
    hose,
    isViewMode
}: HosesFormType) => {
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.number}
                        onChange={e => hose.setField("number", e.target.value)}
                        placeholder="Number"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>No_of_hose</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.no_of_hose}
                        onChange={e =>
                            hose.setField("no_of_hose", e.target.value)
                        }
                        placeholder="No_of_hose"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.type}
                        onChange={e => hose.setField("type", e.target.value)}
                        placeholder="Type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.condition}
                        onChange={e =>
                            hose.setField("condition", e.target.value)
                        }
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.location}
                        onChange={e =>
                            hose.setField("location", e.target.value)
                        }
                        placeholder="Location"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Nozzles</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.nozzles}
                        onChange={e => hose.setField("nozzles", e.target.value)}
                        placeholder="Nozzles"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Other_equipment</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hose.other_equipment}
                        onChange={e =>
                            hose.setField("other_equipment", e.target.value)
                        }
                        placeholder="Other_equipment"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default HosesForm;
