import {
	LifeBoyEquipmentType,
} from "../Vessel.form.types";
type LifeBoyItemType = {
	number?: number;
	type?: string;
	condition?: string;
	location?: string;
}
export class LifeBoyItem {
	number: number = 0;
	type: string = "";
	condition: string = "";
	location: string = "";

	onChange = null;

	constructor(params: LifeBoyItemType, onChange) {
		params = {
			...{
				condition: "",
				location: "",
				type: "",
				number: 0,
			}, ...params
		}

		this.condition = params.condition;
		this.location = params.location;
		this.type = params.type;
		this.number = params.number;

		this.onChange = onChange;
	}

	setNumber(number) { this.number = number; this.onChange() }
	setType(type) { this.type = type; this.onChange() }
	setCondition(condition) { this.condition = condition; this.onChange() }
	setLocation(location) { this.location = location; this.onChange() }
}

export default class LifeBoyEquipment {
	bouyant_apparatus: string = "";
	number_of_bouyant_lanyard: number = 0;
	lifejackets: LifeBoyItem = null;
	lifeboats: LifeBoyItem = null;
	liferafts: LifeBoyItem = null;
	launching_arrangements: LifeBoyItem = null;
	floatation_devices: LifeBoyItem = null;
	lifebouys: LifeBoyItem = null;
	parachute_flares: LifeBoyItem = null;
	smoke_signals: LifeBoyItem = null;
	emergency_communications: LifeBoyItem = null;
	radar_transponder: LifeBoyItem = null;
	rescue_boat: LifeBoyItem = null;
	life_boat_equipment: LifeBoyItem = null;

	constructor(params: LifeBoyEquipmentType, onChange) {
		params = {
			...{
				bouyant_apparatus: "",
				number_of_bouyant_lanyard: 0,
				lifejackets: null,
				lifeboats: null,
				liferafts: null,
				launching_arrangements: null,
				floatation_devices: null,
				lifebouys: null,
				parachute_flares: null,
				smoke_signals: null,
				emergency_communications: null,
				radar_transponder: null,
				rescue_boat: null,
				life_boat_equipment: null,
			}, ...params
		}
		this.onChange = onChange;

		this.bouyant_apparatus = params.bouyant_apparatus;
		this.number_of_bouyant_lanyard = params.number_of_bouyant_lanyard;
		this.lifejackets = new LifeBoyItem(params.lifejackets, () => this.onChange());
		this.lifeboats = new LifeBoyItem(params.lifeboats, () => this.onChange());
		this.liferafts = new LifeBoyItem(params.liferafts, () => this.onChange());
		this.launching_arrangements = new LifeBoyItem(params.launching_arrangements, () => this.onChange());
		this.floatation_devices = new LifeBoyItem(params.floatation_devices, () => this.onChange());
		this.lifebouys = new LifeBoyItem(params.lifebouys, () => this.onChange());
		this.parachute_flares = new LifeBoyItem(params.parachute_flares, () => this.onChange());
		this.smoke_signals = new LifeBoyItem(params.smoke_signals, () => this.onChange());
		this.emergency_communications = new LifeBoyItem(params.emergency_communications, () => this.onChange());
		this.radar_transponder = new LifeBoyItem(params.radar_transponder, () => this.onChange());
		this.rescue_boat = new LifeBoyItem(params.rescue_boat, () => this.onChange());
		this.life_boat_equipment = new LifeBoyItem(params.life_boat_equipment, () => this.onChange());

	}

	setBouyant_apparatus(bouyant_apparatus) { this.bouyant_apparatus = bouyant_apparatus; this.onChange() }
	setNumber_of_bouyant_lanyard(number_of_bouyant_lanyard) { this.number_of_bouyant_lanyard = number_of_bouyant_lanyard; this.onChange() }
}