import React, {useState} from 'react';
import InputMask from "react-input-mask";
import {
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Button,
    FormGroup,
    Input,
    Label,
    UncontrolledTooltip,
} from "reactstrap";
import moment from 'moment';

type TypeChecking = {
    userProfile: {},
    approvers: {},
    previousStepStatus: boolean,
    approveStage: () => void,
};

const SurveyDate = ({userProfile, approvers, allData, previousStepStatus, approveStage}: TypeChecking) => {

    const [surveyDate, setSurveyDate] = useState();

    const onChange = (event) => {
        let value = event.target.value;
        setSurveyDate(value);
    };

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Issue of Survey Date&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date !== null ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date_confirmation === true ?
                                            allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date === allData.vesselActivity.stages_info.stages.stage_survey_date.confirmed_survey_date ?
                                                <>
                                                    <CardText>
                                                        The Owner/Agency/Agent has confirmed the date of Survey. Please
                                                        be
                                                        prepared for Vessel Survey
                                                        dated <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> and
                                                        carry all the necessary documents. Good Luck.
                                                    </CardText>
                                                    {
                                                        allData !== undefined ?
                                                            allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status !== "success" ?
                                                                <>
                                                                    <CardText>
                                                                        In case if you want to change the Survey date.
                                                                        Issue
                                                                        Survey date below
                                                                    </CardText>
                                                                    <FormGroup>
                                                                        <Label>Survey Date</Label>
                                                                        <Input
                                                                            required
                                                                            type="date"
                                                                            name="surveyDate"
                                                                            value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}
                                                                            onChange={(e) => onChange(e)}
                                                                        />
                                                                    </FormGroup>

                                                                    <React.Fragment key={"two"}>
                                                                        <Button key={"approveTwo"} id={"approveTwo"}
                                                                                color={"success"}
                                                                                onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?
                                                                                    surveyDate !== undefined ?
                                                                                        approveStage(userProfile, "stage_survey_date", {
                                                                                            date: new Date(surveyDate).toISOString(),
                                                                                            type: "issue-date"
                                                                                        })
                                                                                        :
                                                                                        alert("Date cannot be empty")
                                                                                    :
                                                                                    () => {
                                                                                    }}
                                                                                className="mr-1 mb-1">
                                                                            Issue Survey Date
                                                                        </Button>
                                                                        {!approvers.stage_survey_date.includes(userProfile.id) ?
                                                                            <UncontrolledTooltip
                                                                                placement={"bottom"}
                                                                                target={"approveTwo"}>
                                                                                You are not authorized to Issue Survey
                                                                                Date
                                                                            </UncontrolledTooltip>
                                                                            : null}
                                                                    </React.Fragment>
                                                                </>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    }
                                                </>
                                                :
                                                <>
                                                    <CardText>
                                                        Waiting for acknowledgement of the issued Survey date from
                                                        the
                                                        Owner/Agency/Agent.
                                                    </CardText>
                                                    {
                                                        allData !== undefined ?
                                                            allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status !== "success" ?
                                                                <>
                                                                    <CardText>
                                                                        In case if you want to change the Survey
                                                                        date. Issue
                                                                        Survey date below
                                                                    </CardText>
                                                                    <FormGroup>
                                                                        <Label>Survey Date</Label>
                                                                        <Input
                                                                            required
                                                                            type="date"
                                                                            name="surveyDate"
                                                                            value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}
                                                                            onChange={(e) => onChange(e)}
                                                                        />
                                                                    </FormGroup>

                                                                    <React.Fragment key={"two"}>
                                                                        <Button key={"approveTwo"} id={"approveTwo"}
                                                                                color={"success"}
                                                                                onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?
                                                                                    surveyDate !== undefined ?
                                                                                        approveStage(userProfile, "stage_survey_date", {
                                                                                            date: new Date(surveyDate).toISOString(),
                                                                                            type: "issue-date"
                                                                                        })
                                                                                        :
                                                                                        alert("Date cannot be empty")
                                                                                    :
                                                                                    () => {
                                                                                    }}
                                                                                className="mr-1 mb-1">
                                                                            Issue Survey Date
                                                                        </Button>
                                                                        {!approvers.stage_survey_date.includes(userProfile.id) ?
                                                                            <UncontrolledTooltip
                                                                                placement={"bottom"}
                                                                                target={"approveTwo"}>
                                                                                You are not authorized to Issue
                                                                                Survey Date
                                                                            </UncontrolledTooltip>
                                                                            : null}
                                                                    </React.Fragment>
                                                                </>
                                                                :
                                                                null
                                                            :
                                                            null
                                                    }
                                                </>
                                            :
                                            allData.vesselActivity.stages_info.stages.stage_survey_date.request_another_survey_date === true ?
                                                <>
                                                    <CardText style={{color: "#ff132e"}}>
                                                        The Owner/Agency/Agent have requested for Vessel Survey on
                                                        another
                                                        date.
                                                    </CardText>
                                                    <CardText>
                                                        Please Issue the Survey date of your choice and wait for
                                                        acknowledgement from
                                                        the Owner/Agency/Agent.
                                                    </CardText>
                                                    <FormGroup>
                                                        <Label>Survey Date</Label>
                                                        <Input
                                                            required
                                                            type="date"
                                                            name="surveyDate"
                                                            value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}
                                                            onChange={(e) => onChange(e)}
                                                        />
                                                    </FormGroup>

                                                    <React.Fragment key={"two"}>
                                                        <Button key={"approveTwo"} id={"approveTwo"} color={"success"}
                                                                onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?
                                                                    surveyDate !== undefined ?
                                                                        approveStage(userProfile, "stage_survey_date", {
                                                                            date: new Date(surveyDate).toISOString(),
                                                                            type: "issue-date"
                                                                        })
                                                                        :
                                                                        alert("Date cannot be empty")
                                                                    :
                                                                    () => {
                                                                    }}
                                                                className="mr-1 mb-1">
                                                            Issue Survey Date
                                                        </Button>
                                                        {!approvers.stage_survey_date.includes(userProfile.id) ?
                                                            <UncontrolledTooltip
                                                                placement={"bottom"}
                                                                target={"approveTwo"}>
                                                                You are not authorized to Issue Survey Date
                                                            </UncontrolledTooltip>
                                                            : null}
                                                    </React.Fragment>
                                                </>
                                                :
                                                allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === true ?
                                                    <CardText>
                                                        Waiting for Date request for Re-Survey from Owner/Agency/Agent.
                                                    </CardText>
                                                    :
                                                    <>
                                                        <CardText>
                                                            Waiting for acknowledgement of the issued Survey date from
                                                            the
                                                            Owner/Agency/Agent.
                                                        </CardText>
                                                        {
                                                            allData !== undefined ?
                                                                allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status !== "success" ?
                                                                    <>
                                                                        <CardText>
                                                                            In case if you want to change the Survey
                                                                            date. Issue
                                                                            Survey date below
                                                                        </CardText>
                                                                        <FormGroup>
                                                                            <Label>Survey Date</Label>
                                                                            <Input
                                                                                required
                                                                                type="date"
                                                                                name="surveyDate"
                                                                                value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}
                                                                                onChange={(e) => onChange(e)}
                                                                            />
                                                                        </FormGroup>

                                                                        <React.Fragment key={"two"}>
                                                                            <Button key={"approveTwo"} id={"approveTwo"}
                                                                                    color={"success"}
                                                                                    onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?
                                                                                        surveyDate !== undefined ?
                                                                                            approveStage(userProfile, "stage_survey_date", {
                                                                                                date: new Date(surveyDate).toISOString(),
                                                                                                type: "issue-date"
                                                                                            })
                                                                                            :
                                                                                            alert("Date cannot be empty")
                                                                                        :
                                                                                        () => {
                                                                                        }}
                                                                                    className="mr-1 mb-1">
                                                                                Issue Survey Date
                                                                            </Button>
                                                                            {!approvers.stage_survey_date.includes(userProfile.id) ?
                                                                                <UncontrolledTooltip
                                                                                    placement={"bottom"}
                                                                                    target={"approveTwo"}>
                                                                                    You are not authorized to Issue
                                                                                    Survey Date
                                                                                </UncontrolledTooltip>
                                                                                : null}
                                                                        </React.Fragment>
                                                                    </>
                                                                    :
                                                                    null
                                                                :
                                                                null
                                                        }
                                                    </>
                                        :
                                        <>
                                            {
                                                allData.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor !== null ?
                                                    allData.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor === undefined ?
                                                        <CardText>
                                                            The Owner/Agency/Agent has not requested any date as Survey date.
                                                        </CardText>
                                                        :
                                                        <CardText>
                                                            The Owner/Agency/Agent has requested the
                                                            date {moment(allData.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format('DD-MM-YYYY')} as
                                                            Survey date.
                                                        </CardText>
                                                    :
                                                    null
                                            }
                                            <CardText>
                                                Please Issue the Survey date of your choice and wait for
                                                acknowledgement from
                                                the Owner/Agency/Agent.
                                            </CardText>
                                            <FormGroup>
                                                <Label>Survey Date</Label>
                                                <Input
                                                    required
                                                    type="date"
                                                    name="surveyDate"
                                                    value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}
                                                    onChange={(e) => onChange(e)}
                                                />
                                            </FormGroup>

                                            <React.Fragment key={"two"}>
                                                <Button key={"approveTwo"} id={"approveTwo"} color={"success"}
                                                        onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?
                                                            surveyDate !== undefined ?
                                                                approveStage(userProfile, "stage_survey_date", {
                                                                    date: new Date(surveyDate).toISOString(),
                                                                    type: "issue-date"
                                                                })
                                                                :
                                                                alert("Date cannot be empty")
                                                            :
                                                            () => {
                                                            }}
                                                        className="mr-1 mb-1">
                                                    Issue Survey Date
                                                </Button>
                                                {!approvers.stage_survey_date.includes(userProfile.id) ?
                                                    <UncontrolledTooltip
                                                        placement={"bottom"}
                                                        target={"approveTwo"}>
                                                        You are not authorized to Issue Survey Date
                                                    </UncontrolledTooltip>
                                                    : null}
                                            </React.Fragment>
                                        </>
                                    :
                                    null
                            }

                            <>
                                {/*{*/}
                                {/*    allData !== undefined ?*/}
                                {/*        allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date !== null ?*/}
                                {/*            allData.vesselActivity.stages_info.stages.stage_survey_status.status !== "rejected" ?*/}
                                {/*                <></>*/}
                                {/*                :*/}
                                {/*                <></>*/}
                                {/*            :*/}
                                {/*            allData.vesselActivity.stages_info.stages.stage_survey_status.status === "rejected" ?*/}
                                {/*                allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === true ?*/}
                                {/*                    allData.vesselActivity.stages_info.stages.stage_survey_date.request_another_survey_date === true ?*/}
                                {/*                        <>*/}
                                {/*                            <CardText style={{color: "#ff132e"}}>*/}
                                {/*                                The Owner/Agency/Agent have requested date for Re-Survey.*/}
                                {/*                            </CardText>*/}
                                {/*                            <CardText>*/}
                                {/*                                Please Issue the Survey date of your choice and wait for*/}
                                {/*                                acknowledgement from*/}
                                {/*                                the Owner/Agency/Agent.*/}
                                {/*                            </CardText>*/}
                                {/*                            <FormGroup>*/}
                                {/*                                <Label>Re-Survey Date</Label>*/}
                                {/*                                <Input*/}
                                {/*                                    required*/}
                                {/*                                    type="date"*/}
                                {/*                                    name="surveyDate"*/}
                                {/*                                    value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}*/}
                                {/*                                    onChange={(e) => onChange(e)}*/}
                                {/*                                />*/}
                                {/*                            </FormGroup>*/}

                                {/*                            <React.Fragment key={"two"}>*/}
                                {/*                                <Button key={"approveTwo"} id={"approveTwo"}*/}
                                {/*                                        color={"success"}*/}
                                {/*                                        onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                                            surveyDate !== undefined ?*/}
                                {/*                                                approveStage(userProfile, "stage_survey_date", {*/}
                                {/*                                                    date: new Date(surveyDate).toISOString(),*/}
                                {/*                                                    type: "issue-date"*/}
                                {/*                                                })*/}
                                {/*                                                :*/}
                                {/*                                                alert("Date cannot be empty")*/}
                                {/*                                            :*/}
                                {/*                                            () => {*/}
                                {/*                                            }}*/}
                                {/*                                        className="mr-1 mb-1">*/}
                                {/*                                    Issue Survey Date*/}
                                {/*                                </Button>*/}
                                {/*                                {!approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                                    <UncontrolledTooltip*/}
                                {/*                                        placement={"bottom"}*/}
                                {/*                                        target={"approveTwo"}>*/}
                                {/*                                        You are not authorized to Issue Survey Date*/}
                                {/*                                    </UncontrolledTooltip>*/}
                                {/*                                    : null}*/}
                                {/*                            </React.Fragment>*/}
                                {/*                        </>*/}
                                {/*                        :*/}
                                {/*                        <>*/}
                                {/*                            <CardText>*/}
                                {/*                                Waiting for acknowledgement of the issued Survey date from*/}
                                {/*                                the*/}
                                {/*                                Owner/Agency/Agent.*/}
                                {/*                            </CardText>*/}
                                {/*                            {*/}
                                {/*                                allData !== undefined ?*/}
                                {/*                                    allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status !== "success" ?*/}
                                {/*                                        <>*/}
                                {/*                                            <CardText>*/}
                                {/*                                                In case if you want to change the Survey*/}
                                {/*                                                date. Issue*/}
                                {/*                                                Survey date below*/}
                                {/*                                            </CardText>*/}
                                {/*                                            <FormGroup>*/}
                                {/*                                                <Label>Survey Date</Label>*/}
                                {/*                                                <Input*/}
                                {/*                                                    required*/}
                                {/*                                                    type="date"*/}
                                {/*                                                    name="surveyDate"*/}
                                {/*                                                    value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}*/}
                                {/*                                                    onChange={(e) => onChange(e)}*/}
                                {/*                                                />*/}
                                {/*                                            </FormGroup>*/}

                                {/*                                            <React.Fragment key={"two"}>*/}
                                {/*                                                <Button key={"approveTwo"} id={"approveTwo"}*/}
                                {/*                                                        color={"success"}*/}
                                {/*                                                        onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                                                            surveyDate !== undefined ?*/}
                                {/*                                                                approveStage(userProfile, "stage_survey_date", {*/}
                                {/*                                                                    date: new Date(surveyDate).toISOString(),*/}
                                {/*                                                                    type: "issue-date"*/}
                                {/*                                                                })*/}
                                {/*                                                                :*/}
                                {/*                                                                alert("Date cannot be empty")*/}
                                {/*                                                            :*/}
                                {/*                                                            () => {*/}
                                {/*                                                            }}*/}
                                {/*                                                        className="mr-1 mb-1">*/}
                                {/*                                                    Issue Survey Date*/}
                                {/*                                                </Button>*/}
                                {/*                                                {!approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                                                    <UncontrolledTooltip*/}
                                {/*                                                        placement={"bottom"}*/}
                                {/*                                                        target={"approveTwo"}>*/}
                                {/*                                                        You are not authorized to Issue*/}
                                {/*                                                        Survey Date*/}
                                {/*                                                    </UncontrolledTooltip>*/}
                                {/*                                                    : null}*/}
                                {/*                                            </React.Fragment>*/}
                                {/*                                        </>*/}
                                {/*                                        :*/}
                                {/*                                        null*/}
                                {/*                                    :*/}
                                {/*                                    null*/}
                                {/*                            }*/}
                                {/*                        </>*/}
                                {/*                    :*/}
                                {/*                    <CardText>*/}
                                {/*                        Waiting for Date request for Re-Survey from Owner/Agency/Agent.*/}
                                {/*                    </CardText>*/}
                                {/*                :*/}
                                {/*                <>*/}
                                {/*                    {*/}
                                {/*                        allData.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor !== null ?*/}
                                {/*                            <CardText>*/}
                                {/*                                The Owner/Agency/Agent has requested the*/}
                                {/*                                date {moment(allData.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format('DD-MM-YYYY')} as*/}
                                {/*                                Survey date.*/}
                                {/*                            </CardText>*/}
                                {/*                            :*/}
                                {/*                            null*/}
                                {/*                    }*/}
                                {/*                    <CardText>*/}
                                {/*                        Please Issue the Survey date of your choice and wait for*/}
                                {/*                        acknowledgement from*/}
                                {/*                        the Owner/Agency/Agent.*/}
                                {/*                    </CardText>*/}
                                {/*                    <FormGroup>*/}
                                {/*                        <Label>Survey Date</Label>*/}
                                {/*                        <Input*/}
                                {/*                            required*/}
                                {/*                            type="date"*/}
                                {/*                            name="surveyDate"*/}
                                {/*                            value={surveyDate === undefined ? surveyDate : moment(surveyDate).format('YYYY-MM-DD')}*/}
                                {/*                            onChange={(e) => onChange(e)}*/}
                                {/*                        />*/}
                                {/*                    </FormGroup>*/}

                                {/*                    <React.Fragment key={"two"}>*/}
                                {/*                        <Button key={"approveTwo"} id={"approveTwo"} color={"success"}*/}
                                {/*                                onClick={() => approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                                    surveyDate !== undefined ?*/}
                                {/*                                        approveStage(userProfile, "stage_survey_date", {*/}
                                {/*                                            date: new Date(surveyDate).toISOString(),*/}
                                {/*                                            type: "issue-date"*/}
                                {/*                                        })*/}
                                {/*                                        :*/}
                                {/*                                        alert("Date cannot be empty")*/}
                                {/*                                    :*/}
                                {/*                                    () => {*/}
                                {/*                                    }}*/}
                                {/*                                className="mr-1 mb-1">*/}
                                {/*                            Issue Survey Date*/}
                                {/*                        </Button>*/}
                                {/*                        {!approvers.stage_survey_date.includes(userProfile.id) ?*/}
                                {/*                            <UncontrolledTooltip*/}
                                {/*                                placement={"bottom"}*/}
                                {/*                                target={"approveTwo"}>*/}
                                {/*                                You are not authorized to Issue Survey Date*/}
                                {/*                            </UncontrolledTooltip>*/}
                                {/*                            : null}*/}
                                {/*                    </React.Fragment>*/}
                                {/*                </>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            </>

                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Confirmation of Survey Date&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date !== null ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date_confirmation === true ?
                                            allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date === allData.vesselActivity.stages_info.stages.stage_survey_date.confirmed_survey_date ?
                                                <CardText>
                                                    Please be prepared for Vessel Survey
                                                    dated <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> and
                                                    carry all the necessary documents. Good Luck.
                                                </CardText>
                                                :
                                                <>
                                                    <CardText>
                                                        Surveyor / Chief Surveyor have issued the
                                                        date <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> for
                                                        Survey
                                                        of
                                                        Vessel. Please acknowledge the issued date through
                                                        confirmation.
                                                    </CardText>
                                                    <Button color={"success"}
                                                            onClick={() => approveStage(userProfile, "stage_survey_date", {
                                                                date: new Date(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).toISOString(),
                                                                type: "confirm-date"
                                                            })}
                                                            className="mr-1 mb-1">
                                                        Confirm the Issued
                                                        date <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> for
                                                        Survey / Re-Survey
                                                    </Button>
                                                    <Button color={"warning"}
                                                            onClick={() => approveStage(userProfile, "stage_survey_date", {type: "request-date"})}
                                                            className="mr-1 mb-1">
                                                        Request another date
                                                    </Button>
                                                </>
                                            :
                                            allData.vesselActivity.stages_info.stages.stage_survey_date.request_another_survey_date === true ?
                                                <CardText>
                                                    Your request for anther survey date is in process. Surveyor / Chief
                                                    Surveyor will issue another date for survey. Please wait...
                                                </CardText>
                                                :
                                                allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === true ?
                                                    <>
                                                        <CardText>
                                                            Surveyor / Chief Surveyor have Rejected the
                                                            Survey. Please check the Rejection Reasons in
                                                            Survey Status.
                                                            After rectifying the Rejection Reasons, request
                                                            another date for Survey.
                                                        </CardText>
                                                        <Button color={"warning"}
                                                                onClick={() => approveStage(userProfile, "stage_survey_date", {type: "request-date"})}
                                                                className="mr-1 mb-1">
                                                            Request date for Re-Survey
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <CardText>
                                                            Surveyor / Chief Surveyor have issued the
                                                            date <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> for
                                                            Survey
                                                            of
                                                            Vessel. Please acknowledge the issued date through
                                                            confirmation.
                                                        </CardText>
                                                        <Button color={"success"}
                                                                onClick={() => approveStage(userProfile, "stage_survey_date", {
                                                                    date: new Date(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).toISOString(),
                                                                    type: "confirm-date"
                                                                })}
                                                                className="mr-1 mb-1">
                                                            Confirm the Issued
                                                            date <strong>{moment(allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</strong> for
                                                            Survey / Re-Survey
                                                        </Button>
                                                        <Button color={"warning"}
                                                                onClick={() => approveStage(userProfile, "stage_survey_date", {type: "request-date"})}
                                                                className="mr-1 mb-1">
                                                            Request another date
                                                        </Button>
                                                    </>
                                        :
                                        <CardText>
                                            The Surveyor / Chief Surveyor has not yet issued the date for Survey. Please
                                            wait...
                                        </CardText>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    )
};

export default SurveyDate;
