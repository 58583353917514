import React from 'react';
import {Card, CardBody, CardHeader, Col, Row, Spinner} from "reactstrap";

const CardsRow = ({dashboardBelowVesselsCount}) => {

    return (
        <Row>
            <Col lg="8" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="card-title mb-0 badge badge-primary float-left">Total Vessels</span>
                    </CardHeader>
                    <CardBody className="my-2">
                        <Row className="d-flex align-items-center mb-0">
                            <Col xs="12">
                                {
                                    Object.keys(dashboardBelowVesselsCount).length !== 0 ?
                                        <h1 className="display-4 d-flex align-items-center mb-0 font-weight-light">
                                            {JSON.stringify(dashboardBelowVesselsCount.total)}
                                        </h1>
                                        :
                                        <Spinner color="primary" className="mr-2"/>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="8" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="card-title mb-0 badge badge-warning float-left">Vessels Under Process</span>
                    </CardHeader>
                    <CardBody className="my-2">
                        <Row className="d-flex align-items-center mb-0">
                            <Col xs="12">
                                {
                                    Object.keys(dashboardBelowVesselsCount).length !== 0 ?
                                        <h1 className="display-4 d-flex align-items-center mb-0 font-weight-light">
                                            {JSON.stringify(dashboardBelowVesselsCount.underProcess)}
                                        </h1>
                                        :
                                        <Spinner color="warning" className="mr-2"/>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="8" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="card-title mb-0 badge badge-success float-left">Finished Vessels</span>
                    </CardHeader>
                    <CardBody className="my-2">
                        <Row className="d-flex align-items-center mb-0">
                            <Col xs="12">
                                {
                                    Object.keys(dashboardBelowVesselsCount).length !== 0 ?
                                        <h1 className="display-4 d-flex align-items-center mb-0 font-weight-light">
                                            {JSON.stringify(dashboardBelowVesselsCount.finished)}
                                        </h1>
                                        :
                                        <Spinner color="success" className="mr-2"/>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="8" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="card-title mb-0 badge badge-danger float-left">Cancelled Vessels</span>
                    </CardHeader>
                    <CardBody className="my-2">
                        <Row className="d-flex align-items-center mb-0">
                            <Col xs="12">
                                {
                                    Object.keys(dashboardBelowVesselsCount).length !== 0 ?
                                        <h1 className="display-4 d-flex align-items-center mb-0 font-weight-light">
                                            {JSON.stringify(dashboardBelowVesselsCount.cancelled)}
                                        </h1>
                                        :
                                        <Spinner color="danger" className="mr-2"/>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};

export default CardsRow;
