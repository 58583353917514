import React from 'react';
import $ from "jquery";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import moment from "moment";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import {Button} from "reactstrap";

const VesselBasicFormType = {
    formFieldSource: ApplicationFormFieldsSource,
};

const DeclarationOfSurveyPrint = ({formFieldSource}: VesselBasicFormType) => {

    let printDeclarationOfSurvey = () => {

        let top_left_X_Y_Coordinates = 0;
        let PDF_Width = 1240;
        let PDF_Height = 1754;
        let pdf = "";

        $("#print-wrap-page6").attr("hidden", false);
        html2canvas($("#print-wrap-page6")[0], {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
            pdf.output('dataurlnewwindow');
        });
        $("#print-wrap-page6").attr("hidden", true);
    };

    return (
        <div>
            <Button color={"success"}
                    onClick={() => printDeclarationOfSurvey()}
                    style={{width: 260}}
                    className="mr-1 mb-1">
                Declaration of Survey
            </Button>

            <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                <div id="print-wrap-page6" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}>

                        <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 25,
                                    }}>ANNEXURE - 3 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 50px 0px"
                            }}>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>DECLARATION OF SURVEY FOR WATER SPORTS CRAFT</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 10px 0px"
                            }}>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>1. NAME OF WATER SPORT CRAFT:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.name_of_the_craft}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>2. PARTICULARS OF THE CRAFT (PREVIOUS NAME / OWNER):&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{
                                            formFieldSource.declaration_of_ownership.previous_name_of_the_craft !== "" && formFieldSource.declaration_of_ownership.previous_owner_of_the_craft !== "" ?
                                                formFieldSource.declaration_of_ownership.previous_name_of_the_craft + " / " + formFieldSource.declaration_of_ownership.previous_owner_of_the_craft
                                                :
                                                "N.A"
                                        }
                                        </h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>3. TONNAGE</h4>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-evenly"}}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>G.R.T&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.gross_tonnage}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>N.R.T&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.net_tonnage}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-evenly"}}>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>LENGTH&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.craft_dimension_length}&nbsp;m</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>BREADTH&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.craft_dimension_berth}&nbsp;m</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DRAFT&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.draft}&nbsp;m</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DEPTH&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{formFieldSource.registration_or_survey.craft_dimension_depth}&nbsp;m</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>4. PLACE & DATE OF BUILT:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_built_at},&nbsp;{formFieldSource.declaration_of_ownership.craft_or_vessel_built_year}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>5. NAME & ADDRESS OF PRESENT OWNER:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.owners_name} & {formFieldSource.registration_or_survey.owners_address}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>6. TYPE OF HULL:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.type_of_hull}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>7. PLACE & DATE OF THE CRAFT EXAMINATION CARRIED OUT:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.place_of_proposed_survey}&nbsp;&&nbsp;{formFieldSource.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor !== undefined ? moment(formFieldSource.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format('DD-MM-YYYY') : "-"}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>8. CONDITION OF CRAFT:&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.remarks}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 40px 0px"
                            }}>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 20px 0px"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>TESTS CARRIED OUT</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    padding: "0px 0px 10px 40px"
                                }}>
                                    {
                                        formFieldSource !== undefined ?
                                            formFieldSource.vesselActivity !== undefined ?
                                                formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.details_of_tests_carried_out.length !== 0 ?
                                                    formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.details_of_tests_carried_out.map((each, index) =>
                                                        <div key={index} style={{display: "flex", justifyContent: "flex-start"}}>
                                                            <h4 style={{
                                                                fontFamily: "sans-serif",
                                                                fontSize: 25,
                                                                fontWeight: "bold"
                                                            // }}>{index + 1}.&nbsp;{each}</h4>
                                                            }}>{each}</h4>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                background: "#fff",
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexGrow: 1,
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                }}>
                                    <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>PLACE:&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.declaration_of_ownership.registered_at_port}</h4>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DATE:&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{moment(formFieldSource.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</h4>
                                        </div>
                                    </div>
                                    <br/>
                                    <br/>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", background: "#fff"}}>
                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>
                                            REMARKS OF SURVEYOR -&nbsp;{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.remarks}
                                            {/*{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.remarks === "FOUND SATISFACTORY" ?*/}
                                            {/*    <span>FOUND SATISFACTORY</span> / <span><s>NOT SATISFACTORY</s></span>*/}
                                            {/*    :*/}
                                            {/*    <span><s>FOUND SATISFACTORY</s></span> / <span>NOT SATISFACTORY</span>*/}
                                            {/*}*/}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            background: "#fff",
                            padding: "0px 0px 40px 0px"
                        }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>OWNER / OWNER'S REPRESENTATIVE</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>SURVEYOR</h4>
                            </div>
                        </div>

                        {/* <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 0px 0px"
                        }}>
                            <h4 style={{fontFamily: "sans-serif", fontSize: 20, marginBottom: 0}}>This is a digitally generated form, does not require signature. Date & Time - {moment(formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].timeStamp).format('DD-MM-YYYY h:mm:ss a')}</h4>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
};

export default DeclarationOfSurveyPrint;
