import { combineReducers } from "redux";

import sidebar from "./sidebarReducers";
import layout from "./layoutReducer";
import vesselsReducer from "./vesselsReducer";
import CountsReducers from "./countsReducer";
import expiredVesselsCountReducer from "./expiredVesselsCountReducer";
import expiringVesselsCountReducer from "./expiringVesselsCountReducer";

// Harsha
import BelowVesselReducers from "./Below12Reducers/BelowVesselReducers";
import AllBelowVesselReducers from "./Below12Reducers/AllBelowVesselReducers";
import BelowVesselCountReducers from "./Below12Reducers/BelowVesselCountReducers";
import BelowVesselRegCountReducers from "./Below12Reducers/BelowVesselRegCountReducers";
import ApproversReducers from "./Below12Reducers/ApproversReducers";
import UserReducer from "./Below12Reducers/UserReducer";
import StatusReducers from "./CommonReducers/StatusReducers";

import { reducer as toastr } from "react-redux-toastr";

export default combineReducers({
  sidebar,
  layout,
  toastr,
  counts: CountsReducers,
  expiredVesselsCount: expiredVesselsCountReducer,
  expiringVesselsCount: expiringVesselsCountReducer,
  vessels: vesselsReducer,

  belowVessel: BelowVesselReducers,
  allBelowVessel: AllBelowVesselReducers,
  belowVesselCount: BelowVesselCountReducers,
  belowVesselRegCount: BelowVesselRegCountReducers,
  approvers: ApproversReducers,
  user: UserReducer,
  status: StatusReducers
});
