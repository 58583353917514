import {
    CREATE_VESSEL,
    FETCH_VESSELS,
    UPDATE_VESSEL,
    DELETE_VESSEL,
    SEARCH_VESSEL,
} from "../actions/ActionTypesAllModules";

const VesselsReducers = (state = [], actions) => {
    switch (actions.type) {
        case FETCH_VESSELS:
            return actions.payload;

        case CREATE_VESSEL:
            return state.concat(actions.payload);

        case UPDATE_VESSEL:
            return state.map((item) => {
                return item.id === actions.payload.id ? actions.payload : item;
            });

        case DELETE_VESSEL:
            const dup = [...state];
            state.map((each) => {
                if (actions.payload.includes(each._id)) {
                    const index = state.indexOf(each);
                    dup.splice(index, actions.payload.length);
                }
            });
            return dup;

        case SEARCH_VESSEL:
            return actions.payload;

        default:
            return state;
    }
};

export default VesselsReducers;
