import {
    FETCH_BELOW_VESSEL_USER
} from "../../actions/ActionTypes/b12ActionTypes";

const UserReducer = (state = [], actions) => {
    if (actions.type === FETCH_BELOW_VESSEL_USER) {
        return actions.payload;
    } else {
        return state;
    }
};

export default UserReducer;
