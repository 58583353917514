import async from "../components/Async";
import {
    Layout as LayoutIcon,
    Users as UsersIcon,
    Grid as DashboardIcon,
    List as BelowListIcon,
    File as BelowRegistrationSurveyIcon,
} from "react-feather";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Misc
import Blank from "../pages/misc/Blank";

// Vessels
import VesselsList from "../pages/vessels/vesselsList";
import VesselForm from "../pages/vessels/Vessel.form";
import routesHelper from "./routes.helper";

// Harsha Start
import DashboardComponent from "../below12c/pages/dashboard/DashboardComponent";
import BelowRegistrationSurvey from "../below12c/pages/registrationSurvey/RegistrationSurveyLandingComponent";
import BelowVesselListLandingComponent from "../below12c/pages/list/BelowVesselListLandingComponent";
// Harsha End

// Vessel View
const VesselActivityView = async(() => import("../pages/vessels/ActivityView"));

// Routes
// const landingRoutes = {
//     path: "/",
//     name: "Vessels",
//     component: VesselsList,
//     children: null,
// };

const belowDashboardRoutes = {
    path: routesHelper.below.dashboard.path,
    name: "Dashboard",
    icon: DashboardIcon,
    component: DashboardComponent,
    accessType: [1,2,3],
    children: null,
};

const belowVesselList = {
    path: routesHelper.below.registrationSurvey.list.path,
    name: "Vessel List",
    icon: BelowListIcon,
    component: BelowVesselListLandingComponent,
    accessType: [1,2,3],
    children: null,
};

const belowRegistrationSurveyRoutes = {
    path: routesHelper.below.registrationSurvey.create.path,
    name: "Registration / Survey",
    icon: BelowRegistrationSurveyIcon,
    component: BelowRegistrationSurvey,
    accessType: [1],
    children: null,
};

// This route is not visible in the sidebar
const belowInvisibleRoutes = {
    path: "/private",
    name: "Private",
    children: [
        {
            path: routesHelper.below.registrationSurvey.view.path,
            name: "View Below Vessel",
            component: BelowRegistrationSurvey,
        },
        {
            path: routesHelper.below.registrationSurvey.edit.path,
            name: "Edit Below Vessel",
            component: BelowRegistrationSurvey,
        }
    ]
};

const vesselRoutes = {
    path: "/vessels",
    name: "Vessels",
    icon: LayoutIcon,
    children: [
        {
            path: routesHelper.vesselForm.list.path,
            name: "Vessels List",
            component: VesselsList,
        },
        {
            path: routesHelper.vesselForm.create.path,
            name: "Add Vessel",
            component: VesselForm,
        },
    ],
};

const authRoutes = {
    path: "/auth",
    name: "Auth",
    icon: UsersIcon,
    badgeColor: "secondary",
    badgeText: "12/24",
    children: [
        {
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn,
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
        },
        {
            path: "/auth/reset-password",
            name: "Reset Password",
            component: ResetPassword,
        },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
};

// This route is not visible in the sidebar
const privateRoutes = {
    path: "/private",
    name: "Private",
    children: [
        {
            path: "/private/blank",
            name: "Blank Page",
            component: Blank,
        },
        {
            path: "/vessels/activityView",
            name: "Activity View",
            component: VesselActivityView,
        },
        {
            path: routesHelper.vesselForm.create.path,
            name: "Vessel Form",
            component: VesselForm,
        },
        {
            path: routesHelper.vesselForm.view.path,
            name: "View Vessel",
            component: VesselForm,
        },
        {
            path: routesHelper.vesselForm.edit.path,
            name: "Edit Vessel",
            component: VesselForm,
        },
        {
            path: routesHelper.vesselForm.editViaForms.path,
            name: "Edit with Forms",
            component: VesselForm,
        },
    ],
};

// Harsha Start
export const belowRoutesSidebar = [belowDashboardRoutes, belowVesselList, belowRegistrationSurveyRoutes];
export const belowRoutes =[belowDashboardRoutes, belowVesselList, belowRegistrationSurveyRoutes, belowInvisibleRoutes];
// Harsha End

// Dashboard specific routes
export const dashboard = [vesselRoutes, privateRoutes];

// Landing specific routes
// export const landing = [landingRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [vesselRoutes];
