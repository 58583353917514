import React from "react";
import {Bar} from "react-chartjs-2";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Spinner
} from "reactstrap";

const RegistrationsBarChart = ({dashboardBelowVesselRegCount}) => {

    const data = {
        labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
        ],
        datasets: [
            {
                label: "Count",
                backgroundColor: "#ed8c3b",
                borderColor: "#ed8c3b",
                hoverBackgroundColor: "#ed8c3b",
                hoverBorderColor: "#ed8c3b",
                data: dashboardBelowVesselRegCount
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        display: false
                    },
                    stacked: false,
                    ticks: {
                        stepSize: 20
                    }
                }
            ],
            xAxes: [
                {
                    barPercentage: 0.75,
                    categoryPercentage: 0.5,
                    stacked: false,
                    gridLines: {
                        color: "transparent"
                    }
                }
            ]
        }
    };

    return (
        <Card className="flex-fill w-100">
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">
                    Registrations per Month
                </CardTitle>
            </CardHeader>
            {
                dashboardBelowVesselRegCount.length !== 0 ?
                    <CardBody className="d-flex">
                        <div className="align-self-center w-100">
                            <div className="chart chart-lg">
                                <Bar data={data} options={options}/>
                            </div>
                        </div>
                    </CardBody>
                    :
                    <Spinner color="primary" className="mr-2"/>
            }
        </Card>
    );
};

export default RegistrationsBarChart;
