import React, {useEffect, useState} from 'react';
import {
    Row,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    UncontrolledTooltip,
    Label,
    Input,
    CustomInput, Spinner
} from "reactstrap";
import jQuery from "jquery";
import CertificateOfSurveyPrint from "../prints/CertificateOfSurveyPrint";
import DeclarationOfSurveyPrint from "../prints/DeclarationOfSurveyPrint";
import RejectReasonModal from "./subComponents/RejectReasonModal";
import Modals from "../../../common/Modals";

const $ = jQuery;
window.jQuery = jQuery;

type TypeChecking = {
    userProfile: {},
    approvers: {},
    previousStepStatus: boolean,
    approveStage: () => void,
    rejectStage: () => void,
};

const SurveyStatus = ({userProfile, approvers, allData, previousStepStatus, approveStage, rejectStage}: TypeChecking) => {

    const [registrationNo, setRegistrationNo] = useState("");
    const [tests, setTests] = useState();
    const [limits, setLimits] = useState();
    const [remarks, setRemarks] = useState("");

    useEffect(() => {
        if (allData !== undefined) {
            if(allData.registration_or_survey.registration_number_of_the_craft.length !== 0) {
                setRegistrationNo(allData.registration_or_survey.registration_number_of_the_craft);
            }
        }
    }, [allData]);

    const radios = ["FOUND SATISFACTORY", "NOT SATISFACTORY"];

    const onChangeRegNo = (value) => {
        setRegistrationNo(value);
    };

    const onChangeTests = (value) => {
        setTests(value);
    };

    const onChangeLimitations = (value) => {
        setLimits(value);
    };

    const handleRemarks = radio => event => setRemarks(radio);

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Survey Status&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status === "" ?
                                        <>
                                            <Label for="regNo">Enter Registration Number</Label>
                                            <Input
                                                required
                                                type="text"
                                                name="regNo"
                                                value={registrationNo}
                                                onChange={(e) => onChangeRegNo(e.target.value)}
                                            />
                                            <br/>
                                            <Label for="carriedTests">Details of tests carried out:</Label>
                                            <Input type="textarea" name="input"
                                                   onChange={(e) => onChangeTests(e.target.value)}/>
                                            <br/>
                                            <Label for="limitations">Limitations of Operation:</Label>
                                            <Input type="textarea" name="input"
                                                   onChange={(e) => onChangeLimitations(e.target.value)}/>
                                            <br/>
                                            <Label for="remarks">Remarks of Surveyor / Chief Surveyor:</Label>
                                            <div className="ml-3">
                                                {radios && radios.map((r, index) => {
                                                    return (
                                                        <Row key={index}>
                                                            <CustomInput id={index} onClick={handleRemarks(r)}
                                                                         type="radio"
                                                                         name="settings"/>
                                                            {r}
                                                        </Row>
                                                    )
                                                })}
                                            </div>
                                            <br/>
                                            <CardText>
                                                Survey Status : Pending...
                                            </CardText>
                                            {
                                                allData !== undefined ?
                                                    <>
                                                        {
                                                            allData ?
                                                                <CertificateOfSurveyPrint
                                                                    formFieldSource={allData} />
                                                                :
                                                                <Spinner color="success" className="mr-2" />
                                                        }
                                                        {
                                                            allData ?
                                                                <DeclarationOfSurveyPrint
                                                                    formFieldSource={allData} />
                                                                :
                                                                <Spinner color="success" className="mr-2" />
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                            <br/>
                                            <Row className="px-3">
                                                <Button color={"success"}
                                                        onClick={() => approvers.stage_survey_status.includes(userProfile.id) ?
                                                            registrationNo.length !== 0 && remarks.length !== 0 ?
                                                                approveStage(userProfile, "stage_survey_status", {
                                                                    registrationNo,
                                                                    tests: tests.split("\n"),
                                                                    limits,
                                                                    remarks,
                                                                    type: "success"
                                                                })
                                                                :
                                                                alert("Registration No / Remarks cannot be empty")
                                                            :
                                                            () => {
                                                            }}
                                                        className="mr-3 mt-1 mb-1" style={{height: 31}}>
                                                    Success Survey
                                                </Button>
                                                <div style={{width: 120}}>
                                                    <React.Fragment key={"two"}>
                                                        <RejectReasonModal
                                                            name={"Reject Survey"}
                                                            header={"Rejection Reason Entry"}
                                                            subTitle={"Please enter the reason below"}
                                                            stage={"stage_survey_status"}
                                                            approvers={approvers.stage_survey_status}
                                                            id={userProfile.id}
                                                            rejectStage={approvers.stage_survey_status.includes(userProfile.id) ? rejectStage : () => {
                                                            }}
                                                        />
                                                        {!approvers.stage_survey_status.includes(userProfile.id) ?
                                                            <UncontrolledTooltip
                                                                placement={"bottom"}
                                                                target={"reject"}>
                                                                You are not authorized to Approve or Reject
                                                            </UncontrolledTooltip>
                                                            : null}
                                                    </React.Fragment>
                                                </div>
                                            </Row>
                                        </>
                                        :
                                        allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status === "success" ?
                                            <>
                                                <CardText>
                                                    Vessel Survey is a Success. The Registration Number
                                                    is {allData.vesselActivity.stages_info.stages.stage_survey_status.registration_number}.
                                                </CardText>
                                                <CardText>
                                                    Survey Status : Success
                                                </CardText>
                                                <br/>
                                                {
                                                    allData !== undefined ?
                                                        <>
                                                            {
                                                                allData ?
                                                                    <CertificateOfSurveyPrint
                                                                        formFieldSource={allData} />
                                                                    :
                                                                    <Spinner color="success" className="mr-2" />
                                                            }
                                                            {
                                                                allData ?
                                                                    <DeclarationOfSurveyPrint
                                                                        formFieldSource={allData} />
                                                                    :
                                                                    <Spinner color="success" className="mr-2" />
                                                            }
                                                        </>
                                                        :
                                                        null
                                                }
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?
                                                            <div className="my-3">
                                                                <Modals
                                                                    name={"REJECTION REASON"}
                                                                    header={"Here are the rejection reasons for this application"}
                                                                    reasons={allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason}
                                                                />
                                                            </div>
                                                            : null
                                                        : null
                                                }
                                            </>
                                            :
                                            allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status === "rejected" ?
                                                allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === true ?
                                                    allData.vesselActivity.stages_info.stages.stage_survey_date.request_another_survey_date === true ?
                                                        <CardText>
                                                            Waiting for Re-Survey date confirmation from
                                                            Owner/Agency/Agent.
                                                        </CardText>
                                                        :
                                                        <CardText>
                                                            Waiting for Re-Survey date request from
                                                            Owner/Agency/Agent.
                                                        </CardText>
                                                    :
                                                    allData.vesselActivity.stages_info.stages.stage_survey_date.survey_date_confirmation === true ?
                                                        <>
                                                            <CardText>
                                                                Date for Re-Survey has been confirmed. Good luck with
                                                                the
                                                                Re-Survey.
                                                            </CardText>
                                                            <Label for="reRegNo">Enter Registration Number</Label>
                                                            <Input
                                                                required
                                                                type="text"
                                                                name="reRegNo"
                                                                value={registrationNo}
                                                                onChange={(e) => onChangeRegNo(e.target.value)}
                                                            />
                                                            <br/>
                                                            <Label for="carriedTests">Details of tests carried
                                                                out:</Label>
                                                            <Input type="textarea" name="input"
                                                                   onChange={(e) => onChangeTests(e.target.value)}/>
                                                            <br/>
                                                            <Label for="limitations">Limitations of Operation:</Label>
                                                            <Input type="textarea" name="input"
                                                                   onChange={(e) => onChangeLimitations(e.target.value)}/>
                                                            <br/>
                                                            <Label for="remarks">Remarks of Surveyor / Chief
                                                                Surveyor:</Label>
                                                            <div className="ml-3">
                                                                {radios && radios.map((r, index) => {
                                                                    return (
                                                                        <Row>
                                                                            <CustomInput id={index}
                                                                                         onClick={handleRemarks(r)}
                                                                                         type="radio"
                                                                                         name="settings"/>
                                                                            {r}
                                                                        </Row>
                                                                    )
                                                                })}
                                                            </div>
                                                            <br/>
                                                            <CardText>
                                                                Survey Status : Pending...
                                                            </CardText>
                                                            {
                                                                allData !== undefined ?
                                                                    <>
                                                                        {
                                                                            allData ?
                                                                                <CertificateOfSurveyPrint
                                                                                    formFieldSource={allData} />
                                                                                :
                                                                                <Spinner color="success" className="mr-2" />
                                                                        }
                                                                        {
                                                                            allData ?
                                                                                <DeclarationOfSurveyPrint
                                                                                    formFieldSource={allData} />
                                                                                :
                                                                                <Spinner color="success" className="mr-2" />
                                                                        }
                                                                    </>
                                                                    :
                                                                    null
                                                            }
                                                            <br/>
                                                            <Row className="px-3">
                                                                <Button color={"success"}
                                                                        onClick={() => approvers.stage_survey_status.includes(userProfile.id) ?
                                                                            registrationNo.length !== 0 && remarks.length !== 0 ?
                                                                                approveStage(userProfile, "stage_survey_status", {
                                                                                    registrationNo,
                                                                                    tests: tests.split("\n"),
                                                                                    limits,
                                                                                    remarks,
                                                                                    type: "success"
                                                                                })
                                                                                :
                                                                                alert("Registration No / Remarks cannot be empty")
                                                                            :
                                                                            () => {
                                                                            }}
                                                                        className="mr-1 mt-1 mb-1" style={{height: 31}}>
                                                                    Success Survey
                                                                </Button>
                                                                <div style={{width: 120}}>
                                                                    <React.Fragment key={"two"}>
                                                                        <RejectReasonModal
                                                                            name={"Reject Survey"}
                                                                            header={"Rejection Reason Entry"}
                                                                            subTitle={"Please enter the reason below"}
                                                                            stage={"stage_survey_status"}
                                                                            approvers={approvers.stage_survey_status}
                                                                            id={userProfile.id}
                                                                            rejectStage={approvers.stage_survey_status.includes(userProfile.id) ? rejectStage : () => {
                                                                            }}
                                                                        />
                                                                        {!approvers.stage_survey_status.includes(userProfile.id) ?
                                                                            <UncontrolledTooltip
                                                                                placement={"bottom"}
                                                                                target={"reject"}>
                                                                                You are not authorized to Approve or
                                                                                Reject
                                                                            </UncontrolledTooltip>
                                                                            : null}
                                                                    </React.Fragment>
                                                                </div>
                                                                {
                                                                    allData !== undefined ?
                                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?
                                                                            <div className="ml-1 mt-1">
                                                                                <Modals
                                                                                    name={"REJECTION REASON"}
                                                                                    header={"Here are the rejection reasons for this application"}
                                                                                    reasons={allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason}
                                                                                />
                                                                            </div>
                                                                            : null
                                                                        : null
                                                                }
                                                            </Row>
                                                        </>
                                                        :
                                                        <CardText>
                                                            Waiting for Re-Survey date confirmation from
                                                            Owner/Agency/Agent.
                                                        </CardText>
                                                :
                                                null
                                    :
                                    null
                            }

                            <>
                                {/*allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === false ?*/}

                                {/*allData.vesselActivity.stages_info.stages.stage_survey_date.request_another_survey_date === false ?*/}

                                {/*<Row>*/}
                                {/*    <Button color={"success"}*/}
                                {/*            onClick={() => approvers.includes(userProfile.id) ?*/}
                                {/*                registrationNo.length !== 0 ?*/}
                                {/*                    approveStage(userProfile, "stage_survey_status", {*/}
                                {/*                        regNo: registrationNo,*/}
                                {/*                        type: "success"*/}
                                {/*                    })*/}
                                {/*                    :*/}
                                {/*                    alert("Registration No cannot be empty")*/}
                                {/*                :*/}
                                {/*                () => {*/}
                                {/*                }}*/}
                                {/*            className="mr-1 mt-1 mb-1" style={{height: 31}}>*/}
                                {/*        Success Survey*/}
                                {/*    </Button>*/}
                                {/*    <div style={{width: 120}}>*/}
                                {/*        <React.Fragment key={"two"}>*/}
                                {/*            <RejectReasonModal*/}
                                {/*                name={"Reject Survey"}*/}
                                {/*                header={"Rejection Reason Entry"}*/}
                                {/*                subTitle={"Please enter the reason below"}*/}
                                {/*                stage={"stage_survey_status"}*/}
                                {/*                approvers={approvers}*/}
                                {/*                id={userProfile.id}*/}
                                {/*                rejectStage={approvers.includes(userProfile.id) ? rejectStage : () => {*/}
                                {/*                }}*/}
                                {/*            />*/}
                                {/*            {!approvers.includes(userProfile.id) ?*/}
                                {/*                <UncontrolledTooltip*/}
                                {/*                    placement={"bottom"}*/}
                                {/*                    target={"reject"}>*/}
                                {/*                    You are not authorized to Approve or Reject*/}
                                {/*                </UncontrolledTooltip>*/}
                                {/*                : null}*/}
                                {/*        </React.Fragment>*/}
                                {/*    </div>*/}
                                {/*    {*/}
                                {/*        allData !== undefined ?*/}
                                {/*            allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?*/}
                                {/*                <div className="my-3">*/}
                                {/*                    <Modals*/}
                                {/*                        name={"REJECTION REASON"}*/}
                                {/*                        header={"Here are the reasons why your application has been rejected"}*/}
                                {/*                    />*/}
                                {/*                </div>*/}
                                {/*                : null*/}
                                {/*            : null*/}
                                {/*    }*/}
                                {/*</Row>*/}

                                {/*{*/}
                                {/*    allData !== undefined ?*/}
                                {/*        allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?*/}
                                {/*            <div className="my-3">*/}
                                {/*                <Modals*/}
                                {/*                    name={"REJECTION REASON"}*/}
                                {/*                    header={"Here are the reasons why your application has been rejected"}*/}
                                {/*                />*/}
                                {/*            </div>*/}
                                {/*            : null*/}
                                {/*        : null*/}
                                {/*}*/}

                                {/*<Label for="regNo">Enter Registration Number</Label>*/}
                                {/*<Input*/}
                                {/*    required*/}
                                {/*    type="text"*/}
                                {/*    name="regNumber"*/}
                                {/*    value={registrationNo}*/}
                                {/*    onChange={(e) => onChange(e.target.value)}*/}
                                {/*/>*/}
                                {/*<br/>*/}
                                {/*<CardText>*/}
                                {/*    Survey Status : {*/}
                                {/*    allData !== undefined ?*/}
                                {/*        allData.vesselActivity.stages_info.stages.stage_survey_status.registration_number.length !== 0 ?*/}
                                {/*            "Success"*/}
                                {/*            :*/}
                                {/*            allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === true ?*/}
                                {/*                "Rejected"*/}
                                {/*                :*/}
                                {/*                "Pending..."*/}
                                {/*        :*/}
                                {/*        "Pending..."*/}
                                {/*}*/}
                                {/*</CardText>*/}

                                {/*{*/}
                                {/*    allData.vesselActivity.stages_info.stages.stage_survey_status.request_survey_again === false ?*/}
                                {/*        <Row>*/}
                                {/*            <Button color={"success"} onClick={() => approvers.includes(userProfile.id) ?*/}
                                {/*                registrationNo.length !== 0 ?*/}
                                {/*                    approveStage(userProfile, "stage_survey_status", {*/}
                                {/*                        regNo: registrationNo,*/}
                                {/*                        type: "success"*/}
                                {/*                    })*/}
                                {/*                    :*/}
                                {/*                    alert("Registration No cannot be empty")*/}
                                {/*                :*/}
                                {/*                () => {*/}
                                {/*                }}*/}
                                {/*                    className="mr-1 mt-1 mb-1" style={{height: 31}}>*/}
                                {/*                Success Survey*/}
                                {/*            </Button>*/}
                                {/*            <div style={{width: 120}}>*/}
                                {/*                <React.Fragment key={"two"}>*/}
                                {/*                    <RejectReasonModal*/}
                                {/*                        name={"Reject Survey"}*/}
                                {/*                        header={"Rejection Reason Entry"}*/}
                                {/*                        subTitle={"Please enter the reason below"}*/}
                                {/*                        stage={"stage_survey_status"}*/}
                                {/*                        approvers={approvers}*/}
                                {/*                        id={userProfile.id}*/}
                                {/*                        rejectStage={approvers.includes(userProfile.id) ? rejectStage : () => {*/}
                                {/*                        }}*/}
                                {/*                    />*/}
                                {/*                    {!approvers.includes(userProfile.id) ?*/}
                                {/*                        <UncontrolledTooltip*/}
                                {/*                            placement={"bottom"}*/}
                                {/*                            target={"reject"}>*/}
                                {/*                            You are not authorized to Approve or Reject*/}
                                {/*                        </UncontrolledTooltip>*/}
                                {/*                        : null}*/}
                                {/*                </React.Fragment>*/}
                                {/*            </div>*/}
                                {/*            {*/}
                                {/*                allData !== undefined ?*/}
                                {/*                    allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?*/}
                                {/*                        <div className="my-3">*/}
                                {/*                            <Modals*/}
                                {/*                                name={"REJECTION REASON"}*/}
                                {/*                                header={"Here are the reasons why your application has been rejected"}*/}
                                {/*                            />*/}
                                {/*                        </div>*/}
                                {/*                        : null*/}
                                {/*                    : null*/}
                                {/*            }*/}
                                {/*        </Row>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            </>
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Survey Status&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status === "" ?
                                        <>
                                            <CardText>
                                                Waiting for Survey Status from Surveyor / Chief Surveyor.
                                            </CardText>
                                            <CardText>
                                                Survey Status : Pending...
                                            </CardText>
                                        </>
                                        :
                                        allData.vesselActivity.stages_info.stages.stage_survey_status.survey_status === "success" ?
                                            <>
                                                <CardText>
                                                    Vessel Survey is a Success. Your Registration Number
                                                    is {allData.vesselActivity.stages_info.stages.stage_survey_status.registration_number}.
                                                </CardText>
                                                <CardText>
                                                    Survey Status : Success
                                                </CardText>
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?
                                                            <div className="my-3">
                                                                <Modals
                                                                    name={"REJECTION REASON"}
                                                                    header={"Here are the reasons why your application has been rejected"}
                                                                    reasons={allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason}
                                                                />
                                                            </div>
                                                            : null
                                                        : null
                                                }
                                            </>
                                            :
                                            <>
                                                <CardText>
                                                    Vessel Survey has been Rejected.
                                                    Please check the latest Dated Rejection Reasons for why your Vessel
                                                    Survey was Rejected.
                                                    After rectifying the reasons, request another date from Survey Date
                                                    step.
                                                </CardText>
                                                <CardText>
                                                    Survey Status : Rejected
                                                </CardText>
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason.length !== 0 ?
                                                            <div className="my-3">
                                                                <Modals
                                                                    name={"REJECTION REASON"}
                                                                    header={"Here are the reasons why your application has been rejected"}
                                                                    reasons={allData.vesselActivity.stages_info.stages.stage_survey_status.rejection_reason}
                                                                />
                                                            </div>
                                                            : null
                                                        : null
                                                }
                                            </>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    );
};

export default SurveyStatus;
