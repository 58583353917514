import axios from 'axios';
import history from "../../routes/history";
import Paths from "../../config/Paths";
import {
    CREATE_VESSEL,
    COUNT_VESSELS,
    FETCH_VESSELS,
    UPDATE_VESSEL,
    // DELETE_VESSEL,
    SEARCH_VESSEL,
    CREATE_SUCCESS,
    UPDATE_SUCCESS,
    // DELETE_SUCCESS,
    CREATE_ERROR,
    UPDATE_ERROR,
    // DELETE_ERROR,
    EMPTY_ERROR,
    AUTH_TIMEOUT,
    COUNT_EXPIRING,
    COUNT_EXPIRED,
} from './ActionTypesAllModules';

import moment from "moment";
import routesHelper from '../../routes/routes.helper';


export const CreateVessel = values => dispatch =>

    axios
        .post(Paths.api.CreateVessel(), values)
        .then(response => {
            routesHelper.vesselForm.edit.replace(response.data.id);
            dispatch({type: CREATE_VESSEL, payload: response.data});
            dispatch({
                type: CREATE_SUCCESS,
                payload: {msg: 'Vessel Created Successfully', variant: 'success'},
            });
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: CREATE_ERROR,
                    payload: {
                        msg: 'Vessel Creation Failed! Try again',
                        variant: 'error',
                    },
                });
            }
        });


export const CountVessels = () => {

    return (dispatch) => {
        axios.get(Paths.api.CountVessels())
            .then((response) => {
                dispatch({type: COUNT_VESSELS, payload: response.data});
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                } else {
                    dispatch({
                        type: EMPTY_ERROR,
                        payload: {msg: "Could not fetch Vessels Count! Try again", variant: "error"}
                    });
                }
            });
    };
};

const logout = () => {
    localStorage.removeItem("user");
    history.push("/");
};

export const FetchVessels = (offset, limit, column = "createdAt", order = "DESC") => dispatch => {
    const config = {
        params: {
            filter: {offset: offset, limit: limit, order: `${column} ${order}`},
        },
    };
    return axios
        .get(Paths.api.FetchVessels(offset, limit), config)
        .then(response => {
            dispatch({type: FETCH_VESSELS, payload: response.data});
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: EMPTY_ERROR,
                    payload: {
                        msg: 'Could not fetch Vessels! Try again',
                        variant: 'error',
                    },
                });
            }
        });
};

export const FetchExpiredVessels = (offset, limit, column = "createdAt", order = "DESC", type = "count") => dispatch => {
    const dateObj = new Date();
    const date = dateObj.toISOString();
    const config = {
        params: {
            filter: {
                offset: offset,
                limit: limit,
                order: `${column} ${order}`,
                where: {"registration_expiry_date": {"lt": date}}
            },
        },
    };
    return axios
        .get(Paths.api.FetchVessels(offset, limit), config)
        .then(response => {
            if (response.data && type === "count") {
                dispatch({type: COUNT_EXPIRED, payload: response.data.length});
            } else {
                dispatch({type: FETCH_VESSELS, payload: response.data});
            }

        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: EMPTY_ERROR,
                    payload: {
                        msg: 'Could not Expired fetch Vessels! Try again',
                        variant: 'error',
                    },
                });
            }
        });
};

export const FetchExpiringVessels = (offset, limit, column = "createdAt", order = "DESC", type = "count") => dispatch => {
    const currentDateObj = moment();
    const currentDate = currentDateObj.toISOString();
    const dateObj = moment().add(10, 'days');
    const date = dateObj.toISOString();

    const config = {
        params: {
            filter: {
                offset: offset,
                limit: limit,
                order: `${column} ${order}`,
                where: {"registration_expiry_date": {"between": [currentDate, date]}}
            },
        },
    };
    return axios
        .get(Paths.api.FetchVessels(offset, limit), config)
        .then(response => {
            if (response.data && type === "count") {
                dispatch({type: COUNT_EXPIRING, payload: response.data.length});
            } else {
                dispatch({type: FETCH_VESSELS, payload: response.data});
            }

        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: EMPTY_ERROR,
                    payload: {
                        msg: 'Could not Expired fetch Vessels! Try again',
                        variant: 'error',
                    },
                });
            }
        });
};

export const UpdateVessel = (values, VesselId) => dispatch =>

    axios
        .patch(Paths.api.UpdateVessel(VesselId), values)
        .then(response => {
            dispatch({type: UPDATE_VESSEL, payload: response.data});
            dispatch({
                type: UPDATE_SUCCESS,
                payload: {msg: 'Vessel Updated Successfully', variant: 'success'},
            });
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: UPDATE_ERROR,
                    payload: {msg: 'Vessel Update Failed! Try again', variant: 'error'},
                });
            }
        });


export const SearchVessel = (searchCategory, searchTerm, column = "createdAt", order = "DESC") => dispatch => {
    if (searchCategory === "customer.name") {
        searchCategory = "customer";
    }
    const config = {
        params: {
            filter: {
                order: `${column} ${order}`,
                where: {
                    [searchCategory]: {"like": [searchTerm] + ".*", "options": "i"}
                }
            }
        },
    };

    axios
        .get(Paths.api.SearchVessel(searchCategory, searchTerm), config)
        .then(response => {
            dispatch({type: SEARCH_VESSEL, payload: response.data});
        })
        .catch(error => {
            if (error.response && error.response.status === 403) {
                //token expired, forbidden => logging out
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: EMPTY_ERROR,
                    payload: {
                        msg: 'Search Failed! Refresh and Try again',
                        variant: 'error',
                    },
                });
            }
        });
};
