import axios from 'axios';
import Path from "../../../config/Paths";
import history from "../../../routes/history";
import * as status from '../ActionTypes/statusActionTypes';
import {AUTH_TIMEOUT} from "../ActionTypes/commonActionTypes";
import {UPDATE_BELOW_VESSEL} from "../ActionTypes/b12ActionTypes";

const logout = () => {
    localStorage.removeItem("user");
    history.push("/");
};

export const UploadBelowVesselFile = file => {
    return (dispatch) => {
        axios
            .post(Path.below12Api.UploadFile(), file.formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then(response => {
                let dataToBeStored = {
                    originalFileName: response.data.result.files.files[0].originalFilename,
                    location: response.data.result.files.files[0].providerResponse.location
                };
                if (dataToBeStored.location.length !== 0) {
                    file.allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.enclosures.map(each => {
                        if (file.name in each) {
                            each[file.name] = dataToBeStored;
                        }
                    });
                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(file.allData.vesselActivity.id), file.allData.vesselActivity)
                        .then(response => {
                            file.allData.vesselActivity = {...response.data};
                            dispatch({type: UPDATE_BELOW_VESSEL, payload: file.allData});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {title: "UPLOADED", message: "File Uploaded Successfully", variant: 'success'},
                            });
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout();
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'File Uploading Failed! Try again',
                                    variant: 'error'
                                },
                            })
                        }
                    })
                }
            }).catch(error => {
            if (error.response && error.response.status === 403) {
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'File Uploading Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        })
    }
};

export const DeleteBelowVesselFile = file => {
    return (dispatch) => {
        axios.delete(Path.below12Api.DeleteFile(file.fileName))
            .then(response => {
                if (response.status === 200) {
                    file.allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.enclosures.map(each => {
                        if (file.name in each) {
                            each[file.name] = {};
                        }
                    });
                    axios.patch(Path.below12Api.UpdateBelowVesselActivity(file.allData.vesselActivity.id), file.allData.vesselActivity)
                        .then(response => {
                            file.allData.vesselActivity = {...response.data};
                            dispatch({type: UPDATE_BELOW_VESSEL, payload: file.allData});
                            dispatch({
                                type: status.SUCCESS,
                                payload: {title: "DELETED", message: "File Deleted Successfully", variant: 'success'},
                            });
                        }).catch(error => {
                        if (error.response && error.response.status === 403) {
                            logout();
                        }
                        if (error.response && error.response.status === 401) {
                            dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
                        } else {
                            dispatch({
                                type: status.ERROR,
                                payload: {
                                    title: "FAILED",
                                    message: 'File Deleting Failed! Try again',
                                    variant: 'error'
                                },
                            })
                        }
                    })
                }
            }).catch((error) => {
            if (error.response && error.response.status === 403) {
                logout();
            }
            if (error.response && error.response.status === 401) {
                dispatch({type: AUTH_TIMEOUT, payload: error.response.status});
            } else {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'Stored File Deleting Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        });
    }
};

export const DownloadBelowVesselFile = file => {
    return (dispatch) => {
        axios.get(Path.below12Api.DownloadFile(file), {
            responseType: "blob",
        }).then(({data}) => {
            const downloadUrl = window.URL.createObjectURL(
                new Blob([data], {type: 'application/pdf'})
            );
            // let tab = window.open();
            // tab.location.href = downloadUrl;
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.target = '_blank';
            // link.setAttribute("download", file);
            // document.body.appendChild(link);
            link.click();
            link.remove();
        }).catch((error) => {
            if (error) {
                dispatch({
                    type: status.ERROR,
                    payload: {
                        title: "FAILED",
                        message: 'File Opening Failed! Try again',
                        variant: 'error'
                    },
                })
            }
        });
    }
};
