import React from 'react';
import $ from "jquery";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import moment from "moment";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import {Button} from "reactstrap";

const VesselBasicFormType = {
    formFieldSource: ApplicationFormFieldsSource,
};

const ApplicationForRegistrationSurveyPrint = ({formFieldSource}: VesselBasicFormType) => {

    let printRegistrationOrSurvey = () => {

        let top_left_X_Y_Coordinates = 0;
        let PDF_Width = 1240;
        let PDF_Height = 1754;
        let pdf = "";

        $("#print-wrap-page1").attr("hidden", false);
        html2canvas($("#print-wrap-page1")[0], {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
        });
        $("#print-wrap-page1").attr("hidden", true);

        $("#print-wrap-page2").attr("hidden", false);
        html2canvas($("#print-wrap-page2")[0], {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
        });
        $("#print-wrap-page2").attr("hidden", true);

        $("#print-wrap-page3").attr("hidden", false);
        html2canvas($("#print-wrap-page3")[0], {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf.addPage(PDF_Width, PDF_Height);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
            pdf.output('dataurlnewwindow');
        });
        $("#print-wrap-page3").attr("hidden", true);
    };

    return (
        <div>
            <Button color={"success"}
                    onClick={() => printRegistrationOrSurvey()}
                    style={{width: 260}}
                    className="mr-1 mb-1">
                Application for Registration / Survey
            </Button>

            <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                <div id="print-wrap-page1" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center"
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 70px 0px"
                        }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 28,
                                    fontWeight: "bold"
                                }}>Application for Registration /Survey of Water sports craft/Vessel below 12
                                    Passenger</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 28, fontWeight: "bold"}}>Capacity
                                    as per Go.Ms.No.10</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 50px"
                        }}>
                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                <div style={{display: "flex", justifyContent: "flex-start", marginRight: 625}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>To</h4>
                                </div>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Date :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.date === undefined ? "" : moment(formFieldSource.registration_or_survey.date).format("DD-MM-YYYY")}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                <div style={{display: "flex", justifyContent: "flex-start", marginRight: 215}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>The Registering Authority
                                        / Chief Surveyor</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    background: "#fff"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Place :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.place}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Kakinada/Machilipatnam.</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>I/We the authorized persons of
                                    firm <span style={{fontWeight: "bold"}}>{formFieldSource.registration_or_survey.firm_name}</span> hereby apply to you to make necessary</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>arrangement for the Survey of the Water Sports Craft as per Go
                                    Ms No.10 as detailed below.</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", paddingTop: "30px"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>The particulars of the Craft
                                    are as under:</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 70px"
                        }}>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>1. Name of the Craft
                                        :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.name_of_the_craft}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>2. Type of the Craft
                                        :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.type_of_the_craft}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>3. Registration Number of
                                        the craft :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.registration_number_of_the_craft}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>4. Port of registry of
                                        Craft :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.port_of_registry_of_craft}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>5. Tonnage</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;(i) Net :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.net_tonnage}</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;(ii) Gross
                                        :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.gross_tonnage}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>6. Material of the Craft
                                        :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.material_of_the_craft}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>7. Year of Built & Builder
                                        Address :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.year_of_built + " & " + formFieldSource.registration_or_survey.builder_address}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>8. Passenger Capacity
                                        :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.passenger_capacity}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>9. Craft Dimensions (L x B
                                        x D) :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.craft_dimension_length === undefined ? "-" : formFieldSource.registration_or_survey.craft_dimension_length} x {formFieldSource.registration_or_survey.craft_dimension_berth === undefined ? "-" : formFieldSource.registration_or_survey.craft_dimension_berth} x {formFieldSource.registration_or_survey.craft_dimension_depth === undefined ? "-" : formFieldSource.registration_or_survey.craft_dimension_depth}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>10. Place & date of last
                                        survey :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.date_of_last_survey === undefined ? formFieldSource.registration_or_survey.place_of_last_survey : formFieldSource.registration_or_survey.place_of_last_survey + " & " + moment(formFieldSource.registration_or_survey.date_of_last_survey).format('DD-MM-YYYY')}</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>11. Engine Particulars
                                        :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 20px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Make
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.engine_make}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Type
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.engine_type}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>c. Serial No
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.engine_serial_Number}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                <div id="print-wrap-page2" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center"
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 70px"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                padding: "0px 30px 0px 30px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    background: "#fff",
                                    paddingTop: "30px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>d. HP :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.engine_hp}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>12. LSA / FFA
                                        /communication equipment :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 0px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Adult Life
                                                Jackets :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.adult_life_jackets}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Child Life
                                                Jackets :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.child_life_jackets}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>c. Life Buoys
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.life_buoys}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>d. Fire Buckets
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.fire_buckets}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>e. Fire
                                                Extinguishers :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.fire_extinguishers}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>f. Rescue quoits
                                                with 30m rope :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.rescue_quoits_with_30m_rope}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>g. Communication
                                                Equipment (Mobile/Walkie Talkie/VHF/AIS etc) :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.communication_equipment}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>13. Insurance Details
                                        :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 0px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Hull &Machinery
                                                Insurance company :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.hull_and_machinery_insurance_company}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Policy No :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.hull_and_machinery_policy_number}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Insured value/person :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.hull_and_machinery_insured_value_or_person}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Valid Till :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.hull_and_machinery_valid_till === undefined ? "" : moment(formFieldSource.registration_or_survey.hull_and_machinery_valid_till).format("DD-MM-YYYY")}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Passenger
                                                Insurance company :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.passenger_insurance_company}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Policy No :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.passenger_insurance_policy_number}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Insured value/person :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.passenger_insurance_insured_value_or_person}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25
                                            }}>&nbsp;&nbsp;&nbsp;&nbsp;Valid Till :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.passenger_insurance_valid_till === undefined ? "" : moment(formFieldSource.registration_or_survey.passenger_insurance_valid_till).format("DD-MM-YYYY")}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>14. Driver certificate
                                        details (PBH& LST) from NIWS/RYA/YAI :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.driver_certificate_details}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>15. Details of other
                                        machineries if any :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.details_of_other_machineries_if_any}</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>16. Owner ‘s :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 0px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.owners_name}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                <div id="print-wrap-page3" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center"
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 70px"
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                padding: "0px 30px 0px 30px"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    background: "#fff",
                                    paddingTop: "30px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.owners_address}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    background: "#fff",
                                    paddingTop: "30px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Email :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.owners_email}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    background: "#fff",
                                    paddingTop: "30px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Telephone :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.owners_phone_number}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>17. Company Name & Address
                                        if different from above :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 0px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.company_name_if_different}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.company_address_if_different}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                paddingTop: "40px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>18. Agent's :&nbsp;</h4>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    background: "#fff",
                                    padding: "0px 30px 0px 30px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.agents_name}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.agents_address}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        background: "#fff",
                                        paddingTop: "30px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Telephone
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.agents_phone}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>19. Date and time of
                                        Request for visit of surveyor :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor === undefined ? "" : moment(formFieldSource.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format("DD-MM-YYYY")}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>20. Place of proposed
                                        Survey :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.place_of_proposed_survey}</h4>
                                </div>
                            </div>
                            <div
                                style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>21. NOC Obtained from
                                        Tourism Department for Registration (Yes / No) :&nbsp;</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{
                                        fontFamily: "Times New Roman",
                                        fontSize: 25,
                                        fontWeight: "bold"
                                    }}>{formFieldSource.registration_or_survey.noc_obtained_from_tourism_department_for_registration === true ? "Yes" : "No"}</h4>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 50px"
                        }}>
                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Station :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.place}</h4>
                                    </div>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-end", flexGrow: 1}}>
                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Signature of
                                        Owner/Authorized Person</h4>
                                </div>
                            </div>
                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Date :&nbsp;</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold"
                                        }}>{formFieldSource.registration_or_survey.date === undefined ? "" : moment(formFieldSource.registration_or_survey.date).format("DD-MM-YYYY")}</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Enclosures : </h4>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                background: "#fff",
                                padding: "0px 20px 50px 20px"
                            }}>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>1. Operator
                                        certificate (PBH& LST) copy from NIWS/RYA/YAI etc</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>2. Builder
                                        Certificate Copy</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>3. Hull and
                                        Machinery Insurance Certificates copy</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>4. Passenger
                                        Insurance Certificates copy</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>5. Copy of
                                        Certificate of Registration (if already registered)</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>6. Document
                                        establishing the Authority of Authorized Person (if making request)</h4>
                                </div>
                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                    <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>7. Copy of NOC
                                        from APTDC.</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ApplicationForRegistrationSurveyPrint;
