import React, { useState, useEffect } from "react";
import "./provisional.css";
import Vessel from "../vessels/Vessel";
import Axios from "axios";
import paths from "../../config/Paths";
import apgov_logo from "../../assets/img/brands/apgov_logo.png";
import dept_of_ports from "../../assets/img/brands/dept_of_ports.png";

type ProvisionalCertificateType = {
  match?: {
    params: {
      vesselId: string
    }
  }
};

const ProvisionalCertificate: React.FC<ProvisionalCertificateType> = (
  props: ProvisionalCertificateType
) => {
  const vesselId = props.match.params.vesselId;
  const [vesselState, setVessel] = useState(new Vessel());
  vesselState.subscribeForStateChange(setVessel);

  useEffect(() => {
    Axios.get(paths.api.FetchVessel(vesselId)).then(response => {
      setVessel(new Vessel({ ...vesselState, ...response.data }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    hull_details,
    propulsion_of_engines,
    equipment_details,
    fire_fighting,
    machinery,
    accomodation,
    freeboard,
    navigation_equipment,
    life_saving_equipment,
    Owner
  } = vesselState;
  return (
    <div className="container">
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          marginTop: "2%"
        }}
      >
        <img src={apgov_logo} alt="AP Govt. Logo" style={{ width: "100px" }} />
        <h3 style={{ textAlign: "center" }}>
          ANDHRA PRADESH MARITIME BOARD <br /> GOVERNMENT OF ANDHRA PRADESH{" "}
          <br /> INDIA
				<h6 style={{ textAlign: "center" }}>
					KAKINADA -533001, INDIA
				</h6>
        </h3>
        <img
          src={dept_of_ports}
          alt="Dept. of Ports"
          style={{ width: "100px" }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        <p>
          Phone: <br /> E-mail: portofficerkkd@gmail.com
        </p>
        <p>Website: ports.ap.gov.in</p>
      </div>
      <hr />
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
          fontWeight: "bold",
          textDecoration: "underline"
        }}
      >
        CERTIFICATE OF REGISTRY
      </div>
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
          marginTop: "1%"
        }}
      >
        of Motor Vessel under Section 3.9 of the AP Inland Vessel Rules 2017
      </div>

      <div style={{ fontSize: "20px", textAlign: "left", marginTop: "1%" }}>
        This is to certify that in accordance with section 19F of Inland Vessel
        Act 1917 (1 of 1917) the registration requested for hereby Grants and
        Authorizes Certificate of Registry to the vessel whose particular are
        given below
      </div>

      <div className="space"></div>
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "1%"
        }}
      >
        <i>
          <u>PARTICULARS OF VESSEL</u>
        </i>
      </div>
      <table className="customTable">
        <thead>
          <tr>
            <th>Name of Boat</th>
            <th>{vesselState.name}</th>
            <th>Registration Number</th>
            <th>{vesselState.official_no_of_vessel}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Owners Name and address</td>
            <td>{Owner.name}</td>
            <td>Date and Place of Built</td>
            <td></td>
          </tr>
          <tr>
            <td>Previous names of vessel</td>
            <td></td>
            <td>Previous Registration Number/s</td>
            <td></td>
          </tr>
          <tr>
            <td>Type of vessel</td>
            <td>{vesselState.vessel_type}</td>
            <td>Passenger Capacity</td>
            <td>{vesselState.freeboard.no_of_passengers}</td>
          </tr>
          <tr>
            <td>Length of vessel (m)</td>
            <td>{vesselState.length}</td>
            <td>L.B.P (m)</td>
            <td></td>
          </tr>
          <tr>
            <td>Breadth of vessel (m)</td>
            <td>{vesselState.breadth}</td>
            <td>Material Of construction</td>
            <td>{hull_details.hull_material}</td>
          </tr>
          <tr>
            <td>Depth of vessel (m)</td>
            <td>{vesselState.depth}</td>
            <td>Loaded Draft (m)</td>
            <td></td>
          </tr>
          <tr>
            <td>Gross tonnage</td>
            <td>{vesselState.tonnage_gross}</td>
            <td>Speed (kn)</td>
            <td></td>
          </tr>
          <tr>
            <td>Net tonnage</td>
            <td></td>
            <td>Number of Decks</td>
            <td></td>
          </tr>
          <tr>
            <td>Deat Weight (Mt)</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div className="space"></div>
      <div
        style={{
          fontSize: "20px",
          textAlign: "center",
          fontWeight: "bold",
          marginTop: "1%"
        }}
      >
        <i>
          <u>MAIN ENGINE PARTICULARS</u>
        </i>
      </div>
      <table className="customTable">
        <thead>
          <tr>
            <th>Main Engine Make</th>
            <th></th>
            <th>Number of Rudders</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Main Engine Serial Number</td>
            <td></td>
            <td>Number of Propellers</td>
            <td></td>
          </tr>
          <tr>
            <td>B.H.P @ rpm</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Engines Year of Built</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Number of Cylinders</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Type of Engine</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div className="space"></div>
      <div style={{ fontSize: "20px", textAlign: "left", marginTop: "1%" }}>
        This vessel is mortgage to ......................... for RS
        .............................. as per letter no .....................
      </div>

      <div style={{ fontSize: "20px", marginTop: "1%", marginLeft: "2%" }}>
        Restrictions if any:
        <br />
        Date Issued on: .................. at ............
        <br />
        Valid Till: ................
      </div>

      <div style={{ fontSize: "20px", fontWeight: "bold" }}>
        ***The ORIGINAL Certificate of Registry needs to be produced at the time
        of Survey by the Port Department.
      </div>

      <div style={{ fontSize: "20px", textAlign: "right" }}>
        Registering Authority<span style={{ marginLeft: "10%" }}></span>
      </div>

      <hr />

      <div style={{ fontSize: "20px", textAlign: "left" }}>Note:-</div>

      <div style={{ fontSize: "20px", marginTop: "1%", marginLeft: "2%" }}>
        <ol>
          <li>
            This certificate of registration to be produced for inspeciton on
            demand by any authority authorized by the State Government.
          </li>
          <li>
            This certificate must be surrendered to the Registering Authority if
            so required by him.
          </li>
          <li>
            While the certificate is in force, the vessel name and the
            registration mark as painted or otherwise marked in position
            approved by the Registering Authority must not be removed or
            defaced.
          </li>
          <li>
            In case of any Accident occasioning loss of life, or efficiency of
            the vessel, either the hull or in any part of the machineary, a
            report by letter signed by the owner or master, is to be forwarded
            to the Registering Authority, within 24 hours after the happening of
            the accident or as soon thereafter as possible.
          </li>
          <li>Illegal and Anti National activities not to be undertaken</li>
          <li>Survey of vessel is undertaken as per IV Rules.</li>
          <li>
            Registration is valid subject to validity of certificate of Survey.
          </li>
          <li>The vessel should ensure that valid insurance all the time.</li>
        </ol>
      </div>
    </div>
  );
};
export default ProvisionalCertificate;
