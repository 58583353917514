import React from "react";
import VesselFireAppliance, { Hydrants } from "../Vessel.Fireappliance";
import { Input, Label, FormGroup } from "reactstrap";
type HydrantsFormType = {
    hydrants: Hydrants;
    isViewMode: boolean;
};
const HydrantsForm: React.FC<HydrantsFormType> = ({
    hydrants,
    isViewMode
}: HydrantsFormType) => {
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.type}
                        onChange={e =>
                            hydrants.setField("type", e.target.value)
                        }
                        placeholder="Type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.condition}
                        onChange={e =>
                            hydrants.setField("condition", e.target.value)
                        }
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.location}
                        onChange={e =>
                            hydrants.setField("location", e.target.value)
                        }
                        placeholder="Location"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Fire_mains</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.fire_mains}
                        onChange={e =>
                            hydrants.setField("fire_mains", e.target.value)
                        }
                        placeholder="Fire_mains"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Material</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.material}
                        onChange={e =>
                            hydrants.setField("material", e.target.value)
                        }
                        placeholder="Material"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.number}
                        onChange={e =>
                            hydrants.setField("number", e.target.value)
                        }
                        placeholder="Number"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Diameter</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.diameter}
                        onChange={e =>
                            hydrants.setField("diameter", e.target.value)
                        }
                        placeholder="Diameter"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>No_of_hydrants</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hydrants.no_of_hydrants}
                        onChange={e =>
                            hydrants.setField("no_of_hydrants", e.target.value)
                        }
                        placeholder="No_of_hydrants"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default HydrantsForm;
