import React from 'react';
import $ from "jquery";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import moment from "moment";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import { Button } from "reactstrap";

const VesselBasicFormType = {
    formFieldSource: ApplicationFormFieldsSource,
};

const CertificateOfSurveyPrint = ({ formFieldSource }: VesselBasicFormType) => {

    let printCertificateOfSurvey = () => {

        let top_left_X_Y_Coordinates = 0;
        let PDF_Width = 1240;
        let PDF_Height = 1754;
        let pdf = "";

        $("#print-wrap-page5").attr("hidden", false);
        html2canvas($("#print-wrap-page5")[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
            pdf.output('dataurlnewwindow');
        });
        $("#print-wrap-page5").attr("hidden", true);
    };

    let getExpiryDate = () => {
        let issuedDate = new Date(formFieldSource.vesselActivity.stages_info.stages.stage_survey_date.survey_date);
        issuedDate.setFullYear(issuedDate.getFullYear() + 1);
        issuedDate.setDate(issuedDate.getDate() - 1);

        return (
            issuedDate.toLocaleString("en", { day: "numeric" }) + ' ' +
            issuedDate.toLocaleString("en", { month: "short" }) + ' ' +
            issuedDate.toLocaleString("en", { year: "numeric" })
        )
    };

    return (
        <div>
            <Button color={"success"}
                onClick={() => printCertificateOfSurvey()}
                style={{ width: 260 }}
                className="mr-1 mb-1">
                Certificate of Survey
            </Button>

            <div style={{ width: 1240, display: "flex", justifyContent: "center" }}>
                <div id="print-wrap-page5" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}>

                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 25,
                                    }}>ANNEXURE - 2 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 50px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>CERTIFICATE OF SURVEY</h4>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>TO REMAIN IN FORCE UNTIL THE:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                                <span>{getExpiryDate()}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>NAME OF WATER SPORT CRAFT:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                                <span>{formFieldSource.registration_or_survey.name_of_the_craft}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>NAME OF THE OWNER:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                                <span>{formFieldSource.registration_or_survey.owners_name}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>NAME OF THE OPERATOR:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                                <span>{formFieldSource.registration_or_survey.name_of_the_operator}</span>
                                            </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 40px 0px"
                            }}>
                                <table style={{
                                    width: "100%",
                                    border: "1px solid black",
                                    borderCollapse: "collapse",
                                    fontFamily: "sans-serif",
                                    fontSize: 20
                                }}>
                                    <tbody>
                                        <tr>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>Place of Registry & No.</th>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>GRT / NRT</th>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>Engine Details / Speed</th>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>Hull Details</th>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>Draft</th>
                                            <th style={{ border: "2px solid black", borderCollapse: "collapse" }}>Passenger & Crew Capacity</th>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", height: 35 }}>
                                                &nbsp;{formFieldSource.registration_or_survey.vessel_registration_place}<br />&nbsp;{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.registration_number}
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">
                                                {formFieldSource.registration_or_survey.gross_tonnage} / {formFieldSource.registration_or_survey.net_tonnage}
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>
                                                &nbsp;Make:&nbsp;{formFieldSource.registration_or_survey.engine_make}
                                                <br />&nbsp;Model:&nbsp;{formFieldSource.registration_or_survey.engine_type}
                                                <br />&nbsp;Sl.No:&nbsp;{formFieldSource.registration_or_survey.engine_serial_Number}
                                                <br />&nbsp;H.P: &nbsp;{formFieldSource.registration_or_survey.engine_hp}
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">
                                                <span>{formFieldSource.registration_or_survey.material_of_the_craft}</span>
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", align: "center" }}>
                                                <span>{formFieldSource.registration_or_survey.loaded_draft}&nbsp;m</span></td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">
                                                <span>{formFieldSource.registration_or_survey.passenger_capacity} + {formFieldSource.registration_or_survey.crew_capacity}&nbsp;Crew</span>
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", height: 35 }}
                                                colSpan="6"> </td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>LSA / FFA EQUIPMENT:&nbsp;</h4>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "0px 0px 10px 40px" }}>

                                    {formFieldSource.registration_or_survey.adult_life_jackets === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>1. Adult Life Jackets&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.adult_life_jackets + " Nos"}
                                            </h4>
                                        </div>
                                    }

                                    {formFieldSource.registration_or_survey.child_life_jackets === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>2. Child Life Jackets&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.child_life_jackets + " Nos"}
                                            </h4>
                                        </div>
                                    }

                                    {formFieldSource.registration_or_survey.life_buoys === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>3. Life Buoy&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.life_buoys + " Nos"}
                                            </h4>
                                        </div>
                                    }

                                    {formFieldSource.registration_or_survey.fire_buckets === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>4. Fire Bucket&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.fire_buckets + " Nos"}
                                            </h4>
                                        </div>
                                    }

                                    {formFieldSource.registration_or_survey.four_point_five_fire_extinguishers === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>5. 4.5 kg DCP Fire Extinguisher&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.four_point_five_fire_extinguishers + " Nos"}
                                            </h4>
                                        </div>
                                    }

                                    {formFieldSource.registration_or_survey.one_point_nine_fire_extinguishers === undefined ? "" :
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>6. 1. 9 Ltr Foam Fire Extinguisher&nbsp;-&nbsp;</h4>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                {formFieldSource.registration_or_survey.one_point_nine_fire_extinguishers + " Nos"}
                                            </h4>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>INSURANCE DETAILS:&nbsp;</h4>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "0px 0px 10px 40px" }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>Hull & Machinery Insurance Valid Till&nbsp;</h4>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                            {formFieldSource.registration_or_survey.hull_and_machinery_valid_till === undefined ? "" : moment(formFieldSource.registration_or_survey.hull_and_machinery_valid_till).format('DD-MM-YYYY')}
                                        </h4>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>Passenger Insurance Valid Till&nbsp;</h4>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                            {formFieldSource.registration_or_survey.passenger_insurance_valid_till === undefined ? "" : moment(formFieldSource.registration_or_survey.passenger_insurance_valid_till).format('DD-MM-YYYY')}
                                        </h4>
                                    </div>
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>DETAILS OF TESTS CARRIED OUT:&nbsp;</h4>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", padding: "0px 0px 10px 40px" }}>
                                    {
                                        formFieldSource !== undefined ?
                                            formFieldSource.vesselActivity !== undefined ?
                                                formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.details_of_tests_carried_out.length !== 0 ?
                                                    formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.details_of_tests_carried_out.map((each, index) =>
                                                        <div key={index} style={{ display: "flex", justifyContent: "flex-start" }}>
                                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>
                                                                {/*{index + 1}.&nbsp;{each}</h4>*/}
                                                                {each}</h4>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                                :
                                                null
                                            :
                                            null
                                    }
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 10px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>LIMITS OF OPERATION:&nbsp;</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20 }}>{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.limitations_of_operations}</h4>
                                    </div>
                                </div>
                            </div>

                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 50px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 20, fontWeight: "bold" }}>Note: The validity of this certificate deemed to be expired if insurance of craft & passengers are not valid.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            background: "#fff",
                            padding: "0px 0px 20px 0px"
                        }}>
                            <div style={{ display: "flex", flexGrow: 1, flexDirection: "column", justifyContent: "center", background: "#fff" }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>Issued on:&nbsp;</h4>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                            {moment(formFieldSource.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</h4>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>Place of
                                            Inspection:&nbsp;</h4>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 25, fontWeight: "bold" }}>
                                            {formFieldSource.registration_or_survey.place_of_proposed_survey}</h4>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 0px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25, marginBottom: 0 }}>CHIEF SURVEYOR</h4>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25, marginBottom: 0 }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                </div>
                            </div>
                        </div>
                        {/* <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 0px 0px"
                        }}>
                            <h4 style={{fontFamily: "sans-serif", fontSize: 20, marginBottom: 0}}>This is a digitally generated form, does not require signature. Date & Time - {moment(formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].timeStamp).format('DD-MM-YYYY h:mm:ss a')}</h4>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
};

export default CertificateOfSurveyPrint;
