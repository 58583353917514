import { OwnerType } from "../forms/Vessel.form.types";

export default class VesselOwner {
    onChange: (updatedOwner: VesselOwner) => void = null;
    name: string = "";
    address: string = "";
    telephone_no: number = 0;

    constructor(
        params: OwnerType,
        onChange: (updatedOwner: VesselOwner) => void
    ) {
        params = {
            ...{ address: "", name: "", telephone_no: 0 },
            ...params
        };
        this.address = params.address;
        this.name = params.name;
        this.telephone_no = params.telephone_no;
        this.onChange = onChange;
    }

    setName(name) {
        this.name = name;
        this.onChange(this);
    }
    setAddress(address) {
        this.address = address;
        this.onChange(this);
    }
    setTelephone_no(telephone_no) {
        this.telephone_no = telephone_no;
        this.onChange(this);
    }
}
