import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import NavigationEquipment from "./Navigation.equipment";
type NavigationEquipmenFormType = {
    navigation_equipment: NavigationEquipment;
    isViewMode: boolean;
};
const NavigationEquipmentForm: React.FC<NavigationEquipmenFormType> = ({
    navigation_equipment,
    isViewMode
}: NavigationEquipmenFormType) => {
    const {
        log_book,
        nautical_publications,
        communication_equipment,
        echo_sounder,
        gps_receiver,
        compass,
        radar,
        shapes,
        sound_signals: soundEquipment,
        navigation_lights: navigationLights
    } = navigation_equipment;
    return (
        <div className="row">
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Navigation Lights</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.number}
                                onChange={e =>
                                    navigationLights.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.condition}
                                onChange={e =>
                                    navigationLights.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.location}
                                onChange={e =>
                                    navigationLights.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Particulars</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.particulars}
                                onChange={e =>
                                    navigationLights.setField(
                                        "particulars",
                                        e.target.value
                                    )
                                }
                                placeholder="Particulars"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Certificate</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.certificate}
                                onChange={e =>
                                    navigationLights.setField(
                                        "certificate",
                                        e.target.value
                                    )
                                }
                                placeholder="Certificate"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Main_mast</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.main_mast}
                                onChange={e =>
                                    navigationLights.setField(
                                        "main_mast",
                                        e.target.value
                                    )
                                }
                                placeholder="Main_mast"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Auxiliary_mast</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.auxiliary_mast}
                                onChange={e =>
                                    navigationLights.setField(
                                        "auxiliary_mast",
                                        e.target.value
                                    )
                                }
                                placeholder="Auxiliary_mast"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Port</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.port}
                                onChange={e =>
                                    navigationLights.setField(
                                        "port",
                                        e.target.value
                                    )
                                }
                                placeholder="Port"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Starboard</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.starboard}
                                onChange={e =>
                                    navigationLights.setField(
                                        "starboard",
                                        e.target.value
                                    )
                                }
                                placeholder="Starboard"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Stem</Label>
                            <Input
                                disabled={isViewMode}
                                value={navigationLights.stem}
                                onChange={e =>
                                    navigationLights.setField(
                                        "stem",
                                        e.target.value
                                    )
                                }
                                placeholder="Stem"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Anchor</Label>
                            <Input
                                disabled={isViewMode}
                                type="text"
                                value={navigationLights.anchor}
                                onChange={e =>
                                    navigationLights.setField(
                                        "anchor",
                                        e.target.value
                                    )
                                }
                                placeholder="Anchor"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Sound Equipments</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={soundEquipment.number}
                                onChange={e =>
                                    soundEquipment.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={soundEquipment.location}
                                onChange={e =>
                                    soundEquipment.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={soundEquipment.condition}
                                onChange={e =>
                                    soundEquipment.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Sound_signals_type</Label>
                            <Input
                                disabled={isViewMode}
                                value={soundEquipment.sound_signals_type}
                                onChange={e =>
                                    soundEquipment.setField(
                                        "sound_signals_type",
                                        e.target.value
                                    )
                                }
                                placeholder="Sound_signals_type"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Shapes</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={shapes.number}
                                onChange={e =>
                                    shapes.setField("number", e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={shapes.location}
                                onChange={e =>
                                    shapes.setField("location", e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={shapes.condition}
                                onChange={e =>
                                    shapes.setField("condition", e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Radar</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={radar.number}
                                onChange={e =>
                                    radar.setField("number", e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={radar.location}
                                onChange={e =>
                                    radar.setField("location", e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={radar.condition}
                                onChange={e =>
                                    radar.setField("condition", e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Compass</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={compass.number}
                                onChange={e =>
                                    compass.setField("number", e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={compass.location}
                                onChange={e =>
                                    compass.setField("location", e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={compass.condition}
                                onChange={e =>
                                    compass.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Gps_receiver</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={gps_receiver.number}
                                onChange={e =>
                                    gps_receiver.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={gps_receiver.location}
                                onChange={e =>
                                    gps_receiver.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={gps_receiver.condition}
                                onChange={e =>
                                    gps_receiver.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Echo_sounder</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={echo_sounder.number}
                                onChange={e =>
                                    echo_sounder.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={echo_sounder.location}
                                onChange={e =>
                                    echo_sounder.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={echo_sounder.condition}
                                onChange={e =>
                                    echo_sounder.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Communication_equipment</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={communication_equipment.number}
                                onChange={e =>
                                    communication_equipment.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={communication_equipment.location}
                                onChange={e =>
                                    communication_equipment.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={communication_equipment.condition}
                                onChange={e =>
                                    communication_equipment.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Nautical_publications</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={nautical_publications.number}
                                onChange={e =>
                                    nautical_publications.setField(
                                        "number",
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={nautical_publications.location}
                                onChange={e =>
                                    nautical_publications.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={nautical_publications.condition}
                                onChange={e =>
                                    nautical_publications.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Log_book</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
                                disabled={isViewMode}
                                value={log_book.number}
                                onChange={e =>
                                    log_book.setField("number", e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
                                disabled={isViewMode}
                                value={log_book.location}
                                onChange={e =>
                                    log_book.setField(
                                        "location",
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
                                disabled={isViewMode}
                                value={log_book.condition}
                                onChange={e =>
                                    log_book.setField(
                                        "condition",
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default NavigationEquipmentForm;
