import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Container,
    UncontrolledTooltip,
    // Row,
    // Input, Label
} from "reactstrap";
import ReactQuill from "react-quill";
// import html2pdf from "html2pdf.js";
// import moment from "moment";

type TypeChecking = {
    userProfile: {},
    approvers: {},
    previousStepStatus: boolean,
    approveStage: () => void,
};

const NoteFile = ({ userProfile, approvers, allData, previousStepStatus, approveStage }: TypeChecking) => {

    // const [amount, setAmount] = useState("");
    // const [gst, setGst] = useState("");
    // const [dated, setDated] = useState("");
    const [noteContent, setNoteContent] = useState("");
    // const [receiptDate, setReceiptDate] = useState("");

    useEffect(() => {
        if (allData !== undefined) {
            // setAmount(allData.vesselActivity.stages_info.stages.stage_note_file.note_file.amount);
            // setGst(allData.vesselActivity.stages_info.stages.stage_note_file.note_file.gst);
            // setDated(allData.vesselActivity.stages_info.stages.stage_note_file.note_file.dated);
            setNoteContent(allData.vesselActivity.stages_info.stages.stage_note_file.note_file.noteContent);
            // setReceiptDate(allData.vesselActivity.stages_info.stages.stage_note_file.note_file.receiptDate);
        }
    }, [allData]);

    const handleChange = (value) => {
        setNoteContent(value)
    };

    // const printNoteFile = () => {
    //     let printArea = document.getElementById('noteFile');
    //     let opt = {
    //         margin: 0,
    //         filename: 'testPrint.pdf',
    //         image: { type: 'jpeg', quality: 0.98 },
    //         html2canvas: { scale: 2, scrollX: 0, scrollY: 0 },
    //         jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
    //     };
    //     html2pdf().from(printArea).set(opt).save();
    // };

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Container fluid className="p-0">
                        <Card>
                            <CardHeader>
                                <CardTitle tag="h5">
                                    Note File&nbsp;
                                    {
                                        allData !== undefined ?
                                            allData.vesselActivity.stages_info.stages.stage_note_file.approved_by.length !== 0 ?
                                                <span style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_note_file.approved_by[0].user.full_name} )</span>
                                                :
                                                null
                                            :
                                            null
                                    }
                                </CardTitle>
                            </CardHeader>
                            <CardBody>
                                <ReactQuill placeholder="Type something" value={noteContent || ''} onChange={(value) => handleChange(value)} />
                                {/* <Row className="px-4">
                                    <div className="col-md-4 mb-3">
                                        <Label>Amount</Label>
                                        <Input
                                            required
                                            type="text"
                                            name="amount"
                                            disabled={false}
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <Label>GST</Label>
                                        <Input
                                            required
                                            type="text"
                                            name="gst"
                                            disabled={false}
                                            value={gst}
                                            onChange={(e) => setGst(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <Label>Dated</Label>
                                        <Input
                                            required
                                            type="text"
                                            name="amount"
                                            disabled={false}
                                            value={dated}
                                            onChange={(e) => setDated(e.target.value)}
                                        />
                                    </div>
                                </Row>
                                <Row className="px-4">
                                    <div className="col-md-4 mb-3">
                                        <Label>Receipt</Label>
                                        <Input
                                            required
                                            type="text"
                                            name="receipt"
                                            disabled={false}
                                            value={receipt}
                                            onChange={(e) => setReceipt(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <Label>Receipt Date</Label>
                                        <Input
                                            required
                                            type="text"
                                            name="receiptDate"
                                            disabled={false}
                                            value={receiptDate}
                                            onChange={(e) => setReceiptDate(e.target.value)}
                                        />
                                    </div>
                                </Row> */}

                                {/* <div className="border border-dark">
                                    <div id="noteFile" className="p-6 text-justify">
                                        <h4>In the reference cite, the owner has requested to renew Registration
                                            Certificate and
                                            Certificate of Survey for the Work Boat "<strong
                                                className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>"
                                            Registered
                                            in {allData.registration_or_survey.vessel_registration_place} Port
                                            vide IV Registration
                                            number {allData.vesselActivity.stages_info.stages.stage_note_file.registration_number} for
                                            year {moment(allData.registration_or_survey.date).format('YYYY')} under
                                            Inland Vessels
                                            Rules G.O.RT.No.10 2020.</h4>

                                        <h4>In this connection, it is submitted that the
                                            firm {allData.registration_or_survey.firm_name} has requested for
                                            Registration of Work Boat "<strong
                                                className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>".
                                            Further, the firm {allData.registration_or_survey.firm_name} has requested
                                            to renew the
                                            above boat as per A.P.M.B Inland Vessels Rules G.O.RT.No.10 2020.</h4>

                                        <h4>Further, the firm has submitted the following documents in respect of the
                                            Work
                                            Boat "<strong
                                                className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>"
                                            for
                                            the Registration Certificate and Certificate of Survey
                                            for the year 2020 are here with put up for kind perusal.</h4>

                                        <br/>

                                        <div className="pl-7">
                                            <h4>1. Application for Registration / Survey</h4>
                                            <h4>2. Declaration of Ownership</h4>
                                        </div>

                                        <br/>

                                        <h4>Further, the Chief Surveyor & Surveyor has submitted Certificate of Survey &
                                            Declaration of Survey as per
                                            Rules G.O.RT.No.10 2020.</h4>

                                        <h4>In view of the above Work Boat "<strong
                                            className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>"
                                            compiled all the rules as per IV, Rules G.O.RT.No.10 2020, may issue new
                                            Certificate of Registry for
                                            Work Boat "<strong
                                                className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>"
                                            as
                                            per Certificate of Survey & Declaration of Survey which is issued by chief
                                            surveyor & Surveyor and as per rules of the Inland Vessels Rules
                                            G.O.RT.No.10 2020.</h4>

                                        <br/>

                                        <Row className="justify-content-around">
                                            <div>
                                                <h4>Submitted for orders please.</h4>
                                                <h4 className="text-center">PC (IV)</h4>
                                                <br/>
                                                <h4 className="text-center">O.N. dated {dated}</h4>
                                            </div>
                                            <div className="py-4">
                                                <h4>
                                                    PO (FAC)
                                                </h4>
                                            </div>
                                        </Row>

                                        <br/>

                                        <h4>An amount of Rs.{amount}/- towards Survey & Registration fee and
                                            GST Rs.{gst}/- has been collected vide Receipt {receipt} dated {receiptDate}.
                                            Hence, the Draft Certificate of Registry and Certificate of Survey
                                            of Work Boat "<strong
                                                className="text-uppercase">{allData.registration_or_survey.name_of_the_craft}</strong>"
                                            is
                                            prepared and here with put up for signature of
                                            the Registering Officer, {allData.registration_or_survey.vessel_registration_place}.</h4>

                                        <br/>

                                        <Row className="justify-content-around">
                                            <h4>
                                                PC (IV)
                                            </h4>
                                            <h4>
                                                PO (FAC)
                                            </h4>
                                        </Row>
                                    </div>
                                </div> */}

                                <br />
                                {
                                    allData.vesselActivity.stages_info.stages.stage_note_file.status === "approved" ?
                                        <Button color={"success"}
                                                id={"approveFour"}
                                                onClick={() => approvers.stage_note_file.includes(userProfile.id) ?
                                                    approveStage(userProfile, "stage_note_file", {
                                                        type: "note-update",
                                                        noteContent,
                                                    })
                                                    :
                                                    () => { }
                                                }
                                                className="mr-1 mb-1">
                                            Update
                                        </Button>
                                        :
                                        <Button color={"success"}
                                                id={"approveFour"}
                                                onClick={() => approvers.stage_note_file.includes(userProfile.id) ?
                                                    approveStage(userProfile, "stage_note_file", {
                                                        type: "note-save",
                                                        // amount,
                                                        // gst,
                                                        // dated,
                                                        noteContent,
                                                        // receiptDate
                                                    })
                                                    :
                                                    () => { }
                                                }
                                                className="mr-1 mb-1">
                                            Save
                                        </Button>
                                }

                                {!approvers.stage_note_file.includes(userProfile.id) ?
                                    <UncontrolledTooltip
                                        placement={"bottom"}
                                        target={"approveFour"}>
                                        You are not authorized to Save the Note File
                                    </UncontrolledTooltip>
                                    : null
                                }
                                {/* <Button onClick={() => printNoteFile()} className="mr-1 mb-1">
                                    Print
                                </Button> */}
                            </CardBody>
                        </Card>
                    </Container>
                    :
                    <h3>Proceed to the Registration Fee step.</h3>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    );
};

export default NoteFile;
