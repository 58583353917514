import React from "react";
import Vessel from "../Vessel";
import { FormGroup, Label, Input } from "reactstrap";

type VesselOtherFormType = {
    vessel: Vessel;
    onSave: (updatedVessel: Vessel) => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};

const VesselOtherForm: React.FC<VesselOtherFormType> = ({
    vessel,
    mode
}: VesselOtherFormType) => {
    const isViewMode = mode === "View";
    return (
        <div
            className="row vesselOtherForm"
            style={{
                alignContent: "start"
            }}
        >
            <div className="col-12">
                <FormGroup>
                    <Label>Communication Equipment</Label>
                    <Input
                        disabled={isViewMode}
                        type="textarea"
                        rows="4"
                        value={vessel.communication_equipment_particulars}
                        onChange={e =>
                            vessel.setCommunication_equipment_particulars(
                                e.target.value
                            )
                        }
                        placeholder="Communication Equipment"
                    />
                </FormGroup>
            </div>
            <div className="col-12">
                <FormGroup>
                    <Label>Pollution Control Devices</Label>
                    <Input
                        disabled={isViewMode}
                        rows="4"
                        type="textarea"
                        value={vessel.pollution_control_equipment_particulars}
                        onChange={e =>
                            vessel.setPollution_control_equipment_particulars(
                                e.target.value
                            )
                        }
                        placeholder="Pollution Control Devices"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default VesselOtherForm;
