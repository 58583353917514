import React, { useState, useEffect } from "react";
import axios from "axios";
import "./vesselFormStyle.css";
import Vessel from "../vessels/Vessel";
import paths from "../../config/Paths";

type VesselFormProps = {
    match?: {
        params: {
            vesselId: string;
        };
    };
};

const VesselForm = (props: VesselFormProps) => {
    const vesselId = props.match.params.vesselId;
    const [vesselState, setVessel] = useState(new Vessel());
    vesselState.subscribeForStateChange(setVessel);

    useEffect(() => {
        axios.get(paths.api.FetchVessel(vesselId)).then(response => {
            setVessel(new Vessel({ ...vesselState, ...response.data }));
        });
    }, []);
    const [data, setData] = useState({
        name_of_the_vessel: "name",
        type_of_vessel: "",
        category_of_vessel: "",
        number_of_passengers: "",
        official_number: "",
        hull_material: "name",
        name_of_the_builder: "",
        place_of_building: "",
        date_of_build: "",
        means_of_propulsion: "",
        total_bhp_of_main_engine: "",
        date_of_engine_construction: "",
        length: "3421",
        breadth: "",
        depth: "",
        grt: "",
        nrt: ""
    });

    const {
        total_bhp_of_main_engine,
        date_of_engine_construction,
        grt,
        nrt
    } = data;

    const {
        hull_details,
        propulsion_of_engines,
        equipment_details,
        fire_fighting,
        machinery,
        accomodation,
		freeboard,
		navigation_equipment,
		life_saving_equipment
    } = vesselState;
    return (
        <>
            <div>
                <div
                    style={{
                        fontSize: 20,
                        marginLeft: "30%",
                        fontWeight: "bold"
                    }}
                >
                    RECORD OF EQUIPMENT AND VESSEL INFORMATION
                </div>
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Name of the vessel</th>
                            <th>Type of vessel</th>
                            <th>Category of Vessel</th>
                            <th>Number of passengers</th>
                            <th>Official number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{vesselState.name}</td>
                            <td>{vesselState.vessel_type}</td>
                            <td>{vesselState.category}</td>
                            <td>{freeboard.no_of_passengers}</td>
                            <td>{vesselState.official_no_of_vessel}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="hullInfoTable">
                    <thead>
                        <tr>
                            <th>Hull Material</th>
                            <th>Name of the Builder</th>
                            <th>Place of Building</th>
                            <th>Date of build</th>
                            <th>Means of Propulsion</th>
                            <th>Total BHP of Main Engine</th>
                            <th>Date of Engine Construction</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{hull_details.hull_material}</td>
                            <td>{hull_details.builder_name}</td>
                            <td>{hull_details.place_of_building}</td>
                            <td>{hull_details.date_of_build}</td>
                            <td></td>
                            <td>{total_bhp_of_main_engine}</td>
                            <td>{date_of_engine_construction}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Length</th>
                            <th>Breadth</th>
                            <th>Depth</th>
                            <th>GRT</th>
                            <th>NRT</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{vesselState.length}</td>
                            <td>{vesselState.breadth}</td>
                            <td>{vesselState.depth}</td>
                            <td>{grt}</td>
                            <td>{nrt}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Fire fighting</th>
                            <th>Number/Type</th>
                            <th>Condition</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Fire Plan</td>
                            <td>{fire_fighting.fire_plan.number}</td>
                            <td>{fire_fighting.fire_plan.condition}</td>
                            <td>{fire_fighting.fire_plan.location}</td>
                        </tr>
                        <tr>
                            <td>Fire pumps (hand)</td>
                            <td>{fire_fighting.hand_fire_pumps.number}</td>
                            <td>{fire_fighting.hand_fire_pumps.condition}</td>
                            <td>{fire_fighting.hand_fire_pumps.location}</td>
                        </tr>
                        <tr>
                            <td>Fire pumps (mech)</td>
                            <td>{fire_fighting.mech_fire_pumps.number}</td>
                            <td>{fire_fighting.mech_fire_pumps.condition}</td>
                            <td>{fire_fighting.mech_fire_pumps.location}</td>
                        </tr>
                        <tr>
                            <td>Portable extinguishers</td>
                            <td>{fire_fighting.portable_extinguishers.number}</td>
                            <td>{fire_fighting.portable_extinguishers.condition}</td>
                            <td>{fire_fighting.portable_extinguishers.location}</td>
                        </tr>
                        <tr>
                            <td>Fixed extinguishing system:</td>
                            <td>{fire_fighting.portable_extinguishers.number}</td>
                            <td>{fire_fighting.portable_extinguishers.condition}</td>
                            <td>{fire_fighting.portable_extinguishers.location}</td>
                        </tr>
                        <tr>
                            <td>Components</td>
                            <td>{fire_fighting.components.number}</td>
                            <td>{fire_fighting.components.condition}</td>
                            <td>{fire_fighting.components.location}</td>
                        </tr>
                        <tr>
                            <td>Fire buckets</td>
                            <td>{fire_fighting.fire_buckets.number}</td>
                            <td>{fire_fighting.fire_buckets.condition}</td>
                            <td>{fire_fighting.fire_buckets.location}</td>
                        </tr>
                        <tr>
                            <td>Sand boxes</td>
                            <td>{fire_fighting.sand_boxes.number}</td>
                            <td>{fire_fighting.sand_boxes.condition}</td>
                            <td>{fire_fighting.sand_boxes.location}</td>
                        </tr>
                        <tr>
                            <td>Hydrants</td>
                            <td>{fire_fighting.hydrants.number}</td>
                            <td>{fire_fighting.hydrants.condition}</td>
                            <td>{fire_fighting.hydrants.location}</td>
                        </tr>
                        <tr>
                            <td>Hoses, fittings and nozzles</td>
                            <td>{fire_fighting.hoses_fittings_nozzles.number}</td>
                            <td>{fire_fighting.hoses_fittings_nozzles.condition}</td>
                            <td>{fire_fighting.hoses_fittings_nozzles.location}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Equipment</th>
                            <th>Number</th>
                            <th>Type/Condition</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Anchors</td>
                            <td>{equipment_details.anchor.number}</td>
                            <td>{equipment_details.anchor.condition}</td>
                            <td>{equipment_details.anchor.location}</td>
                        </tr>
                        <tr>
                            <td>Anchor cables/chains</td>
                            <td>{equipment_details.anchor_chains.number}</td>
                            <td>{equipment_details.anchor_chains.condition}</td>
                            <td>{equipment_details.anchor_chains.location}</td>
                        </tr>
                        <tr>
                            <td>Bilge pumps</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Portable extinguishers</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Fixed extinguishing system:</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Winches</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Cranes</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Derricks</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Towing equipment</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Ropes and lines</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Collision mat</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Gangway</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Fenders</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Boat hook</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>First aid kit</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Binoculars</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Waste containers</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Heaving line</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Axe</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Torch</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Navigation Equipment</th>
                            <th>Number</th>
                            <th>Condition</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Navigation lights</td>
                            <td>{navigation_equipment.navigation_lights.number}</td>
                            <td>{navigation_equipment.navigation_lights.condition}</td>
                            <td>{navigation_equipment.navigation_lights.location}</td>
                        </tr>
                        <tr>
                            <td>Sound signals</td>
                            <td>{navigation_equipment.sound_signals.number}</td>
                            <td>{navigation_equipment.sound_signals.condition}</td>
                            <td>{navigation_equipment.sound_signals.location}</td>
                        </tr>
                        <tr>
                            <td>Fire pumps (mech)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Shapes</td>
                            <td>{navigation_equipment.shapes.number}</td>
                            <td>{navigation_equipment.shapes.condition}</td>
                            <td>{navigation_equipment.shapes.location}</td>
                        </tr>
                        <tr>
                            <td>Radar</td>
                            <td>{navigation_equipment.radar.number}</td>
                            <td>{navigation_equipment.radar.condition}</td>
                            <td>{navigation_equipment.radar.location}</td>
                        </tr>
                        <tr>
                            <td>Compass</td>
                            <td>{navigation_equipment.compass.number}</td>
                            <td>{navigation_equipment.compass.condition}</td>
                            <td>{navigation_equipment.compass.location}</td>
                        </tr>
                        <tr>
                            <td>GPS receiver</td>
                            <td>{navigation_equipment.gps_receiver.number}</td>
                            <td>{navigation_equipment.gps_receiver.condition}</td>
                            <td>{navigation_equipment.gps_receiver.location}</td>
                        </tr>
                        <tr>
                            <td>Echo sounder</td>
                            <td>{navigation_equipment.echo_sounder.number}</td>
                            <td>{navigation_equipment.echo_sounder.condition}</td>
                            <td>{navigation_equipment.echo_sounder.location}</td>
                        </tr>
                        <tr>
                            <td>Communications equipment</td>
                            <td>{navigation_equipment.communication_equipment.number}</td>
                            <td>{navigation_equipment.communication_equipment.condition}</td>
                            <td>{navigation_equipment.communication_equipment.location}</td>
                        </tr>
                        <tr>
                            <td>Nautical publications</td>
                            <td>{navigation_equipment.nautical_publications.number}</td>
                            <td>{navigation_equipment.nautical_publications.condition}</td>
                            <td>{navigation_equipment.nautical_publications.location}</td>
                        </tr>
                        <tr>
                            <td>Log book</td>
                            <td>{navigation_equipment.log_book.number}</td>
                            <td>{navigation_equipment.log_book.condition}</td>
                            <td>{navigation_equipment.log_book.location}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div>
                <div className="space" />
                <div className="borderDiv">
                    Location of passengers on specific decks and spaces in
                    maximum loaded condition
                </div>
                {/* HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear */}
                <table className="deckCSS">
                    <tbody>
                        <tr>
                            <td>Deck/Space</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Maxm number of passengers</td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <div className="borderDiv">Freeboard</div>
                {/* HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear */}
                <table className="deckCSS">
                    <tbody>
                        <tr>
                            <td>Minimum freeboard, equivalent to:</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Number of passengers</td>
                            <td style={{ borderRight: "none" }} />
                            <td
                                style={{
                                    borderRight: "none",
                                    borderLeft: "none"
                                }}
                            />
                            <td style={{ borderLeft: "none" }} />
                        </tr>
                        <tr>
                            <td>Cargo (tonnes)</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
                {/* Generated at CSSPortal.com */}
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Life-saving equipment</th>
                            <th>Number</th>
                            <th>Type/Condition</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Lifejackets</td>
                            <td>{life_saving_equipment.lifejackets.number}</td>
                            <td>{life_saving_equipment.lifejackets.condition}</td>
                            <td>{life_saving_equipment.lifejackets.location}</td>
                        </tr>
                        <tr>
                            <td>Lifeboats</td>
                            <td>{life_saving_equipment.lifeboats.number}</td>
                            <td>{life_saving_equipment.lifeboats.condition}</td>
                            <td>{life_saving_equipment.lifeboats.location}</td>
                        </tr>
                        <tr>
                            <td>Liferafts</td>
                            <td>{life_saving_equipment.liferafts.number}</td>
                            <td>{life_saving_equipment.liferafts.condition}</td>
                            <td>{life_saving_equipment.liferafts.location}</td>
                        </tr>
                        <tr>
                            <td>Launching arrangements</td>
                            <td>{life_saving_equipment.launching_arrangements.number}</td>
                            <td>{life_saving_equipment.launching_arrangements.condition}</td>
                            <td>{life_saving_equipment.launching_arrangements.location}</td>
                        </tr>
                        <tr>
                            <td>Flotation devices</td>
                            <td>{life_saving_equipment.floatation_devices.number}</td>
                            <td>{life_saving_equipment.floatation_devices.condition}</td>
                            <td>{life_saving_equipment.floatation_devices.location}</td>
                        </tr>
                        <tr>
                            <td>Lifebuoys</td>
                            <td>{life_saving_equipment.lifebouys.number}</td>
                            <td>{life_saving_equipment.lifebouys.condition}</td>
                            <td>{life_saving_equipment.lifebouys.location}</td>
                        </tr>
                        <tr>
                            <td>Flares – parachute</td>
                            <td>{life_saving_equipment.parachute_flares.number}</td>
							<td>{life_saving_equipment.parachute_flares.condition}</td>
							<td>{life_saving_equipment.parachute_flares.location}</td>
                        </tr>
                        <tr>
                            <td>Flares – hand-held</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Smoke signals</td>
                            <td>{life_saving_equipment.smoke_signals.number}</td>
                            <td>{life_saving_equipment.smoke_signals.condition}</td>
                            <td>{life_saving_equipment.smoke_signals.location}</td>
                        </tr>
                        <tr>
                            <td>Emergency communications</td>
                            <td>{life_saving_equipment.emergency_communications.number}</td>
                            <td>{life_saving_equipment.emergency_communications.condition}</td>
                            <td>{life_saving_equipment.emergency_communications.location}</td>
                        </tr>
                        <tr>
                            <td>Radar transponder/reflector</td>
                            <td>{life_saving_equipment.radar_transponder.number}</td>
                            <td>{life_saving_equipment.radar_transponder.condition}</td>
                            <td>{life_saving_equipment.radar_transponder.location}</td>
                        </tr>
                        <tr>
                            <td>Rescue boat</td>
                            <td>{life_saving_equipment.rescue_boat.number}</td>
                            <td>{life_saving_equipment.rescue_boat.condition}</td>
                            <td>{life_saving_equipment.rescue_boat.location}</td>
                        </tr>
                        <tr>
                            <td>Liferaft/lifeboat equipment</td>
                            <td>{life_saving_equipment.life_boat_equipment.number}</td>
                            <td>{life_saving_equipment.life_boat_equipment.condition}</td>
                            <td>{life_saving_equipment.life_boat_equipment.location}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Machinery/Electrical</th>
                            <th>Power BHP</th>
                            <th>Type/Condition</th>
                            <th>Location</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Main machinery</td>
                            <td>{machinery.main_machinery.power_bhp}</td>
                            <td>{machinery.main_machinery.condition}</td>
                            <td>{machinery.main_machinery.location}</td>
                        </tr>
                        <tr>
                            <td>Main generator</td>
                            <td>{machinery.main_generator.power_bhp}</td>
                            <td>{machinery.main_generator.condition}</td>
                            <td>{machinery.main_generator.location}</td>
                        </tr>
                        <tr>
                            <td>Emergency generator</td>
                            <td>{machinery.emergency_generator.power_bhp}</td>
                            <td>{machinery.emergency_generator.condition}</td>
                            <td>{machinery.emergency_generator.location}</td>
                        </tr>
                        <tr>
                            <td>Main steering gear</td>
                            <td>{machinery.main_steering_gear.power_bhp}</td>
                            <td>{machinery.main_steering_gear.condition}</td>
                            <td>{machinery.main_steering_gear.location}</td>
                        </tr>
                        <tr>
                            <td>Auxiliary steering gear</td>
                            <td>{machinery.auxillary_steering_gear.power_bhp}</td>
                            <td>{machinery.auxillary_steering_gear.condition}</td>
                            <td>{machinery.auxillary_steering_gear.location}</td>
                        </tr>
                        <tr>
                            <td>Switchboard</td>
                            <td>{machinery.switchboard.power_bhp}</td>
                            <td>{machinery.switchboard.condition}</td>
                            <td>{machinery.switchboard.location}</td>
                        </tr>
                        <tr>
                            <td>Shore connection</td>
                            <td>{machinery.shore_connection.power_bhp}</td>
                            <td>{machinery.shore_connection.condition}</td>
                            <td>{machinery.shore_connection.location}</td>
                        </tr>
                        <tr>
                            <td>Batteries</td>
                            <td>{machinery.batteries.power_bhp}</td>
                            <td>{machinery.batteries.condition}</td>
                            <td>{machinery.batteries.location}</td>
                        </tr>
                        <tr>
                            <td>Signal light switches</td>
                            <td>{machinery.signal_light_switch.power_bhp}</td>
                            <td>{machinery.signal_light_switch.condition}</td>
                            <td>{machinery.signal_light_switch.location}</td>
                        </tr>
                    </tbody>
                </table>
                <div className="space" />
                <table className="customTable">
                    <thead>
                        <tr>
                            <th>Accommodation/Safety</th>
                            <th>Condition</th>
                            <th>Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Escape routes</td>
                            <td>{accomodation.escape_routes.condition}</td>
                            <td>{accomodation.escape_routes.comment}</td>
                        </tr>
                        <tr>
                            <td>Washrooms</td>
                            <td>{accomodation.washrooms.condition}</td>
                            <td>{accomodation.washrooms.comment}</td>
                        </tr>
                        <tr>
                            <td>Toilets</td>
                            <td>{accomodation.toilets.condition}</td>
                            <td>{accomodation.toilets.comment}</td>
                        </tr>
                        <tr>
                            <td>Galley</td>
                            <td>{accomodation.galley.condition}</td>
                            <td>{accomodation.galley.comment}</td>
                        </tr>
                        <tr>
                            <td>Messroom</td>
                            <td>{accomodation.messrooms.condition}</td>
                            <td>{accomodation.messrooms.comment}</td>
                        </tr>
                        <tr>
                            <td>Lifebuoys</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Flares – parachute</td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td>Water tanks</td>
                            <td>{accomodation.water_tanks.condition}</td>
                            <td>{accomodation.water_tanks.comment}</td>
                        </tr>
                        <tr>
                            <td>Heating/cooling/ventilation</td>
                            <td>{accomodation.heating_cooling_ventilation.condition}</td>
                            <td>{accomodation.heating_cooling_ventilation.comment}</td>
                        </tr>
                        <tr>
                            <td>Berth/locker</td>
                            <td>{accomodation.berth.condition}</td>
                            <td>{accomodation.berth.comment}</td>
                        </tr>
                        <tr>
                            <td>Access to accommodation</td>
                            <td>{accomodation.access.condition}</td>
                            <td>{accomodation.access.comment}</td>
                        </tr>
                        <tr>
                            <td>Lighting</td>
                            <td>{accomodation.lighting.condition}</td>
                            <td>{accomodation.lighting.comment}</td>
                        </tr>
                        <tr>
                            <td>Machinery guards</td>
                            <td>{accomodation.machinery_guards.condition}</td>
                            <td>{accomodation.machinery_guards.comment}</td>
                        </tr>
                        <tr>
                            <td>Guard rails</td>
                            <td>{accomodation.guard_rails.condition}</td>
                            <td>{accomodation.guard_rails.comment}</td>
                        </tr>
                        <tr>
                            <td>Non-slip surfaces</td>
                            <td>{accomodation.non_slip_surfaces.condition}</td>
                            <td>{accomodation.non_slip_surfaces.comment}</td>
                        </tr>
                        <tr>
                            <td>adders/companionways</td>
                            <td>{accomodation.ladders.condition}</td>
                            <td>{accomodation.ladders.comment}</td>
                        </tr>
                        <tr>
                            <td>Noise insulation/protection/notices</td>
                            <td>{accomodation.noise_insulation.condition}</td>
                            <td>{accomodation.noise_insulation.comment}</td>
                        </tr>
                        <tr>
                            <td>Foam flotation material: density</td>
                            <td>{accomodation.floam_flotation_material.condition}</td>
                            <td>{accomodation.floam_flotation_material.comment}</td>
                        </tr>
                    </tbody>
                </table>
                <div style={{ marginTop: "5%" }}>
                    Issued at: ..................(Place of issue of certificate)
                    ............... on: .............. (Date of
                    Issue)...............
                </div>
                <div style={{ marginTop: "15%" }}>
                    (Signature of authorized official issuing the certificate)
                    <span style={{ marginLeft: "10%" }}>
                        (Seal of the issuing authority appropriate).
                    </span>
                </div>
            </div>
        </>
    );
};

export default VesselForm;
