import { accomodationItem, accomodationType } from '../forms/Vessel.form.types';
export default class VesselAccomodation {
	escape_routes: AccomodationItem;
	washrooms: AccomodationItem;
	toilets: AccomodationItem;
	galley: AccomodationItem;
	messrooms: AccomodationItem;
	water_tanks: AccomodationItem;
	heating_cooling_ventilation: AccomodationItem;
	berth: AccomodationItem;
	access: AccomodationItem;
	lighting: AccomodationItem;
	machinery_guards: AccomodationItem;
	guard_rails: AccomodationItem;
	non_slip_surfaces: AccomodationItem;
	ladders: AccomodationItem;
	noise_insulation: AccomodationItem;
	floam_flotation_material: AccomodationItem;

	constructor(params: accomodationType, onChange) {
		params = {
			...{
				escape_routes: null,
				washrooms: null,
				toilets: null,
				galley: null,
				messrooms: null,
				water_tanks: null,
				heating_cooling_ventilation: null,
				berth: null,
				access: null,
				lighting: null,
				machinery_guards: null,
				guard_rails: null,
				non_slip_surfaces: null,
				ladders: null,
				noise_insulation: null,
				floam_flotation_material: null,
			}, ...params
		}

		this.onChange = onChange;
		this.escape_routes = new AccomodationItem(params.escape_routes, () => this.onChange());
		this.washrooms = new AccomodationItem(params.washrooms, () => this.onChange());
		this.toilets = new AccomodationItem(params.toilets, () => this.onChange());
		this.galley = new AccomodationItem(params.galley, () => this.onChange());
		this.messrooms = new AccomodationItem(params.messrooms, () => this.onChange());
		this.water_tanks = new AccomodationItem(params.water_tanks, () => this.onChange());
		this.heating_cooling_ventilation = new AccomodationItem(params.heating_cooling_ventilation, () => this.onChange());
		this.berth = new AccomodationItem(params.berth, () => this.onChange());
		this.access = new AccomodationItem(params.access, () => this.onChange());
		this.lighting = new AccomodationItem(params.lighting, () => this.onChange());
		this.machinery_guards = new AccomodationItem(params.machinery_guards, () => this.onChange());
		this.guard_rails = new AccomodationItem(params.guard_rails, () => this.onChange());
		this.non_slip_surfaces = new AccomodationItem(params.non_slip_surfaces, () => this.onChange());
		this.ladders = new AccomodationItem(params.ladders, () => this.onChange());
		this.noise_insulation = new AccomodationItem(params.noise_insulation, () => this.onChange());
		this.floam_flotation_material = new AccomodationItem(params.floam_flotation_material, () => this.onChange());
	}
}

export class AccomodationItem {
	condition: string;
	comment: string;

	constructor(params: accomodationItem, onChange) {
		params = {
			...{
				condition: "",
				comment: ""
			}, ...params
		}
		this.onChange = onChange;
		this.condition = params.condition;
		this.comment = params.comment;
	}

	setField(key:
		| "condition"
		| "comment", value) {
		this[key] = value;
		this.onChange()
	}
}