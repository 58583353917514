import { SearchLightEquipmentType } from "../Vessel.form.types";

export default class SearchLightEquipment {
	size: number = 0;
	power: string = "";
	number: number = 0;

	onChange = null;

	constructor(params: SearchLightEquipmentType, onChange) {
		params = {
			...{
				number: 0,
				power: "",
				size: 0
			},
			...params
		}

		this.size = params.size;
		this.power = params.power;
		this.number = params.number;
		this.onChange = onChange;
	}


	setSize(size) { this.size = size; this.onChange() }
	setPower(power) { this.power = power; this.onChange() }
	setNumber(number) { this.number = number; this.onChange() }
}