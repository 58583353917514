import { matchPath } from "react-router";
import routesHelper from "../../routes/routes.helper";
import { ApplicationFormsTypeDeclarations } from "../typeDeclarations/ApplicationFormsTypeDeclarations";

type routeFunction = (
    path: string,
    params: any
) => {
    mode: "Create" | "View" | "Edit" | "EditViaForm";
    vesselId?: string;
    payload?: {
        activityId: string;
        updatedVessel: ApplicationFormsTypeDeclarations;
    };
};
export const getFormRouteInformation: routeFunction = (path, params) => {
    const createMode = matchPath(path, {
        path: routesHelper.below.registrationSurvey.create.path,
        exact: true,
        strict: false
    });
    const viewMode = matchPath(path, {
        path: routesHelper.below.registrationSurvey.view.path,
        exact: true,
        strict: false
    });
    const editMode = matchPath(path, {
        path: routesHelper.below.registrationSurvey.edit.path,
        exact: true,
        strict: false
    });

    if (createMode)
        return {
            mode: "Create"
        };
    if (viewMode)
        return {
            mode: "View",
            vesselId: params.vesselId
        };
    if (editMode)
        return {
            mode: "Edit",
            vesselId: params.vesselId
        };
};
