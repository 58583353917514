import React, {useState} from 'react';
import {
    FormGroup,
    Input,
    Label,
    Col,
    Row,
    Spinner,
    Button
} from "reactstrap";
import ApplicationFormFieldsSource from "../../../../formFieldsSource/ApplicationFormFieldsSource";

type TypeChecking = {
    formFieldSource: ApplicationFormFieldsSource,
    userProfile: {},
    formsToBeFilledMode: boolean,
    fileUploadOne: () => void,
    fileDeleteOne: () => void,
    fileDownloadOne: () => void
};

const RegistrationSurveyAttachments: React.FC<TypeChecking> = ({formsToBeFilledMode, userProfile, allData, fileUploadOne, fileDeleteOne, fileDownloadOne}: TypeChecking) => {

    const [spinner, setSpinner] = useState(false);

    const isViewMode = userProfile.userType === "port-operator" ?
        allData !== undefined ?
            allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.status === "rejected" ?
                true
                :
                formsToBeFilledMode
            :
            formsToBeFilledMode
        :
        formsToBeFilledMode;

    const fileUploadHandler = (event) => {
        event.preventDefault();
        // setSpinner(true);
        const formData = new FormData();
        formData.append("files", ...event.target.files);
        let file = {
            allData: allData,
            name: event.target.getAttribute('name'),
            formData: formData
        };
        fileUploadOne(file);
    };

    const fileDeleteHandler = (event, fileURL) => {
        event.preventDefault();
        // setSpinner(true);
        if (fileURL) {
            const temp = fileURL.split("/");
            const fileName = temp[temp.length - 1];
            let file = {
                allData: allData,
                name: event.target.getAttribute('name'),
                fileName: fileName
            };
            fileDeleteOne(file);
        }
    };

    const fileDownloadHandler = (event, fileURL) => {
        event.preventDefault();
        if (fileURL) {
            const temp = fileURL.split("/");
            const fileName = temp[temp.length - 1];
            fileDownloadOne(fileName);
        }
    };

    // const startSpinner = () => {
    //     setSpinner(true);
    // };
    //
    // const stopSpinner = () => {
    //     setSpinner(false);
    // };

    let uploadedFiles = allData !== undefined ? allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.enclosures : null;

    return (
        <div className="m-3">
            <div className="form-row justify-content-center">
                <h3>ENCLOSURES / ATTACHMENTS</h3>
            </div>
            <br/>
            <Col>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>1. Signed copy of Application for Registration /
                            Survey <span
                                style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="signed_reg"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {/*{uploadedFiles[7] !== undefined ?*/}
                        {/*    Object.keys(uploadedFiles[7].signed_reg).length !== 0 ?*/}
                        {/*        console.log("ONE " + spinner)*/}
                        {/*        :*/}
                        {/*        console.log("TWO " + spinner)*/}
                        {/*    :*/}
                        {/*    console.log("THREE " + spinner)*/}
                        {/*}*/}
                        {/*<Button className="mx-2"*/}
                        {/*        onClick={() => startSpinner()}>START</Button>*/}
                        {/*<Button className="mx-2"*/}
                        {/*        onClick={() => stopSpinner()}>STOP</Button>*/}

                        {uploadedFiles[7] !== undefined ?
                            Object.keys(uploadedFiles[7].signed_reg).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[7].signed_reg.location)}
                                        >{uploadedFiles[7].signed_reg.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="signed_reg" className="mx-2" disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[7].signed_reg.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>2. Signed copy of Declaration of Ownership <span
                            style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="signed_dcl"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />

                                :
                                null
                        }

                        {uploadedFiles[8] !== undefined ?
                            Object.keys(uploadedFiles[8].signed_dcl).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[8].signed_dcl.location)}
                                        >{uploadedFiles[8].signed_dcl.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="signed_dcl" className="mx-2" disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[8].signed_dcl.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>3. Operator certificate (PBH& LST) copy from
                            NIWS/RYA/YAI etc <span
                                style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="operator_certificate"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[0] !== undefined ?
                            Object.keys(uploadedFiles[0].operator_certificate).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[0].operator_certificate.location)}
                                        >{uploadedFiles[0].operator_certificate.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="operator_certificate" className="mx-2" disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[0].operator_certificate.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>4. Builder Certificate Copy <span
                            style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="builder_certificate"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[1] !== undefined ?
                            Object.keys(uploadedFiles[1].builder_certificate).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[1].builder_certificate.location)}
                                        >{uploadedFiles[1].builder_certificate.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="builder_certificate" className="mx-2" disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[1].builder_certificate.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>5. Hull and Machinery Insurance Certificates
                            copy <span
                                style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="hull_and_machinery_insurance_certificate"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[2] !== undefined ?
                            Object.keys(uploadedFiles[2].hull_and_machinery_insurance_certificate).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[2].hull_and_machinery_insurance_certificate.location)}
                                        >{uploadedFiles[2].hull_and_machinery_insurance_certificate.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="hull_and_machinery_insurance_certificate" className="mx-2"
                                                disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[2].hull_and_machinery_insurance_certificate.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>6. Passenger Insurance Certificates copy <span
                            style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="passenger_insurance_certificate"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[3] !== undefined ?
                            Object.keys(uploadedFiles[3].passenger_insurance_certificate).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[3].passenger_insurance_certificate.location)}
                                        >{uploadedFiles[3].passenger_insurance_certificate.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="passenger_insurance_certificate" className="mx-2"
                                                disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[3].passenger_insurance_certificate.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>7. Copy of Certificate of Registration (if already
                            registered) <span
                                style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="certificate_of_registration"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[4] !== undefined ?
                            Object.keys(uploadedFiles[4].certificate_of_registration).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[4].certificate_of_registration.location)}
                                        >{uploadedFiles[4].certificate_of_registration.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="certificate_of_registration" className="mx-2"
                                                disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[4].certificate_of_registration.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>8. Document establishing the Authority of
                            Authorized
                            Person (if making
                            request) <span
                                style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="authority_of_authorized_person"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[5] !== undefined ?
                            Object.keys(uploadedFiles[5].authority_of_authorized_person).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[5].authority_of_authorized_person.location)}
                                        >{uploadedFiles[5].authority_of_authorized_person.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="authority_of_authorized_person" className="mx-2"
                                                disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[5].authority_of_authorized_person.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>

                <FormGroup className="mb-4">
                    <div className="row ml-1">
                        <Label style={{fontSize: 15, color: "black"}}>9. Copy of NOC from APTDC. <span
                            style={{fontSize: 18, fontWeight: "bold", color: "black"}}>( PDF only )</span></Label>
                        {
                            spinner ?
                                <div className="px-2">
                                    <Spinner color="secondary" size="sm" className="mr-2"/>
                                </div>
                                :
                                null
                        }
                    </div>
                    <div>
                        {
                            userProfile.userType === "agency-admin" ?
                                <Input
                                    type="file"
                                    name="noc"
                                    accept="application/pdf"
                                    disabled={isViewMode}
                                    onChange={(event) => fileUploadHandler(event)}
                                    className="w-25"
                                    style={{marginBottom: 10}}
                                />
                                :
                                null
                        }

                        {uploadedFiles[6] !== undefined ?
                            Object.keys(uploadedFiles[6].noc).length !== 0 ?
                                <div className="form-row">
                                    <div className="col-5 py-1">
                                        <a href="#" className="text-decoration-underline" style={{fontSize: 18}}
                                           onClick={event => fileDownloadHandler(event, uploadedFiles[6].noc.location)}
                                        >{uploadedFiles[6].noc.originalFileName}</a>
                                    </div>
                                    <div className="col-7">
                                        <Button name="noc" className="mx-2" disabled={isViewMode}
                                                onClick={event => fileDeleteHandler(event, uploadedFiles[6].noc.location)}>DELETE</Button>
                                    </div>
                                </div>
                                :
                                null
                            :
                            null
                        }
                    </div>
                </FormGroup>
            </Col>
        </div>
    );
};

export default RegistrationSurveyAttachments;
