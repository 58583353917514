import { FireFightingType, HosesFittingNozzleType, PortableExtinguishersType, HydrantsType, FireFightingItemType } from "./Vessel.form.types"

export class HosesFittingNozzles {
	number: number = null;
	no_of_hose: number = null;
	type: string = null;
	condition: string = null;
	location: string = null;
	nozzles: string = null;
	other_equipment: string = null;

	constructor(params: HosesFittingNozzleType, onChange) {
		params = {
			...{
				number: 0,
				no_of_hose: 0,
				type: "",
				condition: "",
				location: "",
				nozzles: "",
				other_equipment: "",
			}, ...params
		}

		this.number = params.number;
		this.no_of_hose = params.no_of_hose;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;
		this.nozzles = params.nozzles;
		this.other_equipment = params.other_equipment;

		this.onChange = onChange;
	}

	setField(key:
		| "number"
		| "type"
		| "condition"
		| "location"
		| "no_of_hose"
		| "nozzles"
		| "other_equipment", value
	) {
		this[key] = value;
		this.onChange();
	}
}

export class PortableExtinguishers {
	number: number = null;
	no_of_portable_fire_extinguishers: number = null;
	type: string = null;
	condition: string = null;
	location: string = null;
	manufacturers_name: string = null;
	manufacturer_details: string = null;

	constructor(params: PortableExtinguishersType, onChange) {
		params = {
			...{
				number: 0,
				no_of_portable_fire_extinguishers: 0,
				type: "",
				condition: "",
				location: "",
				manufacturers_name: "",
				manufacturer_details: "",
			}, ...params
		}

		this.number = params.number;
		this.no_of_portable_fire_extinguishers = params.no_of_portable_fire_extinguishers;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;
		this.manufacturers_name = params.manufacturers_name;
		this.manufacturer_details = params.manufacturer_details;

		this.onChange = onChange;
	}

	setField(key:
		| "number"
		| "type"
		| "condition"
		| "location"
		| "no_of_portable_fire_extinguishers"
		| "manufacturers_name"
		| "manufacturer_details",
		value
	) {
		this[key] = value;
		this.onChange();
	}
}

export class VesselFireApplianceItem {
	number: number = null;
	type: string = null;
	condition: string = null;
	location: string = null;

	constructor(params: FireFightingItemType, onChange) {
		params = {
			...{
				number: 0,
				type: "",
				condition: "",
				location: "",
			}, ...params
		}

		this.number = params.number;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;

		this.onChange = onChange;
	}

	setField(
		key: | "number"
			| "type"
			| "condition"
			| "location",
		value
	) {
		this[key] = value;
		this.onChange()
	}
}

export class Hydrants {
	type: string = null;
	condition: string = null;
	location: string = null;
	fire_mains: string = null;
	material: string = null;
	number: number = null;
	diameter: number = null;
	no_of_hydrants: number = null;

	onChange = null;

	constructor(params: HydrantsType, onChange) {
		params = {
			...{
				type: "",
				condition: "",
				location: "",
				fire_mains: "",
				material: "",
				number: 0,
				diameter: 0,
				no_of_hydrants: 0,
			}, ...params
		};

		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;
		this.fire_mains = params.fire_mains;
		this.material = params.material;
		this.number = params.number;
		this.diameter = params.diameter;
		this.no_of_hydrants = params.no_of_hydrants;

		this.onChange = onChange;
	}

	setField(key: | "number"
		| "type"
		| "condition"
		| "location"
		| "fire_mains"
		| "diameter"
		| "material"
		| "no_of_hydrants", value) {
		this[key] = value;
		this.onChange();
	}
}

export default class VesselFireAppliance {
	no_of_fire_pumps: number = 0;
	size_of_fire_pumps: number = 0;
	capacity_fire_pumps: number = 0;
	fire_plan: VesselFireApplianceItem = null;
	hand_fire_pumps: VesselFireApplianceItem = null;
	mech_fire_pumps: VesselFireApplianceItem = null;
	fixed_extinguishing_system: VesselFireApplianceItem = null;
	components: VesselFireApplianceItem = null;
	fire_buckets: VesselFireApplianceItem = null;
	portable_extinguishers: PortableExtinguishers = null;
	sand_boxes: VesselFireApplianceItem = null;
	hydrants: Hydrants = null;
	hoses_fittings_nozzles: HosesFittingNozzles = null;

	constructor(params: FireFightingType, onChange) {
		params = {
			...{
				no_of_fire_pumps: 0,
				size_of_fire_pumps: 0,
				capacity_fire_pumps: 0,
				fire_plan: null,
				hand_fire_pumps: null,
				mech_fire_pumps: null,
				portable_extinguishers: null,
				fixed_extinguishing_system: null,
				components: null,
				fire_buckets: null,
				sand_boxes: null,
				hydrants: null,
				hoses_fittings_nozzles: null,
			}, ...params
		};

		this.onChange = onChange;

		this.no_of_fire_pumps = params.no_of_fire_pumps;
		this.size_of_fire_pumps = params.size_of_fire_pumps;
		this.capacity_fire_pumps = params.capacity_fire_pumps;

		this.fire_plan = new VesselFireApplianceItem(params.fire_plan, () => this.onChange())
		this.hand_fire_pumps = new VesselFireApplianceItem(params.hand_fire_pumps, () => this.onChange())
		this.mech_fire_pumps = new VesselFireApplianceItem(params.mech_fire_pumps, () => this.onChange())
		this.portable_extinguishers = new PortableExtinguishers(params.portable_extinguishers, () => this.onChange())
		this.fixed_extinguishing_system = new VesselFireApplianceItem(params.fixed_extinguishing_system, () => this.onChange())
		this.components = new VesselFireApplianceItem(params.components, () => this.onChange())
		this.fire_buckets = new VesselFireApplianceItem(params.fire_buckets, () => this.onChange())
		this.sand_boxes = new VesselFireApplianceItem(params.sand_boxes, () => this.onChange())
		this.hydrants = new Hydrants(params.hydrants, () => this.onChange())
		this.hoses_fittings_nozzles = new HosesFittingNozzles(params.hoses_fittings_nozzles, () => this.onChange())
	}

	setField(key:
		| "no_of_fire_pumps"
		| "size_of_fire_pumps"
		| "capacity_fire_pumps", value) {
		this[key] = value;
		this.onChange();
	}

	setNo_of_fire_pumps(no_of_fire_pumps) { this.no_of_fire_pumps = no_of_fire_pumps; this.onChange() }

	static PortableExtinguishers = PortableExtinguishers;
	static VesselFireApplianceItem = VesselFireApplianceItem;
	static Hydrants = Hydrants;
	static HosesFittingNozzles = HosesFittingNozzles;
};
