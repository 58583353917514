import { RopeType, AnchorItemType, AnchorChainsItemType, AnchorType, AnchorChainsType, EquipmentType } from "../forms/Vessel.form.types"
export class Rope {
	size: number = 0;
	material: string = "";
	number = 0;
	type: string = "";
	condition: string = "";
	location: string = "";

	onChange: () => void = null;
	constructor(params: RopeType, onChange) {
		params = {
			...{
				number: 0,
				size: 0,
				material: "",
				type: "",
				condition: "",
				location: "",
			}, ...params
		}
		this.size = params.size;
		this.material = params.material;
		this.number = params.number;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;
		this.onChange = onChange;
	}

	setSize(size) { this.size = size; this.onChange() }
	setMaterial(material) { this.material = material; this.onChange() }
	setNumber(number) { this.number = number; this.onChange() }
	setType(type) { this.type = type; this.onChange() }
	setCondition(condition) { this.condition = condition; this.onChange() }
	setLocation(location) { this.location = location; this.onChange() }
}

export class AnchorItem {
	weight: number = 0;
	material: string = "";
	onChange: () => void = null

	constructor(params: AnchorItemType, onChange) {
		params = {
			...{
				material: "",
				weight: 0,
			}, ...params
		}
		this.material = params.material;
		this.weight = params.weight;
		this.onChange = onChange;
	}

	setWeight(weight) { this.weight = weight; this.onChange() }
	setMaterial(material) { this.material = material; this.onChange() }
}

export class AnchorChainsItem {
	size: number = 0;
	type: string = "";
	length: number = 0;
	test_certificate: string = "";

	onChange: () => void = null;

	constructor(params: AnchorChainsItemType, onChange) {
		params = {
			...{
				size: 0,
				type: "",
				length: 0,
				test_certificate: ""
			}, ...params
		}
		this.size = params.size;
		this.type = params.type;
		this.length = params.length;
		this.test_certificate = params.test_certificate;
		this.onChange = onChange;
	}
	setSize(size) { this.size = size; this.onChange() }
	setType(type) { this.type = type; this.onChange() }
	setLength(length) { this.length = length; this.onChange() }
	setTest_certificate(test_certificate) { this.test_certificate = test_certificate; this.onChange() }
}

export class Anchor {
	anchor_port: AnchorItem = null;
	starboard: AnchorItem = null;
	anchor_spare: AnchorItem = null;
	number: number = 0;
	type: string = "";
	condition: string = "";
	location: string = "";

	onChange: () => void = null;

	constructor(params: AnchorType, onChange) {
		params = {
			...{
				anchor_port: null,
				anchor_spare: null,
				starboard: null,
				condition: "",
				type: "",
				location: "", number: 0
			}, ...params
		}
		this.onChange = onChange;

		this.anchor_port = new AnchorItem(params.anchor_port, () => this.onChange());
		this.starboard = new AnchorItem(params.starboard, () => this.onChange());
		this.anchor_spare = new AnchorItem(params.anchor_spare, () => this.onChange());
		this.number = params.number;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;
	}

	setNumber(number) { this.number = number; this.onChange() }
	setType(type) { this.type = type; this.onChange() }
	setCondition(condition) { this.condition = condition; this.onChange() }
	setLocation(location) { this.location = location; this.onChange() }
}

export class AnchorChains {
	port_chain: AnchorChainsItem;
	starboard_chain: AnchorChainsItem;
	number: number = 0;
	type: string = "";
	condition: string = "";
	location: string = "";

	constructor(params: AnchorChainsType, onChange) {
		params = {
			...{
				port_chain: null,
				starboard_chain: null,
				condition: "",
				type: "",
				location: "",
				number: 0
			}, ...params
		}

		this.onChange = onChange;

		this.port_chain = new AnchorChainsItem(params.port_chain, () => this.onChange());
		this.starboard_chain = new AnchorChainsItem(params.starboard_chain, () => this.onChange());
		this.condition = params.condition;
		this.type = params.type;
		this.location = params.location;
		this.number = params.number;
	}

	setNumber(number) { this.number = number; this.onChange() }
	setType(type) { this.type = type; this.onChange() }
	setCondition(condition) { this.condition = condition; this.onChange() }
	setLocation(location) { this.location = location; this.onChange() }
}

export default class VesselEquipment {
	anchor: Anchor;
	anchor_chains: AnchorChains;
	ropes: Rope;
	bulge_pumps: string = "";
	winches: string = "";
	cranes: string = "";
	derricks: string = "";
	towing_equipment: string = "";
	collision_mats: string = "";
	gangway: string = "";
	fenders: string = "";
	boat_hook: string = "";
	first_aid_kit: string = "";
	binoculars: string = "";
	waste_containers: string = "";
	heaving_line: string = "";
	torch: string = "";
	axe: string = "";

	onChange: null;

	constructor(params: EquipmentType, onChange) {
		params = {
			...{
				anchor: null,
				anchor_chains: null,
				ropes: null,
				bulge_pumps: "",
				winches: "",
				cranes: "",
				derricks: "",
				towing_equipment: "",
				collision_mats: "",
				gangway: "",
				fenders: "",
				boat_hook: "",
				first_aid_kit: "",
				binoculars: "",
				waste_containers: "",
				heaving_line: "",
				torch: "",
				axe: "",
			}, ...params
		};

		this.onChange = onChange;

		this.anchor = new Anchor(params.anchor, () => this.onChange());
		this.anchor_chains = new AnchorChains(params.anchor_chains, () => this.onChange());
		this.ropes = new Rope(params.ropes, () => this.onChange());
		this.bulge_pumps = params.bulge_pumps;
		this.winches = params.winches;
		this.cranes = params.cranes;
		this.derricks = params.derricks;
		this.towing_equipment = params.towing_equipment;
		this.collision_mats = params.collision_mats;
		this.gangway = params.gangway;
		this.fenders = params.fenders;
		this.boat_hook = params.boat_hook;
		this.first_aid_kit = params.first_aid_kit;
		this.binoculars = params.binoculars;
		this.waste_containers = params.waste_containers;
		this.heaving_line = params.heaving_line;
		this.torch = params.torch;
		this.axe = params.axe;
	}

	setBulge_pumps(bulge_pumps) { this.bulge_pumps = bulge_pumps; this.onChange() }
	setWinches(winches) { this.winches = winches; this.onChange() }
	setCranes(cranes) { this.cranes = cranes; this.onChange() }
	setDerricks(derricks) { this.derricks = derricks; this.onChange() }
	setTowing_equipment(towing_equipment) { this.towing_equipment = towing_equipment; this.onChange() }
	setCollision_mats(collision_mats) { this.collision_mats = collision_mats; this.onChange() }
	setGangway(gangway) { this.gangway = gangway; this.onChange() }
	setFenders(fenders) { this.fenders = fenders; this.onChange() }
	setBoat_hook(boat_hook) { this.boat_hook = boat_hook; this.onChange() }
	setFirst_aid_kit(first_aid_kit) { this.first_aid_kit = first_aid_kit; this.onChange() }
	setBinoculars(binoculars) { this.binoculars = binoculars; this.onChange() }
	setWaste_containers(waste_containers) { this.waste_containers = waste_containers; this.onChange() }
	setHeaving_line(heaving_line) { this.heaving_line = heaving_line; this.onChange() }
	setTorch(torch) { this.torch = torch; this.onChange() }
	setAxe(axe) { this.axe = axe; this.onChange() }
}