import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import LifeBoyEquipment from "./LifeBoy.equipment";
type LifeBoyEquipmentFormType = {
    life_saving_equipment: LifeBoyEquipment;
    isViewMode: boolean;
};
const LifeBoyEquipmentForm: React.FC<LifeBoyEquipmentFormType> = ({
    life_saving_equipment,
    isViewMode
}: LifeBoyEquipmentFormType) => {
    const {
        bouyant_apparatus,
        number_of_bouyant_lanyard,
        lifejackets,
        emergency_communications,
        floatation_devices,
        launching_arrangements,
        life_boat_equipment,
        lifeboats,
        lifebouys,
        liferafts,
        parachute_flares,
        radar_transponder,
        rescue_boat,
        smoke_signals
    } = life_saving_equipment;
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Bouyant_apparatus</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={bouyant_apparatus}
                        onChange={e =>
                            life_saving_equipment.setBouyant_apparatus(
                                e.target.value
                            )
                        }
                        placeholder="Bouyant_apparatus"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Number_of_bouyant_lanyard</Label>
                    <Input
						disabled={isViewMode}
                        type="NUMBER"
                        value={number_of_bouyant_lanyard}
                        onChange={e =>
                            life_saving_equipment.setNumber_of_bouyant_lanyard(
                                e.target.value
                            )
                        }
                        placeholder="Number_of_bouyant_lanyard"
                    />
                </FormGroup>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Life Jackets</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={lifejackets.number}
                                onChange={e =>
                                    lifejackets.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.type}
                                onChange={e =>
                                    lifejackets.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.condition}
                                onChange={e =>
                                    lifejackets.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    lifejackets.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Lifeboats</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={lifeboats.number}
                                onChange={e =>
                                    lifeboats.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifeboats.type}
                                onChange={e =>
                                    lifeboats.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifeboats.condition}
                                onChange={e =>
                                    lifeboats.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    lifeboats.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Liferafts</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={liferafts.number}
                                onChange={e =>
                                    liferafts.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={liferafts.type}
                                onChange={e =>
                                    liferafts.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={liferafts.condition}
                                onChange={e =>
                                    liferafts.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    liferafts.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Launching_arrangements</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={launching_arrangements.number}
                                onChange={e =>
                                    launching_arrangements.setNumber(
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={launching_arrangements.type}
                                onChange={e =>
                                    launching_arrangements.setType(
                                        e.target.value
                                    )
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={launching_arrangements.condition}
                                onChange={e =>
                                    launching_arrangements.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    launching_arrangements.setLocation(
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Floatation_devices</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={floatation_devices.number}
                                onChange={e =>
                                    floatation_devices.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={floatation_devices.type}
                                onChange={e =>
                                    floatation_devices.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={floatation_devices.condition}
                                onChange={e =>
                                    floatation_devices.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    floatation_devices.setLocation(
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Lifebouys</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={lifebouys.number}
                                onChange={e =>
                                    lifebouys.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifebouys.type}
                                onChange={e =>
                                    lifebouys.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifebouys.condition}
                                onChange={e =>
                                    lifebouys.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    lifebouys.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Parachute_flares</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={parachute_flares.number}
                                onChange={e =>
                                    parachute_flares.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={parachute_flares.type}
                                onChange={e =>
                                    parachute_flares.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={parachute_flares.condition}
                                onChange={e =>
                                    parachute_flares.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    parachute_flares.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Smoke_signals</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={smoke_signals.number}
                                onChange={e =>
                                    smoke_signals.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={smoke_signals.type}
                                onChange={e =>
                                    smoke_signals.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={smoke_signals.condition}
                                onChange={e =>
                                    smoke_signals.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    smoke_signals.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Emergency_communications</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={emergency_communications.number}
                                onChange={e =>
                                    emergency_communications.setNumber(
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={emergency_communications.type}
                                onChange={e =>
                                    emergency_communications.setType(
                                        e.target.value
                                    )
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={emergency_communications.condition}
                                onChange={e =>
                                    emergency_communications.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    emergency_communications.setLocation(
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Radar_transponder</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={radar_transponder.number}
                                onChange={e =>
                                    radar_transponder.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={radar_transponder.type}
                                onChange={e =>
                                    radar_transponder.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={radar_transponder.condition}
                                onChange={e =>
                                    radar_transponder.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    radar_transponder.setLocation(
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Rescue_boat</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={rescue_boat.number}
                                onChange={e =>
                                    rescue_boat.setNumber(e.target.value)
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={rescue_boat.type}
                                onChange={e =>
                                    rescue_boat.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={rescue_boat.condition}
                                onChange={e =>
                                    rescue_boat.setCondition(e.target.value)
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    rescue_boat.setLocation(e.target.value)
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Life_boat_equipment</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Number</Label>
                            <Input
								disabled={isViewMode}
                                type="number"
                                value={life_boat_equipment.number}
                                onChange={e =>
                                    life_boat_equipment.setNumber(
                                        e.target.value
                                    )
                                }
                                placeholder="Number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={life_boat_equipment.type}
                                onChange={e =>
                                    life_boat_equipment.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Condition</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={life_boat_equipment.condition}
                                onChange={e =>
                                    life_boat_equipment.setCondition(
                                        e.target.value
                                    )
                                }
                                placeholder="Condition"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Location</Label>
                            <Input
								disabled={isViewMode}
                                type="text"
                                value={lifejackets.location}
                                onChange={e =>
                                    life_boat_equipment.setLocation(
                                        e.target.value
                                    )
                                }
                                placeholder="Location"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default LifeBoyEquipmentForm;
