import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Vessel from "../Vessel";
import { VesselPropulsionEngine } from "../models/Vessel.propulsion";
type VesselPropulsionFormType = {
    vessel: Vessel;
    onSave: (updatedVessel: Vessel) => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};
const VesselPropulsionForm: React.FC<VesselPropulsionFormType> = ({
    vessel: vesselState,
    onSave,
    mode
}: VesselPropulsionFormType) => {
    const isViewMode = mode === "View";

    const [vesselPropulsionEngine, setVesselPropulsionEngine] = useState(
        new VesselPropulsionEngine(null)
    );
    vesselPropulsionEngine.subscribeForStateChange(propulsionEngine =>
        setVesselPropulsionEngine(new VesselPropulsionEngine(propulsionEngine))
    );
    vesselState.subscribeForStateChange(newVessel => {
        onSave(newVessel);
    });
    const { propulsion_of_engines: propulsion } = vesselState;

    const onEngineAdd = () => {
        propulsion.addEngine(vesselPropulsionEngine);
        setVesselPropulsionEngine(new VesselPropulsionEngine(null));
    };

    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>No of sets fitted</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={propulsion.no_of_sets_fitted}
                        onChange={e =>
                            propulsion.setNo_of_sets_fitted(e.target.value)
                        }
                        placeholder="No_of_sets_fitted"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Manufacturer name</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={propulsion.manufacturer_name}
                        onChange={e =>
                            propulsion.setManufacturer_name(e.target.value)
                        }
                        placeholder="Manufacturer_name"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Manufacturer address</Label>
                    <Input
                        disabled={isViewMode}
                        value={propulsion.manufacturer_address}
                        onChange={e =>
                            propulsion.setManufacturer_address(e.target.value)
                        }
                        placeholder="Manufacturer_address"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Model no</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={propulsion.model_no}
                        onChange={e => propulsion.setModel_no(e.target.value)}
                        placeholder="Model_no"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Year of built</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={propulsion.year_of_built}
                        onChange={e =>
                            propulsion.setYear_of_built(e.target.value)
                        }
                        placeholder="Year_of_built"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Propulsion shaft diameter</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={propulsion.propulsion_shaft_diameter}
                        onChange={e =>
                            propulsion.setPropulsion_shaft_diameter(
                                e.target.value
                            )
                        }
                        placeholder="Propulsion shaft diameter"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Propulsion shaft material</Label>
                    <Input
                        disabled={isViewMode}
                        value={propulsion.propulsion_shaft_material}
                        onChange={e =>
                            propulsion.setPropulsion_shaft_material(
                                e.target.value
                            )
                        }
                        placeholder="Propulsion shaft material"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Gears type</Label>
                    <Input
                        disabled={isViewMode}
                        value={propulsion.gears_type}
                        onChange={e => propulsion.setGears_type(e.target.value)}
                        placeholder="Gears_type"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>No of gears</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={propulsion.no_of_gears}
                        onChange={e =>
                            propulsion.setNo_of_gears(e.target.value)
                        }
                        placeholder="No_of_gears"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Test certificate</Label>
                    <Input
                        disabled={isViewMode}
                        value={propulsion.test_certificate}
                        onChange={e =>
                            propulsion.setTest_certificate(e.target.value)
                        }
                        placeholder="Test certificate"
                    />
                </FormGroup>
            </div>
            {true ? (
                <div className="col-10 mt-3 mb-4">
                    <h3>Engines</h3>
                    <table
                        className="table table-borderless"
                        style={{ marginLeft: "-12px" }}
                    >
                        {propulsion.engine.length >= 1 ? (
                            <thead>
                                <tr>
                                    <th>Engine_type</th>
                                    <th>HP</th>
                                    <th></th>
                                </tr>
                            </thead>
                        ) : null}
                        <tbody>
                            {propulsion.engine.map((engine, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{engine.engine_type}</td>
                                        <td>{engine.HP}</td>
                                        {!isViewMode && (
                                            <td>
                                                <button
                                                    className="btn btn-outline-info btn-sm"
                                                    onClick={() =>
                                                        propulsion.removeEngine(
                                                            engine
                                                        )
                                                    }
                                                >
                                                    Remove
                                                </button>
                                            </td>
                                        )}
                                    </tr>
                                );
                            })}
                        </tbody>
                        {!isViewMode && (
                            <tfoot>
                                <tr>
                                    <td>
                                        <FormGroup>
                                            <Label>Engine Type</Label>
                                            <Input
                                                disabled={isViewMode}
                                                value={
                                                    vesselPropulsionEngine.engine_type
                                                }
                                                onChange={e =>
                                                    vesselPropulsionEngine.setEngine_type(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="Engine Type"
                                            />
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup>
                                            <Label>HP</Label>
                                            <Input
                                                disabled={isViewMode}
                                                value={
                                                    vesselPropulsionEngine.HP
                                                }
                                                onChange={e =>
                                                    vesselPropulsionEngine.setHP(
                                                        e.target.value
                                                    )
                                                }
                                                placeholder="HP"
                                            />
                                        </FormGroup>
                                    </td>
                                    <td>
                                        <FormGroup className="flex-end mb-0 pt-2">
                                            <label> </label>
                                            <br />
                                            <button
                                                className="btn btn-info"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    onEngineAdd();
                                                }}
                                            >
                                                Add new Engine
                                            </button>
                                        </FormGroup>
                                    </td>
                                </tr>
                            </tfoot>
                        )}
                    </table>
                </div>
            ) : null}
        </div>
    );
};
export default VesselPropulsionForm;
