import { freeboard_type } from "../forms/Vessel.form.types";
export default class VesselFreeboard {
	min_freeboard: string;
	no_of_passengers: number;
	cargo: number;

	constructor(params: freeboard_type, onChange) {
		params = {
			...{
				cargo: "",
				min_freeboard: "",
				no_of_passengers: ""
			}, ...params
		}
		this.onChange = onChange;
		this.min_freeboard = params.min_freeboard;
		this.no_of_passengers = params.no_of_passengers;
		this.cargo = params.cargo;
	}

	setNo_of_passengers(no_of_passengers) { this.no_of_passengers = no_of_passengers; this.onChange() }
	setCargo(cargo) { this.cargo = cargo; this.onChange() }
	setMin_freeboard(min_freeboard) { this.min_freeboard = min_freeboard; this.onChange() }

	pushNo_of_passengers(no_of_passengers, callback) {
		this.no_of_passengers = [...this.no_of_passengers, no_of_passengers];
		console.log("New No Of Passengers are:", this.no_of_passengers)
		this.onChange();
	}
	pushCargo(cargo) {
		this.cargo.push(cargo);
		this.onChange();
	}
	removeNo_of_passengers(item) {
		this.no_of_passengers = this.no_of_passengers.filter((itemToCheck) => {
			if (item === itemToCheck)
				return false;
			else
				return true;
		})
		this.onChange()
	}
	removeCargo(item) {
		this.cargo = this.cargo.filter((itemToCheck) => {
			if (item === itemToCheck)
				return false;
			else
				return true;
		})
		this.onChange()
	}
}