import React from 'react';
import {Input, Label, Button} from "reactstrap";
import moment from 'moment';
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";

type TypeChecking = {
    formFieldSource: ApplicationFormFieldsSource,
    formsToBeFilledMode: boolean,
    userProfile: {},
};

const ApplicationForRegistrationSurvey: React.FC<TypeChecking> = ({formFieldSource, formsToBeFilledMode, userProfile, allData}: TypeChecking) => {

    const isViewMode = userProfile.userType === "port-operator" ?
        allData !== undefined ?
            allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.status === "rejected" ?
                true
                :
                formsToBeFilledMode
            :
            formsToBeFilledMode
        :
        formsToBeFilledMode;

    const {registration_or_survey} = formFieldSource;

    // const fillFunction = () => {
    //     registration_or_survey.setFirm_name("Suzuki Demo One");
    //     registration_or_survey.setName_of_the_craft("Yamato Demo One");
    //     registration_or_survey.setType_of_the_craft("Speed Boat");
    //     registration_or_survey.setRegistration_number_of_the_craft("");
    //     registration_or_survey.setPort_of_registry_of_craft("Kakinada");
    //     registration_or_survey.setName_of_the_operator("Xing");
    //     registration_or_survey.setNet_tonnage(5);
    //     registration_or_survey.setGross_tonnage(3);
    //     registration_or_survey.setMaterial_of_the_craft("Carbon Fiber");
    //     registration_or_survey.setYear_of_built("2020-08-01");
    //     registration_or_survey.setBuilder_address("#13 FIRST FLOOR, 1ST B CROSS BENS SATYA ENCLAVE, KALYAN NAGAR POST, GEDDALAHALLI");
    //     registration_or_survey.setPassenger_capacity(11);
    //     registration_or_survey.setCrew_capacity(2);
    //     registration_or_survey.setDraft(0.7);
    //     registration_or_survey.setLoaded_draft(0.4);
    //     registration_or_survey.setCraft_dimension_length(5);
    //     registration_or_survey.setCraft_dimension_berth(2);
    //     registration_or_survey.setCraft_dimension_depth(2);
    //     registration_or_survey.setPlace_of_last_survey("Vizag");
    //     registration_or_survey.setDate_of_last_survey("2020-08-01");
    //     registration_or_survey.setEngine_make("Rolls Royce");
    //     registration_or_survey.setEngine_type("V8 Twin Turbo");
    //     registration_or_survey.setEngine_serial_Number("753951852");
    //     registration_or_survey.setEngine_hp("2059");
    //     registration_or_survey.setAdult_life_jackets(12);
    //     registration_or_survey.setChild_life_jackets(2);
    //     registration_or_survey.setLife_buoys(2);
    //     registration_or_survey.setFire_buckets(2);
    //     registration_or_survey.setFire_extinguishers(2);
    //     registration_or_survey.setFour_point_five_fire_extinguishers(3);
    //     registration_or_survey.setOne_point_nine_fire_extinguishers(3);
    //     registration_or_survey.setRescue_quoits_with_30m_rope(1);
    //     registration_or_survey.setCommunication_equipment(6);
    //     registration_or_survey.setHull_and_machinery_insurance_company("Insurance Company");
    //     registration_or_survey.setHull_and_machinery_insured_value_or_person("Test Person");
    //     registration_or_survey.setHull_and_machinery_policy_number(123456);
    //     registration_or_survey.setHull_and_machinery_valid_till("2020-08-01");
    //     registration_or_survey.setType_of_hull("Flat Bottom");
    //     registration_or_survey.setLbp(5);
    //     registration_or_survey.setPassenger_insurance_company("Test");
    //     registration_or_survey.setPassenger_insurance_policy_number(123456);
    //     registration_or_survey.setPassenger_insurance_valid_till("2020-08-01");
    //     registration_or_survey.setPassenger_insurance_insured_value_or_person("Test");
    //     registration_or_survey.setDriver_certificate_details("Driver Name");
    //     registration_or_survey.setDetails_of_other_machineries_if_any("None");
    //     registration_or_survey.setOwners_name("Ping");
    //     registration_or_survey.setOwners_address("#13 FIRST FLOOR, 1ST B CROSS BENS SATYA ENCLAVE, KALYAN NAGAR POST, GEDDALAHALLI");
    //     registration_or_survey.setOwners_email("test@gmail.com");
    //     registration_or_survey.setOwners_phone_number(7893227117);
    //     registration_or_survey.setCompany_name_if_different("Same");
    //     registration_or_survey.setCompany_address_if_different("Same");
    //     registration_or_survey.setAgents_name("Some name");
    //     registration_or_survey.setAgents_address("#13 FIRST FLOOR, 1ST B CROSS BENS SATYA ENCLAVE, KALYAN NAGAR POST, GEDDALAHALLI");
    //     registration_or_survey.setAgents_phone(7893227117);
    //     registration_or_survey.setDate_and_time_of_request_for_visit_of_surveyor("2020-08-10");
    //     registration_or_survey.setNoc_obtained_from_tourism_department_for_registration(true);
    //     registration_or_survey.setPlace_of_proposed_survey("Vizag");
    //     registration_or_survey.setPlace("Kakinada");
    //     registration_or_survey.setDate("2020-08-01");
    // };

    return (
        <div className="m-3">
            {/*{*/}
            {/*    !isViewMode ?*/}
            {/*        <Button onClick={() => fillFunction()}>*/}
            {/*            Fill Form*/}
            {/*        </Button>*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}
            <div className="form-row justify-content-center">
                <h3>APPLICATION FOR REGISTRATION / SURVEY</h3>
            </div>
            <br/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label for="nameOfTheFirm">Name of the Firm</Label>
                    <Input
                        required
                        type="text"
                        name="firmName"
                        disabled={isViewMode}
                        value={registration_or_survey.firm_name}
                        onChange={(e) => registration_or_survey.setFirm_name(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter name of the firm.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Name of the Craft</Label>
                    <Input
                        required
                        type="text"
                        name="craftName"
                        disabled={isViewMode}
                        value={registration_or_survey.name_of_the_craft}
                        onChange={(e) => registration_or_survey.setName_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter name of the craft.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Type of the Craft</Label>
                    <Input
                        required
                        type="text"
                        name="craftType"
                        disabled={isViewMode}
                        value={registration_or_survey.type_of_the_craft}
                        onChange={(e) => registration_or_survey.setType_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter type of the craft.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>New Craft Registration No</Label>
                    <Input
                        required
                        type="text"
                        name="craftRegistrationNo"
                        disabled={isViewMode}
                        value={registration_or_survey.registration_number_of_the_craft}
                        onChange={(e) => registration_or_survey.setRegistration_number_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter new registration no.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Previous Craft Registration No</Label>
                    <Input
                        required
                        type="text"
                        name="previousCraftRegistrationNo"
                        disabled={isViewMode}
                        value={registration_or_survey.previous_registration_number_of_the_craft}
                        onChange={(e) => registration_or_survey.setPrevious_registration_number_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter previous registration no.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Port of Registry of Craft</Label>
                    <Input
                        required
                        type="text"
                        name="portOfRegistryOfCraft"
                        disabled={isViewMode}
                        value={registration_or_survey.vessel_registration_place}
                        onChange={(e) => registration_or_survey.setPort_of_registry_of_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter port of registry of craft.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Net Tonnage</Label>
                    <Input
                        required
                        type="text"
                        name="netTonnage"
                        disabled={isViewMode}
                        value={registration_or_survey.net_tonnage}
                        onChange={(e) => registration_or_survey.setNet_tonnage(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter net tonnage.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Gross Tonnage</Label>
                    <Input
                        required
                        type="text"
                        name="grossTonnage"
                        disabled={isViewMode}
                        value={registration_or_survey.gross_tonnage}
                        onChange={(e) => registration_or_survey.setGross_tonnage(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter gross tonnage.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Material of the Craft</Label>
                    <Input
                        required
                        type="text"
                        name="materialOfTheCraft"
                        disabled={isViewMode}
                        value={registration_or_survey.material_of_the_craft}
                        onChange={(e) => registration_or_survey.setMaterial_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter material of the craft.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Year of Built</Label>
                    <Input
                        required
                        type="text"
                        name="yearOfBuilt"
                        disabled={isViewMode}
                        value={registration_or_survey.year_of_built}
                        onChange={(e) => registration_or_survey.setYear_of_built(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter year of built.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Builder Address</Label>
                    <Input
                        required
                        type="text"
                        name="builderAddress"
                        disabled={isViewMode}
                        value={registration_or_survey.builder_address}
                        onChange={(e) => registration_or_survey.setBuilder_address(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter builder address.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Passenger Capacity</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="passengerCapacity"
                        disabled={isViewMode}
                        value={registration_or_survey.passenger_capacity}
                        onChange={(e) => registration_or_survey.setPassenger_capacity(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter passenger capacity.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Crew Capacity</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="crewCapacity"
                        disabled={isViewMode}
                        value={registration_or_survey.crew_capacity}
                        onChange={(e) => registration_or_survey.setCrew_capacity(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter crew capacity.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Draft</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="draft"
                        disabled={isViewMode}
                        value={registration_or_survey.draft}
                        onChange={(e) => registration_or_survey.setDraft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter draft.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Loaded Draft</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="loadedDraft"
                        disabled={isViewMode}
                        value={registration_or_survey.loaded_draft}
                        onChange={(e) => registration_or_survey.setLoaded_draft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter loaded draft.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Craft Dimension Length</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="craftDimensionLength"
                        disabled={isViewMode}
                        value={registration_or_survey.craft_dimension_length}
                        onChange={(e) => registration_or_survey.setCraft_dimension_length(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft dimension length.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Craft Dimension Berth</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="craftDimensionBerth"
                        disabled={isViewMode}
                        value={registration_or_survey.craft_dimension_berth}
                        onChange={(e) => registration_or_survey.setCraft_dimension_berth(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft dimension berth.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Craft Dimension Depth</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="craftDimensionDepth"
                        disabled={isViewMode}
                        value={registration_or_survey.craft_dimension_depth}
                        onChange={(e) => registration_or_survey.setCraft_dimension_depth(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft dimension depth.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Place of Last Survey</Label>
                    <Input
                        required
                        type="text"
                        name="placeOfLastSurvey"
                        disabled={isViewMode}
                        value={registration_or_survey.place_of_last_survey}
                        onChange={(e) => registration_or_survey.setPlace_of_last_survey(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter place of last survey.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Date of Last Survey</Label>
                    <Input
                        required
                        type="date"
                        name="dateOfLastSurvey"
                        disabled={isViewMode}
                        value={registration_or_survey.date_of_last_survey === undefined ? registration_or_survey.date_of_last_survey : moment(registration_or_survey.date_of_last_survey).format('YYYY-MM-DD')}
                        onChange={(e) => registration_or_survey.setDate_of_last_survey(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter date of last survey.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Engine Make</Label>
                    <Input
                        required
                        type="text"
                        name="engineMake"
                        disabled={isViewMode}
                        value={registration_or_survey.engine_make}
                        onChange={(e) => registration_or_survey.setEngine_make(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter engine make.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Engine Type</Label>
                    <Input
                        required
                        type="text"
                        name="engineType"
                        disabled={isViewMode}
                        value={registration_or_survey.engine_type}
                        onChange={(e) => registration_or_survey.setEngine_type(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter engine type.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Engine Serial Number</Label>
                    <Input
                        required
                        // min="0"
                        // type="number"
                        type="text"
                        name="engineSerialNumber"
                        disabled={isViewMode}
                        value={registration_or_survey.engine_serial_Number}
                        onChange={(e) => registration_or_survey.setEngine_serial_Number(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter engine serial number.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Engine HP</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="engineHp"
                        disabled={isViewMode}
                        value={registration_or_survey.engine_hp}
                        onChange={(e) => registration_or_survey.setEngine_hp(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter engine hp.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>No of Adult Life Jackets</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="noOfAdultLifeJackets"
                        disabled={isViewMode}
                        value={registration_or_survey.adult_life_jackets}
                        onChange={(e) => registration_or_survey.setAdult_life_jackets(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter no of adult life jackets.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>No of Child Life Jackets</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="noOfChildLifeJackets"
                        disabled={isViewMode}
                        value={registration_or_survey.child_life_jackets}
                        onChange={(e) => registration_or_survey.setChild_life_jackets(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter no of child life jackets.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Life Buoys</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="lifeBuoys"
                        disabled={isViewMode}
                        value={registration_or_survey.life_buoys}
                        onChange={(e) => registration_or_survey.setLife_buoys(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter life buoys.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Fire Buckets</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="fireBuckets"
                        disabled={isViewMode}
                        value={registration_or_survey.fire_buckets}
                        onChange={(e) => registration_or_survey.setFire_buckets(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter fire buckets.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Fire Extinguishers</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="fireExtinguishers"
                        disabled={isViewMode}
                        value={registration_or_survey.fire_extinguishers}
                        onChange={(e) => registration_or_survey.setFire_extinguishers(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter fire extinguishers.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>4.5 Kg DCP Fire Extinguishers</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="fourPointFive"
                        disabled={isViewMode}
                        value={registration_or_survey.four_point_five_fire_extinguishers}
                        onChange={(e) => registration_or_survey.setFour_point_five_fire_extinguishers(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter extinguishers count.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>9 Ltr Foam Fire Extinguishers</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="onePointNine"
                        disabled={isViewMode}
                        value={registration_or_survey.one_point_nine_fire_extinguishers}
                        onChange={(e) => registration_or_survey.setOne_point_nine_fire_extinguishers(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter extinguishers count.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Rescue Quoits with 30m Rope</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="rescueQuoitsWith30mRope"
                        disabled={isViewMode}
                        value={registration_or_survey.rescue_quoits_with_30m_rope}
                        onChange={(e) => registration_or_survey.setRescue_quoits_with_30m_rope(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter rescue quoits with 30m rope.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label for="equSelect">Communication Equipment</Label>
                    <select
                        id="equSelect"
                        className="form-control"
                        name="communicationEquipment"
                        disabled={isViewMode}
                        value={registration_or_survey.communication_equipment}
                        onChange={(e) => registration_or_survey.setCommunication_equipment(e.target.value)}
                    >
                        <option> </option>
                        <option value="Mobile">Mobile</option>
                        <option value="Walkie-Talkie">Walkie-Talkie</option>
                        <option value="VHF">VHF</option>
                        <option value="AIS">AIS</option>
                    </select>
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter communication equipment.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Hull & Machinery Insurance Company</Label>
                    <Input
                        required
                        type="text"
                        name="hullAndMachineryInsuranceCompany"
                        disabled={isViewMode}
                        value={registration_or_survey.hull_and_machinery_insurance_company}
                        onChange={(e) => registration_or_survey.setHull_and_machinery_insurance_company(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter hull & machinery insurance company.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Hull & Machinery Insured value / person</Label>
                    <Input
                        required
                        type="text"
                        name="hullAndMachineryInsuredValue/Person"
                        disabled={isViewMode}
                        value={registration_or_survey.hull_and_machinery_insured_value_or_person}
                        onChange={(e) => registration_or_survey.setHull_and_machinery_insured_value_or_person(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter hull & machinery insured value / person.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Hull & Machinery Policy Number</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="hullAndMachineryPolicyNumber"
                        disabled={isViewMode}
                        value={registration_or_survey.hull_and_machinery_policy_number}
                        onChange={(e) => registration_or_survey.setHull_and_machinery_policy_number(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter hull & machinery policy number.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Hull & Machinery Valid till</Label>
                    <Input
                        required
                        type="date"
                        name="hullAndMachineryValidTill"
                        disabled={isViewMode}
                        value={registration_or_survey.hull_and_machinery_valid_till === undefined ? registration_or_survey.hull_and_machinery_valid_till : moment(registration_or_survey.hull_and_machinery_valid_till).format('YYYY-MM-DD')}
                        onChange={(e) => registration_or_survey.setHull_and_machinery_valid_till(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter hull & machinery valid till.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Type of Hull</Label>
                    <Input
                        required
                        type="text"
                        name="typeOfHull"
                        disabled={isViewMode}
                        value={registration_or_survey.type_of_hull}
                        onChange={(e) => registration_or_survey.setType_of_hull(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter type of hull.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>LBP</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="lbp"
                        disabled={isViewMode}
                        value={registration_or_survey.lbp}
                        onChange={(e) => registration_or_survey.setLbp(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter lbp.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Passenger Insurance Company</Label>
                    <Input
                        required
                        type="text"
                        name="passengerInsuranceCompany"
                        disabled={isViewMode}
                        value={registration_or_survey.passenger_insurance_company}
                        onChange={(e) => registration_or_survey.setPassenger_insurance_company(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter passenger insurance company.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Passenger Insurance Policy Number</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="passengerInsurancePolicyNumber"
                        disabled={isViewMode}
                        value={registration_or_survey.passenger_insurance_policy_number}
                        onChange={(e) => registration_or_survey.setPassenger_insurance_policy_number(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter passenger insurance policy number.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Passenger Insurance Valid Till</Label>
                    <Input
                        required
                        type="date"
                        name="passengerInsuranceValidTill"
                        disabled={isViewMode}
                        value={registration_or_survey.passenger_insurance_valid_till === undefined ? registration_or_survey.passenger_insurance_valid_till : moment(registration_or_survey.passenger_insurance_valid_till).format('YYYY-MM-DD')}
                        onChange={(e) => registration_or_survey.setPassenger_insurance_valid_till(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter passenger insurance valid till.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Passenger Insurance Insured Value / Person</Label>
                    <Input
                        required
                        type="text"
                        name="passengerInsuranceInsuredValueorPerson"
                        disabled={isViewMode}
                        value={registration_or_survey.passenger_insurance_insured_value_or_person}
                        onChange={(e) => registration_or_survey.setPassenger_insurance_insured_value_or_person(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter passenger insurance insured value / person.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Driver Certificate Details</Label>
                    <Input
                        required
                        type="text"
                        name="driverCertificateDetails"
                        disabled={isViewMode}
                        value={registration_or_survey.driver_certificate_details}
                        onChange={(e) => registration_or_survey.setDriver_certificate_details(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter driver certificate details.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Details of other machinery if any</Label>
                    <Input
                        required
                        type="text"
                        name="detailsOfOtherMachineryIfAny"
                        disabled={isViewMode}
                        value={registration_or_survey.details_of_other_machineries_if_any}
                        onChange={(e) => registration_or_survey.setDetails_of_other_machineries_if_any(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter details of other machinery if any.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Name of the Operator</Label>
                    <Input
                        required
                        type="text"
                        name="nameOfTheOperator"
                        disabled={isViewMode}
                        value={registration_or_survey.name_of_the_operator}
                        onChange={(e) => registration_or_survey.setName_of_the_operator(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter name of the operator.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Owner's Name</Label>
                    <Input
                        required
                        type="text"
                        name="wnersName"
                        disabled={isViewMode}
                        value={registration_or_survey.owners_name}
                        onChange={(e) => registration_or_survey.setOwners_name(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's name.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Owner's Address</Label>
                    <Input
                        required
                        type="text"
                        name="ownersAddress"
                        disabled={isViewMode}
                        value={registration_or_survey.owners_address}
                        onChange={(e) => registration_or_survey.setOwners_address(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's address.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Owner's Email</Label>
                    <Input
                        required
                        type="text"
                        name="ownersEmail"
                        disabled={isViewMode}
                        value={registration_or_survey.owners_email}
                        onChange={(e) => registration_or_survey.setOwners_email(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's email.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Owner's Phone Number</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="ownersPhoneNumber"
                        disabled={isViewMode}
                        value={registration_or_survey.owners_phone_number}
                        onChange={(e) => registration_or_survey.setOwners_phone_number(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's phone number.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Company Name if Different</Label>
                    <Input
                        required
                        type="text"
                        name="companyNameIfDifferent"
                        disabled={isViewMode}
                        value={registration_or_survey.company_name_if_different}
                        onChange={(e) => registration_or_survey.setCompany_name_if_different(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter company name if different.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Company Address if Different</Label>
                    <Input
                        required
                        type="text"
                        name="companyAddressIfDifferent"
                        disabled={isViewMode}
                        value={registration_or_survey.company_address_if_different}
                        onChange={(e) => registration_or_survey.setCompany_address_if_different(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter company address if different.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Agent's Name</Label>
                    <Input
                        required
                        type="text"
                        name="agentsName"
                        disabled={isViewMode}
                        value={registration_or_survey.agents_name}
                        onChange={(e) => registration_or_survey.setAgents_name(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter agent's name.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Agent's Address</Label>
                    <Input
                        required
                        type="text"
                        name="agentsAddress"
                        disabled={isViewMode}
                        value={registration_or_survey.agents_address}
                        onChange={(e) => registration_or_survey.setAgents_address(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter agent's address.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Agent's Phone</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        name="agentsPhone"
                        disabled={isViewMode}
                        value={registration_or_survey.agents_phone}
                        onChange={(e) => registration_or_survey.setAgents_phone(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter agent's phone.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-5 mb-3">
                    <Label>Date And Time of Request For Visit Of Surveyor</Label>
                    <Input
                        required
                        type="date"
                        name=""
                        disabled={isViewMode}
                        value={registration_or_survey.date_and_time_of_request_for_visit_of_surveyor === undefined ? registration_or_survey.date_and_time_of_request_for_visit_of_surveyor : moment(registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format('YYYY-MM-DD')}
                        onChange={(e) => registration_or_survey.setDate_and_time_of_request_for_visit_of_surveyor(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter date and time of request for visit of surveyor.
                    </div>
                </div>
                <div className="col-md-7 mb-3">
                    <Label>Noc Obtained From Tourism Department for Registration (Yes / No)</Label>
                    <Input
                        required
                        type="boolean"
                        name="NocObtainedFromTourismDepartmentForRegistration"
                        disabled={isViewMode}
                        value={registration_or_survey.noc_obtained_from_tourism_department_for_registration}
                        onChange={(e) => registration_or_survey.setNoc_obtained_from_tourism_department_for_registration(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter yes/no for noc obtained from tourism department
                        for
                        registration.
                    </div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Place Of Proposed Survey</Label>
                    <Input
                        required
                        type="text"
                        name="placeOfProposedSurvey"
                        disabled={isViewMode}
                        value={registration_or_survey.place_of_proposed_survey}
                        onChange={(e) => registration_or_survey.setPlace_of_proposed_survey(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter place of proposed survey.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Place</Label>
                    <Input
                        required
                        type="text"
                        name="place"
                        disabled={isViewMode}
                        value={registration_or_survey.place}
                        onChange={(e) => registration_or_survey.setPlace(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter current place.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Date</Label>
                    <Input
                        required
                        type="date"
                        name="date"
                        disabled={isViewMode}
                        value={registration_or_survey.date === undefined ? registration_or_survey.date : moment(registration_or_survey.date).format('YYYY-MM-DD')}
                        onChange={(e) => registration_or_survey.setDate(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter date.</div>
                </div>
            </div>
        </div>
    );
};

export default ApplicationForRegistrationSurvey;
