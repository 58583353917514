import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    UncontrolledCollapse,
} from "reactstrap";
import moment from 'moment';

class Modals extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            openModal: false,
        };
    }

    toggle = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    };

    render() {
        return (
            <React.Fragment key={"rejectButton"}>
                <Button
                    color={"danger"}
                    onClick={() => this.toggle()}
                    className="mr-1"
                >
                    {this.props.name}
                </Button>
                <Modal
                    isOpen={this.state.openModal}
                    toggle={() => this.toggle()}
                    size={"xl"}
                    centered
                >
                    <ModalHeader toggle={() => this.toggle()}>
                        {this.props.header}
                    </ModalHeader>
                    <ModalBody className="text-center m-3 overflow-auto" style={{height: 500}}>
                        {
                            this.props.reasons.map(each => (
                                <div className="accordion" key={each.rejection_date}>
                                    <Card>
                                        <CardHeader>
                                            <CardTitle tag="h5" className="my-2 text-left" id="collapseOne" href="#collapseOne">
                                                Rejection Reason Dated {moment(each.rejection_date).format('DD-MM-YYYY')}
                                            </CardTitle>
                                        </CardHeader>
                                        <UncontrolledCollapse toggler="#collapseOne" defaultOpen={true}>
                                            <CardBody>
                                                <CardText className="text-left">
                                                    {each.reason}
                                                </CardText>
                                            </CardBody>
                                        </UncontrolledCollapse>
                                    </Card>
                                </div>
                            ))
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.toggle()}>
                            Close
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default Modals;
