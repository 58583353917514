import React from "react";
import Vessel from "../Vessel";
import { MachineryItem } from "../models/Vessel.machinery";
import { FormGroup, Label, Input } from "reactstrap";
type VesselMachineryFormType = {
    vessel: Vessel;
    onSave: (updatedVessel: Vessel) => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};
const VesselMachineryForm: React.FC<VesselMachineryFormType> = ({
    vessel, mode
}: VesselMachineryFormType) => {
    const isViewMode = mode === "View";
    const { machinery } = vessel;
    return (
        <div className="row">
            <div className="col-12 mt-3 mb-3">
                <h3>Main machinery</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.main_machinery}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Main generator</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.main_generator}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Emergency generator</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.emergency_generator}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Main steering gear</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.main_steering_gear}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Auxillary steering gear</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.auxillary_steering_gear}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Switchboard</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.switchboard}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Shore connection</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.shore_connection}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Batteries</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.batteries}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Signal light switch</h3>
                <MachineryFormItem
                    isViewMode={isViewMode}
                    machineryItem={machinery.signal_light_switch}
                />
            </div>
        </div>
    );
};
export default VesselMachineryForm;

type MachineryFormItemProps = {
    machineryItem: MachineryItem;
    isViewMode: boolean;
};
const MachineryFormItem = ({
    machineryItem,
    isViewMode
}: MachineryFormItemProps) => {
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Power BHP</Label>
                    <Input
						disabled={isViewMode}
                        type="number"
                        value={machineryItem.power_bhp}
                        onChange={e =>
                            machineryItem.setField("power_bhp", e.target.value)
                        }
                        placeholder="Power BHP"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={machineryItem.type}
                        onChange={e =>
                            machineryItem.setField("type", e.target.value)
                        }
                        placeholder="Type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={machineryItem.condition}
                        onChange={e =>
                            machineryItem.setField("condition", e.target.value)
                        }
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={machineryItem.location}
                        onChange={e =>
                            machineryItem.setField("location", e.target.value)
                        }
                        placeholder="Location"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
