import React, { useState } from "react";
import { formRulesType } from "../Vessel.form.helper";
import { FormGroup, Label, Input } from "reactstrap";
import Vessel from "../Vessel";
type VesselBasicFormType = {
    vessel: Vessel;
    requiredFields: formRulesType;
    onSave: (updatedVessel: Vessel) => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};

const VesselBasicForm: React.FC<VesselBasicFormType> = ({
    vessel: vesselState,
    onSave,
    requiredFields: fields,
    mode
}: VesselBasicFormType) => {
    const isViewMode = mode === "View";
    const { hull_details } = vesselState;
    const { hull_details: req_hull } = fields;
    return (
        <div className="row">
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Builder Name</Label>
                    <Input
                        disabled={isViewMode}
                        required={req_hull.builder_name}
                        type="text"
                        value={hull_details.builder_name}
                        onChange={e =>
                            hull_details.setBuilder_name(e.target.value)
                        }
                        placeholder="Builder Name"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Builder Address</Label>
                    <Input
                        disabled={isViewMode}
                        required={req_hull.builder_address}
                        type="text"
                        value={hull_details.builder_address}
                        onChange={e =>
                            hull_details.setBuilder_address(e.target.value)
                        }
                        placeholder="Builder Address"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Builder Certificate</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.builder_certificate}
                        onChange={e =>
                            hull_details.setBuilder_certificate(e.target.value)
                        }
                        placeholder="Builder Certificate"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Place of Building</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.place_of_building}
                        onChange={e =>
                            hull_details.setPlace_of_building(e.target.value)
                        }
                        placeholder="Place Of Building"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Date of Build</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.date_of_build}
                        onChange={e =>
                            hull_details.setDate_of_build(e.target.value)
                        }
                        placeholder="Date of Build"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Means Of Propulsion</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.means_of_propulsion}
                        onChange={e =>
                            hull_details.setMeans_of_propulsion(e.target.value)
                        }
                        placeholder="Means of Propulsion"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Date of Engine Construction</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.date_of_engine_construction}
                        onChange={e =>
                            hull_details.setDate_of_engine_construction(
                                e.target.value
                            )
                        }
                        placeholder="Date of Engine Construction"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Hull material</Label>
                    <Input
                        disabled={isViewMode}
                        required={req_hull.hull_material}
                        type="text"
                        value={hull_details.hull_material}
                        onChange={e =>
                            hull_details.setHull_material(e.target.value)
                        }
                        placeholder="Hull material"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Bulk heads placement</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.bulk_heads_placement}
                        onChange={e =>
                            hull_details.setBulk_heads_placement(e.target.value)
                        }
                        placeholder="Bulk heads placement"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Hull plating material</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.hull_plating_material}
                        onChange={e =>
                            hull_details.setHull_plating_material(
                                e.target.value
                            )
                        }
                        placeholder="Hull plating material"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Total_BHP</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.total_BHP}
                        onChange={e =>
                            hull_details.setTotal_BHP(e.target.value)
                        }
                        placeholder="Total BHP"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Year of built</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.year_of_built}
                        onChange={e =>
                            hull_details.setYear_of_built(e.target.value)
                        }
                        placeholder="Year of built"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>No of bulk heads</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.no_of_bulk_heads}
                        onChange={e =>
                            hull_details.setNo_of_bulk_heads(e.target.value)
                        }
                        placeholder="No of bulk heads"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Bulk heads thickness</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.bulk_heads_thickness}
                        onChange={e =>
                            hull_details.setBulk_heads_thickness(e.target.value)
                        }
                        placeholder="Bulk heads thickness"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Hull plating thickness</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={hull_details.hull_plating_thickness}
                        onChange={e =>
                            hull_details.setHull_plating_thickness(
                                e.target.value
                            )
                        }
                        placeholder="Hull plating thickness"
                    />
                </FormGroup>
            </div>
            <div className="col-xs-12 col-sm-12 col-12 col-lg-4 col-xxl-3">
                <FormGroup>
                    <Label>Is deck free</Label>
                    <Input
                        disabled={isViewMode}
                        required={req_hull.is_deck_free}
                        type="text"
                        value={hull_details.is_deck_free}
                        onChange={e =>
                            hull_details.setIs_deck_free(e.target.value)
                        }
                        placeholder="Is deck free"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default VesselBasicForm;

/**
 *
 */
