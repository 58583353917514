import React from "react";
import {Route, BrowserRouter, Switch, Router} from "react-router-dom";
import {
    // landing as landingRoutes,
    dashboard as dashboardRoutes,
    page as pageRoutes,
    belowRoutes,
} from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import Page404 from "../pages/auth/Page404";
import history from "./history";
import VesselForm from "../pages/printables/vesselForm";
import ProvisionalCertificate from "../pages/printables/ProvisionalCertificate";
import routesHelper from "./routes.helper";

// Harsha Start
import LandingComponent from "../pages/landing/LandingComponent";
// Harsha End

const childRoutes = (Layout, routes) =>
    routes.map(({children, path, component: Component}, index) =>
        children ? (
            // Route item with children
            children.map(({path, component: Component}, index) => (
                <Route
                    key={index}
                    path={path}
                    exact
                    render={props => (
                        <Layout>
                            <Component {...props} />
                        </Layout>
                    )}
                />
            ))
        ) : (
            // Route item without children
            <Route
                key={index}
                path={path}
                exact
                render={props => (
                    <Layout>
                        <Component {...props} />
                    </Layout>
                )}
            />
        )
    );

const Routes = () => (
    <BrowserRouter>
        <Router history={history}>
            <Switch>
                {/*{childRoutes(DashboardLayout, landingRoutes)}*/}
                {childRoutes(DashboardLayout, dashboardRoutes)}
                {childRoutes(AuthLayout, pageRoutes)}
                <Route path={routesHelper.vesselFormPrint.path} component={VesselForm}/>
                <Route path={routesHelper.provisionalPrint.path} component={ProvisionalCertificate}/>

                {childRoutes(DashboardLayout, belowRoutes)}
                <Route path={routesHelper.landingPage.path} component={LandingComponent}/>

                <Route
                    render={() => (
                        <AuthLayout>
                            <Page404/>
                        </AuthLayout>
                    )}
                />
            </Switch>
        </Router>

    </BrowserRouter>


);

export default Routes;
