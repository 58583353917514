import axios from "axios";

export default function () {
    const token =
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user"))["token"];
    if (token) {
        axios.defaults.headers.common["x-access-token"] = token;
    } else {
        axios.defaults.headers.common["x-access-token"] = null;
    }
}
