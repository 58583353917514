// Actions Types for Status
export const CREATE_SUCCESS = 'create_success';
export const UPDATE_SUCCESS = 'update_success';
export const DELETE_SUCCESS = 'delete_success';
export const EMPTY_SUCCESS = 'empty_success';
export const CREATE_ERROR = 'create_error';
export const UPDATE_ERROR = 'update_error';
export const DELETE_ERROR = 'delete_error';
export const EMPTY_ERROR = 'empty_error';

// Action Types for Vessels Module
export const CREATE_VESSEL = 'create_vessel';
export const COUNT_VESSELS = 'count_vessels';
export const FETCH_VESSELS = 'fetch_vessels';
export const UPDATE_VESSEL = 'update_vessel';
export const DELETE_VESSEL = 'delete_vessel';
export const SEARCH_VESSEL = 'search_vessel';
export const COUNT_EXPIRED = 'count_expired';
export const COUNT_EXPIRING = 'count_expiring';

// Actions Types for Auth
export const AUTH_TIMEOUT = 'auth_timeout';

// Actions Types for Empty Count
export const EMPTY_COUNT = 'empty_count';
