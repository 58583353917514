import {RegistrationOrSurvey} from '../../typeDeclarations/ApplicationFormsTypeDeclarations';

export default class RegistrationOrSurveyModel {
    vessel_registration_place: String = "";
    firm_name: String = "";
    name_of_the_craft: String = "";
    type_of_the_craft: String = "";
    registration_number_of_the_craft: String = "";
    previous_registration_number_of_the_craft: String = "";
    port_of_registry_of_craft: String = "";
    name_of_the_operator: String = "";
    net_tonnage: String = "";
    gross_tonnage: String = "";
    material_of_the_craft: String = "";
    year_of_built: String = "";
    builder_address: String = "";
    passenger_capacity: Number = undefined;
    crew_capacity: Number = undefined;
    draft: Number = undefined;
    loaded_draft: Number = undefined;
    craft_dimension_length: Number = undefined;
    craft_dimension_berth: Number = undefined;
    craft_dimension_depth: Number = undefined;
    place_of_last_survey: String = "";
    date_of_last_survey: Date = undefined;
    engine_make: String = "";
    engine_type: String = "";
    engine_serial_Number: String = "";
    engine_hp: Number = undefined;
    adult_life_jackets: Number = undefined;
    child_life_jackets: Number = undefined;
    life_buoys: Number = undefined;
    fire_buckets: Number = undefined;
    fire_extinguishers: Number = undefined;
    four_point_five_fire_extinguishers: Number = undefined;
    one_point_nine_fire_extinguishers: Number = undefined;
    rescue_quoits_with_30m_rope: Number = undefined;
    communication_equipment: String = "";
    hull_and_machinery_insurance_company: String = "";
    hull_and_machinery_policy_number: Number = undefined;
    hull_and_machinery_insured_value_or_person: String = "";
    hull_and_machinery_valid_till: Date = undefined;
    type_of_hull: String = "";
    lbp: Number = undefined;
    passenger_insurance_company: String = "";
    passenger_insurance_policy_number: Number = undefined;
    passenger_insurance_insured_value_or_person: String = "";
    passenger_insurance_valid_till: Date = undefined;
    driver_certificate_details: String = "";
    details_of_other_machineries_if_any: String = "";
    owners_name: String = "";
    owners_address: String = "";
    owners_email: String = "";
    owners_phone_number: Number = undefined;
    company_name_if_different: String = "";
    company_address_if_different: String = "";
    agents_name: String = "";
    agents_address: String = "";
    agents_phone: Number = undefined;
    date_and_time_of_request_for_visit_of_surveyor: Date = undefined;
    place_of_proposed_survey: String = "";
    noc_obtained_from_tourism_department_for_registration: String = "";
    place: String = "";
    date: Date = undefined;

    onChange: () => void;

    constructor(params: RegistrationOrSurvey, onChange: () => void) {
        // console.log("In RegistrationOrSurveyModel.js File");
        // console.log("One Params " + JSON.stringify(params));
        params = {
            ...{
                vessel_registration_place: "",
                firm_name: "",
                name_of_the_craft: "",
                type_of_the_craft: "",
                registration_number_of_the_craft: "",
                previous_registration_number_of_the_craft: "",
                port_of_registry_of_craft: "",
                name_of_the_operator: "",
                net_tonnage: undefined,
                gross_tonnage: undefined,
                material_of_the_craft: "",
                year_of_built: "",
                builder_address: "",
                passenger_capacity: undefined,
                crew_capacity: undefined,
                draft: undefined,
                loaded_draft: undefined,
                craft_dimension_length: undefined,
                craft_dimension_berth: undefined,
                craft_dimension_depth: undefined,
                place_of_last_survey: "",
                date_of_last_survey: undefined,
                engine_make: "",
                engine_type: "",
                engine_serial_Number: "",
                engine_hp: undefined,
                adult_life_jackets: undefined,
                child_life_jackets: undefined,
                life_buoys: undefined,
                fire_buckets: undefined,
                fire_extinguishers: undefined,
                four_point_five_fire_extinguishers: undefined,
                one_point_nine_fire_extinguishers: undefined,
                rescue_quoits_with_30m_rope: undefined,
                communication_equipment: "",
                hull_and_machinery_insurance_company: "",
                hull_and_machinery_policy_number: undefined,
                hull_and_machinery_insured_value_or_person: "",
                hull_and_machinery_valid_till: undefined,
                type_of_hull: "",
                lbp: undefined,
                passenger_insurance_company: "",
                passenger_insurance_policy_number: undefined,
                passenger_insurance_insured_value_or_person: "",
                passenger_insurance_valid_till: undefined,
                driver_certificate_details: "",
                details_of_other_machineries_if_any: "",
                owners_name: "",
                owners_address: "",
                owners_email: "",
                owners_phone_number: undefined,
                company_name_if_different: "",
                company_address_if_different: "",
                agents_name: "",
                agents_address: "",
                agents_phone: undefined,
                date_and_time_of_request_for_visit_of_surveyor: undefined,
                place_of_proposed_survey: "",
                noc_obtained_from_tourism_department_for_registration: "",
                place: "",
                date: undefined,
            }, ...params
        };
        // console.log("Two Params " + JSON.stringify(params));

        this.vessel_registration_place = params.vessel_registration_place;
        this.firm_name = params.firm_name;
        this.name_of_the_craft = params.name_of_the_craft;
        this.type_of_the_craft = params.type_of_the_craft;
        this.registration_number_of_the_craft = params.registration_number_of_the_craft;
        this.previous_registration_number_of_the_craft = params.previous_registration_number_of_the_craft;
        this.port_of_registry_of_craft = params.port_of_registry_of_craft;
        this.name_of_the_operator = params.name_of_the_operator;
        this.net_tonnage = params.net_tonnage;
        this.gross_tonnage = params.gross_tonnage;
        this.material_of_the_craft = params.material_of_the_craft;
        this.year_of_built = params.year_of_built;
        this.builder_address = params.builder_address;
        this.passenger_capacity = params.passenger_capacity;
        this.crew_capacity = params.crew_capacity;
        this.draft = params.draft;
        this.loaded_draft = params.loaded_draft;
        this.craft_dimension_length = params.craft_dimension_length;
        this.craft_dimension_berth = params.craft_dimension_berth;
        this.craft_dimension_depth = params.craft_dimension_depth;
        this.place_of_last_survey = params.place_of_last_survey;
        this.date_of_last_survey = params.date_of_last_survey;
        this.engine_make = params.engine_make;
        this.engine_type = params.engine_type;
        this.engine_serial_Number = params.engine_serial_Number;
        this.engine_hp = params.engine_hp;
        this.adult_life_jackets = params.adult_life_jackets;
        this.child_life_jackets = params.child_life_jackets;
        this.life_buoys = params.life_buoys;
        this.fire_buckets = params.fire_buckets;
        this.fire_extinguishers = params.fire_extinguishers;
        this.four_point_five_fire_extinguishers = params.four_point_five_fire_extinguishers;
        this.one_point_nine_fire_extinguishers = params.one_point_nine_fire_extinguishers;
        this.rescue_quoits_with_30m_rope = params.rescue_quoits_with_30m_rope;
        this.communication_equipment = params.communication_equipment;
        this.hull_and_machinery_insurance_company = params.hull_and_machinery_insurance_company;
        this.hull_and_machinery_policy_number = params.hull_and_machinery_policy_number;
        this.hull_and_machinery_insured_value_or_person = params.hull_and_machinery_insured_value_or_person;
        this.hull_and_machinery_valid_till = params.hull_and_machinery_valid_till;
        this.type_of_hull = params.type_of_hull;
        this.lbp = params.lbp;
        this.passenger_insurance_company = params.passenger_insurance_company;
        this.passenger_insurance_policy_number = params.passenger_insurance_policy_number;
        this.passenger_insurance_insured_value_or_person = params.passenger_insurance_insured_value_or_person;
        this.passenger_insurance_valid_till = params.passenger_insurance_valid_till;
        this.driver_certificate_details = params.driver_certificate_details;
        this.details_of_other_machineries_if_any = params.details_of_other_machineries_if_any;
        this.owners_name = params.owners_name;
        this.owners_address = params.owners_address;
        this.owners_email = params.owners_email;
        this.owners_phone_number = params.owners_phone_number;
        this.company_name_if_different = params.company_name_if_different;
        this.company_address_if_different = params.company_address_if_different;
        this.agents_name = params.agents_name;
        this.agents_address = params.agents_address;
        this.agents_phone = params.agents_phone;
        this.date_and_time_of_request_for_visit_of_surveyor = params.date_and_time_of_request_for_visit_of_surveyor;
        this.place_of_proposed_survey = params.place_of_proposed_survey;
        this.noc_obtained_from_tourism_department_for_registration = params.noc_obtained_from_tourism_department_for_registration;
        this.place = params.place;
        this.date = params.date;

        this.onChange = onChange;
    }

    setVessel_registration_place(vessel_registration_place) {
        this.vessel_registration_place = vessel_registration_place;
        this.onChange()
    }

    setFirm_name(firm_name) {
        this.firm_name = firm_name;
        this.onChange()
    }

    setName_of_the_craft(name_of_the_craft) {
        this.name_of_the_craft = name_of_the_craft;
        this.onChange()
    }

    setType_of_the_craft(type_of_the_craft) {
        this.type_of_the_craft = type_of_the_craft;
        this.onChange()
    }

    setRegistration_number_of_the_craft(registration_number_of_the_craft) {
        this.registration_number_of_the_craft = registration_number_of_the_craft;
        this.onChange()
    }

    setPrevious_registration_number_of_the_craft(previous_registration_number_of_the_craft) {
        this.previous_registration_number_of_the_craft = previous_registration_number_of_the_craft;
        this.onChange()
    }

    setPort_of_registry_of_craft(port_of_registry_of_craft) {
        this.port_of_registry_of_craft = port_of_registry_of_craft;
        this.onChange()
    }

    setName_of_the_operator(name_of_the_operator) {
        this.name_of_the_operator = name_of_the_operator;
        this.onChange()
    }

    setNet_tonnage(net_tonnage) {
        this.net_tonnage = net_tonnage;
        this.onChange()
    }

    setGross_tonnage(gross_tonnage) {
        this.gross_tonnage = gross_tonnage;
        this.onChange()
    }

    setMaterial_of_the_craft(material_of_the_craft) {
        this.material_of_the_craft = material_of_the_craft;
        this.onChange()
    }

    setYear_of_built(year_of_built) {
        this.year_of_built = year_of_built;
        this.onChange()
    }

    setBuilder_address(builder_address) {
        this.builder_address = builder_address;
        this.onChange()
    }

    setPassenger_capacity(passenger_capacity) {
        this.passenger_capacity = passenger_capacity;
        this.onChange()
    }

    setCrew_capacity(crew_capacity) {
        this.crew_capacity = crew_capacity;
        this.onChange()
    }

    setDraft(draft) {
        this.draft = draft;
        this.onChange()
    }

    setLoaded_draft(loaded_draft) {
        this.loaded_draft = loaded_draft;
        this.onChange()
    }

    setCraft_dimension_length(craft_dimension_length) {
        this.craft_dimension_length = craft_dimension_length;
        this.onChange()
    }

    setCraft_dimension_berth(craft_dimension_berth) {
        this.craft_dimension_berth = craft_dimension_berth;
        this.onChange()
    }

    setCraft_dimension_depth(craft_dimension_depth) {
        this.craft_dimension_depth = craft_dimension_depth;
        this.onChange()
    }

    setPlace_of_last_survey(place_of_last_survey) {
        this.place_of_last_survey = place_of_last_survey;
        this.onChange()
    }

    setDate_of_last_survey(date_of_last_survey) {
        this.date_of_last_survey = date_of_last_survey;
        this.onChange()
    }

    setEngine_make(engine_make) {
        this.engine_make = engine_make;
        this.onChange()
    }

    setEngine_type(engine_type) {
        this.engine_type = engine_type;
        this.onChange()
    }

    setEngine_serial_Number(engine_serial_Number) {
        this.engine_serial_Number = engine_serial_Number;
        this.onChange()
    }

    setEngine_hp(engine_hp) {
        this.engine_hp = engine_hp;
        this.onChange()
    }

    setAdult_life_jackets(adult_life_jackets) {
        this.adult_life_jackets = adult_life_jackets;
        this.onChange()
    }

    setChild_life_jackets(child_life_jackets) {
        this.child_life_jackets = child_life_jackets;
        this.onChange()
    }

    setLife_buoys(life_buoys) {
        this.life_buoys = life_buoys;
        this.onChange()
    }

    setFire_buckets(fire_buckets) {
        this.fire_buckets = fire_buckets;
        this.onChange()
    }

    setFire_extinguishers(fire_extinguishers) {
        this.fire_extinguishers = fire_extinguishers;
        this.onChange()
    }

    setFour_point_five_fire_extinguishers(four_point_five_fire_extinguishers) {
        this.four_point_five_fire_extinguishers = four_point_five_fire_extinguishers;
        this.onChange()
    }

    setOne_point_nine_fire_extinguishers(one_point_nine_fire_extinguishers) {
        this.one_point_nine_fire_extinguishers = one_point_nine_fire_extinguishers;
        this.onChange()
    }

    setRescue_quoits_with_30m_rope(rescue_quoits_with_30m_rope) {
        this.rescue_quoits_with_30m_rope = rescue_quoits_with_30m_rope;
        this.onChange()
    }

    setCommunication_equipment(communication_equipment) {
        this.communication_equipment = communication_equipment;
        this.onChange()
    }

    setHull_and_machinery_insurance_company(hull_and_machinery_insurance_company) {
        this.hull_and_machinery_insurance_company = hull_and_machinery_insurance_company;
        this.onChange()
    }

    setHull_and_machinery_policy_number(hull_and_machinery_policy_number) {
        this.hull_and_machinery_policy_number = hull_and_machinery_policy_number;
        this.onChange()
    }

    setHull_and_machinery_insured_value_or_person(hull_and_machinery_insured_value_or_person) {
        this.hull_and_machinery_insured_value_or_person = hull_and_machinery_insured_value_or_person;
        this.onChange()
    }

    setHull_and_machinery_valid_till(hull_and_machinery_valid_till) {
        this.hull_and_machinery_valid_till = hull_and_machinery_valid_till;
        this.onChange()
    }

    setType_of_hull(type_of_hull) {
        this.type_of_hull = type_of_hull;
        this.onChange()
    }

    setLbp(lbp) {
        this.lbp = lbp;
        this.onChange()
    }

    setPassenger_insurance_company(passenger_insurance_company) {
        this.passenger_insurance_company = passenger_insurance_company;
        this.onChange()
    }

    setPassenger_insurance_policy_number(passenger_insurance_policy_number) {
        this.passenger_insurance_policy_number = passenger_insurance_policy_number;
        this.onChange()
    }

    setPassenger_insurance_insured_value_or_person(passenger_insurance_insured_value_or_person) {
        this.passenger_insurance_insured_value_or_person = passenger_insurance_insured_value_or_person;
        this.onChange()
    }

    setPassenger_insurance_valid_till(passenger_insurance_valid_till) {
        this.passenger_insurance_valid_till = passenger_insurance_valid_till;
        this.onChange()
    }

    setDriver_certificate_details(driver_certificate_details) {
        this.driver_certificate_details = driver_certificate_details;
        this.onChange()
    }

    setDetails_of_other_machineries_if_any(details_of_other_machineries_if_any) {
        this.details_of_other_machineries_if_any = details_of_other_machineries_if_any;
        this.onChange()
    }

    setOwners_name(owners_name) {
        this.owners_name = owners_name;
        this.onChange()
    }

    setOwners_address(owners_address) {
        this.owners_address = owners_address;
        this.onChange()
    }

    setOwners_email(owners_email) {
        this.owners_email = owners_email;
        this.onChange()
    }

    setOwners_phone_number(owners_phone_number) {
        this.owners_phone_number = owners_phone_number;
        this.onChange()
    }

    setCompany_name_if_different(company_name_if_different) {
        this.company_name_if_different = company_name_if_different;
        this.onChange()
    }

    setCompany_address_if_different(company_address_if_different) {
        this.company_address_if_different = company_address_if_different;
        this.onChange()
    }

    setAgents_name(agents_name) {
        this.agents_name = agents_name;
        this.onChange()
    }

    setAgents_address(agents_address) {
        this.agents_address = agents_address;
        this.onChange()
    }

    setAgents_phone(agents_phone) {
        this.agents_phone = agents_phone;
        this.onChange()
    }

    setDate_and_time_of_request_for_visit_of_surveyor(date_and_time_of_request_for_visit_of_surveyor) {
        this.date_and_time_of_request_for_visit_of_surveyor = date_and_time_of_request_for_visit_of_surveyor;
        this.onChange()
    }

    setPlace_of_proposed_survey(place_of_proposed_survey) {
        this.place_of_proposed_survey = place_of_proposed_survey;
        this.onChange()
    }

    setNoc_obtained_from_tourism_department_for_registration(noc_obtained_from_tourism_department_for_registration) {
        this.noc_obtained_from_tourism_department_for_registration = noc_obtained_from_tourism_department_for_registration;
        this.onChange()
    }

    setPlace(place) {
        this.place = place;
        this.onChange()
    }

    setDate(date) {
        this.date = date;
        this.onChange()
    }
}
