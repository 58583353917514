import {
    FETCH_BELOW_VESSEL_APPROVERS
} from "../../actions/ActionTypes/b12ActionTypes";

const ApproversReducers = (state = [], actions) => {
    if (actions.type === FETCH_BELOW_VESSEL_APPROVERS) {
        return actions.payload;
    } else {
        return state;
    }
};

export default ApproversReducers;
