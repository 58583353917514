import React from 'react';
import {Button, Input, Label} from "reactstrap";
import moment from 'moment';
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";

type TypeChecking = {
    formFieldSource: ApplicationFormFieldsSource,
    formsToBeFilledMode: boolean,
    userProfile: {},
};

const DeclarationOfOwnership: React.FC<TypeChecking> = ({formFieldSource, formsToBeFilledMode, userProfile, allData}: TypeChecking) => {

    const isViewMode = userProfile.userType === "port-operator" ?
        allData !== undefined ?
            allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.status === "rejected" ?
                true
                :
                formsToBeFilledMode
            :
            formsToBeFilledMode
        :
        formsToBeFilledMode;

    const { declaration_of_ownership } = formFieldSource;

    // const fillFunction = () => {
    //     declaration_of_ownership.setOwners_name("Jet Lee");
    //     declaration_of_ownership.setPrevious_name_of_the_craft("Test Name");
    //     declaration_of_ownership.setPrevious_owner_of_the_craft("Test Owner");
    //     declaration_of_ownership.setOwners_state_of("Andhra Pradesh");
    //     declaration_of_ownership.setOwners_permanent_address("#13 FIRST FLOOR, 1ST B CROSS BENS SATYA ENCLAVE, KALYAN NAGAR POST, GEDDALAHALLI");
    //     declaration_of_ownership.setOwners_business_address("#13 FIRST FLOOR, 1ST B CROSS BENS SATYA ENCLAVE, KALYAN NAGAR POST, GEDDALAHALLI");
    //     declaration_of_ownership.setCraft_or_vessel_name("Yamato Demo One");
    //     declaration_of_ownership.setCraft_or_vessel_built_at("Vizag");
    //     declaration_of_ownership.setCraft_or_vessel_built_year("2020-08-01");
    //     declaration_of_ownership.setCraft_or_vessel_purchased_date("2020-01-01");
    //     declaration_of_ownership.setCraft_or_vessel_cost("8952631");
    //     declaration_of_ownership.setRegistered_at_port("Vizag");
    //     declaration_of_ownership.setPly_in_the_port("Vizag");
    //     declaration_of_ownership.setMade_and_subscribed("Test");
    //     declaration_of_ownership.setDay_of("Aug");
    //     declaration_of_ownership.setDay_of_20("2020-08-01");
    //     declaration_of_ownership.setAbove_named("Yamato Demo");
    //     declaration_of_ownership.setIn_the_presence_of("Port Officer");
    // };

    return (
        <div className="m-3">
            {/*{*/}
            {/*    !isViewMode ?*/}
            {/*        <Button onClick={() => fillFunction()}>*/}
            {/*            Fill Form*/}
            {/*        </Button>*/}
            {/*        :*/}
            {/*        null*/}
            {/*}*/}
            <div className="form-row justify-content-center">
                <h3>DECLARATION OF OWNERSHIP</h3>
            </div>
            <br/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label for="nameOfOwner">Name of the Owner</Label>
                    <Input
                        required
                        type="text"
                        name="ownerName"
                        disabled={isViewMode}
                        value={declaration_of_ownership.owners_name}
                        onChange={(e) => declaration_of_ownership.setOwners_name(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter name of owner.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label for="nameOfOwner">Previous Name of the Craft</Label>
                    <Input
                        required
                        type="text"
                        name="previousNameOfTheCraft"
                        disabled={isViewMode}
                        value={declaration_of_ownership.previous_name_of_the_craft}
                        onChange={(e) => declaration_of_ownership.setPrevious_name_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter previous name of the craft.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label for="nameOfOwner">Previous Owner of the Craft</Label>
                    <Input
                        required
                        type="text"
                        name="previousOwnerOfTheCraft"
                        disabled={isViewMode}
                        value={declaration_of_ownership.previous_owner_of_the_craft}
                        onChange={(e) => declaration_of_ownership.setPrevious_owner_of_the_craft(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter previous owner of the craft.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Owner's State of</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.owners_state_of}
                        onChange={(e) => declaration_of_ownership.setOwners_state_of(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter state of.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Owner's Permanent Address</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.owners_permanent_address}
                        onChange={(e) => declaration_of_ownership.setOwners_permanent_address(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's permanent address.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Owner's Business Address</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.owners_business_address}
                        onChange={(e) => declaration_of_ownership.setOwners_business_address(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter owner's business address.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Craft/Vessel Name</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.craft_or_vessel_name}
                        onChange={(e) => declaration_of_ownership.setCraft_or_vessel_name(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter name of craft/vessel.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Craft/Vessel Built At</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.craft_or_vessel_built_at}
                        onChange={(e) => declaration_of_ownership.setCraft_or_vessel_built_at(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft/vessel built at.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Craft/Vessel Built Year</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.craft_or_vessel_built_year}
                        onChange={(e) => declaration_of_ownership.setCraft_or_vessel_built_year(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft/vessel built year.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Craft/Vessel Purchased Date</Label>
                    <Input
                        required
                        type="date"
                        disabled={isViewMode}
                        value={declaration_of_ownership.craft_or_vessel_purchased_date === undefined ? declaration_of_ownership.craft_or_vessel_purchased_date : moment(declaration_of_ownership.craft_or_vessel_purchased_date).format('YYYY-MM-DD')}
                        onChange={(e) => declaration_of_ownership.setCraft_or_vessel_purchased_date(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft/vessel purchased date.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Craft/Vessel Cost</Label>
                    <Input
                        required
                        min="0"
                        type="number"
                        disabled={isViewMode}
                        value={declaration_of_ownership.craft_or_vessel_cost}
                        onChange={(e) => declaration_of_ownership.setCraft_or_vessel_cost(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter craft/vessel cost.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Registered at Port</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.registered_at_port}
                        onChange={(e) => declaration_of_ownership.setRegistered_at_port(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter registered at port name.</div>
                </div>
            </div>
            <hr style={{height: "1px", backgroundColor: "black"}}/>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Ply in the Port</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.ply_in_the_port}
                        onChange={(e) => declaration_of_ownership.setPly_in_the_port(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter ply in the port name.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Made and Subscribed</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.made_and_subscribed}
                        onChange={(e) => declaration_of_ownership.setMade_and_subscribed(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter made and subscribed.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Day Of</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.day_of}
                        onChange={(e) => declaration_of_ownership.setDay_of(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter day of.</div>
                </div>
            </div>
            <div className="form-row">
                <div className="col-md-4 mb-3">
                    <Label>Day Of 20</Label>
                    <Input
                        required
                        type="date"
                        disabled={isViewMode}
                        value={declaration_of_ownership.day_of_20 === undefined ? declaration_of_ownership.day_of_20 : moment(declaration_of_ownership.day_of_20).format('YYYY-MM-DD')}
                        onChange={(e) => declaration_of_ownership.setDay_of_20(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter day of 20.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>Above Named</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.above_named}
                        onChange={(e) => declaration_of_ownership.setAbove_named(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter above named.</div>
                </div>
                <div className="col-md-4 mb-3">
                    <Label>In the Presence of</Label>
                    <Input
                        required
                        type="text"
                        disabled={isViewMode}
                        value={declaration_of_ownership.in_the_presence_of}
                        onChange={(e) => declaration_of_ownership.setIn_the_presence_of(e.target.value)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                    <div className="invalid-feedback">Please enter in the presence of.</div>
                </div>
            </div>
        </div>
    );
};

export default DeclarationOfOwnership;
