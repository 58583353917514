import React from 'react';
import $ from "jquery";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import moment from "moment";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import { Button } from "reactstrap";

const VesselBasicFormType = {
    formFieldSource: ApplicationFormFieldsSource,
};

const CertificateOfRegistryPrint = ({ formFieldSource }: VesselBasicFormType) => {

    let printCertificateOfRegistry = () => {

        let top_left_X_Y_Coordinates = 0;
        let PDF_Width = 1240;
        let PDF_Height = 1754;
        let pdf = "";

        $("#print-wrap-page7").attr("hidden", false);
        html2canvas($("#print-wrap-page7")[0], { allowTaint: true }).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
            pdf.output('dataurlnewwindow');
        });
        $("#print-wrap-page7").attr("hidden", true);
    };

    return (
        <div>
            <Button color={"success"}
                onClick={() => printCertificateOfRegistry()}
                style={{ width: 260 }}
                className="mr-1 mb-1">
                Certificate of Registry
            </Button>

            <div style={{ width: 1240, display: "flex", justifyContent: "center" }}>
                <div id="print-wrap-page7" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center",
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between"
                    }}>
                        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 25,
                                    }}>ANNEXURE - 1 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 50px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>CERTIFICATE OF REGISTRY</h4>
                                </div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <h4 style={{
                                        fontFamily: "sans-serif",
                                        fontSize: 28,
                                        fontWeight: "bold",
                                        marginBottom: 0,
                                    }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>Place of Registry:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.registration_or_survey.vessel_registration_place}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "flex-start",
                                        background: "#fff"
                                    }}>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>Registration No:&nbsp;</h4>
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{formFieldSource.vesselActivity.stages_info.stages.stage_survey_status.registration_number}</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>This is to certify that the registration requested
                                        for water sport craft <span style={{ fontWeight: "bold" }}>{formFieldSource.registration_or_survey.name_of_the_craft}</span>&nbsp;
                                        has been approved by this office. Therefore, ANDHRA PRADESH Maritime Board hereby grants and authorizes
                                        Certificate of Registry to water sport craft whose particulars are given below.</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 20px 0px"
                            }}>
                                <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse", fontFamily: "sans-serif", fontSize: 20 }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", width: "25%" }}>&nbsp;Name of the Craft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} colSpan="3">&nbsp;{formFieldSource.registration_or_survey.name_of_the_craft}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Owner's Name</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} colSpan="3">&nbsp;{formFieldSource.registration_or_survey.owners_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Owner's Address</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} colSpan="3">&nbsp;{formFieldSource.registration_or_survey.owners_address}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Previous Name of Craft <br /> &nbsp;Previous Owner Name</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>
                                                {
                                                    formFieldSource.declaration_of_ownership.previous_name_of_the_craft !== "" && formFieldSource.declaration_of_ownership.previous_owner_of_the_craft !== "" ?
                                                        <>&nbsp;{formFieldSource.declaration_of_ownership.previous_name_of_the_craft}<br />&nbsp;{formFieldSource.declaration_of_ownership.previous_owner_of_the_craft}</>
                                                        :
                                                        "N.A"
                                                }
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", width: "20%" }}>&nbsp;Previous Registration &nbsp;Number</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}> </td>
                                             <td style={{border: "2px solid black", borderCollapse: "collapse"}}>&nbsp;{formFieldSource.registration_or_survey.previous_registration_number_of_the_craft}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Place & Year of Built</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} colSpan="3">&nbsp;{formFieldSource.declaration_of_ownership.craft_or_vessel_built_at}&nbsp;&&nbsp;{formFieldSource.declaration_of_ownership.craft_or_vessel_built_year}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Length overall Mtrs / Ft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.craft_dimension_length}&nbsp;m</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;L.B.P. (Mtrs / Ft)</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.lbp}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Breadth of vessel, Mtrs / Ft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.craft_dimension_berth}&nbsp;m</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Material of &nbsp;Construction</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.material_of_the_craft}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Depth of vessel, Mtrs / Ft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.craft_dimension_depth}&nbsp;m</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Loaded Draft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.loaded_draft}&nbsp;m</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;GT / NT</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.gross_tonnage}&nbsp;/&nbsp;{formFieldSource.registration_or_survey.net_tonnage}</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Type of Craft</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.type_of_the_craft}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Engine Particulars</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>
                                                &nbsp;Make:&nbsp;{formFieldSource.registration_or_survey.engine_make}
                                                <br />&nbsp;Model:&nbsp;{formFieldSource.registration_or_survey.engine_type}
                                                <br />&nbsp;Sl.No:&nbsp;{formFieldSource.registration_or_survey.engine_serial_Number}
                                                <br />&nbsp;H.P: &nbsp;{formFieldSource.registration_or_survey.engine_hp}
                                            </td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }}>&nbsp;Passenger Capacity</td>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse" }} align="center">&nbsp;{formFieldSource.registration_or_survey.passenger_capacity} + {formFieldSource.registration_or_survey.crew_capacity}&nbsp;Crew</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 0px 0px"
                            }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25, marginBottom: 0 }}>Other Particulars and Information</h4>
                                </div>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 70px 0px"
                            }}>
                                <table style={{ width: "100%", border: "1px solid black", borderCollapse: "collapse", fontFamily: "sans-serif", fontSize: 20 }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ border: "2px solid black", borderCollapse: "collapse", height: 30 }}>&nbsp;Date Of Issue : {moment(formFieldSource.vesselActivity.stages_info.stages.stage_survey_date.survey_date).format('DD-MM-YYYY')}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                background: "#fff",
                                padding: "0px 0px 30px 0px"
                            }}>
                                <div style={{ display: "flex", flexDirection: "row", background: "#fff" }}>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>REMARKS:&nbsp;</h4>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                        <h4 style={{
                                            fontFamily: "sans-serif",
                                            fontSize: 25,
                                        }}> </h4>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 60px 0px"
                        }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", background: "#fff" }}>
                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25, marginBottom: 0 }}>
                                        REGISTRAR OF WATER SPORTS CRAFT
                                    </h4>
                                    <h4 style={{ fontFamily: "sans-serif", fontSize: 25 }}>
                                        &nbsp;&nbsp;ANDHRA PRADESH MARITIME BOARD
                                    </h4>
                                </div>
                            </div>
                        </div>

                        {/* <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 0px 0px"
                        }}>
                            <h4 style={{fontFamily: "sans-serif", fontSize: 20, marginBottom: 0}}>This is a digitally generated form, does not require signature. Date & Time - {moment(formFieldSource.vesselActivity.stages_info.stages.stage_certificate_issue.approved_by[0].timeStamp).format('DD-MM-YYYY h:mm:ss a')}</h4>
                        </div> */}

                    </div>
                </div>
            </div>
        </div>
    )
};

export default CertificateOfRegistryPrint;
