import React, {useState, useEffect, useRef} from 'react';
import {connect} from "react-redux";
import {Container} from "reactstrap";
import jQuery from "jquery";
import FormsToBeFilled from "./registrationSurveySteps/FormsToBeFilled";
import SurveyFee from "./registrationSurveySteps/SurveyFee";
import SurveyDate from "./registrationSurveySteps/SurveyDate";
import SurveyStatus from "./registrationSurveySteps/SurveyStatus";
import NoteFile from "./registrationSurveySteps/NoteFile";
import RegistrationFee from "./registrationSurveySteps/RegistrationFee";
import CertificateIssue from "./registrationSurveySteps/CertificateIssue";
import ApplicationFormFieldsSource from "../../formFieldsSource/ApplicationFormFieldsSource";
import {getFormRouteInformation} from "../../common/HelperMethods";
import {
    StageWiseApprovers,
    GetUserDetails,
    SaveBelowVesselAndActivity,
    UpdateBelowVessel,
    SubmitBelowVessel,
    FetchBelowVessel,
    ClearBelowVessel,
    // FetchPaymentStatusOfVessel,
    ApproveBelowVesselStage,
    RejectBelowVesselStage
} from "../../../redux/actions/Below12Actions/BelowVesselActions";
import {UploadBelowVesselFile, DeleteBelowVesselFile, DownloadBelowVesselFile} from "../../../redux/actions/CommonActions/FileActions";
import {ClearBelowStatus} from "../../../redux/actions/CommonActions/StatusActions";
import StatusToast from "../../common/StatusToast";

const $ = jQuery;
window.jQuery = jQuery;

require("smartwizard/dist/js/jquery.smartWizard.min.js");

const RegistrationSurveyLandingComponent = ({match, location, belowVessel, approvers, user, status, dispatch}) => {

    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('userProfile')));
    const [formFieldSource, setFormFieldSource] = useState(new ApplicationFormFieldsSource());
    const [formsToBeFilledMode, setFormsToBeFilledMode] = useState(false);
    // const [surveyFeeMode, setSurveyFeeMode] = useState(false);
    // const [surveyDateMode, setSurveyDateMode] = useState(false);
    // const [surveyStatusMode, setSurveyStatusMode] = useState(false);
    // const [noteFileMode, setNoteFileMode] = useState(false);
    // const [paymentMode, setPaymentMode] = useState(false);
    // const [issueMode, setIssueMode] = useState(false);
    const [formsToBeFilledStatus, setFormsToBeFilledStatus] = useState(false);
    const [surveyFeeStatus, setSurveyFeeStatus] = useState(false);
    const [surveyDateStatus, setSurveyDateStatus] = useState(false);
    const [surveyStatusStatus, setSurveyStatusStatus] = useState(false);
    const [noteFileStatus, setNoteFileStatus] = useState(false);
    const [registrationFeeStatus, setRegistrationFeeStatus] = useState(false);
    const [issueStatus, setIssueStatus] = useState(false);
    const [allData, setAllData] = useState();
    // const [surveyFeePaymentStatus, setSurveyFeePaymentStatus] = useState(false);
    // const [registrationFeePaymentStatus, setRegistrationFeePaymentStatus] = useState(false);

    const routeInformation = getFormRouteInformation(match.path, {
        ...match.params,
        payload: location.state,
    });

    const {vesselId} = routeInformation;

    const wizard = useRef(null);

    useEffect(() => {
        if (vesselId) {
            if (Object.keys(belowVessel).length !== 0 && belowVessel.id === vesselId) {
                let adjustedData = {
                    registration_or_survey: belowVessel.registration_or_survey,
                    declaration_of_ownership: belowVessel.declaration_of_ownership
                };
                setFormFieldSource(new ApplicationFormFieldsSource(adjustedData));
                // setFormsToBeFilledMode(belowVessel.vesselActivity.stages_info.stages.stage_forms_to_be_filled.stage_status === "submitted");
                // setSurveyFeeMode(belowVessel.vesselActivity.stages_info.stages.stage_survey_fee.stage_status === "submitted");
                // setSurveyDateMode(belowVessel.vesselActivity.stages_info.stages.stage_survey_date.stage_status === "submitted");
                // setSurveyStatusMode(belowVessel.vesselActivity.stages_info.stages.stage_survey_status.stage_status === "submitted");
                // setNoteFileMode(belowVessel.vesselActivity.stages_info.stages.stage_note_file.stage_status === "submitted");
                // setPaymentMode(belowVessel.vesselActivity.stages_info.stages.stage_registration_fee.stage_status === "submitted");
                // setIssueMode(belowVessel.vesselActivity.stages_info.stages.stage_certificate_issue.stage_status === "submitted");
                setAllData(belowVessel);
            } else {
                dispatch(FetchBelowVessel(vesselId));
            }
        }
    }, [belowVessel]);

    useEffect(() => {
        if (belowVessel !== undefined) {
            if (belowVessel.vesselActivity !== undefined) {
                setFormsToBeFilledMode(belowVessel.vesselActivity.stages_info.stages.stage_forms_to_be_filled.stage_status === "submitted");
                setFormsToBeFilledStatus(belowVessel.vesselActivity.stages_info.stages.stage_forms_to_be_filled.status === "approved");
                setSurveyFeeStatus(belowVessel.vesselActivity.stages_info.stages.stage_survey_fee.status === "approved");
                setSurveyDateStatus(belowVessel.vesselActivity.stages_info.stages.stage_survey_date.status === "approved");
                setSurveyStatusStatus(belowVessel.vesselActivity.stages_info.stages.stage_survey_status.status === "approved");
                setNoteFileStatus(belowVessel.vesselActivity.stages_info.stages.stage_note_file.status === "approved");
                setRegistrationFeeStatus(belowVessel.vesselActivity.stages_info.stages.stage_registration_fee.status === "approved");
                setIssueStatus(belowVessel.vesselActivity.stages_info.stages.stage_certificate_issue.status === "approved");

                // setSurveyFeePaymentStatus(belowVessel.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true);
                // setRegistrationFeePaymentStatus(belowVessel.vesselActivity.stages_info.stages.stage_registration_fee.payment_success === true);
            }
        }
    });

    useEffect(() => {
        $(wizard.current)
            .smartWizard({
                theme: "arrows",
                showStepURLhash: false,
            })
            .on("leaveStep", function (e, anchorObject, stepNumber, stepDirection) {
                if (stepDirection === "forward") {
                    // Step validation
                }
                return true;
            });
        $(wizard.current)
            .find(".btn-submit")
            .on("click", function () {
                alert("Great! The form is ready to submit.");
                // Final validation
                return false;
            });
    });

    useEffect(() => {
        if (userProfile.userType === "port-operator" && vesselId) {
            dispatch(StageWiseApprovers(vesselId));
        }
    }, []);

    useEffect(() => {
        if (Object.keys(status).length !== 0) {
            StatusToast(status);
            dispatch(ClearBelowStatus());
        }
    }, [status]);

    useEffect(() => {
        return function cleanUpBelowVessel() {
            dispatch(ClearBelowVessel());
        }
    }, []);

    const UserDetails = (vesselId) => {
        dispatch(GetUserDetails(vesselId))
    };

    const SaveVessel = () => {
        dispatch(SaveBelowVesselAndActivity(new ApplicationFormFieldsSource(formFieldSource)))
    };

    const UpdateVessel = () => {
        if (vesselId) {
            if (belowVessel.length !== 0) {
                let latestData = new ApplicationFormFieldsSource(formFieldSource);
                let adjustedData = {...belowVessel};
                delete adjustedData.id;
                delete adjustedData.created_by;
                delete adjustedData.vesselActivity;
                adjustedData.registration_or_survey = latestData.registration_or_survey;
                adjustedData.declaration_of_ownership = latestData.declaration_of_ownership;

                dispatch(UpdateBelowVessel(adjustedData, vesselId));
            }
        }
    };

    const SubmitVessel = () => {
        if (vesselId) {
            if (belowVessel.length !== 0) {
                let latestData = new ApplicationFormFieldsSource(formFieldSource);
                let adjustedData = {...belowVessel};
                delete adjustedData.id;
                delete adjustedData.created_by;
                adjustedData.application_status = "submitted";
                adjustedData.vesselActivity.application_status = "submitted";
                adjustedData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.stage_status = "submitted";
                adjustedData.registration_or_survey = latestData.registration_or_survey;
                adjustedData.declaration_of_ownership = latestData.declaration_of_ownership;

                dispatch(SubmitBelowVessel(adjustedData, vesselId));
            }
        }
    };

    const FileUpload = (file) => {
        dispatch(UploadBelowVesselFile(file))
    };

    const FileDelete = (file) => {
        dispatch(DeleteBelowVesselFile(file))
    };

    const FileDownload = (file) => {
        dispatch(DownloadBelowVesselFile(file))
    };

    // const PaymentStatus = (activityId) => {
    //     dispatch(FetchPaymentStatusOfVessel(activityId, allData));
    // };

    const ApproveStage = (userProfile, stage, data) => {
        dispatch(ApproveBelowVesselStage(userProfile, stage, data, allData));
    };

    const RejectStage = (stage, rejectionReason) => {
        dispatch(RejectBelowVesselStage(stage, rejectionReason, allData))
    };

    formFieldSource.subscribeForStateChange(setFormFieldSource);

    return (
        <Container fluid className="p-0">
            <div className="form-row justify-content-between" style={{margin: "0px 2px 0px 2px"}}>
                <h1 className="h3 mb-3">Registration / Survey
                    ( {Object.keys(belowVessel).length !== 0 ?
                        belowVessel.vesselActivity.stages_info.stages.stage_forms_to_be_filled.status === "rejected" ?
                            "Rejected"
                            :
                            belowVessel.vesselActivity.application_status
                        :
                        "New"} )
                </h1>

                {Object.keys(belowVessel).length !== 0 ?
                    <h1 className="h3 mb-3">
                        Application No: {belowVessel.vesselActivity.application_no}
                    </h1>
                    :
                    null
                }
            </div>
            <div ref={wizard} className={`wizard wizard-${"primary"} mb-4`}>
                <ul>
                    <li>
                        <a href={`#step-1`}>
                            Forms to be Filled & Submitted
                        </a>
                    </li>
                    <li>
                        <a href={`#step-2`}>
                            Survey Fee
                        </a>
                    </li>
                    <li>
                        <a href={`#step-3`}>
                            Survey Date
                        </a>
                    </li>
                    <li>
                        <a href={`#step-4`}>
                            Survey Status
                        </a>
                    </li>
                    <li>
                        <a href={`#step-5`}>
                            Note File
                        </a>
                    </li>
                    <li>
                        <a href={`#step-6`}>
                            Registration Fee
                        </a>
                    </li>
                    <li>
                        <a href={`#step-7`}>
                            Certificate Issue
                        </a>
                    </li>
                </ul>

                <div>
                    <div id={`step-1`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <FormsToBeFilled
                            userProfile={userProfile}
                            approvers={approvers}
                            formFieldSource={formFieldSource}
                            onSave={(formFieldValues) => setFormFieldSource(new ApplicationFormFieldsSource(formFieldValues))}
                            vesselId={vesselId}
                            allData={allData}
                            formsToBeFilledMode={formsToBeFilledMode}
                            saveVessel={SaveVessel}
                            updateVessel={UpdateVessel}
                            submitVessel={SubmitVessel}
                            fileUpload={FileUpload}
                            fileDelete={FileDelete}
                            fileDownload={FileDownload}
                            approveStage={ApproveStage}
                            rejectStage={RejectStage}
                        />
                    </div>
                    <div id={`step-2`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <SurveyFee
                            userProfile={userProfile}
                            approvers={approvers}
                            vesselId={vesselId}
                            allData={allData}
                            previousStepStatus={formsToBeFilledStatus}
                            userDetails={UserDetails}
                            approveStage={ApproveStage}
                            user={user}
                            // paymentStatus={PaymentStatus}
                            // surveyFeePaymentStatus={surveyFeePaymentStatus}
                        />
                    </div>
                    <div id={`step-3`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <SurveyDate
                            userProfile={userProfile}
                            approvers={approvers}
                            allData={allData}
                            previousStepStatus={surveyFeeStatus}
                            approveStage={ApproveStage}
                        />
                    </div>
                    <div id={`step-4`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <SurveyStatus
                            userProfile={userProfile}
                            approvers={approvers}
                            allData={allData}
                            previousStepStatus={surveyDateStatus}
                            approveStage={ApproveStage}
                            rejectStage={RejectStage}
                        />
                    </div>
                    <div id={`step-5`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <NoteFile
                            userProfile={userProfile}
                            approvers={approvers}
                            allData={allData}
                            previousStepStatus={surveyStatusStatus}
                            approveStage={ApproveStage}
                        />
                    </div>
                    <div id={`step-6`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <RegistrationFee
                            userProfile={userProfile}
                            approvers={approvers}
                            vesselId={vesselId}
                            allData={allData}
                            userDetails={UserDetails}
                            previousStepStatus={noteFileStatus}
                            approveStage={ApproveStage}
                            user={user}
                            // paymentStatus={PaymentStatus}
                        />
                    </div>
                    <div id={`step-7`} style={{background: "rgba(71,186,193,0.2)"}}>
                        <CertificateIssue
                            userProfile={userProfile}
                            approvers={approvers}
                            allData={allData}
                            previousStepStatus={registrationFeeStatus}
                            approveStage={ApproveStage}
                        />
                    </div>
                </div>
            </div>
        </Container>
    );
};

const mapStateToProps = ({belowVessel, approvers, user, status}) => ({
    belowVessel,
    approvers,
    user,
    status
});

// const mapStateToProps = state => {
//     return {
//         belowVessel: state.belowVessel,
//         approvers: state.approvers,
//         user: state.user,
//         status: state.status,
//         // surveyFeeAmountStatus: state.belowVessel.vesselActivity.stages_info.stages.stage_survey_fee.payment_success,
//         // registrationFeeAmountStatus: state.belowVessel.vesselActivity.stages_info.stages.stage_registration_fee.payment_success
//     }
// };

const mapDispatchToProps = (dispatch) => ({
    StageWiseApprovers,
    GetUserDetails,
    SaveBelowVesselAndActivity,
    UpdateBelowVessel,
    SubmitBelowVessel,
    FetchBelowVessel,
    ClearBelowVessel,
    ClearBelowStatus,
    UploadBelowVesselFile,
    DeleteBelowVesselFile,
    DownloadBelowVesselFile,
    // FetchPaymentStatusOfVessel,
    ApproveBelowVesselStage,
    RejectBelowVesselStage,
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(RegistrationSurveyLandingComponent);
