import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    Row,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Input,
    Label,
    UncontrolledTooltip
} from "reactstrap";

type TypeChecking = {
    userProfile: {},
    approvers: {},
    vesselId: string,
    userDetails: () => void,
    previousStepStatus: boolean,
    approveStage: () => void,
    user: {},
}

const SurveyFee = ({userProfile, approvers, vesselId, allData, userDetails, previousStepStatus, approveStage, user}: TypeChecking) => {

    const [invoiceNo, setInvoiceNo] = useState("");

    useEffect(() => {
        if (userProfile.userType === "port-operator" && vesselId) {
            userDetails(vesselId);
        }
    }, []);

    // useEffect(() => {
    //     if (allData !== undefined) {
    //         if (allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number.length !== 0) {
    //             if (allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === false) {
    //                 if (allData.vesselActivity.stages_info.current_stage === "stage_survey_fee") {
    //                     if (allData.vesselActivity.id) {
    //                         paymentStatus(allData.vesselActivity.id);
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // });

    // const checkPaymentStatus = () => {
    //     if (allData !== undefined) {
    //         if (allData.vesselActivity.id) {
    //             paymentStatus(allData.vesselActivity.id);
    //         }
    //     }
    // };

    const onChange = (value) => {
        setInvoiceNo(value);
    };

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Survey Fee&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_fee.notify_payment === true ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_done === true ?
                                            allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ?
                                                <>
                                                    <CardText>
                                                        Payment is Successful for the Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number}.
                                                    </CardText>
                                                </>
                                                :
                                                <>
                                                    <CardText>
                                                        Amount Payment for the Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number} is
                                                        done.
                                                        Please verify the Invoice Number in Smart Port application for payment status and click on the Payment Successful button.
                                                    </CardText>
                                                    <CardText>
                                                        Status of Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number} is a success from Owner/Agency/Agent's side.
                                                    </CardText>
                                                    <Button
                                                        id={"paymentSuccess"}
                                                        color={"success"}
                                                        onClick={() => approvers.stage_survey_fee.includes(userProfile.id) ?
                                                            approveStage(userProfile, "stage_survey_fee", {
                                                                type: "payment-successful",
                                                                invoiceNo: invoiceNo
                                                            })
                                                            :
                                                            () => {
                                                            }}
                                                        className="mr-1 mb-1">
                                                        Payment successful
                                                    </Button>
                                                    {!approvers.stage_survey_fee.includes(userProfile.id) ?
                                                        <UncontrolledTooltip
                                                            placement={"bottom"}
                                                            target={"paymentSuccess"}>
                                                            You are not authorized
                                                        </UncontrolledTooltip>
                                                        : null}
                                                </>
                                            :
                                            <>
                                                <CardText>
                                                    Payment from Owner/Agency/Agent is still pending.
                                                </CardText>
                                                <CardText>
                                                    Status of Invoice
                                                    No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number} is
                                                    still pending...
                                                </CardText>
                                            </>
                                        :
                                        <>
                                            <CardText>
                                                Please raise an Invoice in Smart Port application
                                                to {user.name} ( {user.username} ) and enter the Invoice No in the field
                                                provided, then click on Notify Payment
                                                button to
                                                inform agent.
                                            </CardText>
                                            <CardText>
                                                Payment Status : INITIATING
                                            </CardText>
                                            <Label for="invoiceNo">Enter Invoice No</Label>
                                            <Input
                                                required
                                                type="text"
                                                name="invoiceNumber"
                                                value={invoiceNo}
                                                onChange={(e) => onChange(e.target.value)}
                                            />
                                            <br/>
                                            <Button
                                                id={"notifyPayment"}
                                                color={"primary"}
                                                onClick={() => approvers.stage_survey_fee.includes(userProfile.id) ?
                                                    invoiceNo.length !== 0 ?
                                                        approveStage(userProfile, "stage_survey_fee", {
                                                            type: "notify-payment",
                                                            invoiceNo: invoiceNo
                                                        })
                                                        :
                                                        alert("Invoice No cannot be empty")
                                                    :
                                                    () => {
                                                    }}
                                                className="mr-1 mb-1">
                                                Notify Payment
                                            </Button>
                                            {!approvers.stage_survey_fee.includes(userProfile.id) ?
                                                <UncontrolledTooltip
                                                    placement={"bottom"}
                                                    target={"notifyPayment"}>
                                                    You are not authorized to Notify Payment
                                                </UncontrolledTooltip>
                                                : null}
                                        </>
                                    :
                                    null
                            }

                            <>
                                {/*{*/}
                                {/*    allData !== undefined ?*/}
                                {/*        allData.vesselActivity.stages_info.stages.stage_survey_fee.notify_payment === true ?*/}
                                {/*            <>*/}
                                {/*                <CardText>*/}
                                {/*                    Invoice*/}
                                {/*                    No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number}.*/}
                                {/*                </CardText>*/}
                                {/*                <Row className="px-3">*/}
                                {/*                    <CardText className="m-0">*/}
                                {/*                        Payment Status*/}
                                {/*                        : {surveyFeePaymentStatus === true ? "SUCCESS" : "PENDING..."}*/}
                                {/*                    </CardText>*/}
                                {/*                    {*/}
                                {/*                        allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ?*/}
                                {/*                            null*/}
                                {/*                            :*/}
                                {/*                            <Button size="sm" className="ml-4"*/}
                                {/*                                    onClick={() => checkPaymentStatus()}>*/}
                                {/*                                REFRESH*/}
                                {/*                            </Button>*/}
                                {/*                    }*/}
                                {/*                </Row>*/}
                                {/*                <br/>*/}
                                {/*                {*/}
                                {/*                    allData.vesselActivity.stages_info.stages.stage_survey_fee.status === "approved" ?*/}
                                {/*                        null*/}
                                {/*                        :*/}
                                {/*                        <>*/}
                                {/*                            <Button*/}
                                {/*                                id={"paymentSuccess"}*/}
                                {/*                                color={"success"}*/}
                                {/*                                onClick={() => approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                                    approveStage(userProfile, "stage_survey_fee", {*/}
                                {/*                                        type: "payment-successful"*/}
                                {/*                                    })*/}
                                {/*                                    :*/}
                                {/*                                    () => {*/}
                                {/*                                    }}*/}
                                {/*                                className="mr-1 mb-1">*/}
                                {/*                                Payment successful*/}
                                {/*                            </Button>*/}
                                {/*                            {!approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                                <UncontrolledTooltip*/}
                                {/*                                    placement={"bottom"}*/}
                                {/*                                    target={"paymentSuccess"}>*/}
                                {/*                                    You are not authorized*/}
                                {/*                                </UncontrolledTooltip>*/}
                                {/*                                : null*/}
                                {/*                            }*/}
                                {/*                        </>*/}
                                {/*                }*/}
                                {/*                <br/>*/}
                                {/*                {*/}
                                {/*                    allData.vesselActivity.stages_info.stages.stage_survey_fee.notify_payment !== true ?*/}
                                {/*                        null*/}
                                {/*                        :*/}
                                {/*                        allData.vesselActivity.stages_info.stages.stage_survey_fee.status === "approved" ?*/}
                                {/*                            null*/}
                                {/*                            :*/}
                                {/*                            <>*/}
                                {/*                                <br/>*/}
                                {/*                                <CardText>*/}
                                {/*                                    In case if you have entered wrong Invoice No, enter*/}
                                {/*                                    number*/}
                                {/*                                    below*/}
                                {/*                                </CardText>*/}
                                {/*                                <Label for="invoiceNo">Enter Invoice No again</Label>*/}
                                {/*                                <Input*/}
                                {/*                                    required*/}
                                {/*                                    type="text"*/}
                                {/*                                    name="invoiceNumber"*/}
                                {/*                                    value={invoiceNo}*/}
                                {/*                                    onChange={(e) => onChange(e.target.value)}*/}
                                {/*                                />*/}
                                {/*                                <br/>*/}
                                {/*                                <Button*/}
                                {/*                                    id={"changeInvoiceNo"}*/}
                                {/*                                    color={"primary"}*/}
                                {/*                                    onClick={() => approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                                        invoiceNo.length !== 0 ?*/}
                                {/*                                            approveStage(userProfile, "stage_survey_fee", {*/}
                                {/*                                                type: "notify-payment",*/}
                                {/*                                                invoiceNo: invoiceNo*/}
                                {/*                                            })*/}
                                {/*                                            :*/}
                                {/*                                            alert("Invoice No cannot be empty")*/}
                                {/*                                        :*/}
                                {/*                                        () => {*/}
                                {/*                                        }}*/}
                                {/*                                    className="mr-1 mb-1">*/}
                                {/*                                    Change Invoice No*/}
                                {/*                                </Button>*/}
                                {/*                                {!approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                                    <UncontrolledTooltip*/}
                                {/*                                        placement={"bottom"}*/}
                                {/*                                        target={"changeInvoiceNo"}>*/}
                                {/*                                        You are not authorized to Change Invoice No*/}
                                {/*                                    </UncontrolledTooltip>*/}
                                {/*                                    : null*/}
                                {/*                                }*/}
                                {/*                            </>*/}
                                {/*                }*/}
                                {/*            </>*/}
                                {/*            :*/}
                                {/*            <>*/}
                                {/*                <CardText>*/}
                                {/*                    Please raise an Invoice in Smart Port application*/}
                                {/*                    to {user.name} ( {user.username} ) and enter the Invoice No in the field*/}
                                {/*                    provided, then click on Notify Payment*/}
                                {/*                    button to*/}
                                {/*                    inform agent.*/}
                                {/*                </CardText>*/}
                                {/*                <CardText>*/}
                                {/*                    Payment Status : INITIATING*/}
                                {/*                </CardText>*/}
                                {/*                <Label for="invoiceNo">Enter Invoice No</Label>*/}
                                {/*                <Input*/}
                                {/*                    required*/}
                                {/*                    type="text"*/}
                                {/*                    name="invoiceNumber"*/}
                                {/*                    value={invoiceNo}*/}
                                {/*                    onChange={(e) => onChange(e.target.value)}*/}
                                {/*                />*/}
                                {/*                <br/>*/}
                                {/*                <Button*/}
                                {/*                    id={"notifyPayment"}*/}
                                {/*                    color={"primary"}*/}
                                {/*                    onClick={() => approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                        invoiceNo.length !== 0 ?*/}
                                {/*                            approveStage(userProfile, "stage_survey_fee", {*/}
                                {/*                                type: "notify-payment",*/}
                                {/*                                invoiceNo: invoiceNo*/}
                                {/*                            })*/}
                                {/*                            :*/}
                                {/*                            alert("Invoice No cannot be empty")*/}
                                {/*                        :*/}
                                {/*                        () => {*/}
                                {/*                        }}*/}
                                {/*                    className="mr-1 mb-1">*/}
                                {/*                    Notify Payment*/}
                                {/*                </Button>*/}
                                {/*                {!approvers.stage_survey_fee.includes(userProfile.id) ?*/}
                                {/*                    <UncontrolledTooltip*/}
                                {/*                        placement={"bottom"}*/}
                                {/*                        target={"notifyPayment"}>*/}
                                {/*                        You are not authorized to Notify Payment*/}
                                {/*                    </UncontrolledTooltip>*/}
                                {/*                    : null}*/}
                                {/*            </>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            </>
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Survey Fee&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_survey_fee.notify_payment === true ?
                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_done === true ?
                                            allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ?
                                                <>
                                                    <CardText>
                                                        Your Payment is Successful
                                                    </CardText>
                                                    <CardText>
                                                        Payment Status : SUCCESS
                                                    </CardText>
                                                </>
                                                :
                                                <>
                                                    <CardText>
                                                        Payment Verification is in process...it may take time
                                                    </CardText>
                                                    <CardText>
                                                        Payment Status : VERIFYING...
                                                    </CardText>
                                                </>
                                            :
                                            <>
                                                <CardText>
                                                    An Invoice is raised in Smart Port application for Payment. Once
                                                    payment is done
                                                    click
                                                    on
                                                    Payment Done.
                                                </CardText>
                                                <CardText>
                                                    Invoice
                                                    No: {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number}
                                                </CardText>
                                                <CardText>
                                                    Payment Status : PENDING
                                                </CardText>

                                                <Button
                                                    color={"success"}
                                                    onClick={() => approveStage(userProfile, "stage_survey_fee", {
                                                        type: "payment-done",
                                                    })}
                                                    className="mr-1 mb-1">
                                                    Payment Done
                                                </Button>
                                            </>
                                        :
                                        <CardText>
                                            Invoice has not be raised yet so please wait...
                                        </CardText>
                                    :
                                    null
                            }

                            <>
                                {/*{*/}
                                {/*    allData !== undefined ?*/}
                                {/*        allData.vesselActivity.stages_info.stages.stage_survey_fee.notify_payment === true ?*/}
                                {/*            <>*/}
                                {/*                {*/}
                                {/*                    allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ?*/}
                                {/*                        null*/}
                                {/*                        :*/}
                                {/*                        <CardText>*/}
                                {/*                            An Invoice is raised in Smart Port application for Payment.*/}
                                {/*                        </CardText>*/}
                                {/*                }*/}
                                {/*                <CardText>*/}
                                {/*                    Invoice*/}
                                {/*                    No {allData.vesselActivity.stages_info.stages.stage_survey_fee.invoice_number}.*/}
                                {/*                </CardText>*/}
                                {/*                <Row className="px-3">*/}
                                {/*                    <CardText className="m-0">*/}
                                {/*                        Payment Status*/}
                                {/*                        : {allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ? "SUCCESS" : "PENDING..."}*/}
                                {/*                    </CardText>*/}
                                {/*                    {*/}
                                {/*                        allData.vesselActivity.stages_info.stages.stage_survey_fee.payment_success === true ?*/}
                                {/*                            null*/}
                                {/*                            :*/}
                                {/*                            <Button size="sm" className="ml-4"*/}
                                {/*                                    onClick={() => checkPaymentStatus()}>*/}
                                {/*                                REFRESH*/}
                                {/*                            </Button>*/}
                                {/*                    }*/}
                                {/*                </Row>*/}
                                {/*            </>*/}
                                {/*            :*/}
                                {/*            <CardText>*/}
                                {/*                Invoice has not be raised yet so please wait...*/}
                                {/*            </CardText>*/}
                                {/*        :*/}
                                {/*        null*/}
                                {/*}*/}
                            </>
                        </CardBody>
                    </Card>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    );
};

export default SurveyFee;
