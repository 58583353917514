import React from 'react';
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, UncontrolledTooltip, Spinner } from "reactstrap";
import CertificateOfSurveyPrint from "../prints/CertificateOfSurveyPrint";
import DeclarationOfSurveyPrint from "../prints/DeclarationOfSurveyPrint";

type TypeChecking = {
    userProfile: {},
    approvers: {},
    previousStepStatus: boolean,
    approveStage: () => void,
}

const CertificateIssue = ({ userProfile, approvers, allData, previousStepStatus, approveStage }: TypeChecking) => {

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Issue of Certificates&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_certificate_issue.approved_by.length !== 0 ?
                                            <span
                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_certificate_issue.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_certificate_issue.issue_certificate === false ?
                                        <>
                                            <CardText tag="h5" className="my-2">
                                                Forms to be Filled & Submitted&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Fee&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Date&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Status&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Note File&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_note_file.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_note_file.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Registration Fee&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>

                                            <br/>

                                            {
                                                allData !== undefined ?
                                                    <>
                                                        {
                                                            allData ?
                                                                <CertificateOfSurveyPrint
                                                                    formFieldSource={allData} />
                                                                :
                                                                <Spinner color="success" className="mr-2" />
                                                        }
                                                        {
                                                            allData ?
                                                                <DeclarationOfSurveyPrint
                                                                    formFieldSource={allData} />
                                                                :
                                                                <Spinner color="success" className="mr-2" />
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }

                                            <br/>

                                            <CardText>
                                                All the Vessel Verifications and Payments have been successfully
                                                completed. Clicking on below button will Issue the Certificates.
                                            </CardText>
                                            <Button
                                                id={"issueCertificates"}
                                                color={"primary"}
                                                style={{ "width": 260 }}
                                                onClick={() => approvers.stage_certificate_issue.includes(userProfile.id) ?
                                                    approveStage(userProfile, "stage_certificate_issue", {})
                                                    :
                                                    () => {
                                                    }}
                                                className="mr-1 mb-1">
                                                Issue Certificates
                                            </Button>
                                            {!approvers.stage_certificate_issue.includes(userProfile.id) ?
                                                <UncontrolledTooltip
                                                    placement={"bottom"}
                                                    target={"issueCertificates"}>
                                                    You are not authorized to Issue Certificates
                                                </UncontrolledTooltip>
                                                : null}
                                        </>
                                        :
                                        <>
                                            <CardText tag="h5" className="my-2">
                                                Forms to be Filled & Submitted&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_forms_to_be_filled.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Fee&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_fee.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Date&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_date.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Survey Status&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_survey_status.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Note File&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_note_file.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_note_file.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <CardText tag="h5" className="my-2">
                                                Registration Fee&nbsp;
                                                {
                                                    allData !== undefined ?
                                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by.length !== 0 ?
                                                            <span
                                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by[0].user.full_name} )</span>
                                                            :
                                                            null
                                                        :
                                                        null
                                                }
                                            </CardText>
                                            <br />
                                            <CardText>
                                                Registration process has been Successfully completed.
                                            </CardText>
                                            <CardText>
                                                Certificates are available for download on Forms to be Filled &
                                                Submitted step.
                                            </CardText>
                                        </>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Issue of Certificates&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_certificate_issue.approved_by.length !== 0 ?
                                            <span
                                                style={{ color: "#0e7a18" }}>( Approved By {allData.vesselActivity.stages_info.stages.stage_certificate_issue.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_certificate_issue.issue_certificate === false ?
                                        <CardText>
                                            Vessel Certificates will be available for download once the Port Officer
                                            issues them.
                                        </CardText>
                                        :
                                        <>
                                            <CardText>
                                                Registration process has been Successfully completed.
                                            </CardText>
                                            <CardText>
                                                Certificates are available for download on Forms to be Filled &
                                                Submitted step.
                                            </CardText>
                                        </>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    );
};

export default CertificateIssue;
