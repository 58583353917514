// Action Types for Each/Individual Vessel
export const SAVE_BELOW_VESSEL = 'SAVE_BELOW_VESSEL';
export const UPDATE_BELOW_VESSEL = 'UPDATE_BELOW_VESSEL';
export const SUBMIT_BELOW_VESSEL = 'SUBMIT_BELOW_VESSEL';
export const FETCH_BELOW_VESSEL = 'FETCH_BELOW_VESSEL';
export const CLOSE_BELOW_VESSEL = 'CLOSE_BELOW_VESSEL';

// Action Type for getting Count of Multiple Vessels
export const COUNT_BElOW_VESSEL = 'COUNT_BElOW_VESSEL';

// Action Type for getting Monthly Registrations Count of Multiple Vessels
export const REG_COUNT_BElOW_VESSEL = 'REG_COUNT_BElOW_VESSEL';

// Action Type for getting Multiple Vessels
export const FETCH_ALL_BELOW_VESSEL = 'FETCH_ALL_BELOW_VESSEL';

// Action Type for Clearing Each/Individual Vessel
export const CLEAR_BELOW_VESSEL = 'CLEAR_BELOW_VESSEL';

// Action Type for Clearing Count of Multiple Vessels
export const CLEAR_COUNT_BELOW_VESSEL = 'CLEAR_COUNT_BELOW_VESSEL';

// Action Type for Clearing Monthly Registrations Count of Multiple Vessels
export const CLEAR_REG_COUNT_BELOW_VESSEL = 'CLEAR_REG_COUNT_BELOW_VESSEL';

// Action Type for Clearing Multiple Vessels
export const CLEAR_ALL_BELOW_VESSEL = 'CLEAR_ALL_BELOW_VESSEL';

// Action Type for Approvers
export const FETCH_BELOW_VESSEL_APPROVERS = 'FETCH_BELOW_VESSEL_APPROVERS';

// Action Type for User/Owner/Agency/Agent
export const FETCH_BELOW_VESSEL_USER = 'FETCH_BELOW_VESSEL_USER';
