import { HullType } from './../forms/Vessel.form.types';
export default class VesselHull {
	builder_name: string = "";
	builder_address: string = "";
	builder_certificate: string = "";
	place_of_building: string = "";
	date_of_build: string = "";
	means_of_propulsion: string = "";
	date_of_engine_construction: string = "";
	hull_material: string = "";
	bulk_heads_placement: string = "";
	hull_plating_material: string = "";
	total_BHP: number = 0;
	year_of_built: number = 0;
	no_of_bulk_heads: number = 0;
	bulk_heads_thickness: number = 0;
	hull_plating_thickness: number = 0;
	is_deck_free: boolean = false;

	onChange: () => void;

	constructor(params: HullType, onChange: () => void) {
		params = {
			...{
				builder_name: "",
				builder_address: "",
				builder_certificate: "",
				place_of_building: "",
				date_of_build: "",
				means_of_propulsion: "",
				date_of_engine_construction: "",
				hull_material: "",
				bulk_heads_placement: "",
				hull_plating_material: "",
				total_BHP: 0,
				year_of_built: 0,
				no_of_bulk_heads: 0,
				bulk_heads_thickness: 0,
				hull_plating_thickness: 0,
				is_deck_free: false
			}, ...params
		}

		this.builder_name = params.builder_name;
		this.builder_address = params.builder_address;
		this.builder_certificate = params.builder_certificate;
		this.place_of_building = params.place_of_building;
		this.date_of_build = params.date_of_build;
		this.means_of_propulsion = params.means_of_propulsion;
		this.date_of_engine_construction = params.date_of_engine_construction;
		this.hull_material = params.hull_material;
		this.bulk_heads_placement = params.bulk_heads_placement;
		this.hull_plating_material = params.hull_plating_material;
		this.total_BHP = params.total_BHP;
		this.year_of_built = params.year_of_built;
		this.no_of_bulk_heads = params.no_of_bulk_heads;
		this.bulk_heads_thickness = params.bulk_heads_thickness;
		this.hull_plating_thickness = params.hull_plating_thickness;
		this.is_deck_free = params.is_deck_free;

		this.onChange = onChange;
	}

	setBuilder_name(builder_name) {
		this.builder_name = builder_name;
		this.onChange()
	}
	setBuilder_address(builder_address) {
		this.builder_address = builder_address;
		this.onChange()
	}
	setBuilder_certificate(builder_certificate) {
		this.builder_certificate = builder_certificate;
		this.onChange()
	}
	setPlace_of_building(place_of_building) {
		this.place_of_building = place_of_building;
		this.onChange()
	}
	setDate_of_build(date_of_build) {
		this.date_of_build = date_of_build;
		this.onChange()
	}
	setMeans_of_propulsion(means_of_propulsion) {
		this.means_of_propulsion = means_of_propulsion;
		this.onChange()
	}
	setDate_of_engine_construction(date_of_engine_construction) {
		this.date_of_engine_construction = date_of_engine_construction;
		this.onChange()
	}
	setHull_material(hull_material) {
		this.hull_material = hull_material;
		this.onChange()
	}
	setBulk_heads_placement(bulk_heads_placement) {
		this.bulk_heads_placement = bulk_heads_placement;
		this.onChange()
	}
	setHull_plating_material(hull_plating_material) {
		this.hull_plating_material = hull_plating_material;
		this.onChange()
	}
	setTotal_BHP(total_BHP) {
		this.total_BHP = total_BHP;
		this.onChange()
	}
	setYear_of_built(year_of_built) {
		this.year_of_built = year_of_built;
		this.onChange()
	}
	setNo_of_bulk_heads(no_of_bulk_heads) {
		this.no_of_bulk_heads = no_of_bulk_heads;
		this.onChange()
	}
	setBulk_heads_thickness(bulk_heads_thickness) {
		this.bulk_heads_thickness = bulk_heads_thickness;
		this.onChange()
	}
	setHull_plating_thickness(hull_plating_thickness) {
		this.hull_plating_thickness = hull_plating_thickness;
		this.onChange()
	}
	setIs_deck_free(is_deck_free) {
		this.is_deck_free = is_deck_free;
		this.onChange()
	}

}