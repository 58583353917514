import { ApplicationFormsTypeDeclarations } from '../typeDeclarations/ApplicationFormsTypeDeclarations';
import RegistrationOrSurveyModel from '../models/applicationFormModels/RegistrationOrSurveyModel';
import DeclarationOfOwnershipModel from '../models/applicationFormModels/DeclarationOfOwnershipModel';
import FileUploadsModel from "../models/applicationFormModels/FileUploadsModel";

export default class ApplicationFormFieldsSource {
    setStateChange = null;

    registration_or_survey: RegistrationOrSurveyModel = null;
    declaration_of_ownership: DeclarationOfOwnershipModel = null;
    file_uploads: FileUploadsModel = null;

    constructor(params: ApplicationFormsTypeDeclarations) {
        // console.log("In ApplicationFormFieldsSource.js File");
        // console.log("One Params " + JSON.stringify(params));
        if(params) {
            // console.log("Inside If Two Params " + JSON.stringify(params));
            this.registration_or_survey = new RegistrationOrSurveyModel(params.registration_or_survey, () => this.onChange());
            this.declaration_of_ownership = new DeclarationOfOwnershipModel(params.declaration_of_ownership, () => this.onChange());
            this.file_uploads = new FileUploadsModel(params.file_uploads, () => this.onChange())
        } else {
            // console.log("Inside Else Three Params " + params);
            this.registration_or_survey = new RegistrationOrSurveyModel(null, () => this.onChange());
            this.declaration_of_ownership = new DeclarationOfOwnershipModel(null, () => this.onChange());
            this.file_uploads = new FileUploadsModel(null, () => this.onChange());
        }
    }

    subscribeForStateChange(setStateFunction) {
        // console.log("Entered subscribeForStateChange");
        this.setStateChange = setStateFunction;
    }

    onChange() {
        // console.log("Entered onChange");
        this.setStateChange(new ApplicationFormFieldsSource(this));
    }
}
