import {
    COUNT_EXPIRED,
    EMPTY_COUNT
} from "../actions/ActionTypesAllModules";

const expiredVesselsCountReducer = (state = 0, actions) => {
    switch (actions.type) {
        case COUNT_EXPIRED:
            if (actions.payload !== undefined) {
                return actions.payload;
            } else {
                return null;
            }


        case EMPTY_COUNT:
            return actions.payload;

        default:
            return state;
    }
};

export default expiredVesselsCountReducer;
