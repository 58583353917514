import {DeclarationOfOwnership} from '../../typeDeclarations/ApplicationFormsTypeDeclarations';

export default class DeclarationOfOwnershipModel {
    owners_name: String = "";
    previous_name_of_the_craft: String = "";
    previous_owner_of_the_craft: String = "";
    owners_state_of: String = "";
    owners_permanent_address: String = "";
    owners_business_address: String = "";
    craft_or_vessel_name: String = "";
    craft_or_vessel_built_at: String = "";
    craft_or_vessel_built_year: String = "";
    craft_or_vessel_purchased_date: Date = undefined;
    craft_or_vessel_cost: Number = undefined;
    registered_at_port: String = "";
    ply_in_the_port: String = "";
    made_and_subscribed: String = "";
    day_of: String = "";
    day_of_20: Date = undefined;
    above_named: String = "";
    in_the_presence_of: String = "";

    onChange: () => void;

    constructor(params: DeclarationOfOwnership, onChange: () => void) {
        params = {
            ...{
                owners_name: "",
                previous_name_of_the_craft: "",
                previous_owner_of_the_craft: "",
                owners_state_of: "",
                owners_permanent_address: "",
                owners_business_address: "",
                craft_or_vessel_name: "",
                craft_or_vessel_built_at: "",
                craft_or_vessel_built_year: "",
                craft_or_vessel_purchased_date: undefined,
                craft_or_vessel_cost: undefined,
                registered_at_port: "",
                ply_in_the_port: "",
                made_and_subscribed: "",
                day_of: "",
                day_of_20: undefined,
                above_named: "",
                in_the_presence_of: "",
            }, ...params
        };

        this.owners_name = params.owners_name;
        this.previous_name_of_the_craft = params.previous_name_of_the_craft;
        this.previous_owner_of_the_craft = params.previous_owner_of_the_craft;
        this.owners_state_of = params.owners_state_of;
        this.owners_permanent_address = params.owners_permanent_address;
        this.owners_business_address = params.owners_business_address;
        this.craft_or_vessel_name = params.craft_or_vessel_name;
        this.craft_or_vessel_built_at = params.craft_or_vessel_built_at;
        this.craft_or_vessel_built_year = params.craft_or_vessel_built_year;
        this.craft_or_vessel_purchased_date = params.craft_or_vessel_purchased_date;
        this.craft_or_vessel_cost = params.craft_or_vessel_cost;
        this.registered_at_port = params.registered_at_port;
        this.ply_in_the_port = params.ply_in_the_port;
        this.made_and_subscribed = params.made_and_subscribed;
        this.day_of = params.day_of;
        this.day_of_20 = params.day_of_20;
        this.above_named = params.above_named;
        this.in_the_presence_of = params.in_the_presence_of;

        this.onChange = onChange;
    }

    setOwners_name(owners_name) {
        this.owners_name = owners_name;
        this.onChange()
    }

    setPrevious_name_of_the_craft(previous_name_of_the_craft) {
        this.previous_name_of_the_craft = previous_name_of_the_craft;
        this.onChange()
    }

    setPrevious_owner_of_the_craft(previous_owner_of_the_craft) {
        this.previous_owner_of_the_craft = previous_owner_of_the_craft;
        this.onChange()
    }

    setOwners_state_of(owners_state_of) {
        this.owners_state_of = owners_state_of;
        this.onChange()
    }

    setOwners_permanent_address(owners_permanent_address) {
        this.owners_permanent_address = owners_permanent_address;
        this.onChange()
    }

    setOwners_business_address(owners_business_address) {
        this.owners_business_address = owners_business_address;
        this.onChange()
    }

    setCraft_or_vessel_name(craft_or_vessel_name) {
        this.craft_or_vessel_name = craft_or_vessel_name;
        this.onChange()
    }

    setCraft_or_vessel_built_at(craft_or_vessel_built_at) {
        this.craft_or_vessel_built_at = craft_or_vessel_built_at;
        this.onChange()
    }

    setCraft_or_vessel_built_year(craft_or_vessel_built_year) {
        this.craft_or_vessel_built_year = craft_or_vessel_built_year;
        this.onChange()
    }

    setCraft_or_vessel_purchased_date(craft_or_vessel_purchased_date) {
        this.craft_or_vessel_purchased_date = craft_or_vessel_purchased_date;
        this.onChange()
    }

    setCraft_or_vessel_cost(craft_or_vessel_cost) {
        this.craft_or_vessel_cost = craft_or_vessel_cost;
        this.onChange()
    }

    setRegistered_at_port(registered_at_port) {
        this.registered_at_port = registered_at_port;
        this.onChange()
    }

    setPly_in_the_port(ply_in_the_port) {
        this.ply_in_the_port = ply_in_the_port;
        this.onChange()
    }

    setMade_and_subscribed(made_and_subscribed) {
        this.made_and_subscribed = made_and_subscribed;
        this.onChange()
    }

    setDay_of(day_of) {
        this.day_of = day_of;
        this.onChange()
    }

    setDay_of_20(day_of_20) {
        this.day_of_20 = day_of_20;
        this.onChange()
    }

    setAbove_named(above_named) {
        this.above_named = above_named;
        this.onChange()
    }

    setIn_the_presence_of(in_the_presence_of) {
        this.in_the_presence_of = in_the_presence_of;
        this.onChange()
    }
}
