import {
	NavigationLightsType,
	NavigationEquipmentType
} from "../Vessel.form.types";

type NavigationEquipmentItemType = {
	number?: number;
	location?: string;
	condition?: string;
};
class NavigationEquipmentItem {
	number: number = 0;
	location: string = "";
	condition: string = "";

	constructor(params: NavigationEquipmentItemType, onChange) {
		params = {
			...{
				number: 0,
				location: "",
				condition: ""
			},
			...params
		};
		this.number = params.number;
		this.location = params.location;
		this.condition = params.condition;
		this.onChange = onChange;
	}

	setField(key: "number" | "location" | "condition", value) {
		this[key] = value;
		this.onChange();
	}
}
class NavigationSoundEquipment extends NavigationEquipmentItem {
	sound_signals_type: "Mechanical" | "Electrical" = "Mechanical";
	constructor(
		params: {
			sound_signals_type?: "Mechanical" | "Electrical";
		} & NavigationEquipmentItemType,
		onChange
	) {
		super(params, onChange);
		params = {
			sound_signals_type: "Mechanical",
			...params
		};
		this.sound_signals_type = params.sound_signals_type;
	}

	setField(
		key: "number" | "location" | "condition" | "sound_signals_type",
		value
	) {
		this[key] = value;
		this.onChange();
	}
}

class NavigationLights {
	number: number = 0;
	condition: string = 0;
	location: string = 0;
	particulars: string = 0;
	certificate: string = 0;
	main_mast: string = 0;
	auxiliary_mast: string = 0;
	port: string = 0;
	starboard: string = 0;
	stem: string = 0;
	anchor: string = 0;

	onChange = null;

	constructor(params: NavigationLightsType, onChange) {
		params = {
			...{
				number: 0,
				condition: 0,
				location: 0,
				particulars: 0,
				certificate: 0,
				main_mast: 0,
				auxiliary_mast: 0,
				port: 0,
				starboard: 0,
				stem: 0,
				anchor: 0
			},
			...params
		};
		this.number = params.number;
		this.condition = params.condition;
		this.location = params.location;
		this.particulars = params.particulars;
		this.certificate = params.certificate;
		this.main_mast = params.main_mast;
		this.auxiliary_mast = params.auxiliary_mast;
		this.port = params.port;
		this.starboard = params.starboard;
		this.stem = params.stem;
		this.anchor = params.anchor;

		this.onChange = onChange;
	}

	setField(
		key:
			| "number"
			| "condition"
			| "location"
			| "particulars"
			| "certificate"
			| "main_mast"
			| "auxiliary_mast"
			| "port"
			| "starboard"
			| "stem"
			| "anchor",
		value
	) {
		this[key] = value;
		this.onChange();
	}
}

export default class NavigationEquipment {
	navigation_lights: NavigationLights;
	sound_signals: NavigationSoundEquipment;
	shapes: NavigationEquipmentItem;
	radar: NavigationEquipmentItem;
	compass: NavigationEquipmentItem;
	gps_receiver: NavigationEquipmentItem;
	echo_sounder: NavigationEquipmentItem;
	communication_equipment: NavigationEquipmentItem;
	nautical_publications: NavigationEquipmentItem;
	log_book: NavigationEquipmentItem;

	onChange = null;

	constructor(params: NavigationEquipmentType, onChange) {
		params = {
			...{
				navigation_lights: null,
				sound_signals: null,
				shapes: null,
				radar: null,
				compass: null,
				gps_receiver: null,
				echo_sounder: null,
				communication_equipment: null,
				nautical_publications: null,
				log_book: null
			},
			...params
		};

		this.onChange = onChange;

		this.navigation_lights = new NavigationLights(
			params.navigation_lights,
			() => this.onChange()
		);
		this.sound_signals = new NavigationSoundEquipment(
			params.sound_signals,
			() => this.onChange()
		);
		this.shapes = new NavigationEquipmentItem(params.shapes, () =>
			this.onChange()
		);
		this.radar = new NavigationEquipmentItem(params.radar, () =>
			this.onChange()
		);
		this.compass = new NavigationEquipmentItem(params.compass, () =>
			this.onChange()
		);
		this.gps_receiver = new NavigationEquipmentItem(
			params.gps_receiver,
			() => this.onChange()
		);
		this.echo_sounder = new NavigationEquipmentItem(
			params.echo_sounder,
			() => this.onChange()
		);
		this.communication_equipment = new NavigationEquipmentItem(
			params.communication_equipment,
			() => this.onChange()
		);
		this.nautical_publications = new NavigationEquipmentItem(
			params.nautical_publications,
			() => this.onChange()
		);
		this.log_book = new NavigationEquipmentItem(params.log_book, () =>
			this.onChange()
		);
	}

	static NavigationLights = NavigationLights;
	static NavigationEquipmentItem = NavigationEquipmentItem;
	static NavigationSoundEquipment = NavigationSoundEquipment;
}

