import React, { useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import Vessel from "../Vessel";
import { Anchor, AnchorChains, Rope } from "../models/Vessel.equipment";
import SearchEquipmentForm from "./equipments/SearchLight.equipment.form";
import LifeBoyEquipmentForm from "./equipments/LifeBoy.equipment.form";
import NavigationEquipmentForm from "./equipments/Navigation.equipment.form";
type VesselEquipmentFormType = {
    vessel: Vessel;
    onSave: (updatedVessel: Vessel) => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};
const VesselEquipmentForm: React.FC<VesselEquipmentFormType> = ({
    vessel: vesselState,
    mode
}: VesselEquipmentFormType) => {
    const isViewMode = mode === "View";
    const {
        equipment_details: equipment,
        search_lights,
        life_saving_equipment,
        navigation_equipment
    } = vesselState;
    const { anchor, anchor_chains, ropes } = equipment;

    return (
        <div className="row">
            <div className="col-12 mt-3 mb-3">
                <h3>Anchor</h3>
                <AnchorForm anchor={anchor} isViewMode={isViewMode} />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Anchor of Chains</h3>
                <AnchorChainForm
                    anchor_chains={anchor_chains}
                    isViewMode={isViewMode}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Ropes</h3>
                <RopeForm ropes={ropes} isViewMode={isViewMode} />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Search Lights</h3>
                <SearchEquipmentForm
                    search_light={search_lights}
                    isViewMode={isViewMode}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Life buoys, Buoyant Apparatus</h3>
                <LifeBoyEquipmentForm
                    life_saving_equipment={life_saving_equipment}
                    isViewMode={isViewMode}
                />
            </div>
            <div className="col-12 mt-3 mb-4">
                <h3>Navigation Equipments</h3>
                <NavigationEquipmentForm
                    navigation_equipment={navigation_equipment}
                    isViewMode={isViewMode}
                />
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Bulge_pumps</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.bulge_pumps}
                        onChange={e => equipment.setBulge_pumps(e.target.value)}
                        placeholder="Bulge_pumps"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Winches</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.winches}
                        onChange={e => equipment.setWinches(e.target.value)}
                        placeholder="Winches"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Cranes</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.cranes}
                        onChange={e => equipment.setCranes(e.target.value)}
                        placeholder="Cranes"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Derricks</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.derricks}
                        onChange={e => equipment.setDerricks(e.target.value)}
                        placeholder="Derricks"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Towing_equipment</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.towing_equipment}
                        onChange={e =>
                            equipment.setTowing_equipment(e.target.value)
                        }
                        placeholder="Towing_equipment"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Collision_mats</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.collision_mats}
                        onChange={e =>
                            equipment.setCollision_mats(e.target.value)
                        }
                        placeholder="Collision_mats"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Gangway</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.gangway}
                        onChange={e => equipment.setGangway(e.target.value)}
                        placeholder="Gangway"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Fenders</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.fenders}
                        onChange={e => equipment.setFenders(e.target.value)}
                        placeholder="Fenders"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Boat_hook</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.boat_hook}
                        onChange={e => equipment.setBoat_hook(e.target.value)}
                        placeholder="Boat_hook"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>First_aid_kit</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.first_aid_kit}
                        onChange={e =>
                            equipment.setFirst_aid_kit(e.target.value)
                        }
                        placeholder="First_aid_kit"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Binoculars</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.binoculars}
                        onChange={e => equipment.setBinoculars(e.target.value)}
                        placeholder="Binoculars"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Waste_containers</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.waste_containers}
                        onChange={e =>
                            equipment.setWaste_containers(e.target.value)
                        }
                        placeholder="Waste_containers"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Heaving_line</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.heaving_line}
                        onChange={e =>
                            equipment.setHeaving_line(e.target.value)
                        }
                        placeholder="Heaving_line"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Torch</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.torch}
                        onChange={e => equipment.setTorch(e.target.value)}
                        placeholder="Torch"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Axe</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={equipment.axe}
                        onChange={e => equipment.setAxe(e.target.value)}
                        placeholder="Axe"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default VesselEquipmentForm;

const RopeForm = ({
    ropes,
    isViewMode
}: {
    ropes: Rope;
    isViewMode: boolean;
}) => {
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Size</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.size}
                        onChange={e => ropes.setSize(e.target.value)}
                        placeholder="Size"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Material</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.material}
                        onChange={e => ropes.setMaterial(e.target.value)}
                        placeholder="Material"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.number}
                        onChange={e => ropes.setNumber(e.target.value)}
                        placeholder="Number"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.type}
                        onChange={e => ropes.setType(e.target.value)}
                        placeholder="Type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.condition}
                        onChange={e => ropes.setCondition(e.target.value)}
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={ropes.location}
                        onChange={e => ropes.setLocation(e.target.value)}
                        placeholder="Location"
                    />
                </FormGroup>
            </div>
        </div>
    );
};

const AnchorChainForm = ({
    anchor_chains,
    isViewMode
}: {
    anchor_chains: AnchorChains;
    isViewMode: boolean;
}) => {
    const { port_chain, starboard_chain } = anchor_chains;
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={anchor_chains.number}
                        onChange={e => anchor_chains.setNumber(e.target.value)}
                        placeholder="Number"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor_chains.type}
                        onChange={e => anchor_chains.setType(e.target.value)}
                        placeholder="Type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor_chains.location}
                        onChange={e =>
                            anchor_chains.setLocation(e.target.value)
                        }
                        placeholder="Location"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor_chains.condition}
                        onChange={e =>
                            anchor_chains.setCondition(e.target.value)
                        }
                        placeholder="Condition"
                    />
                </FormGroup>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Anchor Port Chain</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Size</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={port_chain.size}
                                onChange={e =>
                                    port_chain.setSize(e.target.value)
                                }
                                placeholder="Size"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
                                disabled={isViewMode}
                                type="text"
                                value={port_chain.type}
                                onChange={e =>
                                    port_chain.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Length</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={port_chain.length}
                                onChange={e =>
                                    port_chain.setLength(e.target.value)
                                }
                                placeholder="Length"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Test Certificate</Label>
                            <Input
                                disabled={isViewMode}
                                type="text"
                                value={port_chain.test_certificate}
                                onChange={e =>
                                    port_chain.setTest_certificate(
                                        e.target.value
                                    )
                                }
                                placeholder="Test Certificate"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Anchor Starboard Chain</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Size</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={starboard_chain.size}
                                onChange={e =>
                                    starboard_chain.setSize(e.target.value)
                                }
                                placeholder="Size"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Type</Label>
                            <Input
                                disabled={isViewMode}
                                type="text"
                                value={starboard_chain.type}
                                onChange={e =>
                                    starboard_chain.setType(e.target.value)
                                }
                                placeholder="Type"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Length</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={starboard_chain.length}
                                onChange={e =>
                                    starboard_chain.setLength(e.target.value)
                                }
                                placeholder="Length"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Test Certificate</Label>
                            <Input
                                disabled={isViewMode}
                                type="text"
                                value={starboard_chain.test_certificate}
                                onChange={e =>
                                    starboard_chain.setTest_certificate(
                                        e.target.value
                                    )
                                }
                                placeholder="Test Certificate"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};

const AnchorForm = ({
    anchor,
    isViewMode
}: {
    anchor: Anchor;
    isViewMode: boolean;
}) => {
    const { anchor_port, starboard, anchor_spare } = anchor;
    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Number</Label>
                    <Input
                        disabled={isViewMode}
                        type="number"
                        value={anchor.number}
                        onChange={e => anchor.setNumber(e.target.value)}
                        placeholder="number"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Type</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor.type}
                        onChange={e => anchor.setType(e.target.value)}
                        placeholder="type"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Condition</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor.condition}
                        onChange={e => anchor.setCondition(e.target.value)}
                        placeholder="condition"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Location</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={anchor.location}
                        onChange={e => anchor.setLocation(e.target.value)}
                        placeholder="location"
                    />
                </FormGroup>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Anchor_port</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Weight</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={anchor_port.weight}
                                onChange={e =>
                                    anchor_port.setWeight(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Material</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={anchor_port.material}
                                onChange={e =>
                                    anchor_port.setMaterial(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Starboard</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Weight</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={starboard.weight}
                                onChange={e =>
                                    starboard.setWeight(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Material</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={starboard.material}
                                onChange={e =>
                                    starboard.setMaterial(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-1 mb-1">
                <h5 className="font-weight-bold">Anchor_spare</h5>
                <div className="row">
                    <div className="col-3">
                        <FormGroup>
                            <Label>Weight</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={anchor_spare.weight}
                                onChange={e =>
                                    anchor_spare.setWeight(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                    <div className="col-3">
                        <FormGroup>
                            <Label>Material</Label>
                            <Input
                                disabled={isViewMode}
                                type="number"
                                value={anchor_spare.material}
                                onChange={e =>
                                    anchor_spare.setMaterial(e.target.value)
                                }
                                placeholder="number"
                            />
                        </FormGroup>
                    </div>
                </div>
            </div>
        </div>
    );
};
