import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";

import {Badge, Collapse} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";

import {Box} from "react-feather";
// import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
// import {faCircle} from "@fortawesome/free-solid-svg-icons";

import routes from "../routes/index";
import {belowRoutesSidebar} from "../routes/index";
// import avatar from "../assets/img/avatars/avatar.jpg";

const SidebarCategory = withRouter(
    ({
         name,
         badgeColor,
         badgeText,
         icon: Icon,
         isOpen,
         children,
         onClick,
         location,
         to
     }) => {
        const getSidebarItemClass = path => {
            return location.pathname.indexOf(path) !== -1 ||
            (location.pathname === "/" && path === "/dashboard")
                ? "active"
                : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
        <span
            data-toggle="collapse"
            className={"sidebar-link " + (!isOpen ? "collapsed" : "")}
            onClick={onClick}
            aria-expanded={isOpen ? "true" : "false"}
        >
          <Icon size={18} className="align-middle mr-3"/>
          <span className="align-middle">{name}</span>
            {badgeColor && badgeText ? (
                <Badge color={badgeColor} size={18} className="sidebar-badge">
                    {badgeText}
                </Badge>
            ) : null}
        </span>
                <Collapse isOpen={isOpen}>
                    <ul id="item" className={"sidebar-dropdown list-unstyled"}>
                        {children}
                    </ul>
                </Collapse>
            </li>
        );
    }
);

const SidebarItem = withRouter(
    ({name, badgeColor, badgeText, icon: Icon, location, to}) => {
        const getSidebarItemClass = path => {
            return location.pathname === path ? "active" : "";
        };

        return (
            <li className={"sidebar-item " + getSidebarItemClass(to)}>
                <NavLink to={to} className="sidebar-link" activeClassName="active">
                    {Icon ? <Icon size={18} className="align-middle mr-3"/> : null}
                    {name}
                    {badgeColor && badgeText ? (
                        <Badge color={badgeColor} size={18} className="sidebar-badge">
                            {badgeText}
                        </Badge>
                    ) : null}
                </NavLink>
            </li>
        );
    }
);

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userType: ""
        };
    }

    toggle = index => {
        // Collapse all elements
        Object.keys(this.state).forEach(
            item =>
                this.state[index] ||
                this.setState(() => ({
                    [item]: false
                }))
        );

        // Toggle selected element
        this.setState(state => ({
            [index]: !state[index]
        }));
    };

    componentWillMount() {
        /* Open collapse element that matches current url */
        const pathName = this.props.location.pathname;

        routes.forEach((route, index) => {
            const isActive = pathName.indexOf(route.path) === 0;
            const isOpen = route.open;
            const isHome = route.containsHome && pathName === "/" ? true : false;

            this.setState(() => ({
                [index]: isActive || isOpen || isHome
            }));
        });

        if (JSON.parse(localStorage.getItem('userProfile')).userType === "agency-admin") {
            this.setState({userType: 1})
        } else if (JSON.parse(localStorage.getItem('userProfile')).userType === "port-operator") {
            this.setState({userType: 2})
        } else {
            this.setState({userType: 3})
        }

        // this.setState({userType: JSON.parse(localStorage.getItem('userProfile')).userType === "port-operator" ? "2" : "1"})
    }

    render() {
        const {sidebar, layout} = this.props;
        const {userType} = this.state;

        return (
            <nav
                className={
                    "sidebar" +
                    (!sidebar.isOpen ? " toggled" : "") +
                    (sidebar.isSticky ? " sidebar-sticky" : "")
                }
            >
                <div className="sidebar-content">
                    <PerfectScrollbar>

                        {localStorage.getItem("userChoice") &&
                        localStorage.getItem("userChoice").length > 0 &&
                        localStorage.getItem("userChoice") === "above" ? (
                            <a className="sidebar-brand" href="/vessels/list">
                                <Box className="align-middle text-primary" size={24}/>{" "}
                                <span className="align-middle">IV Above 12</span>
                            </a>
                        ) : (
                            <a className="sidebar-brand" href="/below/dashboard">
                                <Box className="align-middle text-primary" size={24}/>{" "}
                                <span className="align-middle">IV Below 12</span>
                            </a>
                        )}

                        <ul className="sidebar-nav">
                            {localStorage.getItem("userChoice") &&
                            localStorage.getItem("userChoice").length > 0 &&
                            localStorage.getItem("userChoice") === "above" ? (
                                routes.map((category, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {category.children ? (
                                                <SidebarCategory
                                                    name={category.name}
                                                    icon={category.icon}
                                                    to={category.path}
                                                    isOpen={this.state[index]}
                                                    onClick={() => this.toggle(index)}
                                                >
                                                    {category.children.map((route, index) => (
                                                        <SidebarItem
                                                            key={index}
                                                            name={route.name}
                                                            to={route.path}
                                                        />
                                                    ))}
                                                </SidebarCategory>
                                            ) : (
                                                <SidebarItem
                                                    name={category.name}
                                                    to={category.path}
                                                    icon={category.icon}
                                                />
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            ) : (
                                belowRoutesSidebar.map((category, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            {category.children ? (
                                                category.accessType.includes(userType) ?
                                                <SidebarCategory
                                                    name={category.name}
                                                    icon={category.icon}
                                                    to={category.path}
                                                    isOpen={this.state[index]}
                                                    onClick={() => this.toggle(index)}
                                                >
                                                    {category.children.map((route, index) => (
                                                        <SidebarItem
                                                            key={index}
                                                            name={route.name}
                                                            to={route.path}
                                                        />
                                                    ))}
                                                </SidebarCategory> : null
                                            ) : (
                                                category.accessType.includes(userType) ?
                                                <SidebarItem
                                                    name={category.name}
                                                    to={category.path}
                                                    icon={category.icon}
                                                /> : null
                                            )}
                                        </React.Fragment>
                                    );
                                })
                            )}
                        </ul>

                        {/* {!layout.isBoxed && !sidebar.isSticky ? (
              <div className="sidebar-bottom d-none d-lg-block">
                <div className="media">
                  <img
                    className="rounded-circle mr-3"
                    src={avatar}
                    alt="Chris Wood"
                    width="40"
                    height="40"
                  />
                  <div className="media-body">
                    <h5 className="mb-1">Chris Wood</h5>
                    <div>
                      <FontAwesomeIcon
                        icon={faCircle}
                        className="text-success"
                      />{" "}
                      Online
                    </div>
                  </div>
                </div>
              </div>
            ) : null} */}
                    </PerfectScrollbar>
                </div>
            </nav>
        );
    }
}

export default withRouter(
    connect(store => ({
        sidebar: store.sidebar,
        layout: store.layout
    }))(Sidebar)
);
