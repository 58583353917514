// MISC
// const smartPortServerURL = "http://13.127.39.148:4000";
// const inlandVesselsServerURL = "http://13.127.39.148:3000";

// Used in production machine
const smartPortServerURL = "http://35.154.14.242:4000";
const inlandVesselsServerURL = "http://35.154.14.242:8888";

// Used in local machine
// const smartPortServerURL = "http://35.154.14.242:4000";
// const inlandVesselsServerURL = "http://localhost:3003";
const paths = {
    api: {
        // Vessels
        CreateVessel: () => `${inlandVesselsServerURL}/api/inlandVessels`,
        CountVessels: () => `${inlandVesselsServerURL}/api/inlandVessels/count`,
        FetchVessels: () => `${inlandVesselsServerURL}/api/inlandVessels`,
        FetchVessel: (vesselId) =>
            `${inlandVesselsServerURL}/api/inlandVessels/${vesselId}`,
        UpdateVessel: (VesselId) =>
            `${inlandVesselsServerURL}/api/inlandVessels/${VesselId}`,
        CreateVesselFormAcitvities: () =>
            `${inlandVesselsServerURL}/api/vesselFormActivities`,
        FetchVesselFormActivities: () =>
            `${inlandVesselsServerURL}/api/vesselFormActivities`,
        UpdateVesselFormActivites: (activityId) =>
            `${inlandVesselsServerURL}/api/vesselFormActivities/${activityId}`,
        SearchVessel: (category, searchTerm) =>
            `${inlandVesselsServerURL}/api/inlandVessels/?category=${category}&searchTerm=${searchTerm}`,
        // User
        CreateUser: () => `${smartPortServerURL}/api/users`,
        CountUsers: () => `${smartPortServerURL}/api/users/count`,
        FetchUsers: () => `${smartPortServerURL}/api/users`,
        UpdateUser: (UserId) => `${smartPortServerURL}/api/users/${UserId}`,
        SearchUser: () => `${smartPortServerURL}/api/users`,
        AuthenticateUser: () => `${smartPortServerURL}/api/authenticate`,
        GetUser: () => `${smartPortServerURL}/api/user/me`,
        // Storage
        UploadFiles: () =>
            `${inlandVesselsServerURL}/api/storages/inlandprod/upload`,
        DownloadFile: (file) =>
            `${inlandVesselsServerURL}/api/storages/inlandprod/download/${file}`,
    },
    below12Api: {
        // Operations of belowInlandVessels model
        SaveBelowVessel: () => `${inlandVesselsServerURL}/api/belowInlandVessels`,
        UpdateBelowVessel: (vesselId) => `${inlandVesselsServerURL}/api/belowInlandVessels/${vesselId}`,
        SubmitBelowVessel: (vesselId) => `${inlandVesselsServerURL}/api/belowInlandVessels/${vesselId}`,
        FetchBelowVessel: (vesselId) => `${inlandVesselsServerURL}/api/belowInlandVessels/${vesselId}`,
        CountBelowVessels: () => `${inlandVesselsServerURL}/api/belowInlandVessels/count`,
        FetchAllBelowVessels: () => `${inlandVesselsServerURL}/api/belowInlandVessels`,
        // CloseBelowVessel: (vesselId) => `${inlandVesselsServerURL}/api/belowInlandVessels/${vesselId}`,

        // Operations of belowInlandVesselsActivity model
        CreateBelowVesselActivity: () => `${inlandVesselsServerURL}/api/belowInlandVesselsActivities`,
        FetchBelowVesselActivity: () => `${inlandVesselsServerURL}/api/belowInlandVesselsActivities`,
        UpdateBelowVesselActivity: (activityId) => `${inlandVesselsServerURL}/api/belowInlandVesselsActivities/${activityId}`,
        // FetchPaymentStatusOfVessel: (activityId) => `${inlandVesselsServerURL}/api/belowInlandVesselsActivities/${activityId}`,

        // Operations of file upload to amazon web services s3
        UploadFile: () =>
            `${inlandVesselsServerURL}/api/storages/inlandprod/upload`,
        DownloadFile: (file) =>
            `${inlandVesselsServerURL}/api/storages/inlandprod/download/${file}`,
        DeleteFile: (file) =>
            `${inlandVesselsServerURL}/api/storages/inlandprod/files/${file}`,
    }
};

module.exports = paths;
