import history from './history';
import {vessel_type} from '../pages/vessels/forms/Vessel.form.types';

export type formNames =
    "form1"
    | "form1A"
    | "form2Initial"
    | "form2"
    | "form3"
    | "form4"
    | "form5"
    | "form6"
    | "form7"
    | "form8"
    | "form9"
    | "form10"
    | "form11"
    | "form11A"
    | "form17"
    | "form16"
    | "form15"
    | "form14"
    | "form13"
    | "form12";

const routesHelper = {
    landingPage: {
        path: "/",
        url: () => `/`,
        go: () => push(`/`),
        replace: () => replace(`/`)
    },
    below: {
        dashboard: {
            path: "/below/dashboard",
            url: () => `/below/dashboard`,
            go: () => push(`/below/dashboard`),
            replace: () => replace(`/below/dashboard`)
        },
        registrationSurvey: {
            list: {
                path: "/below/list",
                url: () => `/below/list`,
                go: () => push(`/below/list`),
                replace: () => replace(`/below/list`)
            },
            create: {
                path: "/below/createRegistrationSurvey",
                url: () => `/below/createRegistrationSurvey`,
                go: () => push(`/below/createRegistrationSurvey`),
                replace: () => replace(`/below/createRegistrationSurvey`)
            },
            view: {
                path: "/below/:vesselId/view",
                url: (vesselId) => `/below/${vesselId}/view`,
                go: (vesselId) => push(`/below/${vesselId}/view`),
                replace: (vesselId) => replace(`/below/${vesselId}/view`)
            },
			edit: {
				path: "/below/:vesselId/edit",
				url: (vesselId) => `/below/${vesselId}/edit`,
				go: (vesselId) => push(`/below/${vesselId}/edit`),
                goToStep: (vesselId, stepNo) => push(`/below/${vesselId}/edit#step-${stepNo}`),
				replace: (vesselId) => replace(`/below/${vesselId}/edit`)
			},
        }
    },
    vesselForm: {
        list: {
            path: "/vessels/list",
            url: () => `/vessels/list`,
            go: () => push(`/vessels/list`),
            replace: () => replace(`/vessels/list`)
        },
        create: {
            path: "/vessels/create",
            url: () => `/vessels/create`,
            go: () => push(`/vessels/create`),
            replace: () => replace(`/vessels/create`)
        },
        view: {
            path: "/vessels/:vesselId/view",
            url: (vesselId) => `/vessels/${vesselId}/view`,
            go: (vesselId) => push(`/vessels/${vesselId}/view`),
            replace: (vesselId) => replace(`/vessels/${vesselId}/view`)
        },
        edit: {
            path: "/vessels/:vesselId/edit",
            url: (vesselId) => `/vessels/${vesselId}/edit`,
            go: (vesselId) => push(`/vessels/${vesselId}/edit`),
            replace: (vesselId) => replace(`/vessels/${vesselId}/edit`)
        },
        editViaForms: {
            path: "/vessels/:vesselId/edit_for/:formName",
            url: (vesselId, formName: formNames) => `/vessels/${vesselId}/edit_for/${formName}`,
            go: (vesselId, formName: formNames, payload) => push(`/vessels/${vesselId}/edit_for/${formName}`, payload),
            replace: (vesselId, formName: formNames, payload) => replace(`/vessels/${vesselId}/edit_for/${formName}`, payload)
        }
    },
    vesselList: {
        path: "/vessels/activityView",
        url: () => `/vessels/activityView`,
        go: (vesselObject: vessel_type) => push(`/vessels/activityView`, {
            Owner: vesselObject.Owner,
            name: vesselObject.name,
            agency: vesselObject.agency,
            length: vesselObject.length,
            breadth: vesselObject.breadth,
            depth: vesselObject.depth,
            vessel_type: vesselObject.vessel_type
        }),
        replace: (vesselObject: vessel_type) => replace(`/vessels/activityView`, {
            Owner: vesselObject.Owner,
            name: vesselObject.name,
            agency: vesselObject.agency,
            length: vesselObject.length,
            breadth: vesselObject.breadth,
            depth: vesselObject.depth,
            vessel_type: vesselObject.vessel_type
        })
    },
    vesselFormPrint: {
        path: "/dashboard/printables/vesselForm/:vesselId",
        url: (vesselId) => `/dashboard/printables/vesselForm/${vesselId}`,
        go: (vesselId) => push(`/dashboard/printables/vesselForm/${vesselId}`),
        replace: (vesselId) => replace(`/dashboard/printables/vesselForm/${vesselId}`)
    },
    provisionalPrint: {
        path: "/dashboard/printables/provisional/:vesselId",
        url: (vesselId) => `/dashboard/printables/provisional/${vesselId}`,
        go: (vesselId) => push(`/dashboard/printables/provisional/${vesselId}`),
        replace: (vesselId) => replace(`/dashboard/printables/provisional/${vesselId}`)
    }
};

function push(path: string, state = null) {
    history.push(path, state);
}

function replace(path: string, state = null) {
    history.replace(path, state);
}

export default routesHelper;
