import {
    COUNT_VESSELS,
    EMPTY_COUNT
} from "../actions/ActionTypesAllModules";

const CountsReducers = (state = [], actions) => {
    switch (actions.type) {
        case COUNT_VESSELS:
            if (actions.payload !== undefined) {
                return actions.payload.count;
            } else {
                return null;
            }


        case EMPTY_COUNT:
            return actions.payload;

        default:
            return state;
    }
};

export default CountsReducers;
