import React from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import ReactQuill from "react-quill";

class RejectReasonModal extends React.Component {
    constructor(props) {
        super(props);
        this.reactQuillRef = React.createRef();

        this.state = {
            openModal: false,
            quillText: "",
        };
    }

    toggle = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    };

    handleChange (html) {
        this.setState({ quillText: html });
    }

    reject = () => {
        const editor = this.reactQuillRef.current.getEditor();
        const unprivilegedEditor = this.reactQuillRef.current.makeUnprivilegedEditor(editor);
        let reasonText = unprivilegedEditor.getText(this.state.quillText);
        this.props.rejectStage(this.props.stage, reasonText);
        this.toggle();
        this.setState({quillText: ""});
    };

    render() {
        return (
            <React.Fragment key={"rejectReasonButton"}>
                <Button
                    id={"reject"}
                    color={"danger"}
                    type={"button"}
                    onClick={() => this.props.approvers.includes(this.props.id) ? this.toggle() : () => {}}
                    className="mt-1 mb-1 w-100"
                >
                    {this.props.name}
                </Button>
                <Modal
                    isOpen={this.state.openModal}
                    toggle={() => this.toggle()}
                    size={"xl"}
                    backdrop="static"
                    keyboard={false}
                    centered
                >
                    <ModalHeader toggle={() => this.toggle()}>
                        {this.props.header}
                    </ModalHeader>
                    <ModalBody className="text-center m-3 overflow-auto" style={{height: 500}}>
                        <Card>
                            <CardHeader>
                                <h6 className="card-subtitle text-left text-muted">
                                    {this.props.subTitle}
                                </h6>
                            </CardHeader>
                            <hr className="m-0"/>
                            <CardBody>
                                <ReactQuill
                                    ref={this.reactQuillRef}
                                    theme="bubble"
                                    placeholder="Start typing here..."
                                    value={this.state.quillText}
                                    onChange={(html) => this.handleChange(html)}
                                />
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="danger" onClick={() => this.reject()}>
                            Reject
                        </Button>
                        <Button color="danger" onClick={() => this.toggle()}>
                            Cancel
                        </Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

export default RejectReasonModal;
