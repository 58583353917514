// Action types for Operation Status

// Success Status
export const SUCCESS = 'SUCCESS';

// Error Status
export const ERROR = 'ERROR';

// Failed Status
export const FAILED = 'FAILED';

// Clear Status
export const CLEAR_STATUS = 'CLEAR_STATUS';
