import { machineryItem, machinery } from "../forms/Vessel.form.types"
export default class VesselMachinery {
	main_machinery: MachineryItem;
	main_generator: MachineryItem;
	emergency_generator: MachineryItem;
	main_steering_gear: MachineryItem;
	auxillary_steering_gear: MachineryItem;
	switchboard: MachineryItem;
	shore_connection: MachineryItem;
	batteries: MachineryItem;
	signal_light_switch: MachineryItem;

	constructor(params: machinery, onChange) {
		params = {
			...{
				main_machinery: null,
				main_generator: null,
				emergency_generator: null,
				main_steering_gear: null,
				auxillary_steering_gear: null,
				switchboard: null,
				shore_connection: null,
				batteries: null,
				signal_light_switch: null
			}, ...params,
		}
		this.onChange = onChange;
		this.main_machinery = new MachineryItem(params.main_machinery, () => this.onChange())
		this.main_generator = new MachineryItem(params.main_generator, () => this.onChange())
		this.emergency_generator = new MachineryItem(params.emergency_generator, () => this.onChange())
		this.main_steering_gear = new MachineryItem(params.main_steering_gear, () => this.onChange())
		this.auxillary_steering_gear = new MachineryItem(params.auxillary_steering_gear, () => this.onChange())
		this.switchboard = new MachineryItem(params.switchboard, () => this.onChange())
		this.shore_connection = new MachineryItem(params.shore_connection, () => this.onChange())
		this.batteries = new MachineryItem(params.batteries, () => this.onChange())
		this.signal_light_switch = new MachineryItem(params.signal_light_switch, () => this.onChange())
	}
}


export class MachineryItem {
	power_bhp: number = 0;
	type: string = "";
	condition: string = "";
	location: string = "";

	onChange: () => void;

	constructor(params: machineryItem, onChange) {
		params = {
			...{
				condition: "",
				location: "",
				power_bhp: 0,
				type: ""
			},
			...params
		}
		this.onChange = onChange;
		this.power_bhp = params.power_bhp;
		this.type = params.type;
		this.condition = params.condition;
		this.location = params.location;

	}

	setField(key:
		| "power_bhp"
		| "type"
		| "condition"
		| "location", value) {
		this[key] = value;
		this.onChange()
	}
}