import { PropulsionOfEngineType, EngineType } from "../forms/Vessel.form.types"
export class VesselPropulsionEngine {
	engine_type: string = "";
	HP: number = 0;
	onChange = null;
	constructor(params: EngineType, onChange) {
		params = {
			...{
				HP: 0,
				engine_type: ""
			}, ...params
		}
		this.engine_type = params.engine_type;
		this.HP = params.HP;
		this.onChange = onChange;
	}
	setEngine_type(engine_type) {
		this.engine_type = engine_type;
		this.onChange && this.onChange(this)
	}
	setHP(HP) {
		this.HP = HP;
		this.onChange && this.onChange(this)
	}

	subscribeForStateChange(onChange) {
		this.onChange = onChange;
	}
}

export default class VesselPropulsion {

	no_of_sets_fitted: number = 0;
	manufacturer_name: string = "";
	manufacturer_address: string = "";
	model_no: number = 0;
	year_of_built: number = 0;
	propulsion_shaft_diameter: number = 0;
	propulsion_shaft_material: string = "";
	gears_type: string = "";
	no_of_gears: number = 0;
	test_certificate: string = "";
	engine: EngineType[];

	onChange: () => void;

	constructor(params: PropulsionOfEngineType, onChange: () => void) {
		params = {
			...{
				engine: [],
				no_of_sets_fitted: 0,
				manufacturer_name: "",
				manufacturer_address: "",
				model_no: 0,
				year_of_built: 0,
				propulsion_shaft_diameter: 0,
				propulsion_shaft_material: "",
				gears_type: "",
				no_of_gears: 0,
				test_certificate: "",
			},
			...params
		}
		this.engine = params.engine;
		this.no_of_sets_fitted = params.no_of_sets_fitted;
		this.manufacturer_name = params.manufacturer_name;
		this.manufacturer_address = params.manufacturer_address;
		this.model_no = params.model_no;
		this.year_of_built = params.year_of_built;
		this.propulsion_shaft_diameter = params.propulsion_shaft_diameter;
		this.propulsion_shaft_material = params.propulsion_shaft_material;
		this.gears_type = params.gears_type;
		this.no_of_gears = params.no_of_gears;
		this.test_certificate = params.test_certificate;

		this.onChange = onChange;
	}

	setNo_of_sets_fitted(no_of_sets_fitted) {
		this.no_of_sets_fitted = no_of_sets_fitted;
		this.onChange();
	}
	setManufacturer_name(manufacturer_name) {
		this.manufacturer_name = manufacturer_name;
		this.onChange();
	}
	setManufacturer_address(manufacturer_address) {
		this.manufacturer_address = manufacturer_address;
		this.onChange();
	}
	setModel_no(model_no) {
		this.model_no = model_no;
		this.onChange();
	}
	setYear_of_built(year_of_built) {
		this.year_of_built = year_of_built;
		this.onChange();
	}
	setPropulsion_shaft_diameter(propulsion_shaft_diameter) {
		this.propulsion_shaft_diameter = propulsion_shaft_diameter;
		this.onChange();
	}
	setPropulsion_shaft_material(propulsion_shaft_material) {
		this.propulsion_shaft_material = propulsion_shaft_material;
		this.onChange();
	}
	setGears_type(gears_type) {
		this.gears_type = gears_type;
		this.onChange();
	}
	setNo_of_gears(no_of_gears) {
		this.no_of_gears = no_of_gears;
		this.onChange();
	}
	setTest_certificate(test_certificate) {
		this.test_certificate = test_certificate;
		this.onChange();
	}
	setEngine(engine) {
		this.engine = engine;
		this.onChange();
	}

	addEngine(engine) {
		this.engine.push(new VesselPropulsionEngine(engine));
		this.onChange();
	}

	removeEngine(engine) {
		this.engine = this.engine.filter((engineItem) => {
			if (engine === engineItem)
				return false;
			else
				return true;
		})
		this.onChange()
	}
}