import React, { useState } from "react";
import Vessel from "../Vessel";
import { FormGroup, Label, Input } from "reactstrap";
import HydrantsForm from "./fire/Hydrants.form";
import HosesForm from "./fire/Hoses.form";
type VesselFireapplianceFormType = {
    vessel: Vessel;
    onSave: () => void;
    mode: "Create" | "View" | "Edit" | "EditViaForm";
};
const VesselFireapplianceForm: React.FC<VesselFireapplianceFormType> = ({
    vessel: vesselState,
    mode
}: VesselFireapplianceFormType) => {
    const isViewMode = mode === "View";

    const { fire_fighting } = vesselState;

    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>No of fire pumps</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={fire_fighting.no_of_fire_pumps}
                        onChange={e =>
                            fire_fighting.setField(
                                "no_of_fire_pumps",
                                e.target.value
                            )
                        }
                        placeholder="No_of_fire_pumps"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Size of fire pumps</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={fire_fighting.size_of_fire_pumps}
                        onChange={e =>
                            fire_fighting.setField(
                                "size_of_fire_pumps",
                                e.target.value
                            )
                        }
                        placeholder="Size_of_fire_pumps"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Capacity fire pumps</Label>
                    <Input
                        disabled={isViewMode}
                        type="text"
                        value={fire_fighting.capacity_fire_pumps}
                        onChange={e =>
                            fire_fighting.setField(
                                "capacity_fire_pumps",
                                e.target.value
                            )
                        }
                        placeholder="Capacity_fire_pumps"
                    />
                </FormGroup>
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Hydrants</h3>
                <HydrantsForm
                    hydrants={fire_fighting.hydrants}
                    isViewMode={isViewMode}
                />
            </div>
            <div className="col-12 mt-3 mb-3">
                <h3>Hose </h3>
                <HosesForm
                    hose={fire_fighting.hoses_fittings_nozzles}
                    isViewMode={isViewMode}
                />
            </div>
        </div>
    );
};
export default VesselFireapplianceForm;
