import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CardText,
    CardTitle,
    Input,
    Label,
    UncontrolledTooltip
} from "reactstrap";

type TypeChecking = {
    userProfile: {},
    approvers: {},
    vesselId: string,
    userDetails: () => void,
    previousStepStatus: boolean,
    approveStage: () => void,
    user: {}
}

const RegistrationFee = ({userProfile, approvers, vesselId, allData, userDetails, previousStepStatus, approveStage, user}: TypeChecking) => {

    const [invoiceNo, setInvoiceNo] = useState("");

    useEffect(() => {
        if (userProfile.userType === "port-operator" && vesselId) {
            userDetails(vesselId);
        }
    }, []);

    const onChange = (value) => {
        setInvoiceNo(value);
    };

    return (
        previousStepStatus ?
            <div>
                {userProfile.userType === "port-operator" ?
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Registration Fee&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_registration_fee.notify_payment === true ?
                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.payment_done === true ?
                                            allData.vesselActivity.stages_info.stages.stage_registration_fee.payment_success === true ?
                                                <>
                                                    <CardText>
                                                        Payment is Successful for the Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_registration_fee.invoice_number}.
                                                    </CardText>
                                                </>
                                                :
                                                <>
                                                    <CardText>
                                                        Amount Payment for the Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_registration_fee.invoice_number} is
                                                        done.
                                                        Please verify the Invoice Number in Smart Port application for payment status and click on the Payment Successful button.
                                                    </CardText>
                                                    <CardText>
                                                        Status of Invoice
                                                        No {allData.vesselActivity.stages_info.stages.stage_registration_fee.invoice_number} is a success from Owner/Agency/Agent's side.
                                                    </CardText>
                                                    <Button
                                                        id={"paymentSuccess"}
                                                        color={"success"}
                                                        onClick={() => approvers.stage_registration_fee.includes(userProfile.id) ?
                                                            approveStage(userProfile, "stage_registration_fee", {
                                                                type: "payment-successful",
                                                                invoiceNo: invoiceNo
                                                            })
                                                            :
                                                            () => {
                                                            }}
                                                        className="mr-1 mb-1">
                                                        Payment successful
                                                    </Button>
                                                    {!approvers.stage_registration_fee.includes(userProfile.id) ?
                                                        <UncontrolledTooltip
                                                            placement={"bottom"}
                                                            target={"paymentSuccess"}>
                                                            You are not authorized
                                                        </UncontrolledTooltip>
                                                        : null}
                                                </>
                                            :
                                            <>
                                                <CardText>
                                                    Payment from Owner/Agency/Agent is still pending.
                                                </CardText>
                                                <CardText>
                                                    Status of Invoice
                                                    No {allData.vesselActivity.stages_info.stages.stage_registration_fee.invoice_number} is
                                                    still pending...
                                                </CardText>
                                            </>
                                        :
                                        <>
                                            <CardText>
                                                Please raise an Invoice in Smart Port application
                                                to {user.name} ( {user.username} ) and enter the Invoice No in the field
                                                provided, then click on Notify Payment
                                                button to
                                                inform agent.
                                            </CardText>
                                            <CardText>
                                                Payment Status : INITIATING
                                            </CardText>
                                            <Label for="invoiceNo">Enter Invoice No</Label>
                                            <Input
                                                required
                                                type="text"
                                                name="invoiceNumber"
                                                value={invoiceNo}
                                                onChange={(e) => onChange(e.target.value)}
                                            />
                                            <br/>
                                            <Button
                                                id={"notifyPayment"}
                                                color={"primary"}
                                                onClick={() => approvers.stage_registration_fee.includes(userProfile.id) ?
                                                    invoiceNo.length !== 0 ?
                                                        approveStage(userProfile, "stage_registration_fee", {
                                                            type: "notify-payment",
                                                            invoiceNo: invoiceNo
                                                        })
                                                        :
                                                        alert("Invoice No cannot be empty")
                                                    :
                                                    () => {
                                                    }}
                                                className="mr-1 mb-1">
                                                Notify Payment
                                            </Button>
                                            {!approvers.stage_registration_fee.includes(userProfile.id) ?
                                                <UncontrolledTooltip
                                                    placement={"bottom"}
                                                    target={"notifyPayment"}>
                                                    You are not authorized to Notify Payment
                                                </UncontrolledTooltip>
                                                : null}
                                        </>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                    :
                    <Card>
                        <CardHeader>
                            <CardTitle tag="h5" className="my-2">
                                Registration Fee&nbsp;
                                {
                                    allData !== undefined ?
                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by.length !== 0 ?
                                            <span
                                                style={{color: "#0e7a18"}}>( Approved By {allData.vesselActivity.stages_info.stages.stage_registration_fee.approved_by[0].user.full_name} )</span>
                                            :
                                            null
                                        :
                                        null
                                }
                            </CardTitle>
                        </CardHeader>
                        <CardBody>

                            {
                                allData !== undefined ?
                                    allData.vesselActivity.stages_info.stages.stage_registration_fee.notify_payment === true ?
                                        allData.vesselActivity.stages_info.stages.stage_registration_fee.payment_done === true ?
                                            allData.vesselActivity.stages_info.stages.stage_registration_fee.payment_success === true ?
                                                <>
                                                    <CardText>
                                                        Your Payment is Successful
                                                    </CardText>
                                                    <CardText>
                                                        Payment Status : SUCCESS
                                                    </CardText>
                                                </>
                                                :
                                                <>
                                                    <CardText>
                                                        Payment Verification is in process...it may take time
                                                    </CardText>
                                                    <CardText>
                                                        Payment Status : VERIFYING...
                                                    </CardText>
                                                </>
                                            :
                                            <>
                                                <CardText>
                                                    An Invoice is raised in Smart Port application for Payment. Once
                                                    payment is done
                                                    click
                                                    on
                                                    Payment Done.
                                                </CardText>
                                                <CardText>
                                                    Invoice
                                                    No: {allData.vesselActivity.stages_info.stages.stage_registration_fee.invoice_number}
                                                </CardText>
                                                <CardText>
                                                    Payment Status : PENDING
                                                </CardText>

                                                <Button
                                                    color={"success"}
                                                    onClick={() => approveStage(userProfile, "stage_registration_fee", {
                                                        type: "payment-done",
                                                    })}
                                                    className="mr-1 mb-1">
                                                    Payment Done
                                                </Button>
                                            </>
                                        :
                                        <CardText>
                                            Invoice has not be raised yet so please wait...
                                        </CardText>
                                    :
                                    null
                            }
                        </CardBody>
                    </Card>
                }
            </div>
            :
            <div>
                <Card>
                    <CardHeader>
                        <CardTitle tag="h5" className="my-2">
                            Yet to be processed...
                        </CardTitle>
                    </CardHeader>
                </Card>
            </div>
    );
};

export default RegistrationFee;
