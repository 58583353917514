import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import {toastr} from "react-redux-toastr";
import {CreateVessel, UpdateVessel} from "../../redux/actions/vesselActions";
import axios from "axios";
import {vessel_type} from "./forms/Vessel.form.types";
import {Container, CardBody, Button} from "reactstrap";
import Card from "reactstrap/lib/Card";
import Vessel from "./Vessel";

// import B12RegistrationOrSurvey from "./forms/B12.registrationOrSurvey.form";
// import B12DeclarationOfOwnerShip from "./forms/B12.declarationOfOwnership.form";
// import B12RegistrationOrSurveyPrint from "./Prints/B12.registrationOrSurvey.print";
// import B12DeclarationOfOwnershipPrint from "./Prints/B12.declarationOfOwnership.print";
// import {Printer} from "react-feather";
import moment from "moment";
// import html2canvas from "html2canvas";
// import JsPdf from "jspdf";
// import $ from 'jquery';

import VesselBasicForm from "./forms/Vessel.basic.form";
import VesselHullForm from "./forms/Vessel.hull.form";
import VesselPropulsionForm from "./forms/Vessel.propulsion.form";
import VesselEquipmentForm from "./forms/Vessel.equipment.form";
import VesselFireapplianceForm from "./forms/Vessel.fireappliance.form";
import VesselOtherForm from "./forms/Vessel.other.form";
import VesselMachineryForm from "./forms/Vessel.machinery.form";
import VesselAccomodationForm from "./forms/Vessel.accomodation.form";
import VesselFreeboardForm from "./forms/Vessel.freeboard.form";
import SetAxiosAuthHeaders from "../../config/SetAxiosAuthHeaders";
import "./vessel.css";
import {
    getVesselFormRouteInformation,
    getVesselFormRequiredFields,
    isForm5PayloadValid,
} from "./Vessel.form.helper";
import paths from "../../config/Paths";
import routesHelper from "../../routes/routes.helper";

SetAxiosAuthHeaders();

type VesselFormType = {
    defaultValue? : vessel_type,
    onChange: () => void,
    dispatch? : (anything: any) => void,
    match? : {
        path: string,
        url: string,
        params? : {
            vesselId: string,
            formName: string,
        },
    },
    location? : {
        state: {
            activityId: string,
            updateVessel? : vessel_type,
        },
    },
};

const formsList = [
    // {
    //     title: "Application for Registration/Survey",
    //     linkTitle: "Application for Registration/Survey",
    //     component: B12RegistrationOrSurvey,
    //     link: "registrationSurvey",
    //     print: B12RegistrationOrSurveyPrint,
    // },
    // {
    //     title: "Declaration of Ownership",
    //     linkTitle: "Declaration of Ownership",
    //     component: B12DeclarationOfOwnerShip,
    //     link: "declarationOfOwnership",
    //     print: B12DeclarationOfOwnershipPrint,
    // },
    {
        title: "Basic Vessel Information",
        linkTitle: "Basic Information",
        component: VesselBasicForm,
        link: "basic",
    },
    {
        title: "Hull Details",
        linkTitle: "Hull Details",
        component: VesselHullForm,
        link: "hull",
    },
    {
        title: "Propulsion Engine",
        linkTitle: "Propulsion Engine",
        component: VesselPropulsionForm,
        link: "propulsion",
    },
    {
        title: "Equipment Details",
        linkTitle: "Equipments",
        component: VesselEquipmentForm,
        link: "equipments",
    },
    {
        title: "Particulars of Fire Appliance",
        linkTitle: "Fire Appliance",
        component: VesselFireapplianceForm,
        link: "fireappliance",
    },
    {
        title: "Machinery Information",
        linkTitle: "Machinery",
        component: VesselMachineryForm,
        link: "machinery",
    },
    {
        title: "Accomodation Information",
        linkTitle: "Accomodation",
        component: VesselAccomodationForm,
        link: "accomodation",
    },
    {
        title: "Freeboard Information",
        linkTitle: "Freeboard",
        component: VesselFreeboardForm,
        link: "freeboard",
    },
    {
        title: "Other Information",
        linkTitle: "Other Information",
        component: VesselOtherForm,
        link: "other",
    },
];

const VesselForm: React.FC<VesselFormType> = (props: VesselFormType) => {
    //Just for Toast
    const [toastrConfig, setToastrConfig] = useState({
        title: "",
        timeOut: 5000,
        showCloseButton: true,
        progressBar: true,
        position: "top-right",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const showToastr = (type, message) => {
        const options = {
            type: type,
            timeOut: parseInt(toastrConfig.timeOut),
            showCloseButton: toastrConfig.showCloseButton,
            progressBar: toastrConfig.progressBar,
            position: toastrConfig.position,
        };

        const toastrInstance =
            type === "info"
                ? toastr.info
                : type === "warning"
                ? toastr.warning
                : type === "error"
                    ? toastr.error
                    : toastr.success;

        toastrInstance(
            toastrConfig.title,
            message || "Have fun storming the castle!",
            options
        );
    };

    //Acquiring all params and type of operation from route url
    const routeInformation = getVesselFormRouteInformation(props.match.path, {
        ...props.match.params,
        payload: props.location.state,
    });
    const {mode, formName, payload, vesselId} = routeInformation;

    console.log("Mode " + mode);
    console.log("Form Name " + formName);
    console.log("Pay Load " + payload);
    console.log("ID " + vesselId);

    //As per formName param we are getting required validator (Every FormName has its own validation logic)
    const formFields = getVesselFormRequiredFields(formName);

    const [vessel, setVessel] = useState(new Vessel());
    // console.log("In Vessel.form.jsx");
    // console.log(vessel);
    vessel.subscribeForStateChange(setVessel);

    //Is Vessel is passed as payload or do we have to load it from backend
    useEffect(() => {
        if (vesselId) {
            if (!isForm5PayloadValid(payload)) {
                axios.get(paths.api.FetchVessel(vesselId)).then((response) => {
                    setVessel(new Vessel({...vessel, ...response.data}));
                });
            } else {
                const newVessel = new Vessel(payload.updatedVessel);
                setVessel(newVessel);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //As per route url changes text
    const getSubmitButtonText = () =>
        mode === "Create"
            ? "Create"
            : mode === "Edit"
            ? "Save"
            : mode === "View"
                ? "Edit"
                : mode === "EditViaForm"
                    ? "Save"
                    : "Save";

    const onSubmitToastMessage = (
        thatStupidPromise,
        yourSuccessMessage,
        yourErrorMessage
    ) => {
        thatStupidPromise.then(
            () => showToastr("success", yourSuccessMessage),
            () => showToastr("error", yourErrorMessage)
        );
    };

    const formSubmit = () => {
        alert("Entered onSubmit function");
        if (mode === "Create") {
            const stupidPromise = props.dispatch(
                CreateVessel(new Vessel(vessel))
            );
            onSubmitToastMessage(
                stupidPromise,
                "Vessel Created Successfully!!",
                "Failed to Create Vessel"
            );
        }
        if (mode === "Edit") {
            const stupidPromise = props.dispatch(
                UpdateVessel(new Vessel({...vessel, id: vesselId}), vesselId)
            );
            onSubmitToastMessage(
                stupidPromise,
                "Vessel Updated Successfully",
                "Failed to Update Vessel"
            );
        }

        if (mode === "EditViaForm")
            switch (formName) {
                case "form5":
                case "form17":
                    const stupidPromise = axios.patch(
                        paths.api.UpdateVesselFormActivites(payload.activityId),
                        {
                            vesselId: vesselId,
                            [formName]: {
                                updatedVessel: {
                                    ...vessel,
                                },
                            },
                        }
                    );
                    onSubmitToastMessage(
                        stupidPromise,
                        "Vessel Updated for Form5",
                        "Failed to updated vessel for Form5"
                    );
                    return;
                default:
                    const thisIsPromise = props.dispatch(
                        UpdateVessel(
                            {
                                action: formName,
                                ...new Vessel({...vessel, id: vesselId}),
                            },
                            vesselId
                        )
                    );
                    onSubmitToastMessage(
                        thisIsPromise,
                        `Vessel Updated Successfully for '${formName}'`,
                        `Failed to Update Vessel for '${formName}'`
                    );
            }
    };

    // let printRegistrationOrSurvey = () => {
    //
    //     let top_left_X_Y_Coordinates = 0;
    //     let PDF_Width = 1240;
    //     let PDF_Height = 1754;
    //     let pdf = "";
    //
    //     $("#print-wrap-page1").attr("hidden", false);
    //     html2canvas($("#print-wrap-page1")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //     });
    //     $("#print-wrap-page1").attr("hidden", true);
    //
    //     $("#print-wrap-page2").attr("hidden", false);
    //     html2canvas($("#print-wrap-page2")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf.addPage(PDF_Width, PDF_Height);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //     });
    //     $("#print-wrap-page2").attr("hidden", true);
    //
    //     $("#print-wrap-page3").attr("hidden", false);
    //     html2canvas($("#print-wrap-page3")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf.addPage(PDF_Width, PDF_Height);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //         pdf.output('dataurlnewwindow');
    //     });
    //     $("#print-wrap-page3").attr("hidden", true);
    // };
    //
    // let printDeclarationOfOwnership = () => {
    //
    //     let top_left_X_Y_Coordinates = 0;
    //     let PDF_Width = 1240;
    //     let PDF_Height = 1754;
    //     let pdf = "";
    //
    //     $("#print-wrap-page4").attr("hidden", false);
    //     html2canvas($("#print-wrap-page4")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //         pdf.output('dataurlnewwindow');
    //     });
    //     $("#print-wrap-page4").attr("hidden", true);
    // };
    //
    // let printCertificateOfRegistry = () => {
    //
    //     let top_left_X_Y_Coordinates = 0;
    //     let PDF_Width = 1240;
    //     let PDF_Height = 1754;
    //     let pdf = "";
    //
    //     $("#print-wrap-page5").attr("hidden", false);
    //     html2canvas($("#print-wrap-page5")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //         pdf.output('dataurlnewwindow');
    //     });
    //     $("#print-wrap-page5").attr("hidden", true);
    // };
    //
    // let printCertificateOfSurvey = () => {
    //
    //     let top_left_X_Y_Coordinates = 0;
    //     let PDF_Width = 1240;
    //     let PDF_Height = 1754;
    //     let pdf = "";
    //
    //     $("#print-wrap-page6").attr("hidden", false);
    //     html2canvas($("#print-wrap-page6")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //         pdf.output('dataurlnewwindow');
    //     });
    //     $("#print-wrap-page6").attr("hidden", true);
    // };
    //
    // let printDeclarationOfSurvey = () => {
    //
    //     let top_left_X_Y_Coordinates = 0;
    //     let PDF_Width = 1240;
    //     let PDF_Height = 1754;
    //     let pdf = "";
    //
    //     $("#print-wrap-page7").attr("hidden", false);
    //     html2canvas($("#print-wrap-page7")[0], {allowTaint: true}).then(function (canvas) {
    //         canvas.getContext('2d');
    //
    //         let imgData = canvas.toDataURL("image/jpeg", 1.0);
    //         pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
    //         pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
    //         pdf.output('dataurlnewwindow');
    //     });
    //     $("#print-wrap-page7").attr("hidden", true);
    // };

    return (
        <Container fluid className="p-0">

            <Card>
                <CardBody>
                    {/*<button type="button" className="btn btn-primary" style={{margin: 10}}*/}
                    {/*        onClick={printRegistrationOrSurvey}>PrintOne*/}
                    {/*</button>*/}
                    {/*<button type="button" className="btn btn-primary" style={{margin: 10}}*/}
                    {/*        onClick={printDeclarationOfOwnership}>PrintTwo*/}
                    {/*</button>*/}
                    {/*<button type="button" className="btn btn-primary" style={{margin: 10}}*/}
                    {/*        onClick={printCertificateOfRegistry}>PrintThree*/}
                    {/*</button>*/}
                    {/*<button type="button" className="btn btn-primary" style={{margin: 10}}*/}
                    {/*        onClick={printCertificateOfSurvey}>PrintFour*/}
                    {/*</button>*/}
                    {/*<button type="button" className="btn btn-primary" style={{margin: 10}}*/}
                    {/*        onClick={printDeclarationOfSurvey}>PrintFive*/}
                    {/*</button>*/}
                    <form
                        className="needs-validation row"
                        noValidate
                        id="myForm"
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                    >
                        <div className="col-12 flex flex-row d-flex">
                            <div className="vesselWhiteFix"/>
                            <Button
                                className="ml-auto"
                                type="submit"
                                style={{
                                    marginRight: "16px",
                                    paddingLeft: "25px",
                                    paddingRight: "25px",
                                    marginBottom: "-33px",
                                    position: "absolute",
                                    marginLeft: "auto",
                                    top: "18px",
                                    right: "6px",
                                    zIndex: "10",
                                }}
                                {...{
                                    type: mode !== "View" ? "submit" : null,
                                    onClick:
                                        mode === "View"
                                            ? () =>
                                                routesHelper.vesselForm.edit.replace(
                                                    vesselId
                                                )
                                            : null,
                                }}
                            >
                                {getSubmitButtonText()}
                            </Button>
                        </div>
                        <div className="col-3 pt-5">
                            <ul style={{listStyle: "none", marginTop: "16px", paddingLeft: "0px"}}>
                                <li className="font-weight-bolder h5" style={{color: "#777"}}>
                                    Quick Navigation
                                </li>
                                {formsList.map((form, index) => (
                                    <>
                                        <li style={{fontSize: "14px"}} key={index}>
                                            <a href={`#${form.link}`} style={{textDecoration: "none"}}>
                                                {form.linkTitle}
                                            </a>
                                        </li>
                                    </>
                                ))}
                            </ul>
                        </div>
                        <div className="col-9 vesselFormSection scroll">
                            {formsList.map((
                                {component: Component, link, title},
                                key
                                ) => (
                                    <HeaderWithForm
                                        id={link}
                                        title={title}
                                        key={key}
                                    >
                                        {/*{vesselId ?*/}
                                        {/*    <Print*/}
                                        {/*        vessel={vessel}*/}
                                        {/*    />*/}
                                        {/*    : null}*/}
                                        <Component
                                            vessel={vessel}
                                            onSave={(vessel) => {
                                                setVessel(new Vessel(vessel));
                                            }}
                                            requiredFields={formFields}
                                            mode={mode}
                                        />
                                    </HeaderWithForm>
                                )
                            )}
                        </div>
                    </form>
                    <script>
                        (function() {
                        window.addEventListener('load', function () {
                            let forms = document.getElementsByClassName('needs-validation');
                            let validation = Array.prototype.filter.call(forms, function (form) {
                                form.addEventListener('submit', function (event) {
                                    if (form.checkValidity() === false) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    } else {
                                        formSubmit();
                                    }
                                    form.classList.add('was-validated');
                                }, false);
                            });
                        }, false)
                    })();
                    </script>

                {/*BELOW HTML CODE ARE THE 5 PRINT DOCUMENTS OF BELOW 12 PASSENGER CAPACITY OF IV*/}

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page1" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center"
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 0px 70px 0px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 28,
                                            fontWeight: "bold"
                                        }}>Application for Registration /Survey of Water sports craft/Vessel below 12
                                            Passenger</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 28, fontWeight: "bold"}}>Capacity
                                            as per Go.Ms.No.10</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 50px"
                                }}>
                                    <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start", marginRight: 625}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>To</h4>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Date :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{moment(vessel.registration_or_survey.date).format('DD-MM-YYYY')}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start", marginRight: 215}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>The Registering Authority
                                                / Chief Surveyor</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "flex-start",
                                            background: "#fff"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Place :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.place}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Kakinada/Machilipatnam.</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>I/We the authorized persons of
                                            firm <span
                                                style={{fontWeight: "bold"}}>{vessel.registration_or_survey.firm_name}</span> hereby
                                            apply to
                                            you to make necessary arrangement for the Survey of the Water Sports Craft as per Go
                                            Ms No.10 as detailed below.</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", paddingTop: "30px"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>The particulars of the Craft
                                            are as under:</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 70px"
                                }}>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>1. Name of the Craft
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.name_of_the_craft}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>2. Type of the Craft
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.type_of_the_craft}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>3. Registration Number of
                                                the craft :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.registration_number_of_the_craft}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>4. Port of registry of
                                                Craft :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.port_of_registry_of_craft}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>5. Tonnage</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;(i) Net :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.net_tonnage}</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;(ii) Gross
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.gross_tonnage}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>6. Material of the Craft
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.material_of_the_craft}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>7. Year of Built & Builder
                                                Address :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{moment(vessel.registration_or_survey.year_of_built).format('YYYY') + " & " + vessel.registration_or_survey.builder_address}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>8. Passenger Capacity
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.passenger_capacity}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>9. Craft Dimensions (L x B
                                                x D) :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.craft_dimension_length + " X " + vessel.registration_or_survey.craft_dimension_berth + " X " + vessel.registration_or_survey.craft_dimension_depth}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>10. Place & date of last
                                                survey :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.place_of_last_survey + " & " + moment(vessel.registration_or_survey.date_of_last_survey).format('DD-MM-YYYY')}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>11. Engine Particulars
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 20px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Make
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.engine_make}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Type
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.engine_type}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>c. Serial No
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.engine_serial_Number}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page2" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center"
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 70px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        padding: "0px 30px 0px 30px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "#fff",
                                            paddingTop: "30px"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>d. HP :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.engine_hp}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>12. LSA / FFA
                                                /communication equipment :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 0px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Adult Life
                                                        Jackets :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.adult_life_jackets}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Child Life
                                                        Jackets :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.child_life_jackets}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>c. Life Buoys
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.life_buoys}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>d. Fire Buckets
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.fire_buckets}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>e. Fire
                                                        Extinguishers :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.fire_extinguishers}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>f. Rescue quoits
                                                        with 30m rope :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.rescue_quoits_with_30m_rope}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>g. Communication
                                                        Equipment (Mobile/Walkie Talkie/VHF/AIS etc) :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.communication_equipment}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>13. Insurance Details
                                                :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 0px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>a. Hull &Machinery
                                                        Insurance company :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.hull_and_machinery_insurance_company}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Policy No :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.hull_and_machinery_policy_number}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Insured value/person :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.hull_and_machinery_insured_value_or_person}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Valid Till :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{moment(vessel.registration_or_survey.hull_and_machinery_valid_till).format("DD-MM-YYYY")}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>b. Passenger
                                                        Insurance company :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.passenger_insurance_company}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Policy No :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.passenger_insurance_policy_number}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Insured value/person :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.passenger_insurance_insured_value_or_person}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25
                                                    }}>&nbsp;&nbsp;&nbsp;&nbsp;Valid Till :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{moment(vessel.registration_or_survey.passenger_insurance_valid_till).format("DD-MM-YYYY")}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>14. Driver certificate
                                                details (PBH& LST) from NIWS/RYA/YAI :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.driver_certificate_details}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>15. Details of other
                                                machineries if any :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.details_of_other_machineries_if_any}</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>16. Owner ‘s :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 0px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.owners_name}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page3" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center"
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 70px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        padding: "0px 30px 0px 30px"
                                    }}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "#fff",
                                            paddingTop: "30px"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.owners_address}</h4>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "#fff",
                                            paddingTop: "30px"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Email :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.owners_email}</h4>
                                            </div>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "#fff",
                                            paddingTop: "30px"
                                        }}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Telephone :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.owners_phone_number}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>17. Company Name & Address
                                                if different from above :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 0px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.company_name_if_different}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.company_address_if_different}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        paddingTop: "40px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>18. Agent's :&nbsp;</h4>
                                        </div>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            background: "#fff",
                                            padding: "0px 30px 0px 30px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Name :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.agents_name}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Address
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.agents_address}</h4>
                                                </div>
                                            </div>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                background: "#fff",
                                                paddingTop: "30px"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Telephone
                                                        :&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "Times New Roman",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{vessel.registration_or_survey.agents_phone}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>19. Date and time of
                                                Request for visit of surveyor :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{moment(vessel.registration_or_survey.date_and_time_of_request_for_visit_of_surveyor).format("DD-MM-YYYY")}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>20. Place of proposed
                                                Survey :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.place_of_proposed_survey}</h4>
                                        </div>
                                    </div>
                                    <div
                                        style={{display: "flex", flexDirection: "row", background: "#fff", paddingTop: "40px"}}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>21. NOC Obtained from
                                                Tourism Department for Registration (Yes / No) :&nbsp;</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{
                                                fontFamily: "Times New Roman",
                                                fontSize: 25,
                                                fontWeight: "bold"
                                            }}>{vessel.registration_or_survey.noc_obtained_from_tourism_department_for_registration}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 50px"
                                }}>
                                    <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Station :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{vessel.registration_or_survey.place}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-end", flexGrow: 1}}>
                                            <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Signature of
                                                Owner/Authorized Person</h4>
                                        </div>
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Date :&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{moment(vessel.registration_or_survey.date).format("DD-MM-YYYY")}</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Enclosures : </h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        background: "#fff",
                                        padding: "0px 20px 50px 20px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>1. Operator
                                                certificate (PBH& LST) copy from NIWS/RYA/YAI etc</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>2. Builder
                                                Certificate Copy</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>3. Hull and
                                                Machinery Insurance Certificates copy</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>4. Passenger
                                                Insurance Certificates copy</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>5. Copy of
                                                Certificate of Registration (if already registered)</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>6. Document
                                                establishing the Authority of Authorized Person (if making request)</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "Times New Roman", margin: 0, fontSize: 25}}>7. Copy of NOC
                                                from APTDC.</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                {/*******************************************************************************/}

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page4" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center"
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 0px 70px 0px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 28,
                                            fontWeight: "bold"
                                        }}>Declaration of Ownership of Water sports craft/Vessel below 12 Passenger Capacity
                                            as</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "center"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 28, fontWeight: "bold"}}>per
                                            Go.Ms.No.10</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>I/We&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}><span>{vessel.declaration_of_ownership.owners_name}</span></h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>,{}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>subject to the state
                                            of&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.owners_state_of}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>residing permanently
                                            at&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>,{vessel.declaration_of_ownership.owners_permanent_address}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>having original place of
                                            business at&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "flex-start",
                                        width: "100%",
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>,{vessel.declaration_of_ownership.owners_business_address}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>do hereby declare that craft /
                                            vessel named&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.craft_or_vessel_name}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>was built at&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.craft_or_vessel_built_at}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>in the year&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{moment(vessel.declaration_of_ownership.craft_or_vessel_built_year).format("YYYY")}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and was purchased by me
                                            on&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{moment(vessel.declaration_of_ownership.craft_or_vessel_purchased_date).format("DD-MM-YYYY")}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>for rupees&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.craft_or_vessel_cost}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and wish to have the same
                                            registered in my name at the port of&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.registered_at_port}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and that I&nbsp;</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>am the sole owner of the same
                                            . I further declare that the vessel is intended to ply in the port</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>of&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.ply_in_the_port}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontWeight: "bold"}}>Signature
                                            of Owner</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 0px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Made and subscribed
                                            the&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.made_and_subscribed}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;day of&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.day_of}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;20&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{moment(vessel.declaration_of_ownership.day_of_20).format("YY")}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;by the&nbsp;</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 70px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Above named&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.above_named}</h4>
                                    </div>
                                    <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;in the presence
                                            of&nbsp;</h4>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexGrow: 1,
                                        borderBottom: "1px solid",
                                        marginBottom: 10
                                    }}>
                                        <h4 style={{
                                            fontFamily: "Times New Roman",
                                            fontSize: 25,
                                            fontWeight: "bold",
                                            margin: 0
                                        }}>{vessel.declaration_of_ownership.in_the_presence_of}</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 40px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontWeight: "bold"}}>Signature
                                            of Magistrate/ Notary Public/Registering Authority</h4>
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    background: "#fff",
                                    padding: "0px 50px 40px 50px"
                                }}>
                                    <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                        <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontStyle: "italic"}}>Note:-
                                            The declaration shall be made before a registering Authority, a Magistrate or a
                                            Notary Public.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                {/*******************************************************************************/}

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page5" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between"
                            }}>
                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                            }}>ANNEXURE - 1 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 50px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>CERTIFICATE OF REGISTRY</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>Place of Registry:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                    }}>Machilipatnam / Kakinada</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                justifyContent: "flex-start",
                                                background: "#fff"
                                            }}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>Registration No:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>This is to certify that the registration requested
                                                for water sport craft <span style={{fontWeight: "bold"}}>{vessel.registration_or_survey.firm_name}</span>
                                                 has been approved by this office. Therefore, ANDHRA PRADESH Maritime Board hereby grants and authorizes
                                                Certificate of Registry to water sport craft whose particulars are given below.</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 20px 0px"
                                    }}>
                                        <table style={{width: "100%", border: "1px solid black", borderCollapse: "collapse", fontFamily: "sans-serif", fontSize: 20}}>
                                            <tbody>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse", width: "25%"}}>Name of the Craft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}} colSpan="3"> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Owner's Name</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}} colSpan="3"> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Owner's Address</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}} colSpan="3"> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Previous Name of Craft /<br/> Previous Owner Name</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse", width: "20%"}}>Previous Registration Number</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Place & Year of Built</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}} colSpan="3"> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Length overall Mtrs / Ft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>L.B.P. (Mtrs / Ft)</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Breadth of vessel, Mtrs / Ft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Material of Construction</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Depth of vessel, Mtrs / Ft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Loaded Draft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>GT / NT</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Type of Craft</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Engine Particulars</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}>Passenger Capacity</td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 0px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "flex-start"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>Other Particulars and Information</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 70px 0px"
                                    }}>
                                        <table style={{width: "100%", border: "1px solid black", borderCollapse: "collapse", fontFamily: "sans-serif", fontSize: 20}}>
                                            <tbody>
                                            <tr>
                                                <td style={{border: "2px solid black", borderCollapse: "collapse", height: 30}}> </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>REMARKS:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                }}> </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    background: "#fff",
                                }}>
                                    <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-end", background: "#fff"}}>
                                        <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>
                                                REGISTRAR OF WATER SPORTS CRAFT
                                            </h4>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>
                                                &nbsp;&nbsp;ANDHRA PRADESH MARITIME BOARD
                                            </h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                {/*******************************************************************************/}

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page6" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                            }}>ANNEXURE - 2 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 50px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>CERTIFICATE OF SURVEY</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>TO REMAIN IN FORCE UNTIL THE:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>NAME OF WATER SPORT CRAFT:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>NAME OF THE OWNER:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>NAME OF THE OPERATOR:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 50px 0px"
                                    }}>
                                        <table style={{width: "100%", border: "1px solid black", borderCollapse: "collapse", fontFamily: "sans-serif", fontSize: 20}}>
                                            <tbody>
                                                <tr>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>Place of Registry & No.</th>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>GRT / NRT</th>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>Engine Details / Speed</th>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>Hull Details</th>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>Draft</th>
                                                    <th style={{border: "2px solid black", borderCollapse: "collapse"}}>Passenger & Crew Capacity</th>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse", height: 35}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse"}}> </td>
                                                </tr>
                                                <tr>
                                                    <td style={{border: "2px solid black", borderCollapse: "collapse", height: 35}} colSpan="6"> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 40px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>LSA / FFA EQUIPMENT:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 40px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>INSURANCE DETAILS:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 40px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DETAILS OF TESTS CARRIED OUT:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 10px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>LIMITS OF OPERATION:&nbsp;</h4>
                                                </div>
                                                <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                    <h4 style={{
                                                        fontFamily: "sans-serif",
                                                        fontSize: 25,
                                                        fontWeight: "bold"
                                                    }}>{}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 70px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>1. DESIGNATED AREA OF OPERATION BY TOURISM AUTHORITY.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    background: "#fff",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>Issued on:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>Place of Inspection:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 0px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>CHIEF SURVEYOR</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>ANDHRA PRADESH MARITIME BOARD</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                {/*******************************************************************************/}

                    <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                        <div id="print-wrap-page7" style={{
                            width: 1240,
                            height: 1754,
                            background: "#ffffff",
                            display: "flex",
                            justifyContent: "center",
                        }} hidden>
                            <div style={{
                                width: 1040,
                                height: 1554,
                                background: "#ffffff",
                                margin: 100,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                            }}>

                                <div style={{display: "flex", flexDirection: "column", flexGrow: 1}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 30px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 25,
                                            }}>ANNEXURE - 3 TO GO RT NO.10, I&I(PORTS) DEPT. DATE 20-01-2010</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 50px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>DECLARATION OF SURVEY FOR WATER SPORTS CRAFT</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{
                                                fontFamily: "sans-serif",
                                                fontSize: 28,
                                                fontWeight: "bold",
                                                marginBottom: 0,
                                            }}>ANDHRA PRADESH MARITIME BOARD</h4>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 10px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>1. NAME OF WATER SPORT CRAFT:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>2. PARTICULARS OF THE CRAFT (PREVIOUS NAME / OWNER):&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>3. TONNAGE</h4>
                                            </div>
                                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-evenly"}}>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>G.R.T</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>N.R.T</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", flexDirection: "row", flexGrow: 1, justifyContent: "space-evenly"}}>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>LENGTH</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>BREADTH</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DRAFT</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                                <div style={{display: "flex", flexDirection: "row"}}>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DEPTH</h4>
                                                    </div>
                                                    <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                        <h4 style={{
                                                            fontFamily: "sans-serif",
                                                            fontSize: 25,
                                                            fontWeight: "bold"
                                                        }}>{}</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>4. PLACE & DATE OF BUILT:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>5. NAME & ADDRESS OF PRESENT OWNER:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>6. TYPE OF HULL:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>7. PLACE & DATE OF THE CRAFT EXAMINATION CARRIED OUT:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 30px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>8. CONDITION OF CRAFT:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 40px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 20px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>TESTS CARRIED OUT</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 10px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>&nbsp;&nbsp;&nbsp;&nbsp;1.&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 10px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>&nbsp;&nbsp;&nbsp;&nbsp;2.&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 10px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>&nbsp;&nbsp;&nbsp;&nbsp;3.&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 10px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>&nbsp;&nbsp;&nbsp;&nbsp;4.&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff", padding: "0px 0px 10px 0px"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>&nbsp;&nbsp;&nbsp;&nbsp;5.&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 70px 0px"
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "center", background: "#fff"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>REMARKS OF SURVEYOR - FOUND SATISFACTORY / NOT SATISFACTORY</h4>
                                        </div>
                                    </div>
                                </div>

                                <div style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    background: "#fff",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexGrow: 1,
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                    }}>
                                        <div style={{display: "flex", flexDirection: "row", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>PLACE:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                        <div style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", background: "#fff"}}>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{fontFamily: "sans-serif", fontSize: 25}}>DATE:&nbsp;</h4>
                                            </div>
                                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                                <h4 style={{
                                                    fontFamily: "sans-serif",
                                                    fontSize: 25,
                                                    fontWeight: "bold"
                                                }}>{}</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        background: "#fff",
                                        padding: "0px 0px 0px 0px"
                                    }}>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>OWNER / OWNER'S REPRESENTATIVE</h4>
                                        </div>
                                        <div style={{display: "flex", justifyContent: "center"}}>
                                            <h4 style={{fontFamily: "sans-serif", fontSize: 25, marginBottom: 0}}>SURVEYOR</h4>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                {/*******************************************************************************/}

                </CardBody>
            </Card>
        </Container>
    );
};

const mapStateToProps = ({vessels, status, counts}) => ({
    vessels,
    status,
    counts,
});

const mapDispatchToProps = (dispatch) => ({
    CreateVessel,
    UpdateVessel,
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(VesselForm);

const HeaderWithForm = ({title, children, id}) => {
    return (
        <div
            style={{
                paddingTop: "20px",
                marginBottom: "40px",
            }}
            id={id}
        >
            <h1
                style={{
                    position: "sticky",
                    top: "20px",
                    zIndex: 9,
                    background: "white",
                    borderBottom: "",
                }}
                className="border-bottom-fix pb-2 font-weight-bold"
            >
                {title}
            </h1>
            <div>{children}</div>
        </div>
    );
};
