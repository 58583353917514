import React from "react";
import {Redirect} from "react-router-dom";
import history from '../routes/history';
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";

window.addEventListener('popstate', (event) => {
    history.push(`/`);
});

const Dashboard = ({children}) => (
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user")).token &&
    JSON.parse(localStorage.getItem("user")).token.length > 0 ? (
        localStorage.getItem("userChoice") &&
        localStorage.getItem("userChoice").length > 0 ? (
            <React.Fragment>
                <Wrapper>
                    <Sidebar/>
                    <Main>
                        <Navbar/>
                        <Content>{children}</Content>
                        <Footer/>
                    </Main>
                </Wrapper>
            </React.Fragment>
        ) : (
            <Redirect to={"/"}/>
        )
    ) : (
        <Redirect to={"/auth/sign-in/"}/>
    )
);

export default Dashboard;
