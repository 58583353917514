import React, {useState} from "react";
import {Link} from "react-router-dom";

import {
    Button,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    CustomInput
} from "reactstrap";

import avatar from "../../assets/img/avatars/avatar.jpg";
import axios from "axios";
import {Redirect} from 'react-router-dom';
import Paths from "../../config/Paths";
import moment from "moment";

const SignIn = (props) => {
    const [data, setData] = useState({
        email: "",
        password: "",
        message: ""
    });

    const {email, password, message} = data;

    const onChange = e => {
        //console.log(e.target.name);
        setData({
            ...data,
            [e.target.name]: e.target.value,
        });
    };

    const setAxiosInterceptor = auth => {
        console.log('Setting network interceptors');
        // console.log(auth);
        axios.defaults.headers.common["x-access-token"] = auth;
        // console.log(axios.defaults.headers.common.Authorization);
    };

    const handleSubmit = event => {
        event.preventDefault();
        if (email !== '' && password !== '') {
            axios
                .post(Paths.api.AuthenticateUser(), {username: email, password})
                .then(result => {
                    console.log(result);
                    localStorage.setItem('user', JSON.stringify(result.data));
                    setAxiosInterceptor(result.data.token);
                    // now calling api to get the user details
                    axios
                        .get(Paths.api.GetUser())
                        .then(userResult => {
                            console.log(userResult);
                            localStorage.setItem('userProfile', JSON.stringify(userResult.data));
                            setData(() => ({
                                ...data,
                                message: '',
                                redirectToReferrer: true,
                            }));
                        })
                        .catch(error => {
                            console.log(error);
                            if (error.response && error.response.status === 404) {
                                setData({
                                    ...data,
                                    message: 'Authentication failed. User not found.',
                                });
                            } else if (error.response && error.response.status === 401) {
                                setData({
                                    ...data,
                                    message: 'Authentication failed. Wrong Password',
                                });
                            }
                        });
                })
                .catch(error => {
                    console.log(error);
                    if (error.response && error.response.status === 404) {
                        setData({
                            ...data,
                            message: 'Authentication failed. User not found.',
                        });
                    } else if (error.response && error.response.status === 401) {
                        setData({
                            ...data,
                            message: 'Authentication failed. Wrong Password',
                        });
                    }
                });
        } else {
            setData({...data, message: 'Email & Password are required!'});
        }
    };

    const {from} = props.location.state || {
        from: {pathname: '/'},
    };

    if (data.redirectToReferrer) {
        return <Redirect to={from}/>;
    }

    const getUserName = () => {
        if (JSON.parse(localStorage.getItem('userProfile')) &&
            JSON.parse(localStorage.getItem('userProfile')).name) {
            return JSON.parse(localStorage.getItem('userProfile')).name;
        } else {
            return "";
        }
    };
    return (
        <React.Fragment>
            <div className="text-center mt-4">
                <h2>Welcome back, {getUserName()}</h2>
                <p className="lead">Sign in to your account to continue</p>
            </div>

            <Card>
                <CardBody>
                    <div className="m-sm-4">
                        <div className="text-center">
                            <img
                                src={avatar}
                                alt="Chris Wood"
                                className="img-fluid rounded-circle"
                                width="132"
                                height="132"
                            />
                        </div>
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Label>Email</Label>
                                <Input
                                    bsSize="lg"
                                    type="email"
                                    name="email"
                                    placeholder="Enter your email"
                                    value={data.email}
                                    onChange={onChange}

                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Password</Label>
                                <Input
                                    bsSize="lg"
                                    type="password"
                                    name="password"
                                    placeholder="Enter your password"
                                    value={data.password}
                                    onChange={onChange}
                                />
                                {/* <small>
                  <Link to="/auth/reset-password">Forgot password?</Link>
                </small> */}
                            </FormGroup>
                            <div>
                                <CustomInput
                                    type="checkbox"
                                    id="rememberMe"
                                    label="Remember me next time"
                                    defaultChecked
                                />
                            </div>
                            <div className="text-center mt-3">
                                <Button color="primary" size="lg" type="submit">
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                        <p style={{color: 'red'}}>{message}</p>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default SignIn;
