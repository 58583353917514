import {
    FETCH_ALL_BELOW_VESSEL,
    CLEAR_ALL_BELOW_VESSEL
} from "../../actions/ActionTypes/b12ActionTypes";

const AllBelowVesselReducers = (state = [], actions) => {
    switch (actions.type) {
        case FETCH_ALL_BELOW_VESSEL:
            return actions.payload;

        case CLEAR_ALL_BELOW_VESSEL:
            return actions.payload;

        default:
            return state;
    }
};

export default AllBelowVesselReducers;
