import {
    SUCCESS,
    ERROR,
    FAILED,
    CLEAR_STATUS
} from "../../actions/ActionTypes/statusActionTypes";

const StatusReducers = (state = [], actions) => {
    switch (actions.type) {
        case SUCCESS:
            return actions.payload;

        case ERROR:
            return actions.payload;

        case FAILED:
            return actions.payload;

        case CLEAR_STATUS:
            return actions.payload;

        default:
            return state;
    }
};

export default StatusReducers;
