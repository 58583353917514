import React from 'react';
import $ from "jquery";
import html2canvas from "html2canvas";
import JsPdf from "jspdf";
import moment from "moment";
import ApplicationFormFieldsSource from "../../../formFieldsSource/ApplicationFormFieldsSource";
import {Button} from "reactstrap";

const VesselBasicFormType = {
    formFieldSource: ApplicationFormFieldsSource,
};


const DeclarationOfOwnershipPrint = ({formFieldSource}: VesselBasicFormType) => {

    let printDeclarationOfOwnership = () => {

        let top_left_X_Y_Coordinates = 0;
        let PDF_Width = 1240;
        let PDF_Height = 1754;
        let pdf = "";

        $("#print-wrap-page4").attr("hidden", false);
        html2canvas($("#print-wrap-page4")[0], {allowTaint: true}).then(function (canvas) {
            canvas.getContext('2d');

            let imgData = canvas.toDataURL("image/jpeg", 1.0);
            pdf = new JsPdf('p', 'pt', [PDF_Width, PDF_Height]);
            pdf.addImage(imgData, 'JPG', top_left_X_Y_Coordinates, top_left_X_Y_Coordinates, PDF_Width, PDF_Height);
            pdf.output('dataurlnewwindow');
        });
        $("#print-wrap-page4").attr("hidden", true);
    };

    let opaArray = formFieldSource !== undefined ? formFieldSource.declaration_of_ownership.owners_permanent_address.match(/.{1,65}/g) : null;
    let obaArray = formFieldSource !== undefined ? formFieldSource.declaration_of_ownership.owners_business_address.match(/.{1,65}/g) : null;

    return (
        <div>
            <Button color={"success"}
                    onClick={() => printDeclarationOfOwnership()}
                    style={{width: 260}}
                    className="mr-1 mb-1">
                Declaration of Ownership
            </Button>

            <div style={{width: 1240, display: "flex", justifyContent: "center"}}>
                <div id="print-wrap-page4" style={{
                    width: 1240,
                    height: 1754,
                    background: "#ffffff",
                    display: "flex",
                    justifyContent: "center"
                }} hidden>
                    <div style={{
                        width: 1040,
                        height: 1554,
                        background: "#ffffff",
                        margin: 100,
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 0px 70px 0px"
                        }}>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 28,
                                    fontWeight: "bold"
                                }}>Declaration of Ownership of Water sports craft/Vessel below 12 Passenger Capacity
                                    as</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 28, fontWeight: "bold"}}>per
                                    Go.Ms.No.10</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>I/We&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}><span>{formFieldSource.declaration_of_ownership.owners_name}</span></h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "flex-start",
                                width: "100%",
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>,{}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>subject to the state
                                    of&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.owners_state_of}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>residing permanently
                                    at&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{}</h4>
                            </div>
                        </div>
                        {
                            formFieldSource !== undefined ?
                                opaArray !== null ?
                                    opaArray.map((each, index) =>
                                        <div key={index} style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            background: "#fff",
                                            padding: "0px 50px 0px 50px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                                borderBottom: "1px solid",
                                                marginBottom: 10
                                            }}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold",
                                                    margin: 0
                                                }}>{each}</h4>
                                            </div>
                                        </div>
                                    )
                                    :
                                    null
                                :
                                null
                        }
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>having original place of
                                    business at&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{}</h4>
                            </div>
                        </div>
                        {
                            formFieldSource !== undefined ?
                                obaArray !== null ?
                                    obaArray.map((each, index) =>
                                        <div key={index} style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "center",
                                            background: "#fff",
                                            padding: "0px 50px 0px 50px"
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                width: "100%",
                                                borderBottom: "1px solid",
                                                marginBottom: 10
                                            }}>
                                                <h4 style={{
                                                    fontFamily: "Times New Roman",
                                                    fontSize: 25,
                                                    fontWeight: "bold",
                                                    margin: 0
                                                }}>{each}</h4>
                                            </div>
                                        </div>
                                    )
                                    :
                                    null
                                :
                                null
                        }
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>do hereby declare that craft /
                                    vessel named&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_name}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>was built at&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_built_at}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>in the year&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_built_year}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and was purchased by me
                                    on&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_purchased_date === undefined ? "" : moment(formFieldSource.declaration_of_ownership.craft_or_vessel_purchased_date).format("DD-MM-YYYY")}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>for rupees&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.craft_or_vessel_cost}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and wish to have the same
                                    registered in my name at the port of&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.registered_at_port}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>and that I&nbsp;</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>am the sole owner of the same
                                    . I further declare that the vessel is intended to ply in the port</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>of&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.ply_in_the_port}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontWeight: "bold"}}>Signature
                                    of Owner</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 0px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Made and subscribed
                                    the&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.made_and_subscribed}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;day of&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.day_of}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;20&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.day_of_20 === undefined ? "" : moment(formFieldSource.declaration_of_ownership.day_of_20).format("YY")}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;by the&nbsp;</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 70px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>Above named&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.above_named}</h4>
                            </div>
                            <div style={{display: "flex", justifyContent: "flex-start", flexShrink: 1}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25}}>&nbsp;in the presence
                                    of&nbsp;</h4>
                            </div>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                flexGrow: 1,
                                borderBottom: "1px solid",
                                marginBottom: 10
                            }}>
                                <h4 style={{
                                    fontFamily: "Times New Roman",
                                    fontSize: 25,
                                    fontWeight: "bold",
                                    margin: 0
                                }}>{formFieldSource.declaration_of_ownership.in_the_presence_of}</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 40px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontWeight: "bold"}}>Signature
                                    of Magistrate/ Notary Public/Registering Authority</h4>
                            </div>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            background: "#fff",
                            padding: "0px 50px 40px 50px"
                        }}>
                            <div style={{display: "flex", justifyContent: "flex-start", width: "100%"}}>
                                <h4 style={{fontFamily: "Times New Roman", fontSize: 25, fontStyle: "italic"}}>Note:-
                                    The declaration shall be made before a registering Authority, a Magistrate or a
                                    Notary Public.</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DeclarationOfOwnershipPrint;
