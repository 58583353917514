import {
    SAVE_BELOW_VESSEL,
    UPDATE_BELOW_VESSEL,
    SUBMIT_BELOW_VESSEL,
    FETCH_BELOW_VESSEL,
    CLOSE_BELOW_VESSEL,
    CLEAR_BELOW_VESSEL
} from "../../actions/ActionTypes/b12ActionTypes";
import merge from 'lodash/merge';

const BelowVesselReducers = (state = [], actions) => {
    switch (actions.type) {
        case SAVE_BELOW_VESSEL:
            return actions.payload;

        case UPDATE_BELOW_VESSEL:
            return (merge(state, actions.payload));

        case SUBMIT_BELOW_VESSEL:
            return (merge(state, actions.payload));

        case FETCH_BELOW_VESSEL:
            return actions.payload;

        case CLOSE_BELOW_VESSEL:
            return (merge(state, actions.payload));

        case CLEAR_BELOW_VESSEL:
            return actions.payload;

        default:
            return state;
    }
};

export default BelowVesselReducers;
