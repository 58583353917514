import React from "react";
import {Container, Row, Col, Button} from "reactstrap";
import routesHelper from "../../routes/routes.helper";
import {Redirect} from "react-router-dom";
import history from "../../routes/history";
//import Logo from "../../assets/img/LandingPageLogo/Logo.png";

const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("userChoice");
    history.push("/auth/sign-in/");
};

function LandingComponent(props) {
    return (
        JSON.parse(localStorage.getItem("user")) &&
        JSON.parse(localStorage.getItem("user")).token &&
        JSON.parse(localStorage.getItem("user")).token.length > 0 ? (
            <Container fluid className="h-100 w-100 p-0" style={{backgroundImage: 'url(' + require('../../assets/img/LandingPageBackground/PortTwo.jpg') + ')', backgroundSize: "cover"}}>
                <Row className="w-100 justify-content-end ml-0">
                    <Col xl="2" lg="2" className="py-3 pr-3">
                        <Button color="danger" block onClick={logout}>SIGN OUT</Button>
                    </Col>
                </Row>
                <Row className="h-25 w-100 align-items-center justify-content-around ml-0">
                    {/*<img*/}
                    {/*    className="rounded-circle rounded mr-2 mb-2"*/}
                    {/*    src={Logo}*/}
                    {/*    alt="Logo"*/}
                    {/*    width="190"*/}
                    {/*    height="190"*/}
                    {/*/>*/}
                </Row>
                <Row className="h-50 w-100 align-items-center justify-content-around ml-0">
                    <Col xl="5" lg="5">
                        <Button color="success" block style={{height: 100, fontSize: 20}} onClick={() => {
                            localStorage.setItem('userChoice', "above");
                            routesHelper.vesselForm.list.go();
                        }}>IN-LAND VESSELS ABOVE 12 PASSENGER CAPACITY</Button>
                    </Col>
                    <Col xl="5" lg="5">
                        <Button color="success" block style={{height: 100, fontSize: 20}} onClick={() => {
                            localStorage.setItem('userChoice', "below");
                            routesHelper.below.dashboard.go();
                        }}>IN-LAND VESSELS BELOW 12 PASSENGER CAPACITY</Button>
                    </Col>
                </Row>
            </Container>
        ) : (
            <Redirect to={"/auth/sign-in/"}/>
        )
    )
}

export default LandingComponent;

