import {FileUploads} from "../../typeDeclarations/ApplicationFormsTypeDeclarations";

export default class FileUploadsModel {
    operator_certificate: String = "";
    builder_certificate: String = "";
    hull_and_machinery_insurance_certificate: String = "";
    passenger_insurance_certificate: String = "";
    certificate_of_registration: String = "";
    authority_of_authorized_person: String = "";
    noc: String = "";
    signed_reg: String = "";
    signed_dcl: String = "";

    onChange: () => void;

    constructor(params: FileUploads, onChange: () => void) {
        params = {
            ...{
                operator_certificate: "",
                builder_certificate: "",
                hull_and_machinery_insurance_certificate: "",
                passenger_insurance_certificate: "",
                certificate_of_registration: "",
                authority_of_authorized_person: "",
                noc: "",
                signed_reg: "",
                signed_dcl: ""
            }, ...params
        };

        this.operator_certificate = params.operator_certificate;
            this.builder_certificate = params.builder_certificate;
            this.hull_and_machinery_insurance_certificate = params.hull_and_machinery_insurance_certificate;
            this.passenger_insurance_certificate = params.passenger_insurance_certificate;
            this.certificate_of_registration = params.certificate_of_registration;
            this.authority_of_authorized_person = params.authority_of_authorized_person;
            this.noc = params.noc;
            this.signed_reg = params.signed_reg;
            this.signed_dcl = params.signed_dcl;

            this.onChange = onChange;
    }

    setOperator_certificate(operator_certificate) {
        this.operator_certificate = operator_certificate;
        this.onChange();
    }
    setBuilder_certificate(builder_certificate) {
        this.builder_certificate = builder_certificate;
        this.onChange();
    }
    setHull_and_machinery_insurance_certificate(hull_and_machinery_insurance_certificate) {
        this.hull_and_machinery_insurance_certificate = hull_and_machinery_insurance_certificate;
        this.onChange();
    }
    setPassenger_insurance_certificate(passenger_insurance_certificate) {
        this.passenger_insurance_certificate = passenger_insurance_certificate;
        this.onChange();
    }
    setCertificate_of_registration(certificate_of_registration) {
        this.certificate_of_registration = certificate_of_registration;
        this.onChange();
    }
    setAuthority_of_authorized_person(authority_of_authorized_person) {
        this.authority_of_authorized_person = authority_of_authorized_person;
        this.onChange();
    }
    setNoc(noc) {
        this.noc = noc;
        this.onChange();
    }
    setSignedRegisterCopy(signed_reg) {
        this.signed_reg = signed_reg;
        this.onChange();
    }
    setSignedDeclarationCopy(signed_dcl) {
        this.signed_dcl = signed_dcl;
        this.onChange();
    }
}
