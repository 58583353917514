import {
    REG_COUNT_BElOW_VESSEL,
    CLEAR_REG_COUNT_BELOW_VESSEL
} from "../../actions/ActionTypes/b12ActionTypes";

const BelowVesselRegCountReducers = (state = [], actions) => {
    switch (actions.type) {
        case REG_COUNT_BElOW_VESSEL:
            if (actions.payload !== undefined) {
                return actions.payload;
            } else {
                return null;
            }

        case CLEAR_REG_COUNT_BELOW_VESSEL:
            return actions.payload;

        default:
            return state;
    }
};

export default BelowVesselRegCountReducers;
