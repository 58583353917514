import React from 'react';
import {toastr} from "react-redux-toastr";

const StatusToast = (props) => {

    const options = {
        timeOut: parseInt(5000),
        showCloseButton: true,
        progressBar: true,
        position: "top-right"
    };

    const toastrInstance =
        props.variant === "info"
            ? toastr.info
            : props.variant === "warning"
            ? toastr.warning
            : props.variant === "error"
                ? toastr.error
                : toastr.success;

    toastrInstance(
        props.title,
        props.message,
        options
    );
};

export default StatusToast;
