import { matchPath } from "react-router";
import routesHelper, { formNames } from "../../routes/routes.helper";

type routeFunction = (
	path: string,
	params: any
) => {
	mode: "Create" | "View" | "Edit" | "EditViaForm";
	formName?: formNames;
	vesselId?: string;
	payload?: {
		activityId: string;
		updatedVessel: vessel_type;
	};
};
export type formRulesType = {
	name: boolean,
	Owner: {
		name: boolean,
		address: boolean,
		telephone_no: boolean
	},
	length: boolean,
	breadth: boolean,
	depth: boolean,
	vessel_type: boolean,
	area_of_operation: boolean,
	hull_details: {
		builder_name: boolean,
		builder_address: boolean,
		hull_material: boolean,
		is_deck_free: boolean
	},
	agency: {
		name: boolean,
		address: boolean,
		telephone_no: boolean,
	}
}

export const getVesselFormRouteInformation: routeFunction = (path, params) => {
	const createMode = matchPath(path, {
		path: routesHelper.vesselForm.create.path,
		exact: true,
		strict: false
	});
	const viewMode = matchPath(path, {
		path: routesHelper.vesselForm.view.path,
		exact: true,
		strict: false
	});
	const editMode = matchPath(path, {
		path: routesHelper.vesselForm.edit.path,
		exact: true,
		strict: false
	});
	const editViaFormMode = matchPath(path, {
		path: routesHelper.vesselForm.editViaForms.path,
		exact: true,
		strict: false
	});

	if (createMode)
		return {
			mode: "Create"
		};
	if (viewMode)
		return {
			mode: "View",
			vesselId: params.vesselId
		};
	if (editMode)
		return {
			mode: "Edit",
			vesselId: params.vesselId
		};
	if (editViaFormMode)
		return {
			mode: "EditViaForm",
			vesselId: params.vesselId,
			formName: params.formName,
			payload: params.payload
		};
};

export const isForm5PayloadValid = (payload: {
	activityId: string;
	updatedVessel: vessel_type;
}) => {
	return (payload && payload.activityId)
}

const tempRules = {
	name: false,
	Owner: {
		name: false,
		address: false,
		telephone_no: false
	},
	length: false,
	breadth: false,
	depth: false,
	vessel_type: false,
	area_of_operation: false,
	hull_details: {
		builder_name: false,
		builder_address: false,
		hull_material: false,
		is_deck_free: false
	},
	agency: {
		name: false,
		address: false,
		telephone_no: false,
	}
};
const formsRules = {
	form1A: {
		...tempRules,
		name: false,
		Owner: {
			name: true,
			address: true,
			telephone_no: true
		},
		length: true,
		breadth: true,
		depth: true,
		vessel_type: true,
		area_of_operation: true,
		hull_details: {
			builder_name: true,
			builder_address: true,
			hull_material: true,
			is_deck_free: true
		},
	},
	form2Initial: {
		...tempRules,
		Owner: {
			name: true,
			address: true,
			telephone_no: true
		}
	},
	form2: {
		...tempRules,
		name: true,
		official_no_of_vessel: true,
		port_registry_of_vessel: true,
		tonnage_registered: true,
		tonnage_gross: true,
		vessel_type: true,
		category: true,
		hull_details: {
			year_of_built: true,
			builder_address: true,
		},
		agency: {
			name: true,
			address: true,
			telephone_no: true,
		}
	},
	form3: {
		...tempRules,
		name: false,
		Owner: {
			name: true,
			address: true,
			telephone_no: true
		},
		length: true,
		breadth: true,
		depth: true,
		vessel_type: true,
		hull_details: {
			builder_name: true,
			builder_address: true,
			hull_material: true,
		},
	},
	"form4": { ...tempRules, },
	"form5": { ...tempRules, },
	"form6": { ...tempRules, },
	"form7": { ...tempRules, },
	"form8": { ...tempRules, },
	"form9": { ...tempRules, },
	"form10": { ...tempRules, },
	"form11": { ...tempRules, },
	"form12": { ...tempRules, },
	"form13": { ...tempRules, },
	"form14": { ...tempRules, },
	"form15": { ...tempRules, },
	"form16": { ...tempRules, },
	"form17": { ...tempRules, },
}

export function getVesselFormRequiredFields(formName: formNames) {
	if (formsRules[formName]) return formsRules[formName];
	return { ...tempRules }
}

export function validateVesselFormRequiredFields() { }
