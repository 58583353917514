import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import SearchLightEquipment from "./SearchLight.equipment";
type SearchEquipmentFormType = {
    search_light: SearchLightEquipment;
    isViewMode: boolean;
};
const SearchEquipmentForm: React.FC<SearchEquipmentFormType> = ({
    search_light,
    isViewMode
}: SearchEquipmentFormType) => (
    <div className="row">
        <div className="col-3">
            <FormGroup>
                <Label>Size</Label>
                <Input
                    disabled={isViewMode}
                    type="number"
                    value={search_light.size}
                    onChange={e => search_light.setSize(e.target.value)}
                    placeholder="Size"
                />
            </FormGroup>
        </div>
        <div className="col-3">
            <FormGroup>
                <Label>Power</Label>
                <Input
                    disabled={isViewMode}
                    type="text"
                    value={search_light.power}
                    onChange={e => search_light.setPower(e.target.value)}
                    placeholder="Power"
                />
            </FormGroup>
        </div>
        <div className="col-3">
            <FormGroup>
                <Label>Number</Label>
                <Input
                    disabled={isViewMode}
                    type="number"
                    value={search_light.size}
                    onChange={e => search_light.setNumber(e.target.value)}
                    placeholder="Number"
                />
            </FormGroup>
        </div>
    </div>
);
export default SearchEquipmentForm;
