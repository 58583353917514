import { vessel_type } from './forms/Vessel.form.types';
import VesselOwner from './models/Vessel.owner';
import VesselAgency from './models/Vessel.agency';
import VesselHull from './models/Vessel.hull';
import VesselPropulsion from './models/Vessel.propulsion';
import VesselEquipment from './models/Vessel.equipment';
import SearchLightEquipment from './forms/equipments/SearchLight.equipment';
import LifeBoyEquipment from './forms/equipments/LifeBoy.equipment';
import NavigationEquipment from './forms/equipments/Navigation.equipment';
import VesselFireAppliance from './forms/Vessel.Fireappliance';
import VesselMachinery from './models/Vessel.machinery';
import VesselAccomodation from './models/Vessel.accomodation';
import VesselFreeboard from './models/Vessel.freeboard';

export default class Vessel {
	setStateChange = null;

	name: string = "";
	Owner: VesselOwner = null;
	length: number = 0;
	breadth: number = 0;
	depth: number = 0;
	vessel_type: 'Passenger' | 'Cargo cum Passenger' | 'Chemical Carrier' | 'Liguid Carrier' | 'Cargo' | 'Tanker' = "Passenger";
	area_of_operation: 'Zone 1' | 'Zone 2' | 'Zone 3' = "Zone 1";
	official_no_of_vessel: number = 0;
	port_registry_of_vessel: string = "";
	tonnage_registered: number = 0;
	tonnage_gross: number = 0;
	category: 'Category A' | 'Category B' = "Category A";
	place_last_survey: string = "";
	date_last_survey: Date = new Date();
	date_of_proposed_visit: Date = new Date();
	place_of_proposed_visit: string = "";
	hull_details: VesselHull = null;
	propulsion_of_engines: VesselPropulsion = null;
	equipment_details: VesselEquipment = null;
	search_lights: SearchLightEquipment = null;
	life_saving_equipment: LifeBoyEquipment = null;
	navigation_equipment: NavigationEquipment = null;
	machinery: VesselMachinery = null;
	freeboard: VesselFreeboard = null;
	accomodation: VesselAccomodation = null;
	fire_fighting: VesselFireAppliance = null;
	navigation_equipment_particulars: string = "";
	communication_equipment_particulars: string = "";
	pollution_control_equipment_particulars: string = "";
	id: string = "";
	createdAt: Date = new Date();
	updatedAt: Date = new Date();
	agency: VesselAgency = null;

	location_of_passengers: location_of_passengers = null;

	constructor(params: vessel_type) {
		if (params) {
			this.name = params.name;
			this.length = params.length;
			this.breadth = params.breadth;
			this.depth = params.depth;
			this.vessel_type = params.vessel_type;
			this.area_of_operation = params.area_of_operation;
			this.official_no_of_vessel = params.official_no_of_vessel;
			this.port_registry_of_vessel = params.port_registry_of_vessel;
			this.tonnage_registered = params.tonnage_registered;
			this.tonnage_gross = params.tonnage_gross;
			this.category = params.category;
			this.place_last_survey = params.place_last_survey;
			this.date_last_survey = params.date_last_survey;
			this.date_of_proposed_visit = params.date_of_proposed_visit;
			this.place_of_proposed_visit = params.place_of_proposed_visit;
			this.propulsion_of_engines = params.propulsion_of_engines;
			this.equipment_details = params.equipment_details;
			this.navigation_equipment = params.navigation_equipment;
			this.location_of_passengers = params.location_of_passengers;
			this.freeboard = params.freeboard;
			this.life_saving_equipment = params.life_saving_equipment;
			this.machinery = params.machinery;
			this.accomodation = params.accomodation;
			this.fire_fighting = params.fire_fighting;
			this.search_lights = params.search_lights;
			this.communication_equipment_particulars = params.communication_equipment_particulars;
			this.navigation_equipment_particulars = params.navigation_equipment_particulars;
			this.pollution_control_equipment_particulars = params.pollution_control_equipment_particulars;
			this.id = params.id;
			this.createdAt = params.createdAt;
			this.updatedAt = params.updatedAt;

			this.Owner = new VesselOwner(params.Owner, () => this.onChange());
			this.agency = new VesselAgency(params.agency, () => this.onChange());
			this.hull_details = new VesselHull(params.hull_details, () => this.onChange());
			this.propulsion_of_engines = new VesselPropulsion(params.propulsion_of_engines, () => this.onChange());
			this.equipment_details = new VesselEquipment(params.equipment_details, () => this.onChange());
			this.search_lights = new SearchLightEquipment(params.search_lights, () => this.onChange());
			this.life_saving_equipment = new LifeBoyEquipment(params.life_saving_equipment, () => this.onChange());
			this.navigation_equipment = new NavigationEquipment(params.navigation_equipment, () => this.onChange());
			this.fire_fighting = new VesselFireAppliance(params.fire_fighting, () => this.onChange());
			this.machinery = new VesselMachinery(params.machinery, () => this.onChange());
			this.accomodation = new VesselAccomodation(params.accomodation, () => this.onChange());
			this.freeboard = new VesselFreeboard(params.freeboard, () => this.onChange());
		} else {
			this.Owner = new VesselOwner(null, () => this.onChange());
			this.agency = new VesselAgency(null, () => this.onChange());
			this.hull_details = new VesselHull(null, () => this.onChange());
			this.propulsion_of_engines = new VesselPropulsion(null, () => this.onChange());
			this.equipment_details = new VesselEquipment(null, () => this.onChange());
			this.search_lights = new SearchLightEquipment(null, () => this.onChange());
			this.life_saving_equipment = new LifeBoyEquipment(null, () => this.onChange());
			this.navigation_equipment = new NavigationEquipment(null, () => this.onChange());
			this.fire_fighting = new VesselFireAppliance(null, () => this.onChange());
			this.machinery = new VesselMachinery(null, () => this.onChange());
			this.accomodation = new VesselAccomodation(null, () => this.onChange());
			this.freeboard = new VesselFreeboard(null, () => this.onChange());
		}
	}

	setName(name) {
		this.name = name;
		this.onChange();
	}
	setOwner(Owner) {
		this.Owner = Owner;
		this.onChange();
	}
	setLength(length) {
		this.length = length;
		this.onChange();
	}
	setBreadth(breadth) {
		this.breadth = breadth;
		this.onChange();
	}
	setDepth(depth) {
		this.depth = depth;
		this.onChange();
	}
	setVessel_type(vessel_type) {
		this.vessel_type = vessel_type;
		this.onChange();
	}
	setArea_of_operation(area_of_operation) {
		this.area_of_operation = area_of_operation;
		this.onChange();
	}
	setOfficial_no_of_vessel(official_no_of_vessel) {
		this.official_no_of_vessel = official_no_of_vessel;
		this.onChange();
	}
	setPort_registry_of_vessel(port_registry_of_vessel) {
		this.port_registry_of_vessel = port_registry_of_vessel;
		this.onChange();
	}
	setTonnage_registered(tonnage_registered) {
		this.tonnage_registered = tonnage_registered;
		this.onChange();
	}
	setTonnage_gross(tonnage_gross) {
		this.tonnage_gross = tonnage_gross;
		this.onChange();
	}
	setCategory(category) {
		this.category = category;
		this.onChange();
	}
	setPlace_last_survey(place_last_survey) {
		this.place_last_survey = place_last_survey;
		this.onChange();
	}
	setDate_last_survey(date_last_survey) {
		this.date_last_survey = date_last_survey;
		this.onChange();
	}
	setAgency(agency) {
		this.agency = agency;
		this.onChange();
	}
	setDate_of_proposed_visit(date_of_proposed_visit) {
		this.date_of_proposed_visit = date_of_proposed_visit;
		this.onChange();
	}
	setPlace_of_proposed_visit(place_of_proposed_visit) {
		this.place_of_proposed_visit = place_of_proposed_visit;
		this.onChange();
	}
	setHull_details(hull_details) {
		this.hull_details = hull_details;
		this.onChange();
	}
	setPropulsion_of_engines(propulsion_of_engines) {
		this.propulsion_of_engines = propulsion_of_engines;
		this.onChange();
	}
	setEquipment_details(equipment_details) {
		this.equipment_details = equipment_details;
		this.onChange();
	}
	setNavigation_equipment(navigation_equipment) {
		this.navigation_equipment = navigation_equipment;
		this.onChange();
	}
	setLocation_of_passengers(location_of_passengers) {
		this.location_of_passengers = location_of_passengers;
		this.onChange();
	}
	setFreeboard(freeboard) {
		this.freeboard = freeboard;
		this.onChange();
	}
	setLife_saving_equipment(life_saving_equipment) {
		this.life_saving_equipment = life_saving_equipment;
		this.onChange();
	}
	setMachinery(machinery) {
		this.machinery = machinery;
		this.onChange();
	}
	setAccomodation(accomodation) {
		this.accomodation = accomodation;
		this.onChange();
	}
	setFire_fighting(fire_fighting) {
		this.fire_fighting = fire_fighting;
		this.onChange();
	}
	setSearch_lights(search_lights) {
		this.search_lights = search_lights;
		this.onChange();
	}
	setCommunication_equipment_particulars(communication_equipment_particulars) {
		this.communication_equipment_particulars = communication_equipment_particulars;
		this.onChange();
	}
	setNavigation_equipment_particulars(navigation_equipment_particulars) {
		this.navigation_equipment_particulars = navigation_equipment_particulars;
		this.onChange();
	}
	setPollution_control_equipment_particulars(pollution_control_equipment_particulars) {
		this.pollution_control_equipment_particulars = pollution_control_equipment_particulars;
		this.onChange();
	}
	setId(id) {
		this.id = id;
		this.onChange();
	}
	setCreatedAt(createdAt) {
		this.createdAt = createdAt;
		this.onChange();
	}
	setUpdatedAt(updatedAt) {
		this.updatedAt = updatedAt;
		this.onChange();
	}
	subscribeForStateChange(setStateFunction) {
		this.setStateChange = setStateFunction;
	}
	onChange() {
		this.setStateChange(new Vessel(this))
	}
}
