import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from '@sentry/browser';

import App from "./App";

Sentry.init({
    dsn: "https://d7bffcc9880a4938abda6f5908be9c55@sentry.io/1827408", release: "inlandvessels@1.0.0"
});

ReactDOM.render(<App />, document.getElementById("root"));
