import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Container} from "reactstrap";
import BelowVesselList from "./subComponents/BelowVesselList";
import {
    CountBelowVessels,
    FetchAllBelowVessels,
    SearchCountBelowVessels,
    SearchFetchAllBelowVessels,
    ClearCountBelowVessel,
    ClearAllBelowVessels
} from "../../../redux/actions/Below12Actions/BelowVesselActions";
import {ClearBelowStatus} from "../../../redux/actions/CommonActions/StatusActions";
import StatusToast from "../../common/StatusToast";

const BelowVesselListLandingComponent = ({belowVesselCount, allBelowVessel, status, dispatch}) => {

    const [userProfile, setUserProfile] = useState(JSON.parse(localStorage.getItem('userProfile')));
    const [page, setPage] = useState(1);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(5);
    const [column, setColumn] = useState("");
    const [searchFor, setSearchFor] = useState("");
    const [actionRequiredList, setActionRequiredList] = useState([]);

    useEffect(() => {
        console.log("ONE");
        if (column.length === 0 && searchFor.length === 0) {
            dispatch(CountBelowVessels(userProfile));
        }

        return function cleanUpCount() {
            dispatch(ClearCountBelowVessel());
        }
    }, []);

    useEffect(() => {
        console.log("TWO");
        if (column.length !== 0 && searchFor.length !== 0) {
            dispatch(SearchFetchAllBelowVessels(userProfile, offset, limit, column, searchFor))
        } else {
            dispatch(FetchAllBelowVessels(userProfile, offset, limit));
        }

        return function cleanUpAll() {
            dispatch(ClearAllBelowVessels());
        }
    }, [offset, limit]);

    useEffect(() => {
        console.log("THREE");
        let tempArray = [];
        if (Object.keys(allBelowVessel).length !== 0) {
            allBelowVessel.map((each, index) => {
                if (each.vesselActivity !== undefined) {
                    if (each.vesselActivity[0].application_status === "submitted") {
                        let currentStage = each.vesselActivity[0].stages_info.current_stage;
                        let approvers = each.vesselActivity[0].stages_info.stages[currentStage].approvers;
                        approvers.map(item => {
                            if (item.user.email === userProfile.username) {
                                tempArray.push(each.id);
                            }
                        });
                    }
                }
                if (allBelowVessel.length === index + 1) {
                    setActionRequiredList(tempArray);
                }
            });
        }
    }, [allBelowVessel]);

    useEffect(() => {
        console.log("FOUR");
        if (Object.keys(status).length !== 0) {
            StatusToast(status);
            dispatch(ClearBelowStatus());
        }
    }, [status]);

    const searchData = () => {
        if (column.length !== 0 && searchFor.length !== 0) {
            dispatch(SearchCountBelowVessels(userProfile, column, searchFor));
            dispatch(SearchFetchAllBelowVessels(userProfile, offset, limit, column, searchFor))
        } else {
            dispatch(CountBelowVessels(userProfile));
            dispatch(FetchAllBelowVessels(userProfile, offset, limit));
        }
    };

    return (
        <Container fluid className="p-0">
            <h1 className="h3 mb-3">Vessel List</h1>
            <div>
                {belowVesselCount.count > 0 && allBelowVessel.length > 0 && allBelowVessel[0].vesselActivity !== undefined ?
                    <BelowVesselList
                        actionRequiredList={actionRequiredList}
                        belowVesselCount={belowVesselCount.count}
                        allBelowVessel={allBelowVessel}
                        page={page}
                        sizePerPage={limit}
                        column={column}
                        searchFor={searchFor}
                        sendPage={(value) => {
                            setPage(value);
                        }}
                        sendOffset={(value) => {
                            setOffset(value)
                        }}
                        sendLimit={(value) => {
                            setLimit(value)
                        }}
                        sendColumn={(value) => {
                            setColumn(value)
                        }}
                        sendSearchFor={(value) => {
                            setSearchFor(value)
                        }}
                        searchData={searchData}
                    />
                    :
                    <h1 className="h3 mb-3">No vessels to display</h1>
                }
            </div>
        </Container>
    )
};

const mapStateToProps = ({belowVesselCount, allBelowVessel, status}) => ({
    belowVesselCount,
    allBelowVessel,
    status
});

const mapDispatchToProps = (dispatch) => ({
    CountBelowVessels,
    FetchAllBelowVessels,
    SearchCountBelowVessels,
    SearchFetchAllBelowVessels,
    ClearCountBelowVessel,
    ClearAllBelowVessels,
    ClearBelowStatus,
    dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(BelowVesselListLandingComponent);
