import React, { useState } from "react";
import Vessel from "../Vessel";
import {
    FormGroup,
    Label,
    Input,
    InputGroup,
    InputGroupAddon,
    Button,
    Badge
} from "reactstrap";
type VesselFreeboardFormType = {
    vessel: Vessel;
	onSave: (updatedVessel: Vessel) => void;
	mode: "Create" | "View" | "Edit" | "EditViaForm";
};
const VesselFreeboardForm: React.FC<VesselFreeboardFormType> = ({
    vessel,
	onSave,
	mode
}: VesselFreeboardFormType) => {
	const isViewMode = mode === "View"
	const { freeboard } = vessel;

    return (
        <div className="row">
            <div className="col-3">
                <FormGroup>
                    <Label>Min Freeboard</Label>
                    <Input
						disabled={isViewMode}
                        type="text"
                        value={freeboard.min_freeboard}
                        onChange={e =>
                            freeboard.setMin_freeboard(e.target.value)
                        }
                        placeholder="Min Freeboard"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Number of Passengers</Label>
                    <Input
						disabled={isViewMode}
                        type="number"
                        value={freeboard.no_of_passengers}
                        onChange={e =>
                            freeboard.setNo_of_passengers(e.target.value)
                        }
                        placeholder="Number of Passengers"
                    />
                </FormGroup>
            </div>
            <div className="col-3">
                <FormGroup>
                    <Label>Cargo</Label>
                    <Input
						disabled={isViewMode}
                        type="number"
                        value={freeboard.cargo}
                        onChange={e => freeboard.setCargo(e.target.value)}
                        placeholder="Cargo"
                    />
                </FormGroup>
            </div>
        </div>
    );
};
export default VesselFreeboardForm;
